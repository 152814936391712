import styled from 'styled-components';
import { TableRowColumn } from 'material-ui/Table';

const CustomTableRowColumn = styled(TableRowColumn)`
  color: ${props => props.theme.palette.white}!important;
  backgroundColor: ${props => props.theme.palette.primary}!important;
  fontSize: 1em!important;
`;

export default CustomTableRowColumn;
