import styled from 'styled-components';
import RolsButton from '../../../components/Button';

const Button = styled(RolsButton)`
  height: 36px!important;
  marginTop: 10px!important;
  lineHeight: 1em!important;
  borderRadius: 1em!important;
  backgroundColor: #4CB384!important;
  border: 1px solid #4CB384!important;
  color: ${props => props.theme.palette.white}!important;

  span {
    fontWeight: inherit!important;
  }

  &:hover {
    backgroundColor: #30795A!important;
    border: 1px solid #30795A!important;
    color: ${props => props.theme.palette.white}!important;
  }
`;

export default Button;
