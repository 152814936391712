import Immutable from 'seamless-immutable';
import { REHYDRATE } from 'redux-persist/constants';
import { getDefaultEndpoint } from '../../app/api/routes';
import actionTypes from './actionTypes';

const themes = [
  {
    palette: {
      // cathay pacific
      primaryPastel: '#d9f2eb',
      primaryPastelLight: '#ecf8f5',
      primary: '#006564',
      primaryDark: '#005251',
      primaryLightest: '#edf7f7',
      secondary: '#c2262e',
      white: '#FFFFFF',
      greyLight: '#ebedec',
      grey: '#b3b3b3',
      black: '#4c4c4c',
    },
    font: {
      small: '10px',
      default: '14px',
      large: '24px',
      extraLarge: '34px',
    },
  },
  {
    // cathay dragon
    palette: {
      primaryPastel: '#f78789',
      primaryPastelLight: '#f4beca',
      primary: '#780821',
      primaryDark: '#480513',
      primaryLightest: '#fde7ec',
      secondary: '#c2262e',
      white: '#FFFFFF',
      greyLight: '#ebedec',
      grey: '#d9d9d9',
      black: '#4c4c4c',
    },
    font: {
      small: '10px',
      default: '14px',
      large: '24px',
      extraLarge: '34px',
    },
  } /* , { // Communicator
  palette: {
    primaryPastel: '#EDF6F9',
    primaryPastelLight: '#EDF6F9',
    primary: '#00AEE0',
    primaryDark: '#0091BD',
    primaryLightest: '#00AEE0',
    secondary: '#B64F3B',
    white: '#FFFFFF',
    greyLight: '#D3D3D3',
    grey: '#d9d9d9',
    black: '#1F2120',
  },
  font: {
    default: '14px',
    large: '24px',
  },
},*/,
];

const themeNames = [
  { 0: 'Cathay Pacific' },
  { 1: 'Cathay Dragon' },
  // { 2: 'Communicator' },
];

const INITIAL_STATE = {
  webServiceUrl: localStorage.getItem('webServiceUrl') || getDefaultEndpoint(),
  theme: themes[0],
  themeValue: 0,
  themesList: themeNames,
};

const settingsReducer = (state = Immutable.from(INITIAL_STATE), action) => {
  switch (action.type) {
    case actionTypes.WEBSERVICE_URL_SET:
      localStorage.setItem('webServiceUrl', action.payload.url);
      return state.set('webServiceUrl', action.payload.url);
    case actionTypes.THEME_SET:
      return state
        .set('theme', themes[action.payload.theme])
        .set('themeValue', action.payload.theme);
    case actionTypes.LANGUAGE_SET:
      return state.set('languageId', action.payload);
    case REHYDRATE:
      return Immutable.from(action.payload.settings ? action.payload.settings : INITIAL_STATE);
    default:
      return state;
  }
};

export default settingsReducer;
