import React from 'react';
import LoadAuditAppBar from "../component/LoadAuditAppBar";
import LoadAuditDistributionPage from "./LoadAuditDistributionPage";
import SuperHeader from "../../../components/Table/SuperHeader";
import TranslatedLabel from "../../../components/TranslatedLabel/TranslatedLabel";
import styled from "styled-components";
import FlightPage from "../../common/containers/FlightPage";
import Audit2ExcelButton from "../component/Audit2ExcelButton";

const NAVBAR_HEIGHT = 71;
const TITLE_HEIGHT = 53;
const APPBAR_HEIGHT = 48;

class LoadAuditPage extends React.Component {
  state = {
    height: 0,
    distribution: 1,
    header: {},
  }

  handleChangeDistribution = (distribution) => {
    this.setState({distribution: distribution,});
  }

  componentDidMount() {
    window.onresize = this.resize;
    this.resize();
  }

  resize = () => {
    this.setState({height: window.innerHeight - NAVBAR_HEIGHT});
  };

  handleGetFlightHeader = header => {
    this.setState({ header });
  };

  render() {
    const { height, } = this.state;
    const loadSequenceDistributionPageHeight = height - APPBAR_HEIGHT - TITLE_HEIGHT;
    return (
      <Pane height={height}>
        <ListPane>
          <FlightPage
              onGetFlightHeader={this.handleGetFlightHeader}
          />
        </ListPane>
        <DetailPane>
            <SuperHeader style={{display: "flex",  width: "100%"}}>
                <HeaderPane>
                    <TranslatedLabel
                        keyword={'communicator.appBar.loadHistory'}
                        defaultValue={'Load Audits'}
                    />
                </HeaderPane>
                <Audit2ExcelButton
                    distribution={this.state.distribution}
                />
            </SuperHeader>
            <LoadAuditAppBar
                distribution={this.state.distribution}
                onChangeDistribution={this.handleChangeDistribution}
            />
            <LoadAuditDistributionPage
                distribution={this.state.distribution}
                header={this.state.header}
                height={loadSequenceDistributionPageHeight}
            />
        </DetailPane>
      </Pane>);
  }
}

const Pane = styled.div`
    display: flex;
    flex-direction: row;
    height: ${props => props.height}px;
    width: 100%;
    overflow: hidden;
`;

const ListPane = styled.div`
    flex: 1.15;
    height: 100%;
    background: ${props => props.theme.palette.primaryPastel};
    borderRight: 5px solid ${props => props.theme.palette.primary};
    overflow: auto;
`;

const DetailPane = styled.div`
    flex: 3.85;
    height: 100%;
    width: 100%;
    overflow: hidden;
`;

const HeaderPane = styled.div`
    width: 100%;
    overflow: hidden;
    margin: auto;
`;

export default LoadAuditPage;