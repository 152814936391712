import PropTypes from 'prop-types';
import React from 'react';
import MIcon from '../../../../../components/MIcon';
import PrefixIcon from '../PrefixIcon';
import FlightItem from "./FlightItem";

const FlightOpenItems = ({ flights, onItemClick, selectedFlight }) =>
  <div>
    <PrefixIcon>
      <MIcon>airplanemode_active</MIcon>
    </PrefixIcon>
    {flights && flights.length > 0 &&
      flights.map(
        flight =>
          flight.message > 0 &&
          <FlightItem
            onItemClick={onItemClick}
            key={flight.flightId}
            flightInfo={flight}
            selected={flight.flightId === selectedFlight}
          />
      )}
  </div>;

FlightOpenItems.propTypes = {
  system: PropTypes.object,
  flights: PropTypes.array,
  onItemClick: PropTypes.func,
  selectedFlight: PropTypes.string,
};

export default FlightOpenItems;
