import React from 'react';
import LoadSequenceAppBar from "../component/LoadSequenceAppBar";
import LoadSequenceDistributionPage from "./LoadSequenceDistributionPage";
import SuperHeader from "../../../components/Table/SuperHeader";
import TranslatedLabel from "../../../components/TranslatedLabel/TranslatedLabel";
import styled from "styled-components";
import FlightPage from "../../common/containers/FlightPage";
import PropTypes from "prop-types";
import {compose} from "redux";
import injectApiRoutes from "../../../app/api/injectApiRoutes";
import reduxFetch from "react-redux-fetch";
import commonSelectors from "../../common/selectors";
import securitySelectors from "../../security/selectors";
import globals, {RefreshQualifier} from "../../../app/globals";
import adapter from "../../../app/api/adapter";
import * as dateHelpers from "../../../app/utils/dateHelpers";
import flightService from "../../common/domain/flight";
import momentPropTypes from "react-moment-proptypes";
import actions from "../../common/actions";

const NAVBAR_HEIGHT = 71;
const APPBAR_HEIGHT = 48;

class LoadSequencePage extends React.Component {
    static propTypes = {
        flightsFetch: PropTypes.object,
        dispatch: PropTypes.func.isRequired,
        dispatchFlightsPost: PropTypes.func,
        isCurrent: PropTypes.bool,
        departureTime: momentPropTypes.momentObj,
        airport: PropTypes.object,
    }
    state = {
        height: 0,
        distribution: 1,
        header: {},
    }

    handleChangeDistribution = (distribution) => {
        this.setState({distribution: distribution,});
    }

    componentDidMount() {
        window.onresize = this.resize;
        this.resize();
    }

    resize = () => {
        this.setState({height: window.innerHeight - NAVBAR_HEIGHT});
    };

    handleGetFlightHeader = header => {
        this.setState({header});
    };

    fetchFlights = refetchQualifier => (showLoader, ) => {
        const {departureTime, isCurrent, airport, dispatchFlightsPost} = this.props;
        const finalDate = departureTime;
        const finalIsCurrent = flightService.shouldShowCurrentToggle(finalDate)
            ? isCurrent : false
        const startOfDay = flightService.shouldFetchFlightsFromStartOfDay(finalDate);
        const airportCode = airport?airport.iata:'';
        dispatchFlightsPost(
            finalDate,
            startOfDay,
            showLoader,
            finalIsCurrent,
            airportCode,
            refetchQualifier
        );
    };

    setFlight = (flight) => {
        return actions.setFlight(flight);
    }


    onFlightDeleted = () => {
        const {dispatch,} = this.props;
        this.fetchFlights(RefreshQualifier.MANUAL)(true);
        dispatch(this.setFlight({}));
    }

    render() {
        const {height} = this.state;
        const loadSequenceDistributionPageHeight = height - APPBAR_HEIGHT;
        return (
            <Pane height={height}>
                <ListPane>
                    <FlightPage
                        onGetFlightHeader={this.handleGetFlightHeader}
                    />
                </ListPane>
                <DetailPane>
                    <SuperHeader>
                        <TranslatedLabel
                            keyword={'communicator.appBar.loadSequence'}
                            defaultValue={'Load Sequence'}
                        />
                    </SuperHeader>
                    <LoadSequenceAppBar
                        distribution={this.state.distribution}
                        onChangeDistribution={this.handleChangeDistribution}
                    />
                    <LoadSequenceDistributionPage
                        distribution={this.state.distribution}
                        header={this.state.header}
                        height={loadSequenceDistributionPageHeight}
                        onFlightDeleted={this.onFlightDeleted}
                    />
                </DetailPane>
            </Pane>);
    }
}

const Pane = styled.div`
    display: flex;
    flex-direction: row;
    height: ${props => props.height}px;
    width: 100%;
    overflow: hidden;
`;

const ListPane = styled.div`
    flex: 1.15;
    height: 100%;
    background: ${props => props.theme.palette.primaryPastel};
    borderRight: 5px solid ${props => props.theme.palette.primary};
    overflow: auto;
`;

const DetailPane = styled.div`
    flex: 3.85;
    height: 100%;
    width: 100%;
    overflow: hidden;
`;

const getDate = (date, startOfDay) => {
    return startOfDay
        ? adapter.momentDateToServerStartOfDay(date)
        : adapter.momentDateToServerDate(date);
}

export const mapPropToDispatchToProps = props => [
    {
        resource: 'flights',
        method: 'POST',
        /**
         * @param date Moment: The date to fetch flights forbid
         * @param startOfDay Bool: Should the date which will be sent to the server
         *     start from 00:00, or current time?
         @see domain/flight
         * @param showLoader Bool: UI flag. Is set to FALSE if request is a refetch
         *     of the same date. Is set to TRUE if request is another date than the
         *     previous.
         * @param isCurrent Bool: If true, server will return response between an
         *     interval, if false, server will return the full day.
         * @param refreshQualifier constant RefreshQualifier: MANUAL when user
         *     invokes this call. AUTO when call is invoked by polling
         */
        request: (
            date,
            startOfDay = true,
            showLoader = false,
            isCurrent = false,
            airportCode = '',
            refreshQualifier = RefreshQualifier.MANUAL
        ) => ({
            url: props.apiRoutes.getFlights(),
            body: {
                accessToken: props.accessToken,
                date: getDate(date, startOfDay),
                localTimeOffset: dateHelpers.currentTimezoneOffset(getDate(date, startOfDay)),
                current: isCurrent,
                airportCode: airportCode,
                passengerFlights: true,
                freighterFlights: true,
                application: globals.applicationName,
                refreshQualifier,
            },
            meta: {
                showLoader,
            },
        }),
    },
];

const mapStateToProps = state => ({
    flightId: commonSelectors.getFlightId(state),
    departureTime: commonSelectors.getDepartureTime(state),
    isCurrent: commonSelectors.getCurrentFlag(state),
    airport: commonSelectors.getAirport(state),
    userFrequency: securitySelectors.getUserFrequency(state),
    userAirportCode: securitySelectors.getAirport(state),
});

const enhance = compose(
    injectApiRoutes,
    reduxFetch(mapPropToDispatchToProps, mapStateToProps)
);

export default enhance(LoadSequencePage);