export const MARGIN = 10;

const getList = (value) => value?value.nodes():[];

const getNode = (value) => value.node();

export const getMinX = (allRects) => {
    return getList(allRects).reduce(
        (min, rect) => {
            const boundingClientRect = rect.getBoundingClientRect();
            const x = boundingClientRect.x;
            return (x < min ? x : min)
        }, Number.MAX_VALUE);
}

export const getMaxX = (allRects) => {
    return getList(allRects).reduce(
        (max, rect) => {
            const boundingClientRect = rect.getBoundingClientRect();
            const x = boundingClientRect.right;
            return (x > max ? x : max)
        }, Number.MIN_VALUE);
}

export const getMinWidth = (allRects) => {
    return getList(allRects).reduce(
        (minWidth, rect) => {
            const boundingClientRect = rect.getBoundingClientRect();
            const width = boundingClientRect.width;
            return (width < minWidth ? width : minWidth)
        }, Number.MAX_VALUE);
}

export const getMinY = (allRects) => {
    return getList(allRects).reduce(
        (min, rect) => {
            const boundingClientRect = rect.getBoundingClientRect();
            const y = boundingClientRect.y;
            return (y < min ? y : min)
        }, Number.MAX_VALUE);
}

export const getMaxY = (allRects) => {
    return getList(allRects).reduce(
        (max, rect) => {
            const boundingClientRect = rect.getBoundingClientRect();
            const y = boundingClientRect.bottom;
            return (y > max ? y : max)
        }, Number.MIN_VALUE);
}

export const getMaxAllRectsX = (allRectPositions, allRectDoors, allLines) => {
    return Math.max(getMaxX(allRectPositions), getMaxX(allRectDoors), getMaxX(allLines));
}

export const getMinAllRectsX = (allRectPositions, allRectDoors, allLines) => {
    return Math.min(getMinX(allRectPositions), getMinX(allRectDoors), getMinX(allLines));
}

export const getMaxAllRectsY = (allRectPositions, allRectDoors, allLines) => {
    return Math.max(getMaxY(allRectPositions), getMaxY(allRectDoors), getMaxY(allLines));
}

export const getMinAllRectsY = (allRectPositions, allRectDoors, allLines) => {
    return Math.min(getMinY(allRectPositions), getMinY(allRectDoors), getMinY(allLines));
}

const getZoomOutKX = (svgElement, allRectPositions, allRectDoors, allLines) => {
    const boundingClientRect = getNode(svgElement).getBoundingClientRect();
    const max = getMaxAllRectsX(allRectPositions, allRectDoors, allLines);
    const min = getMinAllRectsX(allRectPositions, allRectDoors, allLines);
    const maxSize = max - min;
    return maxSize !== 0 ? (boundingClientRect.width - 2 * MARGIN) / maxSize : 1;
}

const getZoomOutKY = (svgElement, allRectPositions, allRectDoors, allLines) => {
    const boundingClientRect = getNode(svgElement).getBoundingClientRect();
    const max = getMaxAllRectsY(allRectPositions, allRectDoors, allLines);
    const min = getMinAllRectsY(allRectPositions, allRectDoors, allLines);
    const maxSize = max - min;
    return maxSize !== 0 ? (boundingClientRect.height - 2 * MARGIN) / maxSize : 1;
}

const getZoomOutK = (svgElement, allRectPositions, allRectDoors, allLines) => {
    return Math.min(
        getZoomOutKX(svgElement, allRectPositions, allRectDoors, allLines),
        getZoomOutKY(svgElement, allRectPositions, allRectDoors, allLines),
    );
}

const getZoomInK = (allRectPositions, minWidthToShowData) => {
    return minWidthToShowData/getMinWidth(allRectPositions);
}

export const getKs = (svgElement, allRectPositions, allRectDoors, allLines,
                     minWidthToShowData) => {
    const zoomOutK = getZoomOutK(svgElement, allRectPositions, allRectDoors, allLines);
    const zoomInK = getZoomInK(allRectPositions, minWidthToShowData);
    return {minK: Math.min(zoomOutK, zoomInK), maxK: Math.max(zoomOutK, zoomInK)};
}

export const getTopElement = (svgElement) => {
    const boundingClientRect = getNode(svgElement).getBoundingClientRect();
    return boundingClientRect.top;
}

export const getWidthElement = (svgElement) => {
    const boundingClientRect = getNode(svgElement).getBoundingClientRect();
    return boundingClientRect.width;
}

export const getHeightElement = (svgElement) => {
    const boundingClientRect = getNode(svgElement).getBoundingClientRect();
    return boundingClientRect.height;
}

export const getXElement = (svgElement) => {
    const boundingClientRect = getNode(svgElement).getBoundingClientRect();
    return boundingClientRect.x;
}

export const getLeftElement = (svgElement) => {
    const boundingClientRect = getNode(svgElement).getBoundingClientRect();
    return boundingClientRect.left;
}

export const getYElement = (svgElement) => {
    const boundingClientRect = getNode(svgElement).getBoundingClientRect();
    return boundingClientRect.y;
}

export const getUldNumber = (loadStep) => {
    if (loadStep && loadStep.uld) {
        const { name } = loadStep.uld.uldNumber;
        return name;
    }
    return null;
}

const getTstUldNr = (loadStep) => {
    if (loadStep.uld) {
        return getUldNumber(loadStep);
    }
    if (loadStep.noFit) {
        return 'noFit';
    }
    return null;
}

export const addExtraTags = (svgElement, loadStep, allPositionLoadSteps) => {
    svgElement.attr('data-tst-role', 'loadButton');
    svgElement.attr('data-tst-pos', loadStep.position.positionName);
    if (window.getLoadStepCompactLabel) {
        svgElement.attr('data-tst-uldnr', window.getLoadStepCompactLabel(loadStep));
    } else {
        svgElement.attr('data-tst-uldnr', getTstUldNr(loadStep));
    }
    if (loadStep.loadSequence) {
        svgElement.attr('data-tst-stepnr', loadStep.loadSequence.sequence);
    }
    if (window.getSearchUldClass) {
        svgElement.attr('data-tst-status', window.getSearchUldClass(loadStep));
    }
    if (allPositionLoadSteps) {
        svgElement.attr('indexLoadSteps', allPositionLoadSteps.indexOf(loadStep));
        svgElement.attr('numberLoadSteps', allPositionLoadSteps.length);
    }
}