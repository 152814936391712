import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import get from 'lodash/get';
import styled from 'styled-components';

const getColor = (props) => {
    return props.color === 'primary' ? `${props.theme.palette.white}!important` : 'initial';
}

export const StyledAutocomplete = styled(Autocomplete)`
  width: 100%;

  & label {
    color: ${props => getColor(props)};
  }
  & input {
    color: ${props => getColor(props)};
  }
  & button {
    color: ${props => getColor(props)};
  }
`;

class Dropdown extends React.Component {
    static propTypes = {
        label: PropTypes.any,
        items: PropTypes.array,
        item: PropTypes.object,
        getItemLabel: PropTypes.func,
        onItemChange: PropTypes.func,
        color: PropTypes.string,
    };

    handleKeyDown = (event) => {
        if (event.keyCode===9||event.keyCode===13) {
            const value = event.target.value?event.target.value.toUpperCase():null;
            const {items, } = this.props;
            const selecteds = items.filter(item => this.getOptionLabel(item).indexOf(value)>=0 )
            const item = selecteds.length>0?selecteds[0]:null;
            this.handleItemChange(event, item);
        }
    }

    handleItemChange = (event, newValue) => {
        const {onItemChange} = this.props;
        if (onItemChange) {
            onItemChange(newValue);
        }
    }

    shouldComponentUpdate(nextProps) {
        const nextPropsItems = get(nextProps, 'items');
        const propsItems = get(this.props, 'items');
        const nextPropsItem = this.getOptionLabel(nextProps.item);
        const propsItem = this.getOptionLabel(this.props.item);
        return nextPropsItems!==propsItems ||
            nextPropsItem!==propsItem;
    }

    getOptionLabel = (option) => {
        const {getItemLabel} = this.props;
        if (getItemLabel) {
            return getItemLabel(option);
        }
        return option;
    }

    render() {
        const {items, item, label, color} = this.props;
        return <Wrapper>
            <StyledAutocomplete
                id="combo-box-items"
                color={color}
                options={items}
                getOptionSelected={(option, value) =>
                    option && value && this.getOptionLabel(option) === this.getOptionLabel(value)}
                getOptionLabel={this.getOptionLabel}
                autoHighlight
                selectOnFocus
                blurOnSelect
                handleHomeEndKeys
                renderInput={(params) => {
                    return (
                        <TextField {...params}
                                   label={label}
                                   variant={'outlined'}
                                   onKeyDown={this.handleKeyDown}
                                   autoComplete={'off'}
                                   data-tst-tag={label}
                        />)
                }}
                value={item}
                onChange={this.handleItemChange}
            />
        </Wrapper>;
    }
}

const Wrapper = styled.div`
  display: flex;
  width: 80%;
  margin: 10px auto;
`;

export default Dropdown;