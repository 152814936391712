import Immutable from 'seamless-immutable';
import actionTypes from './actionTypes';

const INITIAL_STATE = {
  initialized: false,
  hasPendingActions: false,
  submittingNewMsg: false,
};

const appReducer = (state = Immutable.from(INITIAL_STATE), action) => {
  switch (action.type) {
    case actionTypes.APP_INITIALIZED:
      return state.set('initialized', true);
    case actionTypes.HAS_PENDING_ACTIONS:
      return state.set('hasPendingActions', action.payload);
    case actionTypes.SUBMITTING_NEW_MSG:
      return state.set('submittingNewMsg', action.payload);
    default:
      return state;
  }
};

export default appReducer;
