import selectors from '../selectors';
import { CallState } from '../constants';
import actions from '../actions';

const toMiliseconds = seconds => seconds * 1000;

const betweenStates = [
  { state: CallState.REQUEST_CALL, waitDuration: toMiliseconds(20) },
  { state: CallState.ANSWERING_CALL, waitDuration: toMiliseconds(20) },
  { state: CallState.WAITING_FOR_ANSWER, waitDuration: toMiliseconds(10) },
];

let timeout;

const errorMiddleware = store => next => action => {
  const prevSocketState = selectors.getSocketState(store.getState());
  next(action);
  const newSocketState = selectors.getSocketState(store.getState());
  if (prevSocketState !== newSocketState) {
    clearTimeout(timeout);
    const foundBetweenState = betweenStates.find(
      betweenState => betweenState.state === newSocketState
    );
    if (foundBetweenState) {
      timeout = setTimeout(() => {
        store.dispatch(actions.error());
      }, foundBetweenState.waitDuration);
    }
  }
};

export default errorMiddleware;
