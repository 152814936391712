export default {
  applicationName: 'COMMUNICATOR',
  errors: {
    invalidAccessToken: 'invalid access token used', //e.g.722251e6-772c-426e-bf3c-4e657d0122f0
    userLocked: 'User has been locked',
    failedToFetch: 'Failed to fetch',
  },
  errorNumbers: {
    INVALID_LOGON: 1,
    INVALID_FLIGHT: 2,
    INVALID_DOOR: 3,
    INVALID_LOCATION: 4,
    INVALID_ULD_ENTRY: 5,
    INVALID_BULK_ENTRY: 6,
    INVALID_ACCESS_TOKEN: 7,
    INVALID_STATION: 8,
    INVALID_DATE: 9,
    INVALID_REGISTRATION: 10,
    ACCESS_VIOLATION: 11,
    INVALID_USER: 12,
    ILLEGAL_STATE: 13,
    VALIDATION_EXCEPTION: 14,
    INVALID_PIN_CODE: 15,
    CANNOT_ENGAGE: 16,

    SABLE_BUSINESS_EXCEPTION: 500,
    DIFFERENT_HTTP_STATUS: 998,
    UNKNOWN: 999,
  },
};

export const RefreshQualifier = {
  MANUAL: 'MANUAL',
  AUTO: 'AUTO',
};
