const isAuthenticated = state => state.security.authenticated;
const isDashboard = state =>  state.security.isDashboard;
const isRols = state =>  state.security.isRols;
const getToken = state => state.security.user.accessToken;
const getUsername = state => state.security.username;
const getOnlineCode = state => state.security.onlineCode;
const getAirport = state => state.security.user.airport;
const isLoadSequence = state => state.security.isLoadSequence;
const isLoadAudit = state => state.security.isLoadAudit;
const isChangePsswrdOnNextLogin = state => state.security.changePsswrdOnNextLogin;
const getPrivacyNoticeUrl = state => state.security.privacyNoticeUrl;

/*
TODO: Remove HC frequency below
const getUserFrequency = state => state.security.user.config.frequency;
 */

const freq = { chatMessages: 5, flights: 60, openItems: 10, contactList: 10 };
const getUserFrequency = state =>
  state.security.user.config !== undefined ? state.security.user.config.frequency : freq;

export default {
  isAuthenticated,
  isDashboard,
  isRols,
  getToken,
  getUsername,
  getOnlineCode,
  getAirport,
  getUserFrequency,
  isLoadSequence,
  isLoadAudit,
  isChangePsswrdOnNextLogin: isChangePsswrdOnNextLogin,
  getPrivacyNoticeUrl,
};
