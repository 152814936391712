import styled from 'styled-components';

const ItemContainer = styled.div`
  fontSize: ${props => props.theme.font.default};
  overflow: hidden;
  padding: 10px;
  backgroundColor: ${props => (props.selected ? props.theme.palette.primaryDark : '')};
  color: ${props => props.theme.palette.white};

  &:hover {
    backgroundColor: ${props => props.theme.palette.primaryDark};
    cursor: pointer;
  }

  & > div {
    float: left;
  }
`;

export default ItemContainer;
