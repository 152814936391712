import styled from 'styled-components';

const RightPane = styled.aside`
  flex: 1;
  paddingTop: 48px;
  background: linear-gradient(
    to bottom,
    ${props => props.theme.palette.primary} 0%,
    ${props => props.theme.palette.primaryDark} 100%
  );
  color: ${props => props.theme.palette.white};
  overflow-y: auto;
  overflow-x: hidden;
`;

export default RightPane;
