export const isOnLoad = (props, onLoad) => {
    const {distribution, } = props;
    return (distribution===(onLoad?1:2));
}

export const isFreighter = (props, freighter) => {
    const {flight, } = props;
    const type = freighter?"FCTFREIGHTER":"FCTPASSENGER";
    return (flight.type===type);
}

export const isFlightOnLoadFreighter = (props, onLoad, freighter) => {
    return isOnLoad(props, onLoad) && isFreighter(props, freighter);
}
