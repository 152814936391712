import actionTypes from './actionTypes';

const setFilter = (timeFrom, dateTo, timeTo, distribution) => ({
  type: actionTypes.FILTER,
  payload: {
    timeFrom,
    dateTo,
    timeTo,
    distribution,
  },
});

const setDateTo = dateTo => ({
  type: actionTypes.SET_DATE_TO,
  payload: {
    dateTo,
  },
});

const setTimeFrom = timeFrom => ({
  type: actionTypes.SET_TIME_FROM,
  payload: {
    timeFrom,
  },
});

const setTimeTo = timeTo => ({
  type: actionTypes.SET_TIME_TO,
  payload: {
    timeTo,
  },
});

const setDistribution = distribution => ({
  type: actionTypes.SET_DISTRIBUTION,
  payload: {
    distribution,
  },
});

export default {
  setFilter,
  setDateTo,
  setTimeFrom,
  setTimeTo,
  setDistribution,
};
