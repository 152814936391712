export default function calculateSvgPosition({ coordinates: { x1, y1, x2, y2 } }, horizontal) {
    const invertedY1 = y1.value * -1;
    const invertedY2 = y2.value * -1;

    const convertedContainer = {
        upperLeft: {
            x: x1.value > x2.value ? x2.value : x1.value,
            y: invertedY1 > invertedY2 ? invertedY2 : invertedY1,
        },
        lowerRight: {
            x: x1.value > x2.value ? x1.value : x2.value,
            y: invertedY1 > invertedY2 ? invertedY1 : invertedY2,
        },
    };

    const deltaXContainer = convertedContainer.lowerRight.x - convertedContainer.upperLeft.x;
    const deltaYContainer = convertedContainer.lowerRight.y - convertedContainer.upperLeft.y;

    return !horizontal?{
        width: deltaYContainer,
        height: deltaXContainer,
        x: convertedContainer.upperLeft.y,
        y: convertedContainer.upperLeft.x,
    }:{
        width: deltaXContainer,
        height: deltaYContainer,
        x: convertedContainer.upperLeft.x,
        y: -convertedContainer.upperLeft.y - deltaYContainer,
    };
}