import { combineReducers } from 'redux';
import { reducer as fetchReducer } from 'react-redux-fetch';
import moment from 'moment';
import omit from 'lodash/omit';
import security from './security';
import app from './app';
import settings from './settings';
import notification from './notification';
import call from './call';
import filter from './dashboard';
import commonReducer from './common/reducers/commonReducer';
import actionTypes from './communicator/actionTypes';

const repositoryReducer = (state, action) => {
  // Remove older messages that are fulfilled and older than 2 minutes
  if (action.type === actionTypes.MESSAGES_CLEARED) {
    const subtractedNow = moment().subtract(2, 'minutes');

    const messagesToDelete = Object.entries(state)
      .filter(([key]) => key.includes('messages'))
      .filter(([, promiseState]) => {
        return promiseState.fulfilled && moment(promiseState.value.utc).isBefore(subtractedNow);
      })
      .map(([key]) => key);

    const newState = omit(state, messagesToDelete);
    return fetchReducer(newState, action);
  }
  return fetchReducer(state, action);
};

export default combineReducers({
  app: app.reducer,
  repository: repositoryReducer,
  security: security.reducer,
  common: commonReducer,
  call: call.reducer,
  settings: settings.reducer,
  notification: notification.reducer,
  filter: filter.reducer.filter,
});
