const getWebServiceUrl = state => state.settings.webServiceUrl;
const getTheme = state => state.settings.theme;
const getThemeValue = state => state.settings.themeValue;
const getThemesList = state => state.settings.themesList;
const getLanguageId = state => state.settings.languageId;

export default {
  getWebServiceUrl,
  getTheme,
  getThemeValue,
  getThemesList,
  getLanguageId,
};
