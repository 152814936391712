import styled from 'styled-components';

const SuperHeader = styled.div`
  textAlign: center;
  color: ${props => props.theme.palette.white};
  backgroundColor: ${props => props.theme.palette.primary};
  padding: 8px;
  fontSize: 1.1em;
`;

export default SuperHeader;
