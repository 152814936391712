import React from "react";
import BottomWrapper from "../../BottomWrapper";
import Method from "./Method";
import MethodButton from "./MethodButton";
import PropTypes from "prop-types";
import StyledPane from "../../StyledPane";
import DeleteButton from "./DeleteButton";
import ForceDeleteButton from "./ForceDeleteButton";

class MethodPanel extends React.Component {
    static propTypes = {
        flight: PropTypes.object.isRequired,
        distribution: PropTypes.number.isRequired,
        onLoadSequenceAction: PropTypes.func.isRequired,
        onDeleteFlight: PropTypes.func.isRequired,
    };

    state = {
        method: null,
        pending: false,
    };

    componentDidMount() {
        this.updateState();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.flight !== this.props.flight) {
            this.updateState();
        }
    }

    updateState = () => {
        const method = this.props.flight.currentLoadSequenceMethod;
        this.setState({
            method: method,
            pending: false, } );
    }

    handleChangeMethod = (method) => {
        this.setState({method: method,});
    }

    handleLoadSequence = (loadSequenceMethod) => {
        this.setState({pending: true,});
        const {onLoadSequenceAction,} = this.props;
        if (onLoadSequenceAction) {
            onLoadSequenceAction(loadSequenceMethod);
        }
    }

    isForceNeeded = () => {
        const {flight} = this.props;
        return (flight.hasConfirmedUldsInAnyLoadSequence);
    }

    deleteFlight = () => {
        this.setState({pending: true,});
        const {onDeleteFlight,} = this.props;
        if (onDeleteFlight) {
            onDeleteFlight();
        }
    }

    render() {
        const {flight, distribution,} = this.props;

        const force = this.isForceNeeded();

        return <StyledPane>
            <BottomWrapper style={{width: '100%', margin: '10'}}>
                <BottomWrapper style={{flex: 1}}>
                    <Method
                        flight={flight}
                        method={this.state.method}
                        onChangeMethod={this.handleChangeMethod}/>
                </BottomWrapper>
                <BottomWrapper style={{flex: 4, justifyContent: 'right'}}>
                    <MethodButton
                        keyword={'reAssignSequence'}
                        defaultValue={'(Re)Assign sequence'}
                        method={this.state.method}
                        action={LOAD_SEQUENCE_ACTION.GENERATE}
                        distribution={distribution}
                        onClick={this.handleLoadSequence}
                        pending={this.state.pending}
                    />
                    <MethodButton
                        keyword={'resetSequence'}
                        defaultValue={'Reset sequence'}
                        method={this.state.method}
                        action={LOAD_SEQUENCE_ACTION.RESET}
                        distribution={distribution}
                        onClick={this.handleLoadSequence}
                        pending={this.state.pending}
                    />
                    {force ?
                    <ForceDeleteButton
                        keyword={'forceDeleteFlight'}
                        defaultValue={'Force Delete Flight'}
                        action={LOAD_SEQUENCE_ACTION.DELETE_FLIGHT}
                        distribution={distribution}
                        onClick={this.deleteFlight}
                        pending={this.state.pending}
                        no_accessValue={'Delete Flight'}
                        no_accessKeyword={'deleteFlight'}
                    />
                    :
                    <DeleteButton
                        keyword={'deleteFlight'}
                        defaultValue={'Delete Flight'}
                        action={LOAD_SEQUENCE_ACTION.DELETE_FLIGHT}
                        distribution={distribution}
                        onClick={this.deleteFlight}
                        pending={this.state.pending}
                    />}
                </BottomWrapper>
            </BottomWrapper>
        </StyledPane>
    }
}

export const LOAD_SEQUENCE_ACTION = {GENERATE: 'GENERATE', RESET: 'RESET', DELETE_FLIGHT: 'DELETE_FLIGHT'};

export default MethodPanel;