import PropTypes from 'prop-types';
import React from 'react';
import Link from 'react-router-dom/Link';
import Bar from './Bar';
import Title from './Title';
import SubTitle from './SubTitle';
import Menu from './Menu';
import TranslatedLabel from "../TranslatedLabel/TranslatedLabel";

const AppItem = ({title, subTitle, to, testTag}) => {
    return title ?
        <Link data-tst-tag={testTag}
            to={to} style={{textDecoration: 'none',
            position: 'relative', marginRight: '20px'}}>
            <Title>{title}</Title>
            {subTitle?<SubTitle>{subTitle}</SubTitle>:''}
        </Link> : ''
};

const getLabel = (access, keyword, defaultValue) => {
    return access?<TranslatedLabel
        keyword={`communicator.appBar.${keyword}`}
        defaultValue={defaultValue} />:null;
};

const AppBar = ({rols, dashboard, loadSequence, loadAudit, menu}) => {
    const titleRols = getLabel(rols,'rols', 'ROLS');
    const subTitleRols = getLabel(rols,'communicator', 'Communicator');
    const titleDashboard = getLabel(dashboard, 'dashboard', 'Dashboard');
    const titleLoadSequence = getLabel(loadSequence, 'loadSequence', 'Load Sequence');
    const titleLoadAudit = getLabel(loadAudit, 'loadAudit', 'Load History');
    return <Bar>
        <AppItem title={titleRols} subTitle={subTitleRols} to={'/'} testTag={'CommunicatorButton'} />
        <AppItem title={titleDashboard} to={'/dashboard'} testTag={'DashboardButton'}/>
        <AppItem title={titleLoadSequence} to={'/loadSequence'} testTag={'LoadSequenceButton'}/>
        <AppItem title={titleLoadAudit} to={'/loadAudit'} testTag={'LoadHistoryButton'}/>
        <Menu>
            {menu}
        </Menu>
    </Bar>
};

AppBar.propTypes = {
  rols: PropTypes.bool.isRequired,
  dashboard: PropTypes.bool.isRequired,
  loadSequence: PropTypes.bool.isRequired,
    loadAudit: PropTypes.bool.isRequired,
  menu: PropTypes.node.isRequired,
};

export default AppBar;