import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import { Table, TableBody, TableHeader, TableRow, TableRowColumn } from 'material-ui/Table';
import TextField from 'material-ui/TextField';
import toUpper from 'lodash/toUpper';
import includes from 'lodash/includes';
import ReactTooltip from 'react-tooltip';
import CenteredCircularProgress from '../../../../components/CenteredCircularProgress';
import SuperHeader from '../../../../components/Table/SuperHeader';
import CustomTableHeaderColumn from '../../../../components/Table/CustomTableHeaderColumn';
import CustomTableRow from '../../../../components/Table/CustomTableRow';
import ButtonsWrapper from './ButtonsWrapper';
import ButtonWrapper from './ButtonWrapper';

const LoadPane = ({
  doors,
  type,
  onSelectedRows,
  selectedList,
  onStopClick,
  onContinueClick,
  message,
  onMsgChange,
  showLoader,
  title,
}) => {
  const { t } = useTranslation();

  return <div>
    <SuperHeader>
      {toUpper(title)} - {t('communicator.rampInstructions.load')}
    </SuperHeader>
    <Table
        multiSelectable
        onRowSelection={selectedRows => {
          onSelectedRows(selectedRows, type);
        }}
    >
      <TableHeader>
        <CustomTableRow>
          <CustomTableHeaderColumn>
            <span>{t('communicator.rampInstructions.doorLabel')}</span>
          </CustomTableHeaderColumn>
          <CustomTableHeaderColumn>
            <span>{t('communicator.rampInstructions.stateLabel')}</span>
          </CustomTableHeaderColumn>
        </CustomTableRow>
      </TableHeader>
      <TableBody deselectOnClickaway={false}>
        {doors.map((door, index) =>
            <TableRow
                key={door.doorId}
                selected={includes(selectedList, index)}
                selectable={door.state.canStart || door.state.canStop}
                style={{color: !(door.state.canStart || door.state.canStop) && 'lightslategray'}}
            >
              <TableRowColumn>
                {door.doorName}
              </TableRowColumn>
              <TableRowColumn>
                {door.state.statusMsg}
              </TableRowColumn>
            </TableRow>
        )}
      </TableBody>
    </Table>

    <div style={{margin: 30}}>
      <TextField
          hintText={t('communicator.rampInstructions.writeMessage')}
          errorText={!message && t('communicator.rampInstructions.writeMessageError')}
          multiLine
          fullWidth
          value={message}
          onChange={onMsgChange}
      />
    </div>

    {showLoader
        ? <CenteredCircularProgress/>
        : <ButtonsWrapper>
          <div
              style={{flex: 1}}
              data-tip={
                selectedList.length === 0 || message === ''
                    ? t('communicator.rampInstructions.stopError')
                    : ''
              }
          >
            <ButtonWrapper
                label={t('communicator.rampInstructions.stop')}
                secondary
                onClick={() => {
                  onStopClick(type, 'stop');
                }}
                disabled={selectedList.length === 0 || message === ''}
            />
            <ReactTooltip/>
          </div>
          <div
              style={{flex: 1}}
              data-tip={selectedList.length === 0 ? t('communicator.rampInstructions.continueError') : ''}
          >
            <ButtonWrapper
                label={t('communicator.rampInstructions.continue')}
                primary
                onClick={() => {
                  onContinueClick(type, 'start');
                }}
                disabled={selectedList.length === 0}
            />
            <ReactTooltip/>
          </div>
        </ButtonsWrapper>}
  </div>;
};

LoadPane.propTypes = {
  doors: PropTypes.array,
  type: PropTypes.string,
  onSelectedRows: PropTypes.func,
  selectedList: PropTypes.array,
  onStopClick: PropTypes.func,
  onContinueClick: PropTypes.func,
  message: PropTypes.string,
  onMsgChange: PropTypes.func,
  showLoader: PropTypes.bool,
  title: PropTypes.string,
};

export default LoadPane;
