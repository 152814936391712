import actionTypes from './actionTypes';

const authenticate = (user, username, onlineCode, isDashboard, isRols, isLoadSequence, isLoadAudit) => ({
  type: actionTypes.AUTHENTICATE,
  payload: {
    user,
    username,
    onlineCode,
    isDashboard,
    isRols,
    isLoadSequence,
    isLoadAudit,
  },
});

const logout = () => ({
  type: actionTypes.LOGOUT,
});

const setChangePsswrdOnNextLogin = changePsswrdOnNextLogin => ({
  type: actionTypes.CHANGE_PSSWRD_ON_NEXT_LOGIN,
  payload: {
    changePsswrdOnNextLogin: changePsswrdOnNextLogin,
  },
});

const setPrivacyNoticeUrl = privacyNoticeUrl => ({
  type: actionTypes.SET_PRIVACY_NOTICE_URL,
  payload: {
    privacyNoticeUrl: privacyNoticeUrl,
  },
});

export default {
  authenticate,
  logout,
  setChangePsswrdOnNextLogin: setChangePsswrdOnNextLogin,
  setPrivacyNoticeUrl,
};
