import LoadAuditTable from "./LoadAuditTable";
import React from "react";
import PropTypes from "prop-types";

class LoadAuditPanel extends React.Component {
    static propTypes = {
        flight: PropTypes.object.isRequired,
        distribution: PropTypes.number.isRequired,
        loadAudits: PropTypes.array.isRequired,
        height: PropTypes.number,
    }

    render() {
        const {loadAudits, height, distribution} = this.props;
        return (<div style={{width: '100%'}}>
            <LoadAuditTable
                loadAudits={loadAudits}
                height={height}
                distribution={distribution}
            />
        </div>);
    }
}

export default LoadAuditPanel;