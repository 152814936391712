import React from 'react';
import NewMessageForm from '../../containers/NewMessageForm';
import OpenItemsPage from '../../containers/OpenItemsPage';
import ChatDetailPage from '../../containers/ChatDetailPage';
import RampInstructionsPage from '../../containers/RampInstructionsPage';
import BaggagePage from '../../containers/BaggagePage';
import LeftPane from '../../../common/components/Layout/LeftPane';
import CenterPane from '../../../common/components/Layout/CenterPane';
import RightPane from '../../../common/components/Layout/RightPane';
import Wrapper from '../../../common/components/Layout/Wrapper';
import ChatDetailPageWrapper from './ChatDetailPageWrapper';
import NewMessageWrapper from './NewMessageWrapper';
import TabsWrapper from './TabsWrapper';
import TabsPane from '../Tabs';
import ContactListPage from '../../containers/ContactListPage';
import call from '../../../call';
import {connect} from 'react-redux';
import SystemMessagesPage from '../../containers/SystemMessagesPage';
import FlightPage from "../../../common/containers/FlightPage";
import commonSelectors from "../../../common/selectors";

const NAVBAR_HEIGHT = 71;

class Layout extends React.Component {
  state = {
    height: 0,
    selectedTab: '',
    header: {},
    showSystemMessages: false,
    refreshOpenItems: false,
  };

  componentDidMount() {
    window.addEventListener('resize', this.calculateHeight);
    this.props.initApp();
    this.calculateHeight();
    this.initFlight();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.calculateHeight);
  }

  calculateHeight = () => {
    this.setState({
      height: window.innerHeight - NAVBAR_HEIGHT,
    });
  };

  initFlight = () => {
    const {flight,} = this.props;
    if (flight && flight.flightNumber) {
      const header = {
        flNr: flight.flightNumber,
        dpAir: flight.departureAirport.iata,
        arrAir: flight.arrivalAirport.iata,
      };
      this.setState({
        selectedTab: '0',
        header,
        showSystemMessages: false,
        refreshOpenItems: false,
      });
    }
  }

  handleGetFlightHeader = header => {
    this.setState({
      showSystemMessages: false,
      header });
  };

  handleTabChange = selectedTab => {
    this.setState({
      selectedTab,
    });
  };

  handleSystemMessagesClick = () => {
    this.setState({
      showSystemMessages: true,});
  }

  handleRefreshOpenItems = (refreshOpenItems) => {
    this.setState({
      refreshOpenItems: refreshOpenItems,
    });
  }

  render() {
    const { height, header, selectedTab, showSystemMessages, refreshOpenItems } = this.state;
    return (
      <Wrapper height={height}>
        <LeftPane>
          <FlightPage
            onGetFlightHeader={this.handleGetFlightHeader}
            onTabChange={this.handleTabChange}
            onSystemMessagesClick={this.handleSystemMessagesClick}
            onAirportChange={this.handleRefreshOpenItems}
          />
        </LeftPane>
        <CenterPane>
          {!showSystemMessages ?
            <>
              <TabsWrapper>
                <TabsPane
                  header={header}
                  selectedTab={selectedTab}
                  onTabChange={this.handleTabChange}
                />
              </TabsWrapper>
              {selectedTab === '0' && (
                <ChatDetailPageWrapper>
                  <ChatDetailPage />
                </ChatDetailPageWrapper>
              )}
              {selectedTab === '0' && (
                <NewMessageWrapper>
                  <NewMessageForm/>
                </NewMessageWrapper>
              )}
              {selectedTab === '1' && <RampInstructionsPage/>}
              {selectedTab === '2' && <BaggagePage/>}
              {selectedTab === '3' && <ContactListPage/>}
            </>
            :
            <ChatDetailPageWrapper>
              <SystemMessagesPage/>
            </ChatDetailPageWrapper>
          }
        </CenterPane>
        <RightPane>
          <OpenItemsPage
            onGetFlightHeader={this.handleGetFlightHeader}
            onTabChange={this.handleTabChange}
            onSystemMessagesClick={this.handleSystemMessagesClick}
            refreshOpenItems={refreshOpenItems}
            onRefreshOpenItems={this.handleRefreshOpenItems}
          />
        </RightPane>
        {/*<SockJSListenerContainer onTabChange={this.handleTabChange} />*/}
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  flight: commonSelectors.getFlight(state),
});

const mapDispatchToProps = dispatch => {
  const initializeApp = call.actions.initializeApp();
  return {
    initApp: () => dispatch(initializeApp),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
