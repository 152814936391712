import styled from 'styled-components';

const Form = styled.form`
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -200px;
  margin-top: -240px;
  width: 400px;
  height: 400px;

  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  box-shadow: 0 -1px 1px rgba(130, 130, 130, 0.5);
  border-radius: 3%;
  text-align: center;
`;

export default Form;
