import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { compose } from 'redux';
import connect from 'react-redux-fetch';
import injectApiRoutes from '../api/injectApiRoutes';
import i18n from './i18n';
import convertResourceToObject from '../utils/convertResourceToObject';
import security from "../../modules/security";
import settings from '../../modules/settings';
import convertBackendLanguageForFrontend from "../utils/convertBackendLanguageForFrontend";
import actions from "../../modules/security/actions";

const I18nGate = (
    {
        initDataFetch,
        webserviceUrl,
        dispatch,
        dispatchInitDataGet,
        loading,
        children,
        accessToken,
        languageId,
        dispatchGetLanguageTextPost,
        getLanguageTextFetch
    }) => {
    const [i18nLoaded, setI18nLoaded] = useState(false);

    async function setI18n(resource, language) {
        await i18n(resource, language);
        setI18nLoaded(true);
    }

    useEffect(() => {
        if (i18nLoaded) return;

        if (accessToken && languageId) {
            dispatchGetLanguageTextPost(languageId);
        } else {
            dispatchInitDataGet();
        }
        // eslint-disable-next-line
    }, [webserviceUrl, accessToken, i18nLoaded, languageId]);

    useEffect(() => {
        if (i18nLoaded) return;

        function getLanguageLabel() {
            return convertBackendLanguageForFrontend(_get(getLanguageTextFetch, 'value.languages.language[0].locale'));
        }

        if (getLanguageTextFetch.fulfilled) {
            setI18n(
                convertResourceToObject(_get(getLanguageTextFetch, 'value.languageTexts.languageText') || {}),
                getLanguageLabel()
            );
        }

        if (initDataFetch.rejected) {
            setI18n([], 'en-US');
        }
    }, [getLanguageTextFetch, i18nLoaded, initDataFetch.rejected]);

    useEffect(() => {
        if (i18nLoaded) return;

        if (initDataFetch.fulfilled) {
            setI18n(
                convertResourceToObject(_get(initDataFetch, 'value.systemLanguageText.languageTexts.languageText') || {}),
                _get(initDataFetch, 'value.systemLanguageText.language.locale')
            );
            dispatch(actions.setPrivacyNoticeUrl(_get(initDataFetch, 'value.privacyNoticeUrl')));
        }

        if (initDataFetch.rejected) {
            setI18n([], 'en-US');
        }
    }, [initDataFetch, i18nLoaded, dispatch]);

    if (initDataFetch.pending || !i18nLoaded) return loading;

    return children;
};

I18nGate.propTypes = {
    children: PropTypes.node,
    loading: PropTypes.any,
    dispatch: PropTypes.func.isRequired,
    dispatchInitDataGet: PropTypes.func,
    initDataFetch: PropTypes.object,
    webserviceUrl: PropTypes.string,

    accessToken: PropTypes.string,
    dispatchGetLanguageTextPost: PropTypes.func.isRequired,
    getLanguageTextFetch: PropTypes.object,
};

const mapStateToProps = state => ({
    accessToken: security.selectors.getToken(state),
    languageId: settings.selectors.getLanguageId(state),
});

const mapPropsToDispatchToProps = props => [
    {
        resource: 'initData',
        request: () => ({
            url: props.apiRoutes.initData(),
        }),
    },
    {
        resource: 'getLanguageText',
        method: 'POST',
        request: languageId => ({
            url: props.apiRoutes.getLanguageText(),
            body: {
                accessToken: props.accessToken,
                languageId: Number(languageId),
            },
        }),
    },
];

const enhance = compose(injectApiRoutes, connect(mapPropsToDispatchToProps, mapStateToProps));

export default enhance(I18nGate);
