import { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import selectors from '../selectors';
import PeerConnection from '../domain/PeerConnection';
import { CallType, MessageTypes } from '../constants';

const usePeerConnection = ({ isCaller, audio, video, sendToUser, selectedCallType }) => {
  const dispatch = useDispatch();
  const lastMessage = useSelector(selectors.getLastMessage);
  const [callType, setCallType] = useState(isCaller ? selectedCallType : lastMessage.data.callType);
  const localStreamRef = useRef(null);
  const remoteStreamRef = useRef(null);
  const peerConnectionRef = useRef(
    new PeerConnection({
      isCaller,
      audio,
      video: video ? video : callType === CallType.SHOW_THEIR_CAMERA,
      localStreamRef,
      remoteStreamRef,
      dispatch,
      sendToUser,
    })
  );

  useEffect(() => {
    const peerConnection = peerConnectionRef.current;
    if (!peerConnection.isStarted) {
      peerConnection.start().then(() => {
        console.debug('Stream started');
      });
    }

    return () => {
      if (peerConnection) {
        peerConnection.stop();
      }
    };
  }, []);

  useEffect(() => {
    if (lastMessage && peerConnectionRef.current) {
      if (lastMessage.type === MessageTypes.CALL_REQUEST && !isCaller) {
        const callType = lastMessage.data.callType;
        setCallType(callType);
      } else {
        peerConnectionRef.current.handle(lastMessage);
      }
    }
  }, [lastMessage, isCaller]);

  return [peerConnectionRef, localStreamRef, remoteStreamRef, callType];
};

export default usePeerConnection;
