import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MIcon from '../../../../components/MIcon';
import { TimePicker } from '@material-ui/pickers';
import DatePickerWrapper from '../../../common/components/FlightList/DatePickerWrapper';

const ButtonTimePicker = ({ defaultTime, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleIconClick = () => {
    setIsOpen(true);
  };

  return (
    <DatePickerWrapper>
      <MIcon style={{ padding: '12px 20px 10px', cursor: 'pointer' }} onClick={handleIconClick}>
        schedule
      </MIcon>
      <TimePicker
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        style={{ marginRight: 25 }}
        name="time"
        openTo="hours"
        views={['hours', 'minutes']}
        value={defaultTime}
        onChange={onChange}
        autoOk
      ></TimePicker>
    </DatePickerWrapper>
  );
};

ButtonTimePicker.propTypes = {
  defaultTime: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default ButtonTimePicker;
