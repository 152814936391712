import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import {compose} from 'redux';
import connect, {container} from 'react-redux-fetch';
import ReactTooltip from 'react-tooltip';
import Badge from 'material-ui/Badge';
import TabsMIcon, {WorkIcon} from './TabsMIcon';
import Container from './Container';
import Header from './Header';
import MIconWrapper from './MIconWrapper';
import commonSelectors from '../../../common/selectors';
import injectApiRoutes from '../../../../app/api/injectApiRoutes';
import toMilliseconds from '../../../../app/utils/toMilliseconds';
import securitySelectors from '../../../security/selectors';
import {RefreshQualifier} from '../../../../app/globals';

const fetchIsFulfilled = container.getUtil('componentHelpers').fetchIsFulfilled;

class TabsPane extends React.Component {
  static propTypes = {
    flightId: PropTypes.string,
    header: PropTypes.object,
    onTabChange: PropTypes.func,
    selectedTab: PropTypes.string,
    apiRoutes: PropTypes.shape({
      getQueuedMessages: PropTypes.func,
    }),
    accessToken: PropTypes.string,
    dispatchQueuedMessagesPost: PropTypes.func,
    queuedMessagesFetch: PropTypes.shape({
      queuedMessages: PropTypes.number,
    }),
    userFrequency: PropTypes.shape({
      openItems: PropTypes.number,
    }),

    // injected by i18next
    t: PropTypes.func.isRequired,
  };

  static contextTypes = {
    registerRefreshAction: PropTypes.func.isRequired,
    removeRefreshAction: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.startHandleDispatchQueuedMessaged(this.props);
  }

  componentWillUnmount() {
    this.stopTimer();
    this.context.removeRefreshAction('queuedMessages');
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.flightId !== nextProps.flightId && nextProps.flightId) {
      this.props.onTabChange('0');

      this.props.dispatchQueuedMessagesPost(nextProps.flightId);
    }

    if (!nextProps.flightId) {
      this.stopTimer();
    }

    if (!this.props.flightId && nextProps.flightId) {
      this.context.removeRefreshAction('queuedMessages');
      this.context.registerRefreshAction(
        'queuedMessages',
        this.handleDispatchQueuedMessaged(nextProps, RefreshQualifier.MANUAL)
      );
    }

    if (fetchIsFulfilled(this.props, nextProps, 'queuedMessages')) {
      this.startHandleDispatchQueuedMessaged(nextProps);
    }
  }

  startHandleDispatchQueuedMessaged = (props) => {
    // used openItems time because I didn't find if where this was configured
    if (props.flightId) {
      this.stopTimer();
      this.timer = setTimeout(
          this.handleDispatchQueuedMessaged(props, RefreshQualifier.AUTO),
          toMilliseconds(this.props.userFrequency.openItems)
      );
    }
  }
  
  stopTimer = () => {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  handleTabChange = e => {
    const { onTabChange } = this.props;
    const tab = e.currentTarget.value;
    onTabChange(tab);
  };

  handleDispatchQueuedMessaged = (nextProps, refreshQualifier) => () => {
    this.props.dispatchQueuedMessagesPost(nextProps.flightId, refreshQualifier);
  };

  render() {
    const { flightId, header, selectedTab, queuedMessagesFetch, t } = this.props;
    return (
      <Container>
        <Header>
          {Object.keys(header).length > 0 ? (
            `${header.flNr}: ${header.dpAir} - ${header.arrAir}`
          ) : (
              <span>{t('communicator.flight.nonSelected')}</span>
          )}
        </Header>
        {flightId && (
          <span data-tip={t('communicator.tabs.chatPanel')}>
            <MIconWrapper value={'0'} selected={selectedTab === '0'} onClick={this.handleTabChange}>
              <TabsMIcon>message</TabsMIcon>
            </MIconWrapper>
            <ReactTooltip />
          </span>
        )}
        {flightId && (
          <span data-tip={t('communicator.tabs.rampInstructions')}>
            <MIconWrapper value={'1'} selected={selectedTab === '1'} onClick={this.handleTabChange}>
              <TabsMIcon>assignment</TabsMIcon>
            </MIconWrapper>
            <ReactTooltip />
          </span>
        )}
        {flightId && (
          <span data-tip={t('communicator.tabs.baggageInformation')}>
            <MIconWrapper value={'2'} selected={selectedTab === '2'} onClick={this.handleTabChange}>
              {queuedMessagesFetch.value && queuedMessagesFetch.value.queuedMessages ? (
                <Badge
                  secondary={true}
                  badgeContent={queuedMessagesFetch.value.queuedMessages}
                  style={{ padding: '15px 20px' }}
                >
                  <WorkIcon
                    queuedMessagesPresent={queuedMessagesFetch.value.queuedMessages > 0}
                    selected={selectedTab === '2'}
                  />
                </Badge>
              ) : (
                <WorkIcon
                  style={{ marginTop: '10px' }}
                  queuedMessagesPresent={false}
                  selected={selectedTab === '2'}
                />
              )}
            </MIconWrapper>
            <ReactTooltip />
          </span>
        )}
        {flightId && (
          <span data-tip="Contact list">
            <MIconWrapper value={'3'} selected={selectedTab === '3'} onClick={this.handleTabChange}>
              <TabsMIcon>contacts</TabsMIcon>
            </MIconWrapper>
            <ReactTooltip />
          </span>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  flightId: commonSelectors.getFlightId(state),
  userFrequency: securitySelectors.getUserFrequency(state),
});

const mapPropToDispatchToProps = props => [
  {
    resource: 'queuedMessages',
    method: 'POST',
    request: (flightId, refreshQualifier = RefreshQualifier.MANUAL) => ({
      url: props.apiRoutes.getQueuedMessages(),
      body: {
        accessToken: props.accessToken,
        flightId: flightId,
        distribution: 'ON_LOAD',
        refreshQualifier,
      },
    }),
  },
];

const enhance = compose(
  injectApiRoutes,
  connect(
    mapPropToDispatchToProps,
    mapStateToProps
  )
);

export default enhance(withTranslation()(TabsPane));
