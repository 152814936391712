import PropTypes from 'prop-types';
import React from 'react';
import routes, { MatchWithSubRoutes } from '../../../../app/routes';
import AuthenticatedLayout from '../../containers/AuthenticatedLayout';
import NotAuthenticatedLayout from '../../containers/NotAuthenticatedLayout';
// import Link from 'react-router/Link';

const Layout = ({ isAuthenticated, location }) => {
  const Wrapper = isAuthenticated ? AuthenticatedLayout : NotAuthenticatedLayout;

  return (
    <Wrapper>
      {routes.map(route =>
        <MatchWithSubRoutes
          key={route.path}
          {...route}
          isAuthenticated={isAuthenticated}
          location={location}
        />
      )}
    </Wrapper>
  );
};

Layout.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  location: PropTypes.object,
};

export default Layout;
