import React from 'react';
import styled from 'styled-components';
import {CircularProgress} from "@material-ui/core";
import BackgroundImage from "../../../security/components/BackgroundImage";

const Progress = styled(CircularProgress)`
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 9999;
`;

const Loader = () => (
    <React.Fragment>
        <Progress />
        <BackgroundImage />
    </React.Fragment>
);

export default Loader;