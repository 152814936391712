import styled from 'styled-components';

const MsgText = styled.div`
  flex: 8;
  margin: 6px 10px;
  color: ${props => (props.received ? props.theme.palette.black : props.theme.palette.white)};
  color: ${props => props.unread && props.theme.palette.black};
  font-size: 1em;
  white-space: pre-wrap;
`;

export default MsgText;
