import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { createMuiTheme } from '@material-ui/core';

const configureMuiTheme = theme =>
  getMuiTheme({
    palette: {
      primary1Color: theme.palette.primary,
      accent1Color: theme.palette.secondary,
      primary2Color: theme.palette.primaryDark,
      primaryPastel: theme.palette.primaryPastel,
      secondary1Color: theme.palette.secondary,
      greyLight: theme.palette.greyLight,
      white: theme.palette.white,
      black: theme.palette.black,
      pickerHeaderColor: theme.palette.primary,
    },
    datePicker: {
      color: theme.palette.primary1Color,
      textColor: theme.palette.greyLight,
      calendarTextColor: theme.palette.textColor,
      selectColor: theme.palette.primary2Color,
      selectTextColor: theme.palette.greyLight,
      calendarYearBackgroundColor: theme.palette.greyLight,
    },
  });

export default configureMuiTheme;

export const convertTheme = theme =>
  createMuiTheme({
    palette: {
      primary: {
        main: theme.palette.primary1Color,
        dark: theme.palette.primary2Color,
      },
      secondary:{
        main: theme.palette.secondary1Color,
      },
      white: theme.palette.white,
    },
  });
