import React from 'react';
import PropTypes from "prop-types";
import Line from "./Line";
import Rectangle from "./Rectangle";

class Shapes extends React.Component {
    static propTypes = {
        id: PropTypes.string,
        svg: PropTypes.object,
        shapes: PropTypes.object,
        group: PropTypes.object,
        horizontal: PropTypes.bool,
    }

    getLine = (index, shape) => {
        const { svg, group, horizontal, } = this.props;
        return <Line
            key={index}
            id={index}
            svg={svg}
            line={shape}
            group={group}
            horizontal={horizontal}
        />
    }

    getRectangle = (index, shape) => {
        const { svg, group, horizontal, } = this.props;
        return <Rectangle
            key={index}
            id={index}
            svg={svg}
            rectangle={shape}
            borderColor={shape.color}
            group={group}
            horizontal={horizontal}
        />
    }

    render() {
        const { svg, shapes, id, } = this.props;
        return (
            <>
            {svg && shapes && shapes.shape.map((shape, index) => {
                const idIndex = `${id}-Shape${index}`;
                if (shape.shapeType==='LINE') {
                    return this.getLine(idIndex, shape);
                } else if (shape.shapeType==='RECTANGLE') {
                    return this.getRectangle(idIndex, shape);
                }
                return null;
            })}
            </>
        );
    }
}

export default Shapes;