import styled from 'styled-components';

const Title = styled.h1`
  display: inline-block;
  margin: 1em .2em .2em 0;
  padding: 0;
  color: #4cb384;
  fontSize: ${props => props.theme.font.extraLarge};
`;

export default Title;
