import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SystemMsg from '../components/Message/SystemMsg';
import {compose} from 'redux';
import injectApiRoutes from '../../../app/api/injectApiRoutes';
import connect from 'react-redux-fetch';
import get from 'lodash/get';

class SystemMsgContainer extends Component {
  static propTypes = {
    msg: PropTypes.object,
    received: PropTypes.bool,
    sent: PropTypes.bool,
    unread: PropTypes.bool,
    read: PropTypes.bool,
    dispatchReadPost: PropTypes.func,
    readMsgUpdate: PropTypes.func,
    loaderInfo: PropTypes.object,
    readFetch: PropTypes.object,
  };
  componentWillReceiveProps(nextProps) {
    if (get(nextProps.readFetch, 'meta.imSequence') !== nextProps.msg.sequenceId) {
      return;
    }

    if (this.props.readFetch.pending && nextProps.readFetch.fulfilled) {
      this.props.readMsgUpdate(true, nextProps.readFetch.meta.imSequence);
    }
  }

  readMessage = () => {
    this.props.dispatchReadPost(this.props.msg.sequenceId);
  };

  render() {
    const { loaderInfo, msg, readFetch } = this.props;

    const isFetchingMessages =
      loaderInfo.msgId === msg.sequenceId && loaderInfo.showCircularProgress;
    const isPostingAction =
      get(readFetch, 'meta.imSequence') === msg.sequenceId && readFetch.pending;

    return (
      <SystemMsg
        {...this.props}
        readMessage={this.readMessage}
        isPending={isFetchingMessages || isPostingAction}
      />
    );
  }
}

const mapPropToDispatchToProps = props => [
  {
    resource: 'read',
    method: 'POST',
    request: messageId => ({
      url: props.apiRoutes.readMessage(),
      body: {
        accessToken: props.accessToken,
        messageId,
      },
      meta: {
        imSequence: messageId,
      },
    }),
  },
];

const enhance = compose(
  injectApiRoutes,
  connect(mapPropToDispatchToProps)
);

export default enhance(SystemMsgContainer);
