import styled from 'styled-components';

const BackgroundImage = styled.div`
  background: url(assets/img/Boeing-777-9X.jpg) no-repeat center center fixed;
  /*background: url(http://media.istockphoto.com/photos/passenger-airplane-getting-ready-for-flight-picture-id497310630?s=2048x2048) no-repeat center center fixed;*/
  background-size: cover;
  filter: blur(5px);

  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

export default BackgroundImage;
