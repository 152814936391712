import React from 'react';
import styled from 'styled-components';
import CircularProgress from 'material-ui/CircularProgress';

const CenteredCircularProgress = ({ ...other }) => {
  const Container = styled.div`
    display: flex;
    height: 100%;
    padding: 0;
    margin: 0;
    align-items: center;
    justify-content: center;
  `;

  return (
    <Container>
      <CircularProgress {...other} />
    </Container>
  );
};

export default CenteredCircularProgress;
