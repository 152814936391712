import styled from 'styled-components';
import Button from '../../../../components/Button';

const ButtonWrapper = styled(Button)`
  margin-left: ${props => (props.type ? '' : '20px')}!important;
  color: ${props => (props.disabled ? props.theme.palette.black : '')}!important;
  border-color: ${props => (props.disabled ? props.theme.palette.black : '')}!important;

  &:hover {
    background-color: ${props =>
      props.disabled ? props.theme.palette.black : props.theme.palette.primary}!important;
    color: ${props => props.theme.palette.white}!important;
    cursor: ${props => (props.disabled ? 'no-drop' : '')}!important;
  }
`;

export default ButtonWrapper;
