const getSocketState = state => state.call.callState;
const getLastMessage = state => (state.call.message ? state.call.message.body : {});
const getToOnlineCode = state => state.call.toOnlineCode;
const getToOnlineName = state => state.call.toOnlineName;

export default {
  getSocketState,
  getLastMessage,
  getToOnlineCode,
  getToOnlineName,
};
