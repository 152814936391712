import styled from 'styled-components';
import Button from '../../../../components/Button';

const MsgButton = styled(Button)`
  flex: 1;
  minWidth: 50px!important;
  margin: 0!important;
`;

export default MsgButton;
