import React from "react";
import PropTypes from "prop-types";
import calculateSvgCoordinates from "../../lib/calculateSvgCoordinates";

class Line extends React.Component {
    static propTypes = {
        id: PropTypes.string,
        svg: PropTypes.object,
        line: PropTypes.object,
        group: PropTypes.object,
        horizontal: PropTypes.bool,
    }

    componentDidMount() {
        const { svg, line, group, horizontal, } = this.props;
        const color = line.color?line.color.value:'#000000';
        const { x1, y1, x2, y2 } = calculateSvgCoordinates(line, horizontal);
        const svgLine = svg.line(x1, y1, x2, y2);
        svgLine.style({
            width: 1,
            stroke: color,
        });
        group.add(svgLine);
    }

    render() {
        return null;
    }
}

export default Line;