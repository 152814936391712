import styled from 'styled-components';

const DatePickerWrapper = styled.div`
  display: flex;
  width: 80%;
  margin: 20px auto 10px auto;
  borderRadius: 0.4em;
  // border: 1px solid ${props => props.theme.palette.primary};
  backgroundColor: ${props => props.theme.palette.primaryPastelLight};
  color: ${props => props.theme.palette.primary};
`;

export default DatePickerWrapper;
