import styled from 'styled-components';

const FormWrapper = styled.div`
  margin: 20px 100px;
  padding: 50px 0px;
  borderRadius: 1em !important;
  border: 2px solid ${props => props.theme.palette.primary}!important;
  backgroundColor: ${props => props.theme.palette.primaryLightest}!important;
  display: flex;
  flex-direction: column;
`;

export default FormWrapper;
