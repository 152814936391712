import React from 'react';
import PieChart from './component/Chart/PieChart';
import ColorPieChart from './component/Chart/ColorPieChart';
import RemarkIcon from './component/Remark/RemarkIcon';

const LOADRLC = 'LOADRCL';
const OFFLOADRCL = 'OFFLOADRCL';
const OFFLOAD = 'OFF_LOAD';
const LOAD = 'ON_LOAD';

export function getMainDeckTime(times) {
  return times.filter(
    time =>
      time.eventNameId === 'OFFLOADMDSIDE' ||
      time.eventNameId === 'OFFLOADMDNOSE' ||
      time.eventNameId === 'LOADMDSIDE' ||
      time.eventNameId === 'LOADMDNOSE'
  );
}

export function getBulkTime(times) {
  return times.filter(time => time.eventNameId === 'OFFLOADBLK' || time.eventNameId === 'LOADBLK');
}

export function getFwdTime(times) {
  return times.filter(time => time.eventNameId === 'LOADFWD' || time.eventNameId === 'OFFLOADFWD');
}

export function getAftTime(times) {
  return times.filter(time => time.eventNameId === 'LOADAFT' || time.eventNameId === 'OFFLOADAFT');
}

export function getReportsPerDistribution(reports, distribution) {
  let newReports = [...reports];
  return Array.from(
    newReports
      .filter(item => item.distribution === distribution)
      .sort((a, b) => {
        return b.reportId - a.reportId;
      })
      .map(item => ({ ...item }))
  );
}

export function getRampTime(times) {
  for (let i = 0; i < times.length; i++) {
    if (times[i].eventNameId === LOADRLC || times[i].eventNameId === OFFLOADRCL) {
      return times[i];
    }
  }
  return times[0];
}

export function hasFlightAndArrivalDate(time) {
  let date = getDate(time);
  return date !== null;
}

export function isNotRamp(time) {
  return !(time.eventNameId === LOADRLC || time.eventNameId === OFFLOADRCL);
}

export function getDate(time) {
  if (!time) {
    return null;
  }
  if (time.distribution === LOAD) {
    if (time.flight.scheduledDeparture) {
      return time.flight.scheduledDeparture;
    } else {
      return time.flight.estimatedDeparture;
    }
  } else {
    if (time.flight.scheduledArrival) {
      return time.flight.scheduledArrival;
    } else {
      return time.flight.estimatedArrival;
    }
  }
}

export function getLongestDuration(time) {
  return time.distribution === LOAD ? time.flight.durationLoad : time.flight.durationOffload;
}

export function getDelayDuration(time) {
  return time.distribution === LOAD
    ? time.flight.delayDurationLoad
    : time.flight.delayDurationOffLoad;
}

export function getFirstloaded(time) {
  return time.flight.criticalTime;
}

export function getEndedloaded(time) {
  return time.flight.criticalTime;
}

export function getDeviation(time) {
  return time.eventNameId === LOADRLC ? time.flight.delayLoad : time.flight.delayOffload;
}

export function getFirstloadedTime(time) {
  return time.flight.criticalTime.scheduledStartTime;
}

export function getEndedloadedTime(time) {
  return time.flight.criticalTime.scheduledEndTime;
}
export function isFullyLoadedForOnLoad(time) {
  return time.flight.flightState === 'FSCOMPLETED' && time.distribution === LOAD;
}

export function hideLoadingForOffload(time) {
  return (
    (time.flight.flightState === 'FSSTARTEDLOADING' ||
      time.flight.flightState === 'FSWAITINGFORSTARTLOADING') &&
    time.distribution === OFFLOAD
  );
}

function getCountsPerDeck(decks, doorType) {
  if (decks) {
    for (let i = 0; i < decks.length; i++) {
      if (decks[i].doortype === doorType) {
        return {
          actual: decks[i].progress.actualCount,
          todo: decks[i].progress.toDoCount,
          completed: decks[i].progress.completed,
        };
      }
    }
  }
  return { actual: 0, todo: 0 };
}

function getWeightsPerDeck(decks, doorType) {
  if (decks) {
    for (let i = 0; i < decks.length; i++) {
      if (decks[i].doortype === doorType) {
        return {
          actual: decks[i].progress.actualWeight,
          todo: decks[i].progress.toDoWeight,
          completed: decks[i].progress.completed,
        };
      }
    }
  }
  return { actual: null, todo: null };
}

export const getCounts = (decks, deck) => {
  const { actual, todo } = getCountsPerDeck(decks, deck);
  return `${actual} / ${todo}`;
};

export const getWeights = (decks, deck) => {
  const { actual, todo } = getWeightsPerDeck(decks, deck);
  const actualValue = getMeasureText(actual);
  const todoValue = getMeasureText(todo);
  return `${actualValue} / ${todoValue}`;
};

export function getPieChartValuesForWeight(time) {
  if (time) {
    const actualWeight = getMeasureValue(time.progress.actualWeight);
    const toDoWeight = getMeasureValue(time.progress.toDoWeight);
    return getTimePieChart(time, actualWeight, toDoWeight);
  }
  return getTimePieChart(time);
}

export function getPieChartValuesForCount(time) {
  if (time) {
    const actualCount = time.progress.actualCount;
    const toDoCount = time.progress.toDoCount;
    return getTimePieChart(time, actualCount, toDoCount);
  }
  return getTimePieChart(time);
}

const getTimePieChart = (time, actualPieParts, totalPieParts) => {
  if (time) {
    const completed = time.progress.completed;
    const percentage = isFullyLoadedForOnLoad(time)
        ? 100
        : hideLoadingForOffload(time)
            ? 0
            : getPieChartPercentage(actualPieParts, totalPieParts, completed);
    return <PieChart percentage={percentage} />;
  }
  return <ColorPieChart color={{ backgroundColor: 'orange' }} />;
}

export function renderPieChartDecksWeights(decks, doorType) {
  const { actual, todo, completed } = getWeightsPerDeck(decks, doorType);
  const actualValue = getMeasureValue(actual);
  const todoValue = getMeasureValue(todo);
  return getPieChart(actualValue, todoValue, completed);
}

export function renderPieChartsDeckCounts(decks, doorType) {
  const { actual, todo, completed } = getCountsPerDeck(decks, doorType);
  return getPieChart(actual, todo, completed);
}

const getPieChart = (actualPieParts, totalPieParts, completed) => {
  const percentage = getPieChartPercentage(actualPieParts, totalPieParts, completed);
  return <PieChart percentage={percentage} />;
}

const getPieChartPercentage = (actualPieParts, totalPieParts, completed) => {
  const actual = totalPieParts !== 0 ? actualPieParts : (completed ? 1 : 0);
  const total = totalPieParts !== 0 ? totalPieParts : 1;
  return (actual / total) * 100;
}

export function renderMessageIcon(time) {
  let count = 0;
  if (time.distribution === LOAD) count = time.flight.amountLoadReports;
  if (time.distribution === OFFLOAD) count = time.flight.amountOffloadReports;
  return count !== 0 ? <RemarkIcon small number={count}></RemarkIcon> : '';
}

export function getColorForEmptyDeck(decks){
  if (decks) {
    for (let i = 0; i < decks.length; i++) {
      if(decks[i].deckState === 'DSNOTHINGTODO'){
        return decks[i].doortype;
      }
    }
  }
}

export const getAmountForWeight = (time) => {
  const weight =
      (isFullyLoadedForOnLoad(time)
          ?time.progress.toDoWeight
          :(!hideLoadingForOffload(time)
              ?time.progress.actualWeight
              :null));
  return getMeasureText(weight);
}

export function getAmountForCount(time) {
  if (isFullyLoadedForOnLoad(time)) return time.progress.toDoCount;
  if (hideLoadingForOffload(time)) return 0;
  return time.progress.actualCount;
}

export function getToDoWeight(time) {
  return getMeasureText(time.progress.toDoWeight);
}

const getMeasureText = (measure) => {
  if (!measure) measure = {value: '0'};
  if (!measure.unit) return `${measure.value}`;
  return `${measure.value} ${measure.unit}`;
};

const getMeasureValue = (measure) => measure?measure.value:0;

//For test purposes only
export function getTimeByFlightId(times, id) {
  return times.filter(time => time.flight.flightId === id && time.distribution === OFFLOAD)[0];
}

export const isFlightLoadingAndNotChangedAircraft = (criticalTime) => {
  return criticalTime.distribution === 'ON_LOAD' && !criticalTime.flight.changedAircraft;
}