import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import { Table, TableBody, TableHeader, TableRow, TableRowColumn } from 'material-ui/Table';
import ReactTooltip from 'react-tooltip';
import reduce from 'lodash/reduce';
import Button from '../../../../components/Button';
import SuperHeader from '../../../../components/Table/SuperHeader';
import CustomTableHeaderColumn from '../../../../components/Table/CustomTableHeaderColumn';
import CustomTableRow from '../../../../components/Table/CustomTableRow';
import CustomTableRowColumn from '../../../../components/Table/CustomTableRowColumn';

const fixedColumnWidth = { width: 100 };

const Baggage = ({ tableHeader, ulds, getAmount }) => {
  const { t } = useTranslation();
  let totals = Array(tableHeader.length).fill(0);

  return (
    <div style={{ margin: 30 }}>
      <SuperHeader>
        <span>{t('communicator.baggage.header')}</span>
      </SuperHeader>
      <Table selectable={false}>
        <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
          <CustomTableRow>
            <CustomTableHeaderColumn style={fixedColumnWidth}>
              {ulds.length > 0 && 'ULD'}
            </CustomTableHeaderColumn>
            {tableHeader.map(type =>
              <CustomTableHeaderColumn key={type}>
                {type}
              </CustomTableHeaderColumn>
            )}
          </CustomTableRow>
        </TableHeader>
        <TableBody displayRowCheckbox={false}>
          {ulds.length > 0 && ulds.map(uld => (
            <TableRow key={uld.uldId} selectable={false}>
              {uld.uldNumber.type === 'BEL'
                ? <TableRowColumn style={fixedColumnWidth}>
                  {uld.uldNumber.serial}
                </TableRowColumn>
                : <TableRowColumn style={fixedColumnWidth}>
                  {uld.uldNumber.type}
                  {uld.uldNumber.serial}
                  {uld.uldNumber.owner}
                </TableRowColumn>}
              {tableHeader.map((th, idx) => {
                const amount = getAmount(th, uld.baggageDetails.baggageDetail);
                totals[idx] = totals[idx] + amount.val;

                return (
                  <TableRowColumn key={th}>
                    {amount.el}
                  </TableRowColumn>
                )
              })}
            </TableRow>
          ))}
          {totals.length > 0 &&
          <CustomTableRow>
            <CustomTableRowColumn>
              <span data-tip={reduce(totals, (s, t) => s + t, 0)}>
                 {t('communicator.baggage.total')}: {reduce(totals, (s, t) => s + t, 0)}
              </span>
              <ReactTooltip />
            </CustomTableRowColumn>
            {totals.map((v, idx) =>
              <TableRowColumn key={idx}>
                {v}
              </TableRowColumn>
            )}
          </CustomTableRow>}
        </TableBody>
      </Table>

      <Button
        primary
        label={t('communicator.baggage.print')}
        style={{ position: 'relative', float: 'right', margin: 30 }}
        onClick={() => window.print()}
      />
    </div>
  );
};

Baggage.propTypes = {
  tableHeader: PropTypes.array,
  ulds: PropTypes.array,
  getAmount: PropTypes.func.isRequired,
};

export default Baggage;
