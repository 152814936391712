import PropTypes from 'prop-types';
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {connect} from 'react-redux';
import reduxFetch, {container} from 'react-redux-fetch';
import {compose} from 'redux';
import delay from 'lodash/delay';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import CenteredCircularProgress from '../../../components/CenteredCircularProgress';
import injectApiRoutes from '../../../app/api/injectApiRoutes';
import toMilliseconds from '../../../app/utils/toMilliseconds';
import securitySelectors from '../../../modules/security/selectors';
import ChatDetail from '../components/ChatDetail';
import commonSelectors from '../../common/selectors';
import actions from '../actions';
import {RefreshQualifier} from '../../../app/globals';
import momentPropTypes from "react-moment-proptypes";
import adapter from "../../../app/api/adapter";
import * as dateHelpers from "../../../app/utils/dateHelpers";
import flightService from "../../common/domain/flight";

const fetchIsFulfilled = container.getUtil('componentHelpers').fetchIsFulfilled;
const scrollToFirstUnreadMargin = 200;

class SystemMessagesPage extends Component {
  static propTypes = {
    isCurrent: PropTypes.bool,
    departureTime: momentPropTypes.momentObj,
    dispatchSystemMessagesPost: PropTypes.func,
    systemMessagesFetch: PropTypes.object,
    dispatch: PropTypes.func,

    distribution: PropTypes.string,
    userFrequency: PropTypes.shape({
      chatMessages: PropTypes.number,
    }),
  };

  static contextTypes = {
    registerRefreshAction: PropTypes.func.isRequired,
    removeRefreshAction: PropTypes.func.isRequired,
  };

  state = {
    // By default, only a loader is shown with the initial fetch.
    // We also want to show the loader if the user picks another flight.
    forceShowLoader: false,
    // for the read functionality
    loaderInfo: {
      showCircularProgress: false,
      msgId: 0,
    },
  };

  // REACT LIFECYCLE

  componentWillMount() {
    this.lastImSequence = 0;
    this.mounted = true;
    this.loadSystemMessages(this.props);

    this.context.registerRefreshAction(
      'newSystemMessageCheck',
      this.checkForNewSystemMessages(RefreshQualifier.MANUAL)
    );
  }

  componentWillReceiveProps(nextProps) {
    if (this.isInputDifferent(nextProps)) {
      this.loadSystemMessages(nextProps);
    }

    if (fetchIsFulfilled(this.props, nextProps, 'systemMessages')) {
      this.onSystemMessagesFetch(nextProps);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const stateIsDifferent = !isEqual(this.state, nextState);
    if (stateIsDifferent) {
      return true;
    }

    const messagesAreDifferent = !isEqual(
      get(this.props.systemMessagesFetch, 'value.instantMessaging'),
      get(nextProps.systemMessagesFetch, 'value.instantMessaging')
    );
    
    return messagesAreDifferent;
  }

  componentWillUpdate() {
    const node = ReactDOM.findDOMNode(this).parentElement; // eslint-disable-line
    this.shouldScrollToBottom = node.scrollTop + node.offsetHeight === node.scrollHeight;
  }

  componentDidUpdate() {
    if (
      this.state.forceShowLoader ||
      (get(this.props.systemMessagesFetch, 'value.instantMessaging.instantMessage') || []).length === 0
    ) {
      return;
    }
    if (this.shouldScrollToBottom) {
      // Add a small delay to make sure everthing is rendered before scrolling to bottom
      // delay(this.scrollToFirstUnread, 200);
      delay(this.scrollToBottom, 200);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.context.removeRefreshAction('newSystemMessageCheck');
  }

  isDateDifferent = nextProps =>
      nextProps.departureTime && !nextProps.departureTime.isSame(this.props.departureTime, 'day');

  isCurrentFlagDifferent = nextProps =>
      !isEqual(this.props.isCurrent, nextProps.isCurrent);

  isInputDifferent = nextProps =>
      this.isDateDifferent(nextProps) || this.isCurrentFlagDifferent(nextProps);

  // TRANSITION HANDLERS

  loadSystemMessages(nextProps) {
    if (!nextProps.departureTime) {
      return;
    }
    this.props.dispatch(actions.clearMessages());
    this.checkForSystemMessages(nextProps);
    this.setState({
      forceShowLoader: true,
    });
    this.lastImSequence = 0;
  }

  onSystemMessagesFetch(nextProps) {
    /*const messagesFlightId = get(nextProps.systemMessages, 'value.flight.flightId');

    // When a message fetch returns when we already selected another flight, do nothing
    if (messagesFlightId !== nextProps.flightId) {
      return;
    }*/

    this.startCheckingForNewSystemMessages();
    const messages = get(nextProps.systemMessagesFetch, 'value.instantMessaging.instantMessage') || [];

    this.lastImSequence = messages.length > 0 ? messages[0].sequenceId : 0;

    this.setState(state => ({
      forceShowLoader: false,
      loaderInfo: { showCircularProgress: false, msgId: state.loaderInfo.msgId },
    }));
  }

  // INTERVAL CHECKER

  startCheckingForNewSystemMessages = () => {
    this.timer = setTimeout(
      this.checkForNewSystemMessages(RefreshQualifier.AUTO),
      toMilliseconds(this.props.userFrequency.chatMessages)
    );
  };

  checkForNewSystemMessages = refreshQualifier => () => {
    this.checkForSystemMessages(this.props);
  };

  checkForSystemMessages = (props) => {
    const { departureTime, isCurrent } = props;
    const finalDate = departureTime;
    const finalIsCurrent = flightService.shouldShowCurrentToggle(finalDate)?isCurrent:false;
    const startOfDay = flightService.shouldFetchFlightsFromStartOfDay(finalDate);
    this.props.dispatchSystemMessagesPost(finalDate,
        startOfDay,
        finalIsCurrent,);
  };

  // UI
  scrollToBottom = () => {
    if (!this.mounted) {
      return;
    }
    // based on http://blog.vjeux.com/2013/javascript/scroll-position-with-react.html
    const node = ReactDOM.findDOMNode(this).parentElement; // eslint-disable-line
    node.scrollTop = node.scrollHeight;
  };

  scrollToFirstUnread = () => {
    if (!this.mounted) {
      return;
    }

    const node = ReactDOM.findDOMNode(this); // eslint-disable-line
    const unreadNodes = node.querySelectorAll('[data-msg-unread="true"]');

    if (unreadNodes.length > 0) {
      node.parentElement.scrollTop =
        unreadNodes[unreadNodes.length - 1].offsetTop - scrollToFirstUnreadMargin;
    }
  };

  handleReadMsgUpdate = (value, id) => {
    if (value) {
      this.checkForSystemMessages(this.props);
      this.setState({ loaderInfo: { showCircularProgress: true, msgId: id } });
    }
  };

  render() {
    if (this.state.forceShowLoader) {
      return <CenteredCircularProgress />;
    }

    const { systemMessagesFetch, distribution } = this.props;

    return systemMessagesFetch ? (
      systemMessagesFetch.fulfilled || systemMessagesFetch.value ? (
        <ChatDetail
          messagesReceived={
            systemMessagesFetch.value.instantMessaging
              ? systemMessagesFetch.value.instantMessaging.instantMessage
              : []
          }
          onReadMsgUpdate={this.handleReadMsgUpdate}
          showCircularProgress={this.state.showCircularProgress}
          loaderInfo={this.state.loaderInfo}
          distribution={distribution}
          senderFilter={systemMessagesFetch.value.senderFilter}
        />
      ) : (
        <CenteredCircularProgress />
      )
    ) : (
      <div />
    );
  }
}

const getDate = (date, startOfDay) => {
  return startOfDay
      ? adapter.momentDateToServerStartOfDay(date)
      : adapter.momentDateToServerDate(date);
}

const mapPropToDispatchToProps = props => [
  {
    resource: 'systemMessages',
    method: 'POST',
    request: (date,
              startOfDay = true,
              isCurrent = false,) => ({
      url: props.apiRoutes.getSystemMessages(),
      body: {
        accessToken: props.accessToken,
        date: getDate(date, startOfDay),
        localTimeOffset: dateHelpers.currentTimezoneOffset(getDate(date, startOfDay)),
        current: isCurrent,
      },
    }),
  },
];

const mapStateToProps = state => ({
  departureTime: commonSelectors.getDepartureTime(state),
  isCurrent: commonSelectors.getCurrentFlag(state),
  distribution: commonSelectors.getDistribution(state),
  userFrequency: securitySelectors.getUserFrequency(state),
});

const enhance = compose(
  injectApiRoutes,
  connect(mapStateToProps),
  reduxFetch(mapPropToDispatchToProps)
);

export default enhance(SystemMessagesPage);
