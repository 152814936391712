import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import ReactDOM from "react-dom";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import styled from "styled-components";
import FormControl from "@material-ui/core/FormControl";
import PropTypes from "prop-types";
import InputLabel from "@material-ui/core/InputLabel";
import muiStyled from "@material-ui/styles/styled";

const StyledFormControl = muiStyled(FormControl)({
    minWidth: 170
});

const FilterBanner = styled.div`
  border-bottom: ${props => `${props.border}px solid rgb(235, 237, 236)`};
  padding: ${props => `${props.padding}px`};
  position: fixed;
  background-color: white;
  width: ${props => `${props.width}px`};
  z-index: 2;
`;

export const MessageTypes = {
    ALL: -1,
    USER_MESSAGES: 2,
    SYSTEM_MESSAGES: 3,
};

const PADDING = 10;
const BORDER = 1;

class Filter extends Component {
    state = {
        width: 0,
    };

    resize = () => {
        const {onResize} = this.props;
        const width = ReactDOM.findDOMNode(this).parentNode.offsetWidth - (PADDING * 2 - BORDER * 2);
        this.setState({width});
        onResize();
    };

    componentWillUnmount() {
        window.onresize = null;
    }

    componentDidMount() {
        this.resize();
        window.onresize = this.resize;
    }

    render() {
        const {onTypeChange, messageTypeValue, onRoleChange, selectedRole, senderFilter, t} = this.props;
        const {width} = this.state;
        return (
            <FilterBanner border={BORDER} padding={PADDING} width={width}>
                <StyledFormControl>
                    <InputLabel htmlFor="messageType-filter">{t('communicator.filter.messageTypeFilter')}</InputLabel>
                    <Select
                        value={messageTypeValue}
                        onChange={event => onTypeChange(event.target.value)}
                        inputProps={{
                            id: "messageType-filter"
                        }}
                    >
                        <MenuItem value={MessageTypes.ALL}>{t('communicator.filter.all')}</MenuItem>
                        <MenuItem value={MessageTypes.USER_MESSAGES}>{t('communicator.filter.userMessages')}</MenuItem>
                        <MenuItem value={MessageTypes.SYSTEM_MESSAGES}>{t('communicator.filter.systemMessages')}</MenuItem>
                    </Select>
                </StyledFormControl>

                {messageTypeValue === MessageTypes.USER_MESSAGES && (
                    <StyledFormControl>
                        <InputLabel htmlFor="role-filter">{t('communicator.filter.roleFilter')}</InputLabel>
                        <Select
                            value={selectedRole}
                            onChange={event => onRoleChange(event.target.value)}
                            inputProps={{
                                id: 'role-filter',
                            }}
                        >
                            <MenuItem value={MessageTypes.ALL}>{t('communicator.filter.all')}</MenuItem>
                            {senderFilter && senderFilter.user.map(userRole => (
                                <MenuItem key={userRole} value={userRole}>
                                    {userRole}
                                </MenuItem>
                            ))}
                        </Select>
                    </StyledFormControl>
                )}
            </FilterBanner>
        );
    }
}

Filter.propTypes = {
    messageTypeValue: PropTypes.number.isRequired,
    senderFilter: PropTypes.object,
    selectedRole: PropTypes.number,
    onTypeChange: PropTypes.func.isRequired,
    onRoleChange: PropTypes.func.isRequired,
    onResize: PropTypes.func.isRequired,

    // injected by i18next
    t: PropTypes.func.isRequired,
};

export default withTranslation()(Filter);
