import Immutable from 'seamless-immutable';
import actionTypes from '../actionTypes';

const INITIAL_STATE = {
  dateFrom: null,
  timeFrom: null,
  dateTo: null,
  timeTo: null,
  airportCode: '',
  distribution: 'ON_LOAD',
};

const filterReducer = (state = Immutable.from(INITIAL_STATE), action) => {
  switch (action.type) {
    case actionTypes.FILTER:
      return state
        .set('dateFrom', action.payload.dateFrom)
        .set('timeFrom', action.payload.timeFrom)
        .set('dateTo', action.payload.dateTo)
        .set('timeTo', action.payload.timeTo)
        .set('airportCode', action.payload.airportCode)
        .set('distribution', action.payload.distribution);
    case actionTypes.SET_DATE_FROM:
      return state.set('dateFrom', action.payload.dateFrom);
    case actionTypes.SET_DATE_TO:
      return state.set('dateTo', action.payload.dateTo);
    case actionTypes.SET_TIME_TO:
      return state.set('timeTo', action.payload.timeTo);
    case actionTypes.SET_TIME_FROM:
      return state.set('timeFrom', action.payload.timeFrom);
    case actionTypes.SET_AIRPORT:
      return state.set('airportCode', action.payload.airportCode);
    case actionTypes.SET_DISTRIBUTION:
      return state.set('distribution', action.payload.distribution);
    default:
      return state;
  }
};

export default filterReducer;
