import SettingsPage from './containers/SettingsPage';
import reducer from './reducer';
import selectors from './selectors';
import actions from './actions';
import actionTypes from './actionTypes';

export default {
  containers: {
    SettingsPage,
  },
  reducer,
  selectors,
  actions,
  actionTypes,
};
