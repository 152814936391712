import React from "react";
import ChangePsswrd from "../components/ChangePsswrd";
import security from "../../security";
import PropTypes from "prop-types";
import Redirect from "react-router-dom/Redirect";
import isEqual from 'lodash/isEqual';
import actions from "../../security/actions";

const NAVBAR_HEIGHT = 71;

class BaseChangePsswrdPage extends React.Component {
    static propTypes = {
        dispatch: PropTypes.func,
        isAuthenticated: PropTypes.bool.isRequired,
    };

    state = {
        height: 0,
        errorNumber: null,
        pending: false,
    };

    componentDidMount() {
        window.addEventListener('resize', this.calculateHeight);
        this.calculateHeight();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.calculateHeight);
    }

    componentDidUpdate(prevProps,) {
        const prevValue = this.getFetchValue(prevProps);
        const value = this.getFetchValue(this.props);
        const fetch = this.getFetch(this.props);
        if (fetch && fetch.fulfilled && !isEqual(prevValue, value)) {
            const errorNumber = value.errorNumber;
            if (errorNumber===0) {
               this.sleep(1000).then(r => {
                   this.update(errorNumber);
               });
            } else {
                this.update(errorNumber);
            }
        } else if (this.state.pending) {
            if (fetch && fetch.rejected) {
                this.setState({
                    pending: false,
                });
            }
        }
    }

    update = (errorNumber) => {
        this.setState({
            errorNumber: errorNumber,
            pending: false,
        });
        if (errorNumber === 0) {
            const {dispatch,} = this.props;
            dispatch(actions.setChangePsswrdOnNextLogin(false));
        }
    }

    calculateHeight = () => {
        this.setState({
            height: window.innerHeight - NAVBAR_HEIGHT,
        });
    };

    handleChangePsswrd = (props) => {
        if (!this.state.pending) {
            this.setPending(true);
            this.executeChangePsswrd(props);
        }
    }

    setPending = (pending) => {
        this.setState({
            pending: pending,
        });
    }

    executeChangePsswrd = (props) => {
    }

    getFetchValue = (props) => {
        return null;
    }

    getFetch = (props) => {
        return null;
    }

    getReferrer = () => {
        return '/';
    }

    isAfterLogin = () => {
        return false;
    }

    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    render() {
        const {isAuthenticated, } = this.props;
        const errorNumber = this.state.errorNumber;
        const fetch = this.getFetch(this.props);
        const canRedirect = fetch && !fetch.rejected
            && !fetch.pending && fetch.fulfilled;
        if (errorNumber!==0 || !canRedirect) {
            return isAuthenticated &&
                <ChangePsswrd
                    height={this.state.height}
                    onChangePsswrd={this.handleChangePsswrd}
                    afterLogin={this.isAfterLogin()}
                    pending={this.state.pending}
                />;
        } else {
            return isAuthenticated &&
                <Redirect to={{pathname: '/', state: {referrer: this.getReferrer()}}}/>
        }
    }
}

export const mapStateToProps = state => ({
    isAuthenticated: security.selectors.isAuthenticated(state),
});

export default BaseChangePsswrdPage;