import React from 'react';
import FormattedMessage from '../../../i18n';
import moment from 'moment';
import PropTypes from 'prop-types';
import FormWrapper from '../../../settings/components/FormWrapper';
import FormRow from '../../../settings/components/FormRow';
import ButtonWrapper from '../../../settings/components/ButtonWrapper';
import Button from '../../../../components/Button';
import ButtonDatePicker from '../../../common/components/FlightList/ButtonDatePicker';
import ButtonTimePicker from './ButtonTimePicker';
import Wrapper from '../../../settings/components/Wrapper';
import TranslatedLabel from "../../../../components/TranslatedLabel/TranslatedLabel";
import Dropdown from "../../../common/components/Dropdown/Dropdown";

const widthStyle = {
  width: 200,
};

const divStyle = {
  display: 'flex',
  justifyContent: 'center',
};

const divSpaceStyle = {
  display: 'flex',
  justifyContent: 'space-around',
};

const loads = [
  { label: 'load', value: 'ON_LOAD' },
  { label: 'offload', value: 'OFF_LOAD' },
  { label: 'both', value: ' ' },
];

const CriticalTimeFilterPopUp = ({
  selectedDateFrom,
  selectedTimeFrom,
  selectedDateTo,
  selectedTimeTo,
  selectedAirport,
  selectedLoad,
  onDropDownChangeDistribution,
  onDropDownChangeAirport,
  onDateChangeFrom,
  onDateChangeTo,
  onTimeChangeTo,
  onTimeChangeFrom,
  onConfirm,
  airports,
  resetForm,
}) => {
  function handleConfirm() {
    onConfirm();
  }

  function handleChangeDateFrom(date) {
    onDateChangeFrom(date);
    if (selectedDateTo) {
      if (moment(selectedDateTo).isBefore(moment(selectedDateFrom))) {
        selectedDateTo = selectedDateFrom;
      }
    }
  }

  function handleChangeTimeFrom(date) {
    onTimeChangeFrom(date);
  }

  function handleChangeDateTo(date) {
    if (moment(date).isBefore(moment(selectedDateFrom))) return;
    onDateChangeTo(date);
  }

  function handleChangeTimeTo(date) {
    onTimeChangeTo(date);
  }

  function handleResetForm() {
    resetForm();
  }

  const getLabel = (keyword, defaultValue) => {
    return <TranslatedLabel
        keyword={`${keyword}`}
        defaultValue={defaultValue} />
  };

  const getDistribution = () => {
    return loads.find((load) => load.value===selectedLoad);
  }

  const convertMoment2Date = (m) => {
    return m ? moment(m).toDate() : null;
  }

  return (
    <Wrapper>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <FormWrapper>
          <FormRow>
            <div style={divStyle}>
              <div>
                <FormattedMessage id="dashboard.dateFrom" defaultMessage="Date from" />
                <ButtonDatePicker
                  defaultDate={convertMoment2Date(selectedDateFrom)}
                  onChange={handleChangeDateFrom}
                />
              </div>
              <div>
                <FormattedMessage id="dashboard.timeFrom" defaultMessage="Time from" />
                <ButtonTimePicker
                  defaultTime={convertMoment2Date(selectedTimeFrom)}
                  onChange={handleChangeTimeFrom}
                />
              </div>
              <div style={widthStyle}>
                <Dropdown
                    label={getLabel('dashboard.airportcode','Airport code')}
                    items={airports}
                    item={selectedAirport}
                    getItemLabel={(airport) => airport?airport.iata:null}
                    onItemChange={onDropDownChangeAirport}
                />
              </div>
            </div>
          </FormRow>

          <FormRow>
            <div>
              <FormattedMessage id="dashboard.dateTo" defaultMessage="Date to" />
              <ButtonDatePicker
                defaultDate={convertMoment2Date(selectedDateTo)}
                onChange={handleChangeDateTo}
              />
            </div>
            <div>
              <FormattedMessage id="dashboard.timeTo" defaultMessage="Time to" />
              <ButtonTimePicker
                defaultTime={convertMoment2Date(selectedTimeTo)}
                onChange={handleChangeTimeTo}
              />
            </div>
            <div style={widthStyle}>
              <Dropdown
                  label={getLabel('dashboard.distribution', 'Indicator Load')}
                  items={loads}
                  item={getDistribution()}
                  getItemLabel={(load) => load?load.label:null}
                  onItemChange={onDropDownChangeDistribution}
              />
            </div>
          </FormRow>

          <div style={divSpaceStyle}>
            <ButtonWrapper>
              <Button
                label={<FormattedMessage id="print.clear" defaultMessage="Clear" />}
                onClick={handleResetForm}
                primary
              />
            </ButtonWrapper>

            <ButtonWrapper>
              <Button
                label={<FormattedMessage id="print.confirm" defaultMessage="Confirm" />}
                onClick={handleConfirm}
                primary
              />
            </ButtonWrapper>
          </div>
        </FormWrapper>
      </div>
    </Wrapper>
  );
};

CriticalTimeFilterPopUp.propTypes = {
  selectedDateFrom: PropTypes.object,
  selectedTimeFrom: PropTypes.object,
  selectedDateTo: PropTypes.object,
  selectedTimeTo: PropTypes.object,
  selectedAirport: PropTypes.object,
  selectedLoad: PropTypes.string,
  onDropDownChangeDistribution: PropTypes.func,
  onDropDownChangeAirport: PropTypes.func,
  handleTimeChangeTo: PropTypes.func,
  handleTimeChangeFrom: PropTypes.func,
  handleDateChangeTo: PropTypes.func,
  handleDateChangeFrom: PropTypes.func,
};

export default CriticalTimeFilterPopUp;
