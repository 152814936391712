import React from "react";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";

class TranslatedLabel extends React.Component {
    static propTypes = {
        keyword: PropTypes.string.isRequired,
        defaultValue: PropTypes.string.isRequired,
        // Injected by i18next
        t: PropTypes.func.isRequired,
    }

    render() {
        const {t, keyword, defaultValue} = this.props;
        return t(`${keyword}`)||defaultValue;
    }
}

export default withTranslation()(TranslatedLabel);