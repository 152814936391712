import PropTypes from 'prop-types';
import React from 'react';
import reduxFetch from 'react-redux-fetch';
import {compose} from 'redux';
import injectApiRoutes from '../../../app/api/injectApiRoutes';
import CenteredCircularProgress from "../../../components/CenteredCircularProgress";
import get from "lodash/get";
import isEqual from 'lodash/isEqual';
import globals from "../../../app/globals";
import commonSelectors from "../../common/selectors";
import LoadSequenceDistribution from "../component/distribution/LoadSequenceDistribution";

class LoadSequenceDistributionPage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    flightId: PropTypes.string,
    distribution: PropTypes.number,
    height: PropTypes.number,
    dispatchLoadSequencePost: PropTypes.func,
    dispatchDeleteFlightPost: PropTypes.func,
    deleteFlightFetch: PropTypes.object,
    loadSequenceFetch: PropTypes.object,
    onFlightDeleted: PropTypes.func,
  };

  static contextTypes = {
    registerRefreshAction: PropTypes.func.isRequired,
    removeRefreshAction: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.fetchLoadSequence();
    this.context.registerRefreshAction(
        'loadSequence',
        this.fetchLoadSequence
    );
  }

  componentWillUnmount() {
    this.context.removeRefreshAction('loadSequence');
  }

  isEqualPropsInput = (props) => {
    return this.props.flightId===props.flightId
        && this.props.distribution===props.distribution;
  }

  componentDidUpdate(prevProps) {
    if (this.props.flightId>0 && !this.isEqualPropsInput(prevProps)) {
      this.fetchLoadSequence();
    }
  }

  fetchLoadSequence = () => {
    if (this.props.flightId>0) {
      this.props.dispatchLoadSequencePost(this.props);
    }
  }

  isDifferent = (obj1, obj2, property) => {
    return !isEqual(get(obj1, property), get(obj2, property));
  }

  shouldComponentUpdate(nextProps) {
    const fetchIsDifferent = this.isDifferent(
        this.props.loadSequenceFetch, nextProps.loadSequenceFetch, 'value');
    const heightDifferent = this.isDifferent(this.props, nextProps, 'height');
    return (fetchIsDifferent || !this.isEqualPropsInput(nextProps)
        || heightDifferent);
  }

  handleLoadSequenceAction = (loadSequenceMethod) => {
    this.props.dispatchLoadSequencePost({
      urlType: URL_TYPE.ACTION,
      loadSequenceMethod: loadSequenceMethod,
    });
  }

  sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  handleDeleteFlight = () => {
    const {flightId, onFlightDeleted} = this.props;
    this.props.dispatchDeleteFlightPost({
      flightId: flightId
    })
    this.sleep(1500).then(r => {
      if (onFlightDeleted) {
        onFlightDeleted();
      }
    });
  }

  handleLock = ({uldId, action}) => {
    this.props.dispatchLoadSequencePost({
      urlType: URL_TYPE.LOCK,
      uldId: uldId,
      action: action,
    });
  }

  render() {
    const { loadSequenceFetch, flightId, distribution, height } = this.props;
    if (!flightId) {
      return (<div />);
    } else if (loadSequenceFetch.fulfilled) {
      const value = get(loadSequenceFetch, 'value');
      const id = `dist${(new Date()).getTime()}`;
      return (
          <LoadSequenceDistribution
              key={id}
              id={id}
              value={value}
              distribution={distribution}
              height={height}
              onLockAction={this.handleLock}
              onLoadSequenceAction={this.handleLoadSequenceAction}
              onDeleteFlight={this.handleDeleteFlight}
          />
      );
    }
    return <CenteredCircularProgress />;
  }
}

const URL_TYPE = {REGULAR: 1, ACTION: 2, LOCK: 3, DELETE: 4};

const getUrl = (urlType, props,) => {
    if (urlType===URL_TYPE.ACTION) {
        return props.apiRoutes.doLoadSequenceAction();
    } else if (urlType===URL_TYPE.LOCK) {
      return props.apiRoutes.doFlightUldLockAction();
    } else if (urlType===URL_TYPE.DELETE) {
      return props.apiRoutes.deleteFlight();
    }
    return props.apiRoutes.getLoadSequence();
}

const getDistribution = (props) =>
    props.distribution===1?"ON_LOAD":"OFF_LOAD";

const mapPropToDispatchToProps = props => [
  {
    resource: 'loadSequence',
    method: 'POST',
    request: ({
                urlType = URL_TYPE.REGULAR,
                loadSequenceMethod = null,
                uldId = null,
                action = null,
              }) => ({
      url: getUrl(urlType, props),
      body: {
        accessToken: props.accessToken,
        application: globals.applicationName,
        flightId: props.flightId,
        distribution: getDistribution(props),
        loadSequenceMethod: loadSequenceMethod,
        uldId: uldId,
        action: action,
      },
    }),
  },
  {
    resource: 'deleteFlight',
    method: 'POST',
    request: ({
                urlType = URL_TYPE.DELETE,
              }) => ({
      url: getUrl(urlType, props),
      body: {
        accessToken: props.accessToken,
        application: globals.applicationName,
        flightId: props.flightId,
        distribution: getDistribution(props),
      },
    }),
  }
];

const mapStateToProps = state => ({
  flightId: commonSelectors.getFlightId(state),
});

const enhance = compose(
  injectApiRoutes,
  reduxFetch(mapPropToDispatchToProps, mapStateToProps)
);

export default enhance(LoadSequenceDistributionPage);