import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
// import { selectors } from 'react-redux-fetch';
import security from '../../modules/security';
import apiRoutes from './routes';
import selectors from './selectors';

const injectApiRoutes = WrappedComponent => {
  const ApiWrapper = ({ webserviceUrl, accessToken, airport, ...other }) =>
    <WrappedComponent
      {...other}
      apiRoutes={apiRoutes(webserviceUrl)}
      accessToken={accessToken}
      airportIataCode={airport && airport.iata}
    />;

  ApiWrapper.propTypes = {
    webserviceUrl: PropTypes.string.isRequired,
    accessToken: PropTypes.string,
    airport: PropTypes.object,
  };

  const mapStateToProps = state => ({
    // webserviceUrl: selectors.getRepository('config')(state).webservice.url,
    webserviceUrl: selectors.getWebServiceUrl(state),
    accessToken: security.selectors.getToken(state),
    airport: security.selectors.getAirport(state),
  });

  return connect(mapStateToProps)(ApiWrapper);
};

export default injectApiRoutes;
