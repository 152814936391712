import styled from 'styled-components';

const RolsIcon = styled.i`
  display: block;
  width: 57px;
  height: 57px;
  position: absolute;
  left: 50%;
  margin-left: -28px;
  margin-top: -28px;
  background: url(assets/img/57x57.png);
`;

export default RolsIcon;
