import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import {compose} from 'redux';
import reduxFetch, {container} from 'react-redux-fetch';
import CenteredCircularProgress
    from '../../../components/CenteredCircularProgress';
import commonSelectors from '../../common/selectors';
import CriticalTimeDetailPrint
    from '../component/CriticalTimeDetail/CriticalTimeDetailPrint';
import injectApiRoutes from '../../../app/api/injectApiRoutes';
import ErrorText from '../../security/components/ErrorText';
import Redirect from 'react-router-dom/Redirect';
import {
    getAftTime,
    getBulkTime,
    getFwdTime,
    getMainDeckTime,
    getRampTime,
    getReportsPerDistribution,
} from '../CriticalTimesFunctions';
import momentPropTypes from 'react-moment-proptypes';
import toMilliseconds from '../../../app/utils/toMilliseconds';
import securitySelectors from '../../security/selectors';
import {withTranslation} from "react-i18next";

const fetchIsFulfilled = container.getUtil('componentHelpers').fetchIsFulfilled;

class CriticalTimeDetailPage extends React.Component {
  static propTypes = {
    dispatchCriticalTimeFlightPost: PropTypes.func,
    dispatch: PropTypes.func.isRequired,
    criticalTimeFlightFetch: PropTypes.object,
    colorsFetch: PropTypes.object,
    flightId: PropTypes.string,
    history: PropTypes.object,
    departureTime: momentPropTypes.momentObj,
    timeDistribution: PropTypes.string,
    distribution: PropTypes.string,
    userFrequency: PropTypes.shape({
      flights: PropTypes.number,
    }),
    // Injected by i18next
    t: PropTypes.func.isRequired,
  };

  static contextTypes = {
    registerRefreshAction: PropTypes.func.isRequired,
    removeRefreshAction: PropTypes.func.isRequired,
  };

  state = {
    redirect: false,
  };

  componentWillMount() {
    this.context.registerRefreshAction('criticalTimeFlight', this.fetchFlightCriticalTimes);
  }

  componentWillReceiveProps(nextProps) {
    if (fetchIsFulfilled(this.props, nextProps, 'criticalTimeFlight')) {
      this.clearTimeOutCriticalTimes();
      this.timer = setTimeout(
        this.fetchFlightCriticalTimes,
        toMilliseconds(this.props.userFrequency.flights)
      );
    }
  }

  componentDidMount() {
    this.props.dispatchCriticalTimeFlightPost();
    this.context.registerRefreshAction('criticalTimeFlight', this.fetchFlightCriticalTimes);
  }

  componentWillUpdate(nextProps) {
    if (this.isDateDifferent(nextProps) && nextProps.flightId) {
      this.clearTimeOutCriticalTimes();
      this.fetchFlightCriticalTimes(nextProps.departureTime);
    }
  }

  clearTimeOutCriticalTimes = () => {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  componentWillUnmount() {
    this.clearTimeOutCriticalTimes();
    this.context.removeRefreshAction('criticalTimeFlight');
  }

  shouldComponentUpdate(nextProps) {
    const flightsFetchIsDifferent = !isEqual(
      this.props.criticalTimeFlightFetch,
      nextProps.criticalTimeFlightFetch
    );
    return this.isDateDifferent(nextProps) || flightsFetchIsDifferent;
  }

  isDateDifferent = nextProps =>
    nextProps.departureTime && !nextProps.departureTime.isSame(this.props.departureTime, 'day');

  handleBackButtonClick = () => {
    this.setState(prevState => ({
      redirect: !prevState.redirect,
    }));
    this.forceUpdate();
  };

  fetchFlightCriticalTimes = () => {
    this.props.dispatchCriticalTimeFlightPost();
  };

  render() {
    const { redirect } = this.state;
    const { criticalTimeFlightFetch, colorsFetch } = this.props;

    if (redirect) {
      return <Redirect to="/dashboard" />;
    }

    if (criticalTimeFlightFetch.value && colorsFetch.value) {
      if (!criticalTimeFlightFetch.value.hasAccess) {
        return <ErrorText>Please provide valid credentials to access this page</ErrorText>;
      }

      const times = get(criticalTimeFlightFetch, 'value.criticalTimes.criticalTime') || [];
      const decks = get(criticalTimeFlightFetch, 'value.decks.deck') || [];
      const time = getRampTime(times);
      const colors = get(colorsFetch, 'value.colorDefinitions.colorDefinition') || [];
      const mainTimes = getMainDeckTime(times);
      const fwdTimes = getFwdTime(times);
      const aftTimes = getAftTime(times);
      const bulkTimes = getBulkTime(times);

      const reports = getReportsPerDistribution(
        time.flight.flightReports.flightReport,
        time.distribution
      );

      return (
        <div>
          {!criticalTimeFlightFetch.fulfilled ? (
            <CenteredCircularProgress size={15} />
          ) : (
            <div style={{ visibility: 'hidden' }}>
              <CenteredCircularProgress size={15} />
            </div>
          )}
          <CriticalTimeDetailPrint
            mainTimes={mainTimes}
            aftTimes={aftTimes}
            fwdTimes={fwdTimes}
            bulkTimes={bulkTimes}
            colors={colors}
            decks={decks}
            onBackButtonClick={this.handleBackButtonClick}
            time={time}
            reports={reports}
          />
        </div>
      );
    }
    return <CenteredCircularProgress />;
  }
}

const mapPropToDispatchToProps = props => [
  {
    resource: 'criticalTimeFlight',
    method: 'POST',
    request: () => ({
      url: props.apiRoutes.getFlightCriticalTime(),
      body: {
        accessToken: props.accessToken,
        flightId: props.flightId,
        distribution: props.timeDistribution,
        criticalTimeDistribution: props.timeDistribution,
      },
    }),
  },
  {
    resource: 'colors',
    method: 'POST',
    request: () => ({
      url: props.apiRoutes.getColors(),
      body: {
        accessToken: props.accessToken,
      },
    }),
  },
];

const mapStateToProps = state => ({
  flightId: commonSelectors.getFlightId(state),
  distribution: commonSelectors.getDistribution(state),
  timeDistribution: commonSelectors.getTimeDistribution(state),
  userFrequency: securitySelectors.getUserFrequency(state),
});

const enhance = compose(
  injectApiRoutes,
  reduxFetch(mapPropToDispatchToProps, mapStateToProps)
);

export default enhance(withTranslation()(CriticalTimeDetailPage));
