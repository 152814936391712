import PropTypes from 'prop-types';
import React from 'react';
import SplitPane from "react-split-pane";
import Graph from "./Graph/Graph";
import StagedUldsPanel from "./Bottom/StagedUldsPanel/StagedUldsPanel";
import LoadSequencePanel from "./Bottom/LoadSequencePanel/LoadSequencePanel";
import "../../style/splitPane.css";
import styled from "styled-components";

class LoadSequenceDistribution extends React.Component {
    static propTypes = {
        id: PropTypes.string,
        value: PropTypes.object,
        distribution: PropTypes.number,
        height: PropTypes.number,
        onLockAction: PropTypes.func,
        onLoadSequenceAction: PropTypes.func,
        onDeleteFlight: PropTypes.func,
    };

    state = {
        splitHeight: 0,
    }

    constructor(props) {
        super(props);
        this.state = {splitHeight: this.getInitSplitHeight()};
    }

    getInitSplitHeight = () => {
        const { height, } = this.props;
        return height / 4;
    }

    handleChange = (height) => {
        this.setState({
            splitHeight: height,
        });
    }

    render() {
        const { value, distribution, height,
            onLockAction, onLoadSequenceAction, onDeleteFlight, id } = this.props;
        const {
            decks,
            flight,
            holds,
            loadsequence,
            locations,
        } = value;
        const splitHeight = this.state.splitHeight;
        const loadSequencePanelHeight = height - splitHeight;
        const graphId = `${id}Graph`;
        return (
            <Container>
                <SplitPane
                    split={'horizontal'}
                    minSize={40}
                    maxSize={height-20}
                    defaultSize={this.getInitSplitHeight()}
                    onChange={this.handleChange}
                >
                    <Graph
                        key={graphId}
                        id={graphId}
                        distribution={distribution}
                        flight={flight}
                        decks={decks}
                        locations={locations}
                        loadSequence={loadsequence}
                        height={splitHeight}
                        onLockAction={onLockAction}
                    />
                    <SplitPane
                        split={'vertical'}
                        minSize={'10%'}
                        defaultSize={'20%'}
                    >
                        <StagedUldsPanel
                            distribution={distribution}
                            holds={holds.hold}
                        />
                        <LoadSequencePanel
                            flight={flight}
                            distribution={distribution}
                            loadSequence={loadsequence}
                            onLoadSequenceAction={onLoadSequenceAction}
                            onDeleteFlight={onDeleteFlight}
                            height={loadSequencePanelHeight}
                        />
                    </SplitPane>
                </SplitPane>
            </Container>
        );
    }
}

const Container = styled.div`
  width: 100%,
  overflow-y: hidden;
  overflow-x: hidden;
  `;

export default LoadSequenceDistribution;