import React from 'react';
import {DataGrid} from '@material-ui/data-grid';
import CheckIcon from '@material-ui/icons/Check';
import TranslatedLabel from "../../../../../../components/TranslatedLabel/TranslatedLabel";
import PropTypes from "prop-types";
import "../../../../style/loadSequence.css";

class LoadSequenceTable extends React.Component {
    static propTypes = {
        loadSequence: PropTypes.object.isRequired,
        height: PropTypes.number,
    }

    getColumns = () => {
        const getHeaderName = (field) => {
            return !isHide(field)?<TranslatedLabel
                keyword={`communicator.loadSequence.table.header.${field.id}`}
                defaultValue={field.name} />:field.id;
        };

        const isHide = (field) => {
            return !field.name;
        };

        const getWidth = (field) => {
            return !isHide(field)?field.width:0;
        };

        const getCellClassNames = (params) => {
            const freightCssClass = (params.data && params.data.freightCssClass?params.data.freightCssClass:null);
            if (params.data && params.data.tailTip) {
                return 'red';
            } else if (params.field===FIELDS.MAC_DOOR.id
                || params.field===FIELDS.MAC_FINAL.id) {
                return 'green';
            } else if (params.field===FIELDS.FREIGHT.id) {
                return freightCssClass;
            }
            return null;
        }

        const getRenderCell = (params) => {
            if ((params.field === FIELDS.PRELOADED.id
                || params.field === FIELDS.CHECKED.id
                || params.field === FIELDS.CONFIRMED.id)) {
                return (params.value?<CheckIcon color={"primary"}/>:<div />);
            }
            return null;
        };

        const getHeader = ({field,}) => (
            {
                field: field.id,
                headerName: getHeaderName(field),
                hide: isHide(field),
                width: getWidth(field),
                flex: 0,
                cellClassName: getCellClassNames,
                renderCell: getRenderCell,
                sortable: false,
            }
        );

        return Object.values(FIELDS).map((field) =>
            getHeader({field: field}));
    };

    getRows = () => {
        const {loadSequence: {loadSequenceChain},} = this.props;
        return (loadSequenceChain?loadSequenceChain.map((loadSequenceChain, index) => {
            return Object.assign({id: index}, loadSequenceChain);
        }):[]);
    };

    render() {
        const {height, } = this.props;
        const rows = this.getRows();
        const columns = this.getColumns();
        return (
            <div style={{display: 'flex', height: height, width: '100%'}}>
                <div style={{ flexGrow: 1 }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        headerHeight={20}
                        rowHeight={20}
                        hideFooter={rows.length<=100}
                        rowsPerPageOptions={[100]}
                    />
                </div>
            </div>
        );
    }
}

const FIELDS = {
    ID: {id: 'id'},
    DOOR_SEQUENCE: {id: 'doorSequence', name: 'Seq', width: 60},
    FROM_POS: {id: 'fromPos', name: 'From Pos', width: 100},
    DOOR: {id: 'door', name: 'Door', width: 80},
    TO_POS: {id: 'toPos', name: 'To Pos', width: 90},
    FREIGHT: {id: 'freight', name: 'Freight', width: 120},
    FROM: {id: 'from', name: 'From', width: 80},
    TO: {id: 'to', name: 'To', width: 80},
    MAC_DOOR: {id: 'macDoor', name: '%Mac Door', width: 110},
    MAC_FINAL: {id: 'macFinal', name: '%Mac Final', width: 110},
    REMARK: {id: 'remark', name: 'Remark', width: 120},
    ACTION: {id: 'action', name: 'Action', width: 80},
    PRELOADED: {id: 'preLoaded', name: 'Preloaded', width: 100},
    CHECKED: {id: 'checked', name: 'Checked', width: 90},
    CONFIRMED: {id: 'confirmed', name: 'Confirmed', width: 100},
    TAIL_TIP: {id: 'tailTip'},
    FREIGHT_CSS_CLASS: {id: 'freightCssClass'}
};

export default LoadSequenceTable;