import React from 'react';
import {List, ListItem} from "material-ui";
import PropTypes from "prop-types";
import TranslatedLabel from "../../../../../../components/TranslatedLabel/TranslatedLabel";
import StyledPane from "../StyledPane";

class StagedUldsPanel extends React.Component {
    static propTypes = {
        distribution: PropTypes.number.isRequired,
        holds: PropTypes.array,
    }

    render() {
        const {distribution, holds} = this.props;
        return <div style={{width: '100%'}}>
            <Title distribution={distribution} />
            <HoldsStagedUlds holds={holds} />
        </div>;
    }
}

class Title extends React.Component {
    static propTypes = {
        distribution: PropTypes.number.isRequired,
    }

    render() {
        const {distribution, } = this.props;
        return (
            <>
                <StyledPane style={{padding: 5, height: 40, verticalAlign: 'middle', fontSize: 14,}}>
                    <TranslatedLabel keyword={'communicator.loadSequence.staging'}
                                     defaultValue={'Staging'} />
                </StyledPane>
                {distribution === 2 ?
                <div style={{padding: 5, fontWeight: 'bold', fontSize: '1.5em'}}>
                    <TranslatedLabel keyword={'communicator.loadSequence.remainOnBoardReload'}
                                     defaultValue={'Remain on board/Reload'} />
                </div>
                : null}
            </>)
    }
}



class HoldsStagedUlds extends React.Component {
    static propTypes = {
        holds: PropTypes.array,
    }

    render() {
        const {holds, } = this.props;
        return <div style={{width: '100%'}}>
            {holds.map((hold, ) => {
                return <HoldStagedUlds key={hold.name} hold={hold} />
            })}
        </div>;
    }
}

class HoldStagedUlds extends React.Component {
    static propTypes = {
        hold: PropTypes.object,
    }

    render() {
        const {hold, } = this.props;
        return <div style={{padding: 5}}>
                {hold.name}
                <StagedUlds stagedUlds={hold.stagedUlds} />
            </div>
    }
}

class StagedUlds extends React.Component {
    static propTypes = {
        stagedUlds: PropTypes.object,
    }

    render() {
        const {stagedUlds} = this.props;
        return (stagedUlds?
            <List>
                {stagedUlds.stagedUld.map((stagedUld) => {
                    return <StagedUld key={stagedUld.id}
                                      stagedUld={stagedUld}/>
                })}
            </List>:null);
    }
}

class StagedUld extends React.Component {
    static propTypes = {
        stagedUld: PropTypes.object,
    }

    render() {
        const {stagedUld} = this.props;
        return <ListItem>
            {stagedUld.positionName} - <span className={stagedUld.uldCssClass}>{stagedUld.name}</span>
        </ListItem>
    }
}

export default StagedUldsPanel;