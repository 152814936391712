import React from 'react';
import PropTypes from "prop-types";
import Rectangle from "./shapes/Rectangle";

class Doors extends React.Component {
    static propTypes = {
        id: PropTypes.string,
        svg: PropTypes.object,
        doors: PropTypes.array,
        rectGroup: PropTypes.object,
        horizontal: PropTypes.bool,
    }

    render() {
        const { svg, doors, rectGroup, horizontal, id} = this.props;
        return (
            <>
                {svg && doors.map((door, index) => {
                    const idIndex = `${id}-Door${index}`;
                    return <React.Fragment key={index}>
                        <Rectangle
                            key={idIndex}
                            id={idIndex}
                            rectangle={door}
                            svg={svg}
                            group={rectGroup}
                            horizontal={horizontal}
                            fillColor={door.graphColor ?
                                door.graphColor.fill.value : null}
                            borderColor={door.graphColor ?
                                door.graphColor.border.value : null}
                        />
                    </React.Fragment>
                })}
            </>);
    }
}

export default Doors;