import React from 'react';
import TranslatedLabel from "../../../../../../../components/TranslatedLabel/TranslatedLabel";
import {Button} from "@material-ui/core";
import PropTypes from "prop-types";
import {compose} from "redux";
import {connect} from "react-redux";
import commonSelectors from "../../../../../../common/selectors";
import ConfirmActionLoadSequence from "./ConfirmActionLoadSequence";
import {LOAD_SEQUENCE_ACTION} from "./MethodPanel";
import {isFlightOnLoadFreighter} from "../../../flightUtils";

class MethodButton extends React.Component {
    static propTypes = {
        flight: PropTypes.object,
        keyword: PropTypes.string.isRequired,
        defaultValue: PropTypes.string.isRequired,
        method: PropTypes.object,
        action: PropTypes.string.isRequired,
        distribution: PropTypes.number.isRequired,
        onClick: PropTypes.func.isRequired,
        pending: PropTypes.bool,
    }

    state = {
        open: false,
    }

    isReset = () => {
        const { action, } = this.props;
        return action===LOAD_SEQUENCE_ACTION.RESET;
    }

    handleClick = () => {
        if (this.isReset()) {
            this.setState({open: true,});
        } else {
            this.handleClickConfirmYes();
        }
    }

    handleClickConfirmYes = () => {
        this.setState( { open: false, } );
        const {method, action, onClick} = this.props;
        if (method && method.loadsequenceMethodId) {
            onClick({
                loadsequenceMethodId: method.loadsequenceMethodId,
                action: action,
            });
        }
    }

    handleClickConfirmNo = () => {
        this.setState({open: false, });
    }

    hasAccess = () => {
        const {flight: {baseFlightAccessFunctions},} = this.props;
        if (baseFlightAccessFunctions && baseFlightAccessFunctions.communicator) {
            const accessFunctions = baseFlightAccessFunctions.communicator;
            if (isFlightOnLoadFreighter(this.props, true, true)) {
                return accessFunctions.allowCommunicatorAssignLoadSequenceFreighterFlights;
            } else if (isFlightOnLoadFreighter(this.props, true, false)) {
                return accessFunctions.allowCommunicatorAssignLoadSequencePassengerFlights;
            } else if (isFlightOnLoadFreighter(this.props, false, true)) {
                return accessFunctions.allowCommunicatorAssignOffLoadSequenceFreighterFlights;
            } else if (isFlightOnLoadFreighter(this.props, false, false)) {
                return accessFunctions.allowCommunicatorAssignOffLoadSequencePassengerFlights;
            }
        }
        return false;
    }

    render() {
        const getLabel = (keyword, defaultValue) => {
            return <TranslatedLabel
                keyword={`communicator.loadSequence.${keyword}`}
                defaultValue={defaultValue} />
        };

        const {method, keyword, defaultValue, action, distribution, pending, } = this.props;

        return this.hasAccess()
            ?<>
                <Button
                    variant="contained"
                    color="primary"
                    style={{margin:10}}
                    onClick={this.handleClick}
                    disabled={!method || pending}
                    data-tst-tag={keyword}
                >
                    {getLabel(keyword, defaultValue)}
                </Button>
                {this.isReset()?
                    <ConfirmActionLoadSequence
                        open={this.state.open}
                        action={action}
                        distribution={distribution}
                        onClickYes={this.handleClickConfirmYes}
                        onClickNo={this.handleClickConfirmNo}
                    />:null}
            </>
            :null;
    }
}

const mapStateToProps = state => ({
    flight: commonSelectors.getFlight(state),
});

const enhance = compose(
    connect(mapStateToProps),
);

export default enhance(MethodButton);