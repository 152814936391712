import React from 'react';
import ConfirmDialog from "../../../../../../../components/Dialog/ConfirmDialog";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import TranslatedLabel from "../../../../../../../components/TranslatedLabel/TranslatedLabel";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

class ConfirmDeleteFlight extends React.Component {
    static propTypes = {
        open: PropTypes.bool.isRequired,
        action: PropTypes.string.isRequired,
        distribution: PropTypes.number.isRequired,
        onClickYes: PropTypes.func.isRequired,
        onClickNo: PropTypes.func.isRequired,
        access: PropTypes.bool,
    }

    render() {
        const {open, action, distribution, onClickYes, onClickNo, access} = this.props;
        const distKeyword = (distribution===1? 'onLoad': 'offLoad');
        const actionKeyword = action.toLowerCase();
        return access ? <ConfirmDialog
            open={open}
            keywordTitle={`communicator.loadSequence.confirm.title.${actionKeyword}.${distKeyword}`}
            defaultTitle={DEFAULT.title[actionKeyword][distKeyword]}
            keywordContent={`communicator.loadSequence.confirm.content.${actionKeyword}.${distKeyword}`}
            defaultContent={DEFAULT.content[actionKeyword][distKeyword]}
            keywordYes={'communicator.yes'}
            defaultYes={DEFAULT.yes}
            keywordNo={'communicator.no'}
            defaultNo={DEFAULT.no}
            onClickYes={onClickYes}
            onClickNo={onClickNo}
        /> :
            <Dialog
                open={open}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <TranslatedLabel
                        keyword={`communicator.loadSequence.confirm.title.${actionKeyword}.no_access`}
                        defaultValue={DEFAULT.title[actionKeyword].no_access} />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <TranslatedLabel
                            keyword={`communicator.loadSequence.confirm.content.${actionKeyword}.no_access`}
                            defaultValue={DEFAULT.content[actionKeyword].no_access} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={onClickNo}
                        color="primary"
                        data-tst-tag={'communicator.ok'}
                    >
                        <TranslatedLabel
                            keyword={'communicator.ok'}
                            defaultValue={DEFAULT.ok} />
                    </Button>
                </DialogActions>
            </Dialog>
    }
}

const DEFAULT = {
    title: {
        delete_flight: {
            onLoad: 'Please confirm flight force delete',
            offLoad: 'Please confirm flight force delete',
            no_access: 'No access to force delete flight,'
        },
    },
    content: {
        delete_flight: {
            onLoad: 'There are already containers confirmed on this flight. ' +
                'Are you sure you want to delete this flight? ',
            offLoad: 'There are already containers confirmed on this flight. ' +
                'Are you sure you want to delete this flight? ',
            no_access: 'This user does not have the right to force delete a flight.'
        },
    },
    yes: 'Yes',
    no: 'No',
    ok: 'Ok',
}

export default ConfirmDeleteFlight;