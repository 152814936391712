import styled from 'styled-components';

const NewMessageWrapper = styled.div`
  height: 75px;
  padding: 10px 24px;
  boxSizing: border-box;
  borderTop: 1px solid ${props => props.theme.palette.greyLight};
`;

export default NewMessageWrapper;
