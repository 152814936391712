import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import actions from '../actions';
import socketActions from '../socketActions.js';
import * as actionTypes from '../actionTypes';

const options = {};
const heartbeat = 10000;

let connected = false;
let client = null;
const subscriptions = new Map();

const _subscribe = (topic, dispatch) => {
  if (!subscriptions.has(topic)) {
    const sub = client.subscribe(topic, msg => {
      const msgBody = JSON.parse(msg.body);
      const data = msgBody.data ? JSON.parse(msgBody.data) : {};

      dispatch(
        socketActions.socketMessage(msgBody.type, {
          ...msg,
          body: {
            ...msgBody,
            data,
          },
        })
      );
    });
    subscriptions.set(topic, sub);
  }
};

const _initStompClient = (url, onlineCode, dispatch) => {
  // if (!url.includes('https')) {
  //   console.warn('Backend needs to be https for call functionality');
  //   return;
  // }

  const headers = { onlineCode };
  const topics = [`/user/${onlineCode}/newmessage`];

  // Websocket held by stompjs can be opened only once
  client = Stomp.over(new SockJS(`${url}/socket`, null, options));

  // to enable logs comment next line
  client.debug = null;

  client.heartbeat.outgoing = heartbeat;
  client.heartbeat.incoming = heartbeat;

  // if (Object.keys(this.props).includes('heartbeatIncoming')) {
  //   this.client.heartbeat.incoming = this.props.heartbeatIncoming
  // }
  // if (Object.keys(this.props).includes('heartbeatOutgoing')) {
  //   this.client.heartbeat.outgoing = this.props.heartbeatOutgoing
  // }
  // if (!this.props.debug) {
  //   this.client.debug = () => {}
  // }

  client.connect(
    headers,
    () => {
      connected = true;
      topics.forEach(topic => {
        _subscribe(topic, dispatch);
      });
      console.log('Connecting');
      dispatch(actions.connect());
    },
    error => {
      if (error) {
        console.warn(error);
      }

      if (!client.connected) {
        if (connected) {
          dispatch(actions.disconnect());
          connected = false;
        }
        client = null;
        setTimeout(() => {
          _initStompClient(url, onlineCode, dispatch);
        }, 1000);
      }
    }
  );
};

const callMiddleware = store => next => action => {
  if (action.type === actionTypes.APP_INITIALIZED) {
    const url = store.getState().settings.webServiceUrl;
    const onlineCode = store.getState().security.onlineCode;
    if (client === null && onlineCode) {
      _initStompClient(url, onlineCode, store.dispatch);
    }
  }
  next(action);
};

export default callMiddleware;
