import actionTypes from './actionTypes';

const appInitialized = () => ({
  type: actionTypes.APP_INITIALIZED,
});

const storeHasPendingActions = hasPendingActions => ({
  type: actionTypes.HAS_PENDING_ACTIONS,
  payload: hasPendingActions,
});

const submittingNewMessage = submitting => ({
  type: actionTypes.SUBMITTING_NEW_MSG,
  payload: submitting,
});

export default {
  appInitialized,
  storeHasPendingActions,
  submittingNewMessage,
};
