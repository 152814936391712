import { createStore, compose, applyMiddleware } from 'redux';
import { middleware as fetchMiddleware } from 'react-redux-fetch';
import immutable from 'seamless-immutable';
import { persistStore, autoRehydrate, createTransform } from 'redux-persist';
import createSagaMiddleware, { END } from 'redux-saga';
import rootReducer from '../../modules/rootReducer';
import call from '../../modules/call';

export default function configureStore(initialState) {
  const sagaMiddleware = createSagaMiddleware();

  const middlewares = [fetchMiddleware, sagaMiddleware, ...call.middlewares];

  const store = createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(...middlewares),
      autoRehydrate()
    )
  );

  store.runSaga = sagaMiddleware.run;
  store.close = () => store.dispatch(END);

  const transformImmutable = createTransform(
    inboundState => inboundState.asMutable(),
    outboundState => immutable(outboundState)
  );

  persistStore(store, { whitelist: [], transforms: [transformImmutable] });

  return store;
}
