import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import omit from 'lodash/omit';
import isFunction from 'lodash/isFunction';
import each from 'lodash/each';
import AppBar from '../../../components/AppBar';
import security from '../../../modules/security';
import app from '../../../modules/app';
import AccountMenu from '../components/AccountMenu';
import TranslatedLabel from "../../../components/TranslatedLabel/TranslatedLabel";

class AuthenticatedLayout extends React.Component {
  static propTypes = {
    logout: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    username: PropTypes.string,
    hasPendingActions: PropTypes.bool,
    isDashboard: PropTypes.bool.isRequired,
    isRols: PropTypes.bool.isRequired,
    isLoadSequence: PropTypes.bool.isRequired,
    isLoadAudit: PropTypes.bool.isRequired,
    isChangePsswrdOnNextLogin: PropTypes.bool,
    privacyNoticeUrl: PropTypes.string,
  };

  static childContextTypes = {
    setPrintComponent: PropTypes.func,
    unsetPrintComponent: PropTypes.func,
    registerRefreshAction: PropTypes.func,
    removeRefreshAction: PropTypes.func,
  };

  state = {
    show: false,
    printComponent: false,
  };

  getChildContext() {
    return {
      setPrintComponent: this.handleSetPrintComponent,
      unsetPrintComponent: this.handleUnsetPrintComponent,
      registerRefreshAction: this.handleRegisterRefreshAction,
      removeRefreshAction: this.handleRemoveRefreshAction,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.show && nextProps.hasPendingActions === false) {
      this.props.logout();
      this.setState({ show: false });
    }
  }

  componentWillUnmount() {
    this.setState({ show: false });
  }

  refreshActions: {};

  handleSetPrintComponent = component => {
    this.setState({
      printComponent: component,
    });
  };

  handleUnsetPrintComponent = () => {
    this.setState({
      printComponent: false,
    });
  };

  handleRegisterRefreshAction = (k, refreshAction) => {
    if (!isFunction(refreshAction)) {
      throw new Error(`RefreshAction for ${k} should be a function.`);
    }
    if (this.refreshActions && this.refreshActions[k]) {
      console.warn(
        `Refresh action ${k} already registered. Cannot register 2 actions under the same name`
      ); // eslint-disable-line
      return;
    }
    this.refreshActions = {
      ...this.refreshActions,
      [k]: refreshAction,
    };
  };

  handleRemoveRefreshAction = k => {
    this.refreshActions = omit(this.refreshActions, k);
  };

  handleRefresh = () => {
    each(this.refreshActions, action => {
      action();
    });
  };

  delayLogout = () => {
    if (!this.props.hasPendingActions) {
      this.props.logout();
    } else {
      this.setState({ show: true });
    }
  };

  hasAccess = (access) => {
    const {isChangePsswrdOnNextLogin} = this.props;
    return access && !isChangePsswrdOnNextLogin;
  }

  render() {
    const {children, username, isRols, isDashboard, isLoadSequence, isLoadAudit, isChangePsswrdOnNextLogin, privacyNoticeUrl } = this.props;
    // console.log(isLoadAudit);
    const { show } = this.state;
    return (
      <div>
        <main id="react-no-print">
          <AppBar
            rols={this.hasAccess(isRols)}
            dashboard={this.hasAccess(isDashboard)}
            loadSequence={this.hasAccess(isLoadSequence)}
            loadAudit={this.hasAccess(isLoadAudit)}
            menu={
              <AccountMenu
                account={username}
                onLogout={this.delayLogout}
                onRefresh={this.handleRefresh}
                isChangePsswrdOnNextLogin={isChangePsswrdOnNextLogin}
                privacyNoticeUrl={privacyNoticeUrl}
              />
            }
          />
          <Dialog open={show}>
            <DialogContent>
              <DialogContentText>
                <TranslatedLabel
                    keyword={'communicator.logOutMsg'}
                    defaultValue={'Logging out...'} />
              </DialogContentText>
            </DialogContent>
          </Dialog>
          {children}
        </main>
        <div id="react-print">
          {this.state.printComponent}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  username: security.selectors.getUsername(state),
  isDashboard: security.selectors.isDashboard(state),
  isRols: security.selectors.isRols(state),
  isLoadSequence: security.selectors.isLoadSequence(state),
  isLoadAudit: security.selectors.isLoadAudit(state),
  hasPendingActions: app.selectors.hasPendingActions(state),
  isChangePsswrdOnNextLogin: security.selectors.isChangePsswrdOnNextLogin(state),
  privacyNoticeUrl: security.selectors.getPrivacyNoticeUrl(state),
});

const mapDispatchToProps = dispatch => ({
  logout: bindActionCreators(security.actions.logout, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedLayout);