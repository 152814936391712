import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TranslatedLabel from "../TranslatedLabel/TranslatedLabel";
import PropTypes from "prop-types";

class ConfirmDialog extends React.Component {
    static propTypes = {
        open: PropTypes.bool.isRequired,
        keywordTitle: PropTypes.string.isRequired,
        defaultTitle: PropTypes.string.isRequired,
        keywordContent: PropTypes.string.isRequired,
        defaultContent: PropTypes.string.isRequired,
        keywordYes: PropTypes.string.isRequired,
        defaultYes: PropTypes.string.isRequired,
        keywordNo: PropTypes.string.isRequired,
        defaultNo: PropTypes.string.isRequired,
        onClickYes: PropTypes.func.isRequired,
        onClickNo: PropTypes.func.isRequired,
    }

    render() {
        const {open, keywordTitle, defaultTitle,
            keywordContent, defaultContent,
            keywordYes, defaultYes,
            keywordNo, defaultNo,
            onClickYes, onClickNo,
        } = this.props;

        return <Dialog
            open={open}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <TranslatedLabel
                    keyword={keywordTitle}
                    defaultValue={defaultTitle} />
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <TranslatedLabel
                        keyword={keywordContent}
                        defaultValue={defaultContent} />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClickYes}
                    color="primary"
                    data-tst-tag={keywordYes}
                >
                    <TranslatedLabel
                        keyword={keywordYes}
                        defaultValue={defaultYes} />
                </Button>
                <Button
                    onClick={onClickNo}
                    color="primary"
                    data-tst-tag={keywordNo}
                    autoFocus
                >
                    <TranslatedLabel
                        keyword={keywordNo}
                        defaultValue={defaultNo} />
                </Button>
            </DialogActions>
        </Dialog>
    }
}

export default ConfirmDialog;