import transform from 'lodash/transform';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import omit from 'lodash/omit';

const getKeysOfFunctionProps = object =>
  Object.keys(object).filter(k => typeof object[k] === 'function');

const differenceWithoutFunctionCheck = (object, base) => {  
  const objectWithoutFunctions = omit(object, getKeysOfFunctionProps(object));
  const baseWithoutFunctions = omit(base, getKeysOfFunctionProps(base));
  const changes = (object, base) => {
    return transform(object, (result, value, k) => {
      if (!isEqual(value, base[k])) {
        result[k] = isObject(value) && isObject(base[k]) ? changes(value, base[k]) : value;
      }
    });
  };

  return changes(objectWithoutFunctions, baseWithoutFunctions);
};

export default differenceWithoutFunctionCheck;
