import React from 'react';
import SuperHeader from '../../../../components/Table/SuperHeader';
import {
    Table,
    TableBody,
    TableHeader,
    TableRow,
    TableRowColumn
} from 'material-ui/Table';
import FormattedMessage from '../../../i18n/components/FormattedMessage';
import PropTypes from 'prop-types';
import BackButtonWrapper from '../../../settings/components/BackButtonWrapper';
import Button from '../../../../components/Button';
import MaterialFlightLandIcon from '@material-ui/icons/FlightLand';
import MaterialFlightTakeOffIcon from '@material-ui/icons/FlightTakeoff';
import Timezone from '../../../../components/Timezone';
import {
  getColorForEmptyDeck,
  getCounts,
  getDate,
  getWeights,
  hasFlightAndArrivalDate, isFlightLoadingAndNotChangedAircraft,
  renderPieChartDecksWeights,
  renderPieChartsDeckCounts,
} from '../../CriticalTimesFunctions';
import {CriticalTimesColors} from '../../CriticalTimesColors';
import {ColorEndTime} from '../../ColorEndTime';
import {ColorDuration} from '../../ColorDuration';
import TableRowStyled from '../Table/TableRowStyled';
import FlightReportTable from "../Table/FlightReportTable";

const WIDTH_HEADER = '200%';

const CriticalTimeDetailPrint = ({
  mainTimes,
  aftTimes,
  fwdTimes,
  bulkTimes,
  time,
  decks,
  colors,
  onBackButtonClick,
  reports,
}) => {
  const color = new CriticalTimesColors(colors);
  const colorEnd = new ColorEndTime(colors);
  const colorDur = new ColorDuration(colors);

  const getFixedColorForType = getColorForEmptyDeck(decks);

  function handleBackClick() {
    onBackButtonClick();
  }

  function getDurationDelay(time) {
    const str = time.durationDelay > 0 ? '+' : '';
    return time.durationDelay !== 0 ? ' (' + str + time.durationDelay + ')' : '';
  }

  function getStartDelay(time) {
    const str = time.delayInMinStart > 0 ? '+' : '';
    return time.delayInMinStart !== 0 ? ' (' + str + time.delayInMinStart + ')' : '';
  }

  function getEndDelay(time) {
    const str = time.delayInMinEnd > 0 ? '+' : '';
    return time.delayInMinEnd !== 0 ? ' (' + str + time.delayInMinEnd + ')' : '';
  }

  function isNose(time) {
    return time.eventNameId === 'LOADMDNOSE' || time.eventNameId === 'OFFLOADMDNOSE';
  }

  function getColorPerDeckStart(time) {
    if (time.eventNameId.includes(getFixedColorForType)) {
      return { backgroundColor: '#92D050' };
    }
    return color.getColor(time);
  }

  function getColorPerDeckEnd(time) {
    if (time.eventNameId.includes(getFixedColorForType)) {
      return { backgroundColor: '#92D050' };
    }
    return colorEnd.getColor(time);
  }

  function getColorPerDeckDur(time) {
    if (time.eventNameId.includes(getFixedColorForType)) {
      return { backgroundColor: '#92D050' };
    }
    return colorDur.getColor(time);
  }

  const hasDateArrival = hasFlightAndArrivalDate(time);
  return (
    <div style={{ margin: 30 }}>
      <div style={{ marginBottom: 30 }}>
        <SuperHeader>
          <FormattedMessage id="CriticalTime.criticalTimeInfoDetail" defaultMessage="DETAIL" />
        </SuperHeader>
        <Table selectable={false}>
          <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
            <TableRow selectable={false}>
              <TableRowColumn>
                <FormattedMessage id="print.load" defaultMessage="L/O" />
              </TableRowColumn>
              <TableRowColumn>
                <FormattedMessage id="print.flightNr" defaultMessage="Flight #" />
              </TableRowColumn>
              <TableRowColumn>
                <FormattedMessage id="print.time" defaultMessage="Time" />
              </TableRowColumn>
              <TableRowColumn>
                <FormattedMessage id="print.departure" defaultMessage="Dep" />
              </TableRowColumn>
              <TableRowColumn>
                <FormattedMessage id="print.destination" defaultMessage="Des" />
              </TableRowColumn>
              <TableRowColumn>
                <FormattedMessage id="print.type" defaultMessage="type" />
              </TableRowColumn>
              <TableRowColumn>
                <FormattedMessage id="print.tail" defaultMessage="Tail" />
              </TableRowColumn>
            </TableRow>
          </TableHeader>

          <TableBody displayRowCheckbox={false} adjustForCheckbox={false}>
            {time ? (
              <TableRow>
                {isFlightLoadingAndNotChangedAircraft(time) ? (
                  <TableRowColumn>
                    <MaterialFlightTakeOffIcon>flight</MaterialFlightTakeOffIcon>
                  </TableRowColumn>
                ) : (
                  <TableRowColumn>
                    <MaterialFlightLandIcon>flight-land</MaterialFlightLandIcon>
                  </TableRowColumn>
                )}
                <TableRowColumn>{time.flight.flightNumber}</TableRowColumn>
                <TableRowColumn>
                  {getDate(time) ? <Timezone date={getDate(time)} format="DD MMM HH:mm" /> : ''}
                </TableRowColumn>
                <TableRowColumn>{time.flight.departureAirport.iata}</TableRowColumn>
                <TableRowColumn>{time.flight.arrivalAirport.iata}</TableRowColumn>
                <TableRowColumn>{time.flight.aircraft.aircraftTypeName}</TableRowColumn>
                <TableRowColumn>{time.flight.aircraft.registration}</TableRowColumn>
              </TableRow>
            ) : (
              <TableRow />
            )}
          </TableBody>
        </Table>

        {mainTimes.length > 0 &&
        <Table selectable={false}>
          <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
            <SuperHeader style={{width: WIDTH_HEADER}}>
              <FormattedMessage id="CriticalTime.mainDeck" defaultMessage="Main deck"/>
            </SuperHeader>
            <TableRowStyled/>
          </TableHeader>

          <TableBody displayRowCheckbox={false} adjustForCheckbox={false}>
            {mainTimes.length > 0 &&
            mainTimes.map((maintime, index) => (
                <TableRow key={index}>
                  {isNose(maintime) ? (
                      <>
                        <TableRowColumn>
                          {getCounts(decks, 'NOSE')}
                          {renderPieChartsDeckCounts(decks, 'NOSE')}
                        </TableRowColumn>
                        <TableRowColumn>
                          {getWeights(decks, 'NOSE')}
                          {renderPieChartDecksWeights(decks, 'NOSE')}
                        </TableRowColumn>
                      </>
                  ) : (
                      <>
                        <TableRowColumn>
                          {getCounts(decks, 'SIDE')}
                          {renderPieChartsDeckCounts(decks, 'SIDE')}
                        </TableRowColumn>
                        <TableRowColumn>
                          {getWeights(decks, 'SIDE')}
                          {renderPieChartDecksWeights(decks, 'SIDE')}
                        </TableRowColumn>
                      </>
                  )}
                  <TableRowColumn style={getColorPerDeckStart(maintime)}>
                    <Timezone date={maintime.shouldStartTime} format="HH:mm"/>
                    {getStartDelay(maintime)}
                  </TableRowColumn>
                  <TableRowColumn style={getColorPerDeckEnd(maintime)}>
                    <Timezone date={maintime.shouldEndTime} format="HH:mm"/>
                    {getEndDelay(maintime)}
                  </TableRowColumn>
                  <TableRowColumn style={getColorPerDeckDur(maintime)}>
                    {maintime.duration}
                    {getDurationDelay(maintime)}
                  </TableRowColumn>
                </TableRow>
            ))}
          </TableBody>
        </Table>
        }

        {fwdTimes.length > 0 &&
        <Table selectable={false}>
          <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
            <SuperHeader style={{width: WIDTH_HEADER}}>
              <FormattedMessage id="CriticalTime.FWD" defaultMessage="FWD"/>
            </SuperHeader>
            <TableRowStyled/>
          </TableHeader>

          <TableBody displayRowCheckbox={false} adjustForCheckbox={false}>
            {fwdTimes.length > 0 &&
            fwdTimes.map((fwdTime, index) => (
                <TableRow key={index}>
                  <TableRowColumn>
                    {getCounts(decks, 'FWD')}
                    {renderPieChartsDeckCounts(decks, 'FWD')}
                  </TableRowColumn>

                  <TableRowColumn>
                    {getWeights(decks, 'FWD')}
                    {renderPieChartDecksWeights(decks, 'FWD')}
                  </TableRowColumn>

                  <TableRowColumn style={color.getColor(fwdTime)}>
                    <Timezone date={fwdTime.shouldStartTime} format="HH:mm"/>
                    {getStartDelay(fwdTime)}
                  </TableRowColumn>

                  <TableRowColumn style={colorEnd.getColor(fwdTime)}>
                    {hasDateArrival ? <Timezone date={fwdTime.shouldEndTime} format="HH:mm"/> : ''}
                    {getEndDelay(fwdTime)}
                  </TableRowColumn>
                  <TableRowColumn style={colorDur.getColor(fwdTime)}>
                    {fwdTime.duration}
                    {getDurationDelay(fwdTime)}
                  </TableRowColumn>
                </TableRow>
            ))}
          </TableBody>
        </Table>
        }

        {aftTimes.length > 0 &&
        <Table selectable={false}>
          <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
            <SuperHeader style={{width: WIDTH_HEADER}}>
              <FormattedMessage id="CriticalTime.AFT" defaultMessage="AFT"/>
            </SuperHeader>
            <TableRowStyled/>
          </TableHeader>

          <TableBody displayRowCheckbox={false} adjustForCheckbox={false}>
            {aftTimes.length > 0 &&
            aftTimes.map((aftTime, index) => (
                <TableRow key={index}>
                  <TableRowColumn>
                    {getCounts(decks, 'AFT')}
                    {renderPieChartsDeckCounts(decks, 'AFT')}
                  </TableRowColumn>

                  <TableRowColumn>
                    {getWeights(decks, 'AFT')}
                    {renderPieChartDecksWeights(decks, 'AFT')}
                  </TableRowColumn>

                  <TableRowColumn style={color.getColor(aftTime)}>
                    <Timezone date={aftTime.shouldStartTime} format="HH:mm"/>
                    {getStartDelay(aftTime)}
                  </TableRowColumn>

                  <TableRowColumn style={colorEnd.getColor(aftTime)}>
                    <Timezone date={aftTime.shouldEndTime} format="HH:mm"/>
                    {getEndDelay(aftTime)}
                  </TableRowColumn>
                  <TableRowColumn style={colorDur.getColor(aftTime)}>
                    {aftTime.duration}
                    {getDurationDelay(aftTime)}
                  </TableRowColumn>
                </TableRow>
            ))}
          </TableBody>
        </Table>
        }

        {bulkTimes.length !== 0 &&
        <Table selectable={false}>
          <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
            <SuperHeader style={{width: WIDTH_HEADER}}>
              <FormattedMessage id="CriticalTime.bulk" defaultMessage="Bulk"/>
            </SuperHeader>
            <TableRowStyled/>
          </TableHeader>

          <TableBody displayRowCheckbox={false} adjustForCheckbox={false}>
            {bulkTimes.length !== 0 &&
            bulkTimes.map((bulkTime, index) => (
                <TableRow key={index}>
                  <TableRowColumn>
                    {getCounts(decks, 'BULK')}
                    {renderPieChartsDeckCounts(decks, 'BULK')}
                  </TableRowColumn>

                  <TableRowColumn>
                    {getWeights(decks, 'BULK')}
                    {renderPieChartDecksWeights(decks, 'BULK')}
                  </TableRowColumn>

                  <TableRowColumn style={color.getColor(bulkTime)}>
                    <Timezone date={bulkTime.shouldStartTime} format="HH:mm"/>
                    {getStartDelay(bulkTime)}
                  </TableRowColumn>

                  <TableRowColumn style={colorEnd.getColor(bulkTime)}>
                    <Timezone date={bulkTime.shouldEndTime} format="HH:mm"/>
                    {getEndDelay(bulkTime)}
                  </TableRowColumn>
                  <TableRowColumn style={colorDur.getColor(bulkTime)}>
                    {bulkTime.duration} {getDurationDelay(bulkTime)}
                  </TableRowColumn>
                </TableRow>
            ))}
          </TableBody>
        </Table>
        }
      </div>

      {reports.length !== 0 ? <FlightReportTable reports={reports} /> : <></>}

      <div>
        <BackButtonWrapper>
          <Button
            label={<FormattedMessage id="print.backBtn" defaultMessage="Back" />}
            style={{ width: '20%' }}
            onClick={handleBackClick}
            secondary
          />
        </BackButtonWrapper>
      </div>
    </div>
  );
};

CriticalTimeDetailPrint.propTypes = {
  flightInfo: PropTypes.object,
};

export default CriticalTimeDetailPrint;
