// @flow
import memoize from 'lodash/memoize';
import imageExists from './imageExists';

const PUBLIC_URL = process.env.PUBLIC_URL || '';

const getAssetImageLink = (fileName: string, root: boolean = false): string =>
  imageExists(`${PUBLIC_URL}/${root ? '' : 'assets/img/'}${fileName}`)
    ? `${PUBLIC_URL}/${root ? '' : 'assets/img/'}${fileName}`
    : `${PUBLIC_URL}/${root ? '' : 'assets/img/brc_logo.png'}`;

export default memoize(getAssetImageLink);
