import { take, put } from 'redux-saga/effects';
import { REHYDRATE } from 'redux-persist/constants';
import actions from '../actions';

export function* applicationLoadedChecker() {
  yield take(REHYDRATE);
  // TODO add more checks
  yield put(actions.appInitialized());
}

export default {};
