import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import Lightbox from 'react-image-lightbox';
import get from 'lodash/get';
import Timezone from '../../../../../components/Timezone';
import CenteredCircularProgress from '../../../../../components/CenteredCircularProgress';
import MsgWrapper from '../MsgWrapper';
import MsgBodyWrapper from '../MsgBodyWrapper';
import MsgFrom from '../MsgFrom';
import MsgText from '../MsgText';
import MsgDate from '../MsgDate';
import ButtonsWrapper from './ButtonsWrapper';
import Button from './Button';
import ResponseText from './ResponseText';
import FormattedMessage from "../../../../i18n/components/FormattedMessage";
import TextField from "@material-ui/core/TextField";

class ChangeReqMsg extends React.Component {
  static propTypes = {
    msg: PropTypes.object,
    received: PropTypes.bool,
    unread: PropTypes.bool,
    sendActionRequest: PropTypes.func,
    loaderInfo: PropTypes.object,
    isPending: PropTypes.bool,
    canAddReason: PropTypes.bool,

    // Injected by i18next
    t: PropTypes.func.isRequired,

  };

  state = {
    showLightbox: false,
    error: false,
    reason: '',
    msgId: 0,
  };

  toggleLightbox = () => {
    this.setState({
      showLightbox: !this.state.showLightbox,
    });
  };

  handleChange = e => {
      this.setState({
        reason: e.target.value,
      });
  };

  handleSendActionRequest = (msg, act, op, reason) => {
    if (reason && reason.length > 0 && op.type === 'BAD'){
      this.props.sendActionRequest(msg.sequenceId, act.actionId, op, reason);
      this.setState({
        error: false,
      });
    } else {
      this.setState({
        error: true,
      });
    }
    if (op.type === 'GOOD') {
      this.props.sendActionRequest(msg.sequenceId, act.actionId, op, reason);
      this.setState({
        error: false,
      });
    }
  };

  render() {
    const { msg, received, unread, isPending, t } = this.props;
      const { reason, error} = this.state;

      const canSendReason = msg.actions.action[0].choice.option.length > 0 ? true : false;

    return (
      <MsgWrapper received={received} data-msg-unread={received && unread}>
        {msg.from &&
          <MsgFrom>
            {t('communicator.message.from')}: {msg.from}{' '}
          </MsgFrom>}

        <MsgBodyWrapper received={received} unread={canSendReason}>
          {msg.text &&
            <MsgText received={received} unread={received && unread}>
              {msg.text}
            </MsgText>}

            {<span hidden={canSendReason} required>
              <FormattedMessage id="flight.denyReason" defaultMessage="Deny reason:" />
            </span>}
          {!canSendReason ? (
              <TextField
                  error={error}
                  value={reason}
                  helperText={error ? 'Deny reason needed' : ''}
                  onChange={this.handleChange}
              ></TextField>
          ) : (
             <></>
          )}
          {msg.pic64 !== '' &&
            <img
              alt="ROLS"
              style={{ width: '100%', smarginLeft: 10, cursor: 'pointer' }}
              src={`data:image/png;base64,${msg.pic64}`}
              onClick={this.toggleLightbox}
            />}

          {this.state.showLightbox &&
            <Lightbox
              mainSrc={`data:image/png;base64,${msg.pic64}`}
              mainSrcThumbnail={`data:image/png;base64,${msg.pic64}`}
              onCloseRequest={this.toggleLightbox}
              clickOutsideToClose
              enableZoom
            />}
          {isPending
            ? <CenteredCircularProgress size={30} />
            : unread
              ? <ButtonsWrapper>
                  {msg.actions.action.map(act =>
                    act.options.option.map(op =>
                      <Button
                        key={act.actionId + op.type}
                        primary={op.type === 'GOOD'}
                        secondary={op.type === 'BAD'}
                        onClick={() => {
                          if (!isPending) {
                            this.handleSendActionRequest(msg, act, op, reason)
                          }
                        }}
                        disabled={isPending}
                      >
                        {t(op.name)}
                      </Button>
                    )
                  )}
                </ButtonsWrapper>
              : get(msg, 'actions.action[0].choice.option[0].type') === 'GOOD'
                ? <ResponseText>{t('communicator.message.accepted')}</ResponseText>
                : <ResponseText>{t('communicator.message.denied')}</ResponseText>}
        </MsgBodyWrapper>
        <MsgDate>
          <Timezone date={msg.time} />
        </MsgDate>
      </MsgWrapper>
    );
  }
}

export default withTranslation()(ChangeReqMsg);
