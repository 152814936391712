import PropTypes from 'prop-types';
import React from 'react';
import SuperHeader from '../../../../components/Table/SuperHeader';
import FormattedMessage from '../../../i18n/components/FormattedMessage';
import { Table, TableBody, TableHeader, TableRow, TableRowColumn } from 'material-ui/Table';
import MaterialFlightLandIcon from '@material-ui/icons/FlightLand';
import MaterialFlightTakeOffIcon from '@material-ui/icons/FlightTakeoff';
import Timezone from '../../../../components/Timezone';
import BackButtonWrapper from '../../../settings/components/BackButtonWrapper';
import Button from '../../../../components/Button';
import {
  getAmountForCount,
  getAmountForWeight,
  getToDoWeight,
  getDate,
  getDelayDuration,
  getDeviation,
  getEndedloaded,
  getEndedloadedTime,
  getFirstloaded,
  getFirstloadedTime,
  getLongestDuration,
  getPieChartValuesForCount,
  getPieChartValuesForWeight, renderMessageIcon, isFlightLoadingAndNotChangedAircraft,
} from '../../CriticalTimesFunctions';
import { StatusColor } from '../../StatusColor';
import {ColorEndTime} from "../../ColorEndTime";

const fixedColumnWidth = { width: 80 };
const fixedMediumWidth = { width: 70 };
const fixedSmallerWidth = { width: 30 };

const CriticalTimePrint = ({
  criticalTimes,
  colors,
  onListClick,
  onBackButtonClick,
  isAllowedToGoBack,
}) => {
  const colorEndTime = new ColorEndTime(colors);
  const statusColors = new StatusColor(colors);

  function handleListClick(time) {
    onListClick(time.distribution, time.flight);
  }

  function renderTimeZoneForFlightFull(time) {
    if (getDate(time) !== null) {
      return <Timezone date={getDate(time)} format="DD MMM HH:mm" />;
    }
    return '';
  }

  function getDelay(delay) {
    return delay === 0 ? ' ' : delay > 0 ? ' (+' + delay + ')' : ' (' + delay + ')';
  }

  return (
    <div style={{ margin: 30, marginTop: 10 }}>
      <div style={{ marginBottom: 30 }}>
        <SuperHeader>
          <FormattedMessage id="CriticalTime.criticalTimeInfo" defaultMessage="DASHBOARD" />
        </SuperHeader>
        <Table selectable={true}>
          <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
            <TableRow>
              <TableRowColumn style={{ width: 25 }}>
                <FormattedMessage id="print.load" defaultMessage="L/O" />
              </TableRowColumn>
              <TableRowColumn style={fixedMediumWidth}>
                <FormattedMessage id="print.flightNr" defaultMessage="Flight #" />
              </TableRowColumn>
              <TableRowColumn style={fixedColumnWidth}>
                <FormattedMessage id="print.time" defaultMessage="Time" />
              </TableRowColumn>
              <TableRowColumn style={fixedSmallerWidth}>
                <FormattedMessage id="print.departure" defaultMessage="Dep" />
              </TableRowColumn>
              <TableRowColumn style={fixedSmallerWidth}>
                <FormattedMessage id="print.destination" defaultMessage="Des" />
              </TableRowColumn>
              <TableRowColumn style={fixedMediumWidth}>
                <FormattedMessage id="print.type" defaultMessage="Type" />
              </TableRowColumn>
              <TableRowColumn style={fixedMediumWidth}>
                <FormattedMessage id="print.tail" defaultMessage="Tail" />
              </TableRowColumn>
              <TableRowColumn>
                <FormattedMessage id="print.unit" defaultMessage="Units" />
              </TableRowColumn>
              <TableRowColumn>
                <FormattedMessage id="print.weight" defaultMessage="Weight" />
              </TableRowColumn>
              <TableRowColumn>
                <FormattedMessage id="print.start" defaultMessage="Start" />
              </TableRowColumn>
              <TableRowColumn>
                <FormattedMessage id="print.end" defaultMessage="End" />
              </TableRowColumn>
              <TableRowColumn>
                <FormattedMessage id="print.duration" defaultMessage="Duration" />
              </TableRowColumn>
              <TableRowColumn>
                <FormattedMessage id="print.deviation" defaultMessage="Deviation" />
              </TableRowColumn>
              <TableRowColumn>
                <FormattedMessage id="print.rcl" defaultMessage="RCL" />
              </TableRowColumn>
            </TableRow>
          </TableHeader>

          <TableBody displayRowCheckbox={false}>
            {criticalTimes.length > 0 &&
              criticalTimes.map((criticalTime, index) => (
                <TableRow
                  key={index}
                  onMouseDown={() => handleListClick(criticalTime)}
                  style={{ cursor: 'pointer' }}
                >
                  {isFlightLoadingAndNotChangedAircraft(criticalTime) ? (
                    <TableRowColumn style={fixedSmallerWidth}>
                      <div>{renderMessageIcon(criticalTime)}</div>
                      <MaterialFlightTakeOffIcon>flight</MaterialFlightTakeOffIcon>
                    </TableRowColumn>
                  ) : (
                    <TableRowColumn style={fixedSmallerWidth}>
                      <div>{renderMessageIcon(criticalTime)}</div>
                      <MaterialFlightLandIcon>flight-land</MaterialFlightLandIcon>
                    </TableRowColumn>
                  )}
                  <TableRowColumn style={fixedMediumWidth}>
                    {criticalTime.flight.flightNumber}
                  </TableRowColumn>
                  <TableRowColumn style={fixedColumnWidth}>
                    {renderTimeZoneForFlightFull(criticalTime)}
                  </TableRowColumn>
                  <TableRowColumn style={fixedSmallerWidth}>
                    {criticalTime.flight.departureAirport.iata}
                  </TableRowColumn>
                  <TableRowColumn style={fixedSmallerWidth}>
                    {criticalTime.flight.arrivalAirport.iata}
                  </TableRowColumn>
                  <TableRowColumn style={fixedMediumWidth}>
                    {criticalTime.flight.aircraft.aircraftTypeName}
                  </TableRowColumn>
                  <TableRowColumn style={fixedMediumWidth}>
                    {criticalTime.flight.aircraft.registration}
                  </TableRowColumn>
                  <TableRowColumn>
                    {getAmountForCount(criticalTime)} / {criticalTime.progress.toDoCount}
                    {getPieChartValuesForCount(criticalTime)}
                  </TableRowColumn>
                  <TableRowColumn>
                    {getAmountForWeight(criticalTime)} / {getToDoWeight(criticalTime)}
                    {getPieChartValuesForWeight(criticalTime)}
                  </TableRowColumn>
                  <TableRowColumn style={statusColors.getColor(criticalTime.statusStartTime)}>
                    <Timezone date={getFirstloadedTime(criticalTime)} format="HH:mm" />
                    {getDelay(getFirstloaded(criticalTime).delayInMinStart)}
                  </TableRowColumn>
                  <TableRowColumn style={statusColors.getColor(criticalTime.statusEndTime)}>
                    <Timezone date={getEndedloadedTime(criticalTime)} format="HH:mm" />
                    {getDelay(getEndedloaded(criticalTime).delayInMinEnd)}
                  </TableRowColumn>
                  <TableRowColumn style={statusColors.getColor(criticalTime.statusDuration)}>
                    {getLongestDuration(criticalTime)}
                    {getDelay(getDelayDuration(criticalTime))}
                  </TableRowColumn>
                  <TableRowColumn style={statusColors.getColor(criticalTime.statusDeviation)}>
                    {getDeviation(criticalTime)}
                  </TableRowColumn>
                  <TableRowColumn style={colorEndTime.getColor(criticalTime)}>
                    {criticalTime.shouldEndTime &&
                    <Timezone date={criticalTime.shouldEndTime} format="HH:mm" />}
                    {getDelay(criticalTime.delayInMinEnd)}
                  </TableRowColumn>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
      <div>
        {isAllowedToGoBack ? (
          <BackButtonWrapper>
            <Button
              label={<FormattedMessage id="print.backBtn" defaultMessage="Back" />}
              style={{ width: '20%' }}
              onClick={onBackButtonClick}
              secondary
            />
          </BackButtonWrapper>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

CriticalTimePrint.propTypes = {
  criticalTimes: PropTypes.array,
  colors: PropTypes.array,
  flightInfo: PropTypes.object,
  handleClick: PropTypes.func,
};

export default CriticalTimePrint;
