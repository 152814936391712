import Immutable from 'seamless-immutable';
import actionTypes from '../actionTypes';

const INITIAL_STATE = {
  flight: {},
  departureTime: null,
  current: true,
  airport: null,
  dateTo: null,
  timeDistribution: null,
  oldDistribution: 'ON_LOAD',
};

const commonReducer = (state = Immutable.from(INITIAL_STATE), action) => {
  switch (action.type) {
    case actionTypes.FLIGHT_SET:
      return state.set('flight', action.payload.flight);
    case actionTypes.DEPARTURE_TIME_SET:
      return state.set('departureTime', action.payload.departureTime);
    case actionTypes.CURRENT_SET:
      return state.set('current', action.payload.current);
    case actionTypes.AIRPORT_SET:
      return state.set('airport', action.payload.airport);
    case actionTypes.CRITICAL_TIME_DATE_TO_SET:
      return state.set('dateTo', action.payload.dateTo);
    case actionTypes.TIME_DISTRIBUTION_SET:
      return state.set('timeDistribution', action.payload.timeDistribution);
    case actionTypes.CRITICAl_TIME_DISTRIBUTION_SET:
      return state.set('oldDistribution', action.payload.oldDistribution);
    default:
      return state;
  }
};

export default commonReducer;
