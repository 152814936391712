import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import _sortBy from 'lodash/sortBy';
import TextField from 'material-ui/TextField';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Select from '@material-ui/core/Select';
import Button from '../../../components/Button';
import FormattedMessage from '../../../modules/i18n';
import Wrapper from './Wrapper';
import FormRow from './FormRow';
import Label from './Label';
import ButtonWrapper from './ButtonWrapper';
import BackButtonWrapper from './BackButtonWrapper';
import DialogButtonWrapper from './DialogButtonWrapper';
import Header from './Header';
import FormWrapper from './FormWrapper';
import { CircularProgress } from '@material-ui/core';

const transformLanguages = languages =>
  languages.map(language => ({
    label: language.description,
    value: language.id,
  }));

const Settings = ({
  url,
  onUrlChange,
  onDropDownChange,
  theme,
  themesList,
  onSave,
  showDialog,
  onDialogOpen,
  onDialogClose,
  height,
  onBackButtonClick,
  onLanguageDropdownChange,
  languageId,
  t,
  languages,
  isAuthenticated,
}) => {
  const sortedLanguages = _sortBy(languages, ['description']);

  return (
    <Wrapper height={height}>
      <Header>{t('communicator.settings.header')}</Header>
      <FormWrapper>
        <FormRow>
          <Label>{t('communicator.settings.webservice')}</Label>
          <TextField
            name="webservice"
            style={{ flex: 1.5 }}
            fullWidth
            value={url}
            onChange={onUrlChange}
          />
        </FormRow>

        <FormRow>
          <Label>{t('communicator.settings.theme')}</Label>
          <DropDownMenu
            value={theme}
            onChange={onDropDownChange}
            style={{ flex: 1.5 }}
            labelStyle={{ paddingLeft: 0, paddingRight: 0 }}
            underlineStyle={{ marginLeft: 0, marginRight: 0 }}
            iconStyle={{ right: 0 }}
          >
            {themesList.map((item, index) => (
              <MenuItem key={item[index]} value={index} primaryText={item[index]} />
            ))}
          </DropDownMenu>
        </FormRow>

        {isAuthenticated && (
          <FormRow>
            <Label>{t('communicator.settings.language')}</Label>
            {languages ? (
              <Select
                value={languageId}
                onChange={onLanguageDropdownChange}
                style={{ flex: 1.5 }}
                labelStyle={{ paddingLeft: 0, paddingRight: 0 }}
                underlineStyle={{ marginLeft: 0, marginRight: 0 }}
                iconStyle={{ right: 0 }}
              >
                {transformLanguages(sortedLanguages).map((language, index) => (
                  <MenuItem key={index} value={language.value}>
                    {language.label}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <CircularProgress />
            )}
          </FormRow>
        )}

        <ButtonWrapper>
          <Button
            label={t('communicator.settings.saveChanges')}
            style={{ width: '20%' }}
            onClick={onDialogOpen}
            primary
          />
        </ButtonWrapper>
      </FormWrapper>

      <BackButtonWrapper>
        <Button
          label={t('communicator.settings.backBtn')}
          style={{ width: '20%' }}
          onClick={onBackButtonClick}
          secondary
        />
      </BackButtonWrapper>

      <Dialog open={showDialog}>
        <DialogTitle>{t('communicator.settings.confirmation')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('communicator.settings.confirmChangesLabel')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            label={
              <FormattedMessage
                id="settings.cancelChangesLabel"
                defaultMessage={t('communicator.settings.cancel')}
              />
            }
            onClick={onDialogClose}
          />
          <DialogButtonWrapper
            label={
              <FormattedMessage
                id="settings.confirmChangesLabel"
                defaultMessage={t('communicator.settings.confirmChangesLabel')}
              />
            }
            onClick={onSave}
            primary
          />
        </DialogActions>
      </Dialog>
    </Wrapper>
  );
};

Settings.propTypes = {
  height: PropTypes.number,
  url: PropTypes.string,
  onUrlChange: PropTypes.func,
  onSave: PropTypes.func,
  onDropDownChange: PropTypes.func,
  theme: PropTypes.number,
  themesList: PropTypes.array,
  showDialog: PropTypes.bool,
  onDialogOpen: PropTypes.func,
  onDialogClose: PropTypes.func,
  onBackButtonClick: PropTypes.func,
  onLanguageDropdownChange: PropTypes.func.isRequired,
  languageId: PropTypes.number,
  languages: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  isAuthenticated: PropTypes.bool,

  // Injected by i18next
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Settings);
