import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import Link from 'react-router-dom/Link';
import TextField from 'material-ui/TextField';
import CircularProgress from 'material-ui/CircularProgress';
import MIcon from '../../../components/MIcon';
import SettingsButton from '../../../components/Button';
import BackgroundImage from './BackgroundImage';
import Form from './Form';
import RolsIcon from './RolsIcon';
import Title from './Title';
import SubTitle from './SubTitle';
import Button from './Button';
import TextFieldWrapper from './TextFieldWrapper';
import ErrorText from './ErrorText';
import appSelectors from "../../app/selectors";
import styled from "styled-components";

class Login extends Component {
  static propTypes = {
    onLogin: PropTypes.func.isRequired,
    errors: PropTypes.array,
    hasPendingActions: PropTypes.bool,
    privacyNoticeUrl: PropTypes.string,

    // Injected by i18next
    t: PropTypes.func.isRequired,
  };

  state = {
    username: '',
    pw: '',
  };

  onSubmit = e => {
    e.preventDefault();
    this.props.onLogin(this.state.username, this.state.pw);
  };

  handleUsernameChange = e => {
    this.setState({
      username: e.currentTarget.value,
    });
  };

  handlePwChange = e => {
    this.setState({
      pw: e.currentTarget.value,
    });
  };

  handlePrivacyNotice = () => {
    const { privacyNoticeUrl } = this.props;
    if (privacyNoticeUrl) {
      window.open(privacyNoticeUrl);
    }
  }

  render() {
    const { errors, hasPendingActions, privacyNoticeUrl, t } = this.props;

    return (
      <div>
        <BackgroundImage />
        <Form onSubmit={this.onSubmit} autoComplete="off">
          <RolsIcon />
          <Title>
            {t('communicator.login.title')}
          </Title>
          <SubTitle>
            {t('communicator.login.subTitle')}
          </SubTitle>
          {errors.length > 0 && (
            <span>
              {errors.map(error => (
                <ErrorText key={error}>{error}</ErrorText>
              ))}
            </span>
          )}

          <TextFieldWrapper>
            <TextField
              data-tst-tag="usernameTextField"
              hintText={t('communicator.login.username')}
              value={this.state.username}
              onChange={this.handleUsernameChange}
              underlineFocusStyle={{ borderColor: '#4CB384' }}
              autoComplete="off"
              autoFocus
            />
          </TextFieldWrapper>

          <TextFieldWrapper>
            <TextField
              data-tst-tag="passwordTextField"
              hintText={t('communicator.login.password')}
              type="password"
              onChange={this.handlePwChange}
              underlineFocusStyle={{ borderColor: '#4CB384' }}
              autoComplete="off"
            />
          </TextFieldWrapper>
          {hasPendingActions
            ? <CircularProgress />
            : <Button
                data-tst-tag = "loginButton"
                onClick={this.onSubmit}
                label={t('communicator.login.authenticateBtn')}
                type="submit"
                disabled={hasPendingActions}
              />}
          <div style={{ textAlign: 'center', marginTop: 20 }}>
            <Link to="/settings">
              <SettingsButton icon={<MIcon>settings</MIcon>} />
            </Link>
          </div>
          {privacyNoticeUrl?
              <DivPrivacyNoticeParent>
                <DivPrivacyNotice onClick={this.handlePrivacyNotice}>
                    {t('rols.privacy.notice.title')}
                </DivPrivacyNotice>
              </DivPrivacyNoticeParent>
          :null}
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  hasPendingActions: appSelectors.hasPendingActions(state),
});

const DivPrivacyNoticeParent = styled.div`
    textAlign: center;
    marginTop: 20px;
`;

const DivPrivacyNotice = styled.div`
    display: inline-block;
    &:hover {
      color: #4CB384!important;
      cursor: pointer;
    }
`;

export default connect(mapStateToProps)(withTranslation()(Login));
