import React from 'react';
import { styled } from '@material-ui/styles';
import MuiPersonIcon from '@material-ui/icons/Person';

const NoVideoWrapper = styled('div')({
  height: '100%',
  width: '100%',
});

const PersonIcon = styled(MuiPersonIcon)({
  backgroundColor: '#DEDEDE',
  fontSize: 300,
  border: '1px solid black',
});

const OnlyVoiceIcon = () => (
  <NoVideoWrapper>
    <PersonIcon />
  </NoVideoWrapper>
);

export default OnlyVoiceIcon;
