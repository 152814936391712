import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import init from './app/init';
import configureStore from './app/store/configureStore';
import app from './modules/app';
import rootSaga from './modules/rootSaga';
import { SnackbarProvider } from 'notistack';
import notification from './modules/notification';

import 'i18next';

init();

const store = configureStore();
store.runSaga(rootSaga);

ReactDOM.render(
  <Provider store={store}>
    <SnackbarProvider
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <app.containers.App />
      <notification.components.Notifier />
    </SnackbarProvider>
  </Provider>,
  document.getElementById('root')
);
