import PropTypes from 'prop-types';
import React from 'react';
import reduxFetch, {container} from 'react-redux-fetch';
import moment from 'moment';
import isEqual from 'lodash/isEqual';
import find from 'lodash/find';
import get from 'lodash/get';
import momentPropTypes from 'react-moment-proptypes';
import toMilliseconds from '../../../app/utils/toMilliseconds';
import * as dateHelpers from '../../../app/utils/dateHelpers';
import adapter from '../../../app/api/adapter';
import globals, {RefreshQualifier} from '../../../app/globals';
import CenteredCircularProgress from '../../../components/CenteredCircularProgress';
import FlightList from '../components/FlightList/FlightList';
import flightService from '../domain/flight';
import actions from "../actions";
import commonSelectors from "../selectors";
import securitySelectors from "../../security/selectors";
import {compose} from "redux";
import injectApiRoutes from "../../../app/api/injectApiRoutes";

const fetchIsFulfilled = container.getUtil('componentHelpers').fetchIsFulfilled;

class FlightPage extends React.Component {
  static propTypes = {
    airportsFetch: PropTypes.object,
    flightsFetch: PropTypes.object,
    flightId: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
    dispatchAirportsPost: PropTypes.func,
    dispatchFlightsPost: PropTypes.func,
    onGetFlightHeader: PropTypes.func,
    onTabChange: PropTypes.func,
    selectedFlight: PropTypes.string,
    isCurrent: PropTypes.bool,
    departureTime: momentPropTypes.momentObj,
    userFrequency: PropTypes.shape({
      flights: PropTypes.number,
    }),
    onSystemMessagesClick: PropTypes.func,
    airport: PropTypes.object,
    userAirportCode: PropTypes.object,
    onAirportChange: PropTypes.func,
  };

  static contextTypes = {
    registerRefreshAction: PropTypes.func.isRequired,
    removeRefreshAction: PropTypes.func.isRequired,
  };

  started = false;
  timer = null;

  setDepartureTime = (date) => {
    return actions.setDepartureTime(date);
  }

  setAirport = (airport) => {
    return actions.setAirport(airport);
  }

  setFlight = (flight) => {
    return actions.setFlight(flight);
  }

  setCurrentFlag = (value) => {
    return actions.setCurrentFlag(value);
  }

  componentDidMount() {
    const {dispatchAirportsPost, dispatch, userAirportCode,
      departureTime,} = this.props;
    this.started = false;
    dispatchAirportsPost();
    if (!departureTime) {
      dispatch(this.setDepartureTime(moment()));
      dispatch(this.setAirport(userAirportCode));
      dispatch(this.setCurrentFlag(true));
      dispatch(this.setFlight({}));
    }
    this.context.registerRefreshAction(
        'flights',
        this.fetchFlights(RefreshQualifier.AUTO)
    );
  }

  isAirportsDifferent = (nextProps) => {
    const propsAirports = get(this.props.airportsFetch, 'value.airports.airport');
    const nextPropsAirports = get(nextProps.airportsFetch, 'value.airports.airport');
    return !isEqual(propsAirports, nextPropsAirports);
  }

  componentDidUpdate(prevProps) {
    if (!this.started ||
        this.isDateDifferent(prevProps) ||
        this.isCurrentFlagDifferent(prevProps) ||
        this.isAirportDifferent(prevProps)
    ) {
      this.started = true;
      this.clearTimer();
      this.fetchFlights(RefreshQualifier.MANUAL)(true);
    }
    if (fetchIsFulfilled(prevProps, this.props, 'flights')) {
      this.clearTimer();
      this.timer = setTimeout(
        this.fetchFlights(RefreshQualifier.AUTO),
        toMilliseconds(this.props.userFrequency.flights)
      );
    }
  }

  shouldComponentUpdate(nextProps) {
    const flightsFetchIsDifferent = !isEqual(this.props.flightsFetch, nextProps.flightsFetch);
    const flightIdIsDifferent = !isEqual(this.props.flightId, nextProps.flightId);
    return (
      !this.started ||
      this.isAirportsDifferent(nextProps) ||
      this.isDateDifferent(nextProps) ||
      flightsFetchIsDifferent ||
      flightIdIsDifferent ||
      this.isCurrentFlagDifferent(nextProps) ||
      this.isAirportDifferent(nextProps)
    );
  }

  clearTimer = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  componentWillUnmount() {
    this.clearTimer();
    this.context.removeRefreshAction('flights');
  }

  isDateDifferent = nextProps =>
    nextProps.departureTime && !nextProps.departureTime.isSame(this.props.departureTime, 'day');

  isCurrentFlagDifferent = nextProps => !isEqual(this.props.isCurrent, nextProps.isCurrent);

  isAirportDifferent = nextProps => !isEqual(this.props.airport, nextProps.airport);

  handleDateChange = date => {
    const {dispatch, } = this.props;
    dispatch(this.setDepartureTime(date));
    this.resetSelectFlight();
  };

  handleToggleCurrent = value => {
    const {dispatch, } = this.props;
    dispatch(this.setCurrentFlag(value));
  };

  handleAirportChange = value => {
    const {dispatch, onAirportChange} = this.props;
    dispatch(this.setAirport(value));
    if (onAirportChange) {
      onAirportChange(true);
    }
    this.resetSelectFlight();
  }

  resetSelectFlight = () => {
    const {dispatch, onGetFlightHeader, onTabChange, } = this.props;
    dispatch(this.setFlight({}));
    onGetFlightHeader({});
    if (onTabChange) {
      onTabChange('');
    }
  }

  getFlight = () => {
    const { flightsFetch, flightId } = this.props;
    const flights = get(flightsFetch, 'value.flightInfos.flightInfo') || [];
    const result = find(flights, flight => flight.flightId === flightId);
    return result;
  }

  handleListItemClick = (flight,) => {
    const { dispatch, onGetFlightHeader } = this.props;
    if (dispatch) {
      dispatch(this.setFlight(flight));
    }
    if (onGetFlightHeader) {
      const header = {
        flNr: flight.flightNumber,
        dpAir: flight.departureAirport.iata,
        arrAir: flight.arrivalAirport.iata,
      };
      onGetFlightHeader(header);
      /*departureTime = flight.estimatedDeparture || flight.scheduledDeparture*/
    }
  };

  handleSystemMessagesClick = () => {
    const {dispatch, onSystemMessagesClick} = this.props;
    if (dispatch) {
      dispatch(this.setFlight({}));
    }
    if (onSystemMessagesClick) {
      onSystemMessagesClick();
    }
  }

  startTime = 0;
  fetchFlights = refetchQualifier => (showLoader, ) => {
    this.startTime = new Date().getTime();
    const {departureTime, isCurrent, airport, dispatchFlightsPost} = this.props;
    const finalDate = departureTime;
    const finalIsCurrent = flightService.shouldShowCurrentToggle(finalDate)
        ? isCurrent : false
    const startOfDay = flightService.shouldFetchFlightsFromStartOfDay(finalDate);
    const airportCode = airport?airport.iata:'';
    dispatchFlightsPost(
      finalDate,
      startOfDay,
      showLoader,
      finalIsCurrent,
      airportCode,
      refetchQualifier
    );
  };

  getAirports = () => {
    const {airportsFetch} = this.props;
    const airports = get(airportsFetch, 'value.airports.airport') || [];
    const empty = [{name:'', icao: '', iata:' '}];
    return empty.concat(airports);
  }

  render() {
    const { flightsFetch, airportsFetch, flightId, departureTime,
      isCurrent, onSystemMessagesClick, airport, } = this.props;
    const flightsValue = get(flightsFetch, 'value');
    const airportsValue = get(airportsFetch, 'value');
    if (flightsValue && airportsValue) {
     console.log("Number flights = " + (get(flightsFetch, 'value.flightInfos.flightInfo') || []).length +
         ", duration = " + (new Date().getTime()-this.startTime) + " ms.");
      return (
        <FlightList
          selectedDate={departureTime}
          airports={this.getAirports()}
          selectedFlight={flightId}
          flights={get(flightsFetch, 'value.flightInfos.flightInfo') || []}
          onListItemClick={this.handleListItemClick}
          onDateChange={this.handleDateChange}
          onToggleCurrent={this.handleToggleCurrent}
          pending={flightsFetch.pending && flightsFetch.meta.showLoader}
          isCurrent={isCurrent}
          showCurrentSwitch={flightService.shouldShowCurrentToggle(departureTime)}
          onSystemMessagesClick={onSystemMessagesClick?this.handleSystemMessagesClick:null}
          airport={airport}
          onAirportChange={this.handleAirportChange}
        />
      );
    } else if (flightsFetch.rejected) {
      return <div>Could not get flights</div>;
    }

    return <CenteredCircularProgress />;
  }
}

const getDate = (date, startOfDay) => {
  return startOfDay
      ? adapter.momentDateToServerStartOfDay(date)
      : adapter.momentDateToServerDate(date);
}

export const mapPropToDispatchToProps = props => [
  {
    resource: 'airports',
    method: 'POST',
    request: () => ({
      url: props.apiRoutes.getAirports(),
      body: {
        accessToken: props.accessToken,
      },
    }),
  },
  {
    resource: 'flights',
    method: 'POST',
    /**
     * @param date Moment: The date to fetch flights forbid
     * @param startOfDay Bool: Should the date which will be sent to the server
     *     start from 00:00, or current time?
     @see domain/flight
     * @param showLoader Bool: UI flag. Is set to FALSE if request is a refetch
     *     of the same date. Is set to TRUE if request is another date than the
     *     previous.
     * @param isCurrent Bool: If true, server will return response between an
     *     interval, if false, server will return the full day.
     * @param refreshQualifier constant RefreshQualifier: MANUAL when user
     *     invokes this call. AUTO when call is invoked by polling
     */
    request: (
      date,
      startOfDay = true,
      showLoader = false,
      isCurrent = false,
      airportCode = '',
      refreshQualifier = RefreshQualifier.MANUAL
    ) => ({
      url: props.apiRoutes.getFlights(),
      body: {
        accessToken: props.accessToken,
        date: getDate(date, startOfDay),
        localTimeOffset: dateHelpers.currentTimezoneOffset(getDate(date, startOfDay)),
        current: isCurrent,
        airportCode: airportCode,
        passengerFlights: true,
        freighterFlights: true,
        application: globals.applicationName,
        refreshQualifier,
      },
      meta: {
        showLoader,
      },
    }),
  },
];

const mapStateToProps = state => ({
  flightId: commonSelectors.getFlightId(state),
  departureTime: commonSelectors.getDepartureTime(state),
  isCurrent: commonSelectors.getCurrentFlag(state),
  airport: commonSelectors.getAirport(state),
  userFrequency: securitySelectors.getUserFrequency(state),
  userAirportCode: securitySelectors.getAirport(state),
});

const enhance = compose(
    injectApiRoutes,
    reduxFetch(mapPropToDispatchToProps, mapStateToProps)
);

export default enhance(FlightPage);