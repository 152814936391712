// @flow
import createActionType from '../../app/utils/createActionType';

export default {
  FILTER: createActionType('CriticalTime filter set'),
  SET_DATE_TO : createActionType('CriticalTime filter set dateTo'),
  SET_TIME_FROM : createActionType('CriticalTime filter set timeFrom'),
  SET_TIME_TO : createActionType('CriticalTime filter set timeTo'),
  SET_DISTRIBUTION : createActionType('CriticalTime filter set distribution'),
};
