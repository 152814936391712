import moment from 'moment';

export function currentTimezoneOffset(date) {
  if (date) {
    return moment(date).format('Z');
  } else {
    return moment().format('Z');
  }
}

export function dateIsToday(date) {
  return moment(date).isSame(moment(), 'day');
}
