import PropTypes from "prop-types";
import AppBar from '@material-ui/core/AppBar';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import React from "react";
import TranslatedLabel from "../../../components/TranslatedLabel/TranslatedLabel";

class LoadSequenceAppBar extends React.Component {
    static propTypes = {
        distribution: PropTypes.number.isRequired,
        onChangeDistribution: PropTypes.func.isRequired,
    }

    handleChangeTab = (event, tabValue) => {
        const {
            onChangeDistribution,
        } = this.props;
        onChangeDistribution(tabValue);
    };

    getLabel = (keyword, defaultValue) => {
        return <TranslatedLabel
            keyword={`communicator.loadSequence.distribution.${keyword}`}
            defaultValue={defaultValue} />
    }

    render() {
        const {distribution} = this.props;
        return <AppBar id={"divLoadSequenceAppBar"} position="sticky" color="default">
            <Tabs
                variant="fullWidth"
                value={distribution}
                onChange={this.handleChangeTab}
                indicatorColor="primary"
                textColor="primary"
                centered
            >
                {TABS.map( (tab, index) => {
                    const id = index + 1;
                    return <Tab key={`appBar${id}`}
                                value={id}
                                label={this.getLabel(tab.keyword, tab.defaultValue)}
                                data-tst-tag={tab.keyword}
                    />
                })}
            </Tabs>
        </AppBar>
    }
}

const TABS = [
    {keyword: 'onLoad', defaultValue: 'On-Load'},
    {keyword: 'offLoad', defaultValue: 'Off-Load'},
]

export default LoadSequenceAppBar;