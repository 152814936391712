export class StatusColor {
  constructor(colors) {
    this.colors = colors;

    this.SCHEDULED = { backgroundColor: this.filterColorByType('CRT-SCHEDULED') };
    this.NEARTHRESHOLD = { backgroundColor: this.filterColorByType('CRT-NEAR TRESHOLD') };
    this.TOOLATE = { backgroundColor: this.filterColorByType('CRT-TOO LATE') };
    this.EXECUTED = { backgroundColor: this.filterColorByType('CRT-EXECUTED') };
    this.EXECUTEDLATE = { backgroundColor: this.filterColorByType('CRT-EXECUTED LATE') };
    this.EMPTY = { backgroundColor: '' };
  }

  getColor(status) {

    switch (status) {
      case 'TOO_LATE':
        return this.TOOLATE;
      case 'NEAR_THRESHOLD':
        return this.NEARTHRESHOLD;
      case 'STARTED_ENDED_TOO_LATE':
       return this.EXECUTEDLATE;
      case 'STARTED_ENDED_ON_TIME':
        return this.EXECUTED;
      case 'SCHEDULED':
       return this.SCHEDULED;
      default:
        return this.EMPTY;
    }
  }

  filterColorByType(type) {
    if (this.colors) {
      for (let i = 0; i < this.colors.length; i++) {
        if (this.colors[i].name === type) {
          return this.colors[i].fill.value;
        }
      }
    }
  }
}
