import styled from 'styled-components';

const ItemIcon = styled.div`
  width: 20%;
  textAlign: center;
  marginLeft: 10px;
  marginRight: 10px;
  marginTop: 15px;
  position: relative;

  &:before {
    content: " ";
    height: 1000%;
    left: 50%;
    position: absolute;
    top: -50px;
    width: 1px;
    background: ${props => props.theme.palette.white};
  }
`;

export default ItemIcon;
