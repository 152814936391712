import PropTypes from 'prop-types';
import React from 'react';
import connect from 'react-redux-fetch';
import { compose } from 'redux';
import Redirect from 'react-router-dom/Redirect';
import i18next from 'i18next';
import _get from 'lodash/get';
import security from '../../../modules/security';
import Settings from '../components/Settings';
import selectors from '../selectors';
import actions from '../actions';
import app from '../../../app';
import convertResourceToObject from '../../../app/utils/convertResourceToObject'
import convertBackendLanguageForFrontend from '../../../app/utils/convertBackendLanguageForFrontend'

const NAVBAR_HEIGHT = 71;

class SettingsPage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    webServiceUrl: PropTypes.string,
    isAuthenticated: PropTypes.bool.isRequired,
    themeValue: PropTypes.number,
    themesList: PropTypes.array,
    history: PropTypes.object,

    dispatchLanguagesPost: PropTypes.func,
    dispatchSetLanguagePost: PropTypes.func,
    languagesFetch: PropTypes.object,
    setLanguageFetch: PropTypes.object,
    dispatchGetLanguageTextPost: PropTypes.func,
    getLanguageTextFetch: PropTypes.object,
    languageId: PropTypes.number,
  };

  state = {
    height: 0,
    url: this.props.webServiceUrl,
    themeValue: this.props.themeValue,
    showDialog: false,
    refresh: false,
    languageId: this.props.languageId,
  };

  componentDidMount() {
    const { dispatchLanguagesPost, isAuthenticated } = this.props;
    window.addEventListener('resize', this.calculateHeight);
    this.calculateHeight();

    if (isAuthenticated) {
      dispatchLanguagesPost();
    }
  }

  componentWillReceiveProps(nextProps) {
    const { themeValue } = this.props;

    this.setState({ refresh: false });
    if (themeValue !== nextProps.themeValue) {
      this.setState({
        refresh: true,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { getLanguageTextFetch } = this.props;
    if (this.props.webServiceUrl !== prevProps.webServiceUrl) {
      this.props.dispatchLanguagesPost();
    }

    if (_get(getLanguageTextFetch, 'fulfilled') && _get(prevProps, 'getLanguageTextFetch.pending')) {
      this.updateLanguage();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.calculateHeight);
  }

  updateLanguage = () => {
    const { getLanguageTextFetch } = this.props;

    const newLanguage = getLanguageTextFetch.value.languages.language[0].locale;
    i18next.addResourceBundle(
        convertBackendLanguageForFrontend(newLanguage),
        'translation',
        convertResourceToObject(_get(getLanguageTextFetch, 'value.languageTexts.languageText')),
        true,
        true
    );

    i18next.changeLanguage(newLanguage).then(() => {
      const languageId = getLanguageTextFetch.value.languages.language[0].id;
      this.updateLanguageId(languageId);
    });
  };

  updateLanguageId = languageId => {
    const { dispatch } = this.props;

    this.setState({
      languageId: languageId,
    });
    dispatch(actions.setLanguageId(languageId));
  };

  calculateHeight = () => {
    this.setState({
      height: window.innerHeight - NAVBAR_HEIGHT,
    });
  };

  handleUrlChange = (e, value) => {
    this.setState({
      url: value,
    });
  };

  handleDropDownChange = (event, k) => {
    this.setState({
      themeValue: k,
    });
  };

  handleLanguageDropdownChange = (event, k) => {
    this.setState({
      languageId: k.props.value,
    });
  };

  handleSave = () => {
    const { dispatch, isAuthenticated, history, dispatchSetLanguagePost, dispatchGetLanguageTextPost } = this.props;
    const { languageId, url, themeValue } = this.state;

    dispatch(actions.setWebServiceUrl(url));
    dispatch(actions.setTheme(themeValue));

    if (languageId) {
      dispatchSetLanguagePost(languageId);
      dispatchGetLanguageTextPost(languageId);
    }

    this.handleDialogClose();
    if (!isAuthenticated) {
      history.goBack();
    }
  };

  handleDialogOpen = () => {
    this.setState({ showDialog: true });
  };

  handleDialogClose = () => {
    this.setState({ showDialog: false });
  };

  handleBackButtonClick = () => {
    this.props.history.goBack();
  };

  render() {
    const { height, url, themeValue, showDialog, refresh, languageId } = this.state;
    const { themesList, languagesFetch, isAuthenticated } = this.props;

    if (refresh) {
      return <Redirect to="/settings" />;
    }

    return (
      <Settings
        height={height}
        url={url}
        onUrlChange={this.handleUrlChange}
        theme={themeValue}
        languageId={languageId}
        languages={languagesFetch.fulfilled && _get(languagesFetch, 'value.languages.language')}
        themesList={themesList}
        onDropDownChange={this.handleDropDownChange}
        onSave={this.handleSave}
        showDialog={showDialog}
        onDialogOpen={this.handleDialogOpen}
        onDialogClose={this.handleDialogClose}
        onBackButtonClick={this.handleBackButtonClick}
        onLanguageDropdownChange={this.handleLanguageDropdownChange}
        isAuthenticated={isAuthenticated}
      />
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: security.selectors.isAuthenticated(state),
  webServiceUrl: selectors.getWebServiceUrl(state),
  themeValue: selectors.getThemeValue(state),
  themesList: selectors.getThemesList(state),
  languageId: selectors.getLanguageId(state),
});

const mapPropToDispatchToProps = props => [
  {
    resource: 'languages',
    method: 'POST',
    request: () => ({
      url: props.apiRoutes.languages(),
      body: {
        accessToken: props.accessToken,
      }
    }),
  },
  {
    resource: 'setLanguage',
    method: 'POST',
    request: languageId => ({
      url: props.apiRoutes.setLanguage(),
      body: {
        accessToken: props.accessToken,
        languageId: languageId,
      }
    }),
  },
  {
    resource: 'getLanguageText',
    method: 'POST',
    request: languageId => ({
      url: props.apiRoutes.getLanguageText(),
      body: {
        accessToken: props.accessToken,
        languageId: Number(languageId),
      },
    }),
  },
];

const enhance = compose(app.injectApiRoutes, connect(mapPropToDispatchToProps, mapStateToProps));

export default enhance(SettingsPage);
