import React from 'react';
import Popup from "reactjs-popup";
import SuperHeader from '../../../../components/Table/SuperHeader';
import FormattedMessage from '../../../i18n/components/FormattedMessage';
import {
    Table,
    TableBody,
    TableHeader,
    TableRow,
    TableRowColumn
} from 'material-ui/Table';
import InfoIcon from '@material-ui/icons/Info';
import Timezone from '../../../../components/Timezone';
import moment from "moment";

const FlightReportTable = ({reports}) => {
    return (
        <div>
            <SuperHeader>
                <FormattedMessage id="print.flightreport" defaultMessage="Flight reports" />
            </SuperHeader>
            <Table selectable={false}>
                <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
                    <TableRow>
                        <TableRowColumn>
                            <FormattedMessage id="print.date" defaultMessage="Date" />
                        </TableRowColumn>
                        <TableRowColumn>
                            <FormattedMessage id="print.time" defaultMessage="Time" />
                        </TableRowColumn>
                        <TableRowColumn>
                            <FormattedMessage id="print.user" defaultMessage="User" />
                        </TableRowColumn>
                        <TableRowColumn>
                            <FormattedMessage id="print.remark" defaultMessage="Remark" />
                        </TableRowColumn>
                    </TableRow>
                </TableHeader>
                <TableBody displayRowCheckbox={false} adjustForCheckbox={false}>
                    {reports.length > 0 &&
                    reports.map((report, index) => (
                        <TableRow key={index} style={{ cursor: 'pointer' }}>
                            <TableRowColumn>
                                <Timezone date={moment(report.date)} format="DD-MMM" />
                            </TableRowColumn>
                            <TableRowColumn>
                                <Timezone date={moment(report.date)} format="HH:mm" />
                            </TableRowColumn>
                            <TableRowColumn>{report.user}</TableRowColumn>
                            <TableRowColumn>
                                {report.remark.length > 15
                                    ? report.remark.substring(0, 15) + '...'
                                    : report.remark}
                                {report.remark.length > 15 ? (
                                    <Popup trigger={<InfoIcon style={{ marginRight: 15 }} />} modal={true}>
                                        {report.remark}
                                    </Popup>
                                ) : (
                                    <></>
                                )}
                            </TableRowColumn>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

export default FlightReportTable;
