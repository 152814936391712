import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TranslatedLabel from "../TranslatedLabel/TranslatedLabel";
import PropTypes from "prop-types";

class AlertDialog extends React.Component {
    static propTypes = {
        open: PropTypes.bool.isRequired,
        keywordTitle: PropTypes.string,
        defaultTitle: PropTypes.string.isRequired,
        keywordContent: PropTypes.string,
        defaultContent: PropTypes.string.isRequired,
        keywordOk: PropTypes.string.isRequired,
        defaultOk: PropTypes.string.isRequired,
        onClickOk: PropTypes.func.isRequired,
    }

    handleKey = (e) => {
        if (e.key === 'Escape') {
            const {onClickOk} = this.props;
            onClickOk();
        }
    }

    getLabel = (keyword, defaultValue) => {
        if (keyword!=null) {
            return <TranslatedLabel keyword={keyword} defaultValue={defaultValue} />
        }
        return defaultValue;
    }

    render() {
        const {open, keywordTitle, defaultTitle,
            keywordContent, defaultContent,
            keywordOk, defaultOk, onClickOk
        } = this.props;

        return <Dialog
            open={open}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onKeyUp={this.handleKey}
        >
            <DialogTitle id="alert-dialog-title">
                {this.getLabel(keywordTitle, defaultTitle)}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {this.getLabel(keywordContent, defaultContent)}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClickOk}
                    color="primary"
                    data-tst-tag={keywordOk}
                    autoFocus={true}
                >
                    {this.getLabel(keywordOk, defaultOk)}
                </Button>
            </DialogActions>
        </Dialog>
    }
}

export default AlertDialog;