import PropTypes from 'prop-types';
import React from 'react';
import connect from 'react-redux-fetch';
import {compose} from 'redux';
import reduce from 'lodash/reduce';
import find from 'lodash/find';
import injectApiRoutes from '../../../app/api/injectApiRoutes';
import CenteredCircularProgress
    from '../../../components/CenteredCircularProgress';
import Baggage from '../components/Baggage';
import BaggagePrint from '../components/Baggage/BaggagePrint';
import commonSelectors from '../../common/selectors';

function createTableHeaderName(baggageDetail) {
  return `${baggageDetail.typeCode} (${baggageDetail.destination.iata})`;
}

function getTableHeader(ulds) {
  return reduce(ulds, (tableHeader, uld) => {
    uld.baggageDetails.baggageDetail.forEach(detail => {
      const th = createTableHeaderName(detail);
      if (!detail.transit && detail.amount > 0 && !tableHeader.includes(th)) {
        tableHeader.push(th);
      }
    });
    return tableHeader;
  }, []);
}

function getAmount(th, details) {
  const detail = find(details, (d) => {
    return th === createTableHeaderName(d) && !d.transit;
  });

  if (!detail || !detail.amount || !detail.confirmed) {
    return {
      el: '',
      val: 0,
    };
  }

  return {
    el: <strong style={{textDecoration: 'underline'}}>{detail.amount}</strong>,
    val: detail.amount,
  };
}

const renderPrint = props => {
  /* eslint-disable */
  const { flightBaggageFetch } = props;
  /* eslint-disable */
  if (flightBaggageFetch.fulfilled) {
    const ulds = flightBaggageFetch.value.ulds;
    const flightInfo = flightBaggageFetch.value.flight;

    return (
      <BaggagePrint
        tableHeader={getTableHeader(ulds)}
        ulds={ulds}
        flightInfo={flightInfo}
        getAmount={getAmount}
      />
    );
  }
  return null;
};

const renderPrintComponent = props => renderPrint(props);

class BaggagePage extends React.Component {
  static propTypes = {
    dispatchFlightBaggagePost: PropTypes.func,
    flightBaggageFetch: PropTypes.object,
  };

  static contextTypes = {
    setPrintComponent: PropTypes.func,
    unsetPrintComponent: PropTypes.func,
    registerRefreshAction: PropTypes.func.isRequired,
    removeRefreshAction: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.dispatchFlightBaggagePost();
    this.context.setPrintComponent(renderPrintComponent(this.props));
    this.context.registerRefreshAction('baggage', this.props.dispatchFlightBaggagePost);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.flightBaggageFetch !== nextProps.flightBaggageFetch) {
      this.context.setPrintComponent(renderPrintComponent(nextProps));
    }
  }

  componentWillUnmount() {
    this.context.unsetPrintComponent();
    this.context.removeRefreshAction('baggage');
  }

  render() {
    const { flightBaggageFetch } = this.props;

    if (flightBaggageFetch.fulfilled) {
      const ulds = flightBaggageFetch.value.ulds;

      return (
        <Baggage
          tableHeader={getTableHeader(ulds)}
          ulds={ulds}
          getAmount={getAmount}
        />
      );
    }

    return <CenteredCircularProgress />;
  }
}

const mapPropToDispatchToProps = props => [
  {
    resource: 'flightBaggage',
    method: 'POST',
    request: () => ({
      url: props.apiRoutes.getFlightBaggage(),
      body: {
        accessToken: props.accessToken,
        flightId: props.flightId,
        distribution: props.distribution,
        imSequence: 0,
      },
    }),
  },
];

const mapStateToProps = state => ({
  flightId: commonSelectors.getFlightId(state),
  departureAirportCode: commonSelectors.getAirport(state),
  distribution: commonSelectors.getDistribution(state),
});

const enhance = compose(injectApiRoutes, connect(mapPropToDispatchToProps, mapStateToProps));

export default enhance(BaggagePage);
