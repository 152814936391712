import React from 'react';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import CallIcon from '@material-ui/icons/Call';
import VideoCallIcon from '@material-ui/icons/VideoCall';

const ContactItem = ({
  contact,
  videoPossible,
  audioPossible,
  onStartVideoClicked,
  onStartVoiceClicked,
}) => (
  <ListItem>
    <ListItemText primary={`${contact.userName} - ${contact.roleName}`} />
    {audioPossible && (
      <IconButton onClick={onStartVoiceClicked}>
        <CallIcon />
      </IconButton>
    )}
    {videoPossible && (
      <IconButton onClick={onStartVideoClicked}>
        <VideoCallIcon />
      </IconButton>
    )}
  </ListItem>
);

export default ContactItem;
