import React from 'react';
import { useDispatch } from 'react-redux';
import actions from '../../actions';
import { CallType, MessageTypes } from '../../constants';
import usePeerConnection from '../../hooks/usePeerConnection';
import Video from '../Video';
import Audio from '../Audio';

const Answer = ({ sendMessage }) => {
  const dispatch = useDispatch();
  const [peerConnectionRef, localStreamRef, remoteStreamRef, callType] = usePeerConnection({
    isCaller: false,
    audio: true,
    video: true,
    sendToUser: sendMessage,
    selectedCallType: null,
  });

  const isSelectedTypeVideoCall = callType !== CallType.ONLY_VOICE;

  const handleStopClicked = () => {
    if (peerConnectionRef.current) {
      sendMessage({ type: MessageTypes.CALL_STOP });
      peerConnectionRef.current.stop();
      dispatch(actions.callstop());
    }
  };

  return (
    <>
      {isSelectedTypeVideoCall ? (
          <>
            <Video domRef={remoteStreamRef} domRefOther={localStreamRef} onStopBtnClicked={handleStopClicked}/>
          </>
      ) : (

          <Audio domRef={remoteStreamRef} onStopBtnClicked={handleStopClicked} />
      )}
    </>
  );
};

export default Answer;
