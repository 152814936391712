import get from "lodash/get";
import {compose} from "redux";
import app from "../../../app";
import connect from "react-redux-fetch";
import BaseChangePsswrdPage, {mapStateToProps} from "./BaseChangePsswrdPage";
import PropTypes from "prop-types";

class ChangePsswrdAfterLoginPage extends BaseChangePsswrdPage {
    static propTypes = {
        dispatchChangePasswordAfterLoginPost: PropTypes.func,
        changePasswordAfterLoginFetch: PropTypes.object,
    };

    executeChangePsswrd = (props) => {
        const {dispatchChangePasswordAfterLoginPost} = this.props;
        dispatchChangePasswordAfterLoginPost(props);
    }

    getFetchValue = (props) => {
        const {changePasswordAfterLoginFetch} = props;
        return get(changePasswordAfterLoginFetch, 'value');
    }

    getFetch = (props) => {
        const {changePasswordAfterLoginFetch} = props;
        return changePasswordAfterLoginFetch;
    }

    getReferrer = () => {
        return '/changePasswordAfterLogin';
    }

    isAfterLogin = () => {
        return true;
    }
}

const mapPropToDispatchToProps = props => [
    {
        resource: 'changePasswordAfterLogin',
        method: 'POST',
        request: ({newPw, newPwRepeat, }) => ({
            url: props.apiRoutes.changePasswordAfterLogin(),
            body: {
                accessToken: props.accessToken,
                newPassword: newPw,
                newPasswordRepeat: newPwRepeat,
            }
        }),
    },
];

const enhance = compose(
    app.injectApiRoutes,
    connect(mapPropToDispatchToProps, mapStateToProps));

export default enhance(ChangePsswrdAfterLoginPage);