import styled from 'styled-components';
import RolsButton from '../../../../../components/Button';

const Button = styled(RolsButton)`
  margin: 10px!important;
  height: 30px!important;
  borderColor: ${props =>
    props.primary ? props.theme.palette.primary : props.theme.palette.secondary};
  color: ${props => (props.primary ? props.theme.palette.primary : props.theme.palette.secondary)};
  backgroundColor: ${props => props.theme.palette.primaryPastelLight}!important;

  &:hover {
    backgroundColor: ${props =>
      props.primary ? props.theme.palette.primary : props.theme.palette.secondary};
    border: 1px solid ${props =>
      props.primary ? props.theme.palette.primary : props.theme.palette.secondary};
    color: ${props => props.theme.palette.white};
  }
`;

export default Button;
