import React from 'react';
import { useSelector } from 'react-redux';
import Draggable from 'react-draggable';

import makeStyles from '@material-ui/styles/makeStyles/makeStyles';
import Fab from '@material-ui/core/Fab';
import CallEnd from '@material-ui/icons/CallEnd';

import selectors from '../../selectors';
import OnlyVoiceIcon from '../OnlyVoice';

const useStyles = makeStyles({
  audioElement: {
    display: 'none',
    width: '100%',
  },
  audioWrapper: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    zIndex: 3,
    overflow: 'hidden',
    width: 300,
  },
  audioHeader: {
    backgroundColor: 'rgba(0, 0, 0, 0.54)',
    color: 'white',
    padding: 14,
  },
  fabContainer: {
    position: 'absolute',
    left: '50%',
    bottom: '20px',
    marginLeft: -24,
  },
});

const Audio = props => {
  const { domRef, onStopBtnClicked, ...rest } = props;
  const classes = useStyles();
  const toOnlineName = useSelector(selectors.getToOnlineName);
  return (
    <Draggable bounds="body" handle="#audioHeader">
      <div className={classes.audioWrapper}>
        <div id="audioHeader" className={classes.audioHeader}>
          <span>{toOnlineName}</span>
        </div>
        <OnlyVoiceIcon />
        <audio ref={domRef} className={classes.audioElement} autoPlay controls {...rest} />
        <div className={classes.fabContainer}>
          <Fab size="medium" color="secondary" aria-label="CallEnd" onClick={onStopBtnClicked}>
            <CallEnd />
          </Fab>
        </div>
      </div>
    </Draggable>
  );
};

export default Audio;
