import PropTypes from 'prop-types';
import React, {Component} from 'react';
import momentPropTypes from 'react-moment-proptypes';
import {withTranslation} from "react-i18next";
import Avatar from 'material-ui/Avatar';
import Divider from 'material-ui/Divider';
import {List} from 'material-ui/List';
import Toggle from 'material-ui/Toggle';
import Logo from '../../../../components/Logo';
import CenteredCircularProgress from '../../../../components/CenteredCircularProgress';
import Timezone from '../../../../components/Timezone';
import CustomListItem from './CustomListItem';
import CustomSecondaryText from './CustomSecondaryText';
import CurrentToggleWrapper from './CurrentToggleWrapper';
import ButtonDatePicker from './ButtonDatePicker';
import SystemMessagesButton from './SystemMessagesButton';
import DatePickerSystemMessageWrapper from "./DatePickerSystemMessageWrapper";
import Dropdown from "../Dropdown/Dropdown";

class FlightList extends Component {
  static propTypes = {
    airports: PropTypes.array,
    flights: PropTypes.array,
    onListItemClick: PropTypes.func,
    selectedFlight: PropTypes.string,
    selectedDate: momentPropTypes.momentObj,
    onDateChange: PropTypes.func,
    onToggleCurrent: PropTypes.func,
    airport: PropTypes.object,
    onAirportChange: PropTypes.func,
    pending: PropTypes.bool,
    isCurrent: PropTypes.bool,
    showCurrentSwitch: PropTypes.bool,

    // inserted by i18next
    t: PropTypes.func.isRequired,
  };

  listItemRef = React.createRef();

  constructor(props) {
    super(props);
    // To easily change the date in e2e test suite, usage: window.changeDate(null, '2019-03-20')
    window.changeDate = this.handleDateChange;
  }

  handleDateChange = date => {
    this.props.onDateChange(date);
  };

  handleToggleCurrent = e => {
    this.props.onToggleCurrent(e.currentTarget.checked);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.listItemRef.current) {
      this.listItemRef.current.scrollIntoView({
        block: 'nearest',
      });
    }
  }

  render() {
    const {
      selectedDate,
      flights,
      onListItemClick,
      selectedFlight,
      pending,
      isCurrent,
      showCurrentSwitch,
      onSystemMessagesClick,
      airports,
      airport,
      onAirportChange,
      t,
    } = this.props;
    const dateForDatePicker = selectedDate.toDate();

    const distinctFlightIds = [...new Set(flights.map(flight => flight.flightId))];

    const distinctFlights = distinctFlightIds.map(id =>
      flights.find(flight => flight.flightId === id)
    );

    return (
      <div>
          <DatePickerSystemMessageWrapper>
            <ButtonDatePicker
              defaultDate={dateForDatePicker}
              onChange={this.handleDateChange}
            />
            <SystemMessagesButton
                onClick={onSystemMessagesClick}/>
          </DatePickerSystemMessageWrapper>
        {showCurrentSwitch && (
          <CurrentToggleWrapper>
            <Toggle label={t('communicator.flightList.current')} toggled={isCurrent} onToggle={this.handleToggleCurrent} />
          </CurrentToggleWrapper>
        )}
        <Dropdown
            label={t('communicator.departureAirport')||'Departure airport'}
            items={airports}
            item={airport}
            getItemLabel={(airport) => airport?airport.iata:null}
            onItemChange={onAirportChange}
        />
        <List>
          {pending ? (
            <CenteredCircularProgress />
          ) : (
            distinctFlights.map(flight => {
              const comp = flight.flightNumber.substring(0, 2);
              const text = `${flight.flightNumber}: ${flight.departureAirport.iata} - ${
                flight.arrivalAirport.iata
              } (${flight.aircraft.registration})`;

              const zoned = (
                <CustomSecondaryText selected={selectedFlight === flight.flightId}>
                  <span>{t('communicator.flightList.departureDate')}: </span>
                  <Timezone
                    date={flight.estimatedDeparture || flight.scheduledDeparture}
                    format="DD MMM HH:mm"
                    airport={flight.departureAirport}
                  />
                  {flight.estimatedDeparture ? ' (E)' : ' (S)'}
                </CustomSecondaryText>
              );

              const selectedItem = (selectedFlight === flight.flightId);

              return (
                <span key={flight.flightId} ref={selectedItem?this.listItemRef:null}>
                  <CustomListItem
                    selected={selectedItem}
                    data-tst-flightnr={flight.flightNumber}
                    data-tst-flightdeparture={flight.departureAirport.iata}
                    data-tst-flightarrival={flight.arrivalAirport.iata}
                    onClick={() => {
                      onListItemClick(
                        flight,
                      );
                    }}
                    leftAvatar={<Avatar icon={<Logo company={comp} />} />}
                    primaryText={text}
                    secondaryText={zoned}
                  />
                  <Divider />
                </span>
              );
            })
          )}
        </List>
      </div>
    );
  }
}

export default withTranslation()(FlightList);
