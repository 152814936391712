//@flow
import React from 'react';
import SVG from 'svg.js';
import * as d3 from 'd3';
import {MIN_WIDTH_TO_SHOW_DATA} from './drawingConfig';
import {svgClasses} from '../SvgClasses';
import {
    getHeightElement,
    getKs,
    getMaxAllRectsX,
    getMinAllRectsX,
    getMinAllRectsY,
    getTopElement,
    getWidthElement,
    getXElement,
    getYElement
} from "./canvasUtil";
import Shapes from "./shapes/Shapes";
import Positions from "./Positions";
import Doors from "./Doors";
import PropTypes from "prop-types";
import {getDeckStorageValues, saveDeckStorageValues} from "../lib/sessionStorageUtil";

class Canvas extends React.Component {
    static propTypes = {
        id: PropTypes.string,
        deckIndex: PropTypes.number,
        horizontal: PropTypes.bool,
        shapes: PropTypes.object,
        loadSteps: PropTypes.array,
        doors: PropTypes.array,
        fontSize: PropTypes.number,
        renderPositionLabel: PropTypes.func,
        onClickPosition: PropTypes.func,
        flightId: PropTypes.string,
        zoomButtonAction: PropTypes.number,
        height: PropTypes.number,
        loadSequence: PropTypes.object,
        colorPriorities: PropTypes.object,
        communicator: PropTypes.bool,
    }

    canvasDiv = React.createRef();
    shapesGroup = null;
    rectPositionGroup = null;
    rectDoorGroup = null;
    labelGroup = null;
    zoom = null;

    state = {
        svg: null,
    };

    resize = () => {
        const {communicator, height } = this.props;
        this.state.svg.size('100%', height);
        if (communicator) {
            this.handleZoomButton(false);
        }
    };

    componentDidMount() {
        this.createNewSVG();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState && !prevState.svg) {
            this.addD3Zoom();
        }
        if (this.props.zoomButtonAction!==prevProps.zoomButtonAction) {
            const zoomIn = this.props.zoomButtonAction>prevProps.zoomButtonAction;
            this.handleZoomButton(zoomIn);
        }
        if (this.props.height!==prevProps.height) {
            this.resize();
        }
    }

    shouldComponentUpdate(nextProps) {
        // console.log('canvas', this.props.loadSteps === nextProps.loadSteps);
        return true;
    }

    componentWillUnmount() {
        if (this.canvasDiv.current) {
            this.canvasDiv.current.innerHTML = '';
        }
    }

    createNewSVG = () => {
        const {height, } = this.props;
        const svg = SVG(this.canvasDiv.current).size('100%', height);
        this.shapesGroup = svg.group().attr('id', 'lineShapes');
        this.rectPositionGroup = svg.group().attr('id', 'rectPositions');
        this.rectDoorGroup = svg.group().attr('id', 'rectDoors');
        this.labelGroup = svg.group().attr('id', 'texts');
        this.setState({
            svg: svg,
        });
    };

    selectSvgElements = () => {
        const {communicator, } = this.props;
        const svgElement = d3.select(this.canvasDiv.current).select('svg');
        const groupLineShapes = svgElement.select('#lineShapes');
        const groupRectPositions = svgElement.select('#rectPositions');
        const groupRectDoors = svgElement.select('#rectDoors');
        const loadStepDataGroups = svgElement.select('#texts');
        const textGroups = loadStepDataGroups.selectAll('g');
        const allRectPositions = groupRectPositions.selectAll('rect');
        const allRectDoors = groupRectDoors.selectAll('rect');
        const allLines = groupLineShapes.selectAll('line');
        const clipPaths = svgElement.selectAll('clipPath');
        const ellipsis = textGroups.selectAll(svgClasses.ellipsis.selector);
        const dataAppearsWithZoom = [
            d3.selectAll(svgClasses.totalWeight.selector),
            d3.selectAll(svgClasses.sequence.selector),
            d3.selectAll(svgClasses.foreignObject.selector),
            d3.selectAll(svgClasses.graphLoadAction.selector),
            d3.selectAll(svgClasses.graphLoadState.selector),
        ];
        if (!communicator) {
            dataAppearsWithZoom.push(
                d3.selectAll(svgClasses.nofitUld.selector));
        }

        return {
            svgElement,
            groupLineShapes,
            groupRectPositions,
            groupRectDoors,
            loadStepDataGroups,
            textGroups,
            allRectPositions,
            allRectDoors,
            allLines,
            clipPaths,
            ellipsis,
            dataAppearsWithZoom,
        };
    };

    selectRectangleWithIndex = (allRectPositions, groupList, index) => {
        const correspondingRect = allRectPositions.filter((r, i) => i === index);

        const selectAll = selector => d3.select(groupList[index]).selectAll(selector);

        const positionTexts = selectAll(svgClasses.position.selector);
        const totalWeightTexts = selectAll(svgClasses.totalWeight.selector);
        const seqTexts = selectAll(svgClasses.sequence.selector);
        const nofitUldTexts = selectAll(svgClasses.nofitUld.selector);
        const htmlBlocks = selectAll(svgClasses.foreignObject.selector);
        const actionIcons = selectAll(svgClasses.graphLoadAction.selector);
        const stateIcons = selectAll(svgClasses.graphLoadState.selector);
        const contentCheckIcons = selectAll(svgClasses.contentCheckResult.selector);
        const subPositionRects = selectAll(svgClasses.subPosition.selector);
        const ellipsis = selectAll(svgClasses.ellipsis.selector);

        return {
            correspondingRect,
            positionTexts,
            totalWeightTexts,
            seqTexts,
            nofitUldTexts,
            htmlBlocks,
            actionIcons,
            stateIcons,
            contentCheckIcons,
            subPositionRects,
            ellipsis,
        };
    };

    setVisible = (texts) => {
        texts.attr('visibility', 'visible');
    };

    setInvisible = (texts) => {
        texts.attr('visibility', 'hidden');
    };

    removeInvisible = (texts) => {
        texts.attr('visibility', null);
    };

    setCoordsOfLoadstepData = (
        svgElement,
        textGroups,
        allRectPositions,
        zoomIngFactor,
        dataAppearsWithZoom,
        loadStepDataGroups,
    ) => {
        const {fontSize, communicator, } = this.props;
        const MIN_WIDTH_FOR_NORMAL_FONT_SIZE = 20;
        const svgXElement = getXElement(svgElement);
        const svgYElement = getYElement(svgElement);

        textGroups.each((noIdea, index, groupList) => {
            const {
                correspondingRect,
                positionTexts,
                totalWeightTexts,
                seqTexts,
                nofitUldTexts,
                htmlBlocks,
                actionIcons,
                stateIcons,
                contentCheckIcons,
                subPositionRects,
                ellipsis,
            } = this.selectRectangleWithIndex(allRectPositions, groupList, index);
            const boundingClientRect = correspondingRect.node().getBoundingClientRect();
            const { left, top, right, width, height, bottom } = boundingClientRect;

            const numberLoadSteps = nofitUldTexts.node()?
                parseInt(nofitUldTexts.attr('numberLoadSteps')):0;

            const getMoveCenter = (text) => {
                const rect = text.node().getBBox();
                return -svgXElement + width/2 - rect.width/2;
            }

            const getMoveUp = (text, extra) => {
                const rect = text.node().getBBox();
                return -svgYElement + height/2 + rect.height/2 + extra;
            }

            const isInVisibleWidth = () => {
                return width < MIN_WIDTH_TO_SHOW_DATA;
            }

            if (!communicator) {
                const halfTheFontSize = 20 / 2;
                const maxPositionName = 40;
                const padding = 13;
                const actionIconWidthAndPadding = 16;
                const moveRight = 12;
                const moveUp = 90;

                const getHeightLoadStep = () => {
                    const indexLoadStep = parseInt(nofitUldTexts.attr('indexLoadSteps'));
                    return (5*indexLoadStep)*fontSize;
                }

                const getTopY = (y) => {
                    if (y===0 && numberLoadSteps>1) {
                        return getTopY(1);
                    }
                    return top + halfTheFontSize - moveUp + y*fontSize + (numberLoadSteps>1?getHeightLoadStep():0);
                }

                const getLeftIconsX = () => {
                    if (numberLoadSteps<=1) {
                        return left - halfTheFontSize + moveRight + maxPositionName;
                    } else {
                        const rect = nofitUldTexts.node().getBBox();
                        return rect.x + rect.width + moveRight;
                    }
                }

                const getBottomY = () => {
                    if (numberLoadSteps>1 && !isInVisibleWidth()) {
                        return getTopY(2);
                    }
                    return bottom - halfTheFontSize - moveUp - 20;
                }

                const getBottom = () => {
                    return bottom - 105;
                }

                const getMoveRight = (text) => {
                    return -svgXElement + width - halfTheFontSize;
                }

                const isVisibleEllipsis = (div) => {
                    const rect = div.node().getBBox();
                    const rectBottom = rect.y + rect.height;
                    return rectBottom>getBottom() && !isInVisibleWidth()?'visible':'hidden';
                }

                positionTexts.attr('x', (noIdea, unusedIndex, list) => {
                    return left - halfTheFontSize + moveRight;
                });

                positionTexts.attr('y', (noIdea, index, list) => {
                    return top + halfTheFontSize - moveUp;
                });

                totalWeightTexts.attr('x', (noIdea, unusedIndex, list) => {
                    return left + getMoveRight(totalWeightTexts);
                });

                totalWeightTexts.attr('y', (noIdea, index, list) => {
                    return top + halfTheFontSize - moveUp;
                });

                seqTexts.attr('x', (noIdea, unusedIndex, list) => {
                    return right - halfTheFontSize;
                });

                seqTexts.attr('y', (noIdea, index, list) => {
                    return getTopY(0);
                });

                nofitUldTexts.attr('x', (noIdea, unusedIndex, list) => {
                    return left - halfTheFontSize + moveRight;
                });

                nofitUldTexts.attr('y', (noIdea, index, list) => {
                    return getTopY(1);
                });

                htmlBlocks.attr('x', (noIdea, unusedIndex, list) => {
                    return left - halfTheFontSize + moveRight;
                });

                htmlBlocks.attr('y', (noIdea, index, list) => {
                    return getTopY(2);
                });

                actionIcons.attr('x', (noIdea, unusedIndex, list) => {
                    return getLeftIconsX();
                });

                actionIcons.attr('y', (noIdea, index, list) => {
                    return getTopY(0) - padding;
                });

                stateIcons.attr('x', (noIdea, unusedIndex, list) => {
                    return getLeftIconsX() + actionIconWidthAndPadding;
                });

                stateIcons.attr('y', (noIdea, index, list) => {
                    return getTopY(0) - padding;
                });

                contentCheckIcons.attr('x', (noIdea, unusedIndex, list) => {
                    return right - halfTheFontSize - 10;
                });

                contentCheckIcons.attr('y', (noIdea, index, list) => {
                    return getBottomY();
                });

                subPositionRects.attr('x', (noIdea, index, list) => {
                    return left;
                });
                subPositionRects.attr('y', (noIdea, index, list) => {
                    return getTopY(0) - fontSize;
                });
                subPositionRects.attr('width', (noIdea, index, list) => {
                    return width;
                });
                subPositionRects.attr('height', (noIdea, index, list) => {
                    return getTopY(6) - getTopY(0);
                });

                ellipsis.attr('x', (noIdea, unusedIndex, list) => {
                    return left + getMoveCenter(ellipsis);
                });

                ellipsis.attr('y', (noIdea, unusedIndex, list) => {
                    return getBottom();
                });

                ellipsis.attr('visibility', (noIdea, unusedIndex, list) => {
                    return isVisibleEllipsis(subPositionRects);
                });
            } else {
                const startHeight = Math.max(-numberLoadSteps*fontSize, -height/2 + fontSize);

                positionTexts.attr('x', (noIdea, unusedIndex, list) => {
                    return left + getMoveCenter(positionTexts);
                });

                positionTexts.attr('y', (noIdea, index, list) => {
                    return top + getMoveUp(positionTexts, startHeight);
                });

                nofitUldTexts.attr('x', (noIdea, unusedIndex, list) => {
                    return left + getMoveCenter(nofitUldTexts);
                });

                nofitUldTexts.attr('y', (noIdea, index, list) => {
                    const indexLoadStep = parseInt(nofitUldTexts.attr('indexLoadSteps')) + 1;
                    return top + getMoveUp(nofitUldTexts, startHeight + (indexLoadStep - 0.5) * fontSize) + fontSize;
                });
            }

            if (isInVisibleWidth()) {
                dataAppearsWithZoom.forEach(this.setInvisible);
            }
            if (width > MIN_WIDTH_TO_SHOW_DATA) {
                dataAppearsWithZoom.forEach(this.removeInvisible);
            }

            if (width < MIN_WIDTH_FOR_NORMAL_FONT_SIZE) {
                positionTexts.attr('font-size', communicator?'12':'10');
            }

            if (width > MIN_WIDTH_FOR_NORMAL_FONT_SIZE) {
                positionTexts.attr('font-size', communicator?'12':'16');
            }
        });
        this.setVisible(loadStepDataGroups);
    };

    addD3Zoom = () => {
        const {flightId, deckIndex, communicator} = this.props;

        const {
            svgElement,
            groupLineShapes,
            groupRectPositions,
            groupRectDoors,
            loadStepDataGroups,
            textGroups,
            allRectPositions,
            allRectDoors,
            allLines,
            clipPaths,
            ellipsis,
            dataAppearsWithZoom,
        } = this.selectSvgElements();

        let zoomIngFactor = 1;
        const zoomCallback = e => {
            var t = d3.event.transform;
            zoomIngFactor = t.k;
            groupLineShapes.attr('transform', t);
            groupRectPositions.attr('transform', t);
            groupRectDoors.attr('transform', t);
            clipPaths.attr('transform', t);

            this.setInvisible(loadStepDataGroups);
            this.setInvisible(ellipsis);
        };

        this.zoom = d3
            .zoom()
            .on('zoom', zoomCallback)
            .on('end', () => {
                const { k, x, y } = d3.event.transform;
                if (!communicator) {
                    saveDeckStorageValues(flightId, deckIndex, k, x, y);
                }
                return this.setCoordsOfLoadstepData(
                    svgElement,
                    textGroups,
                    allRectPositions,
                    zoomIngFactor,
                    dataAppearsWithZoom,
                    loadStepDataGroups,
                );
            });

        svgElement.call(this.zoom);
        const {k, x, y} = this.getTransformParameters(svgElement,
            allRectPositions, allRectDoors, allLines);
        this.transform(svgElement, x, y, k);
        /*this.setCoordsOfLoadstepData(
            svgElement,
            textGroups,
            allRectPositions,
            zoomIngFactor,
            dataAppearsWithZoom,
            loadStepDataGroups,
        );*/
    };

    getTransformParameters = (svgElement, allRectPositions, allRectDoors, allLines) => {
        const {flightId, deckIndex, communicator} = this.props;
        const deckStorageValues = !communicator?getDeckStorageValues(flightId, deckIndex):null;
        if (deckStorageValues) {
            return deckStorageValues;
        } else {
            return this.getStandardTransformParameters(svgElement, false,
                allRectPositions, allRectDoors, allLines);
        }
    }

    handleZoomButton = (zoomIn) => {
        const {
            svgElement,
            allRectPositions,
            allRectDoors,
            allLines,
        } = this.selectSvgElements();
        svgElement.call(this.zoom.transform, d3.zoomIdentity);
        const {k, x, y} = this.getStandardTransformParameters(svgElement, zoomIn,
            allRectPositions, allRectDoors, allLines);
        this.transform(svgElement, x, y, k);
    }

    getStandardTransformParameters = (svgElement, zoomIn,
                                      allRectPositions, allRectDoors, allLines) => {
        const {horizontal, communicator, } = this.props;
        const {minK, maxK} = getKs(svgElement, allRectPositions, allRectDoors, allLines,
            MIN_WIDTH_TO_SHOW_DATA);
        const k = !zoomIn?minK:maxK;
        const length = (
            ((communicator?1:-1)*getMaxAllRectsX(allRectPositions, null, allLines))
            - getMinAllRectsX(allRectPositions, null, allLines))*k;
        const x = (getWidthElement(svgElement) - length)/2;
        const y = horizontal?
            getHeightElement(svgElement)/2:
            (getTopElement(svgElement)
                - getMinAllRectsY(allRectPositions, allRectDoors, allLines))*k;
        return {k, x, y};
    }

    transform = (svgElement, x, y, k) => {
        this.setZoomRange();
        const transform = d3.zoomIdentity.translate(x, y).scale(k);
        svgElement.call(this.zoom.transform, transform);
    }

    setZoomRange = () => {
        const {
            svgElement,
            allRectPositions,
            allRectDoors,
            allLines,
        } = this.selectSvgElements();
        const {minK, maxK} = getKs(svgElement, allRectPositions, allRectDoors, allLines,
            MIN_WIDTH_TO_SHOW_DATA);
        const minZoom = Math.min(0.25, minK);
        const maxZoom = Math.max(5, maxK);
        this.zoom.scaleExtent([minZoom, maxZoom]);
    }

    render() {
        const {
            loadSteps,
            doors,
            onClickPosition,
            horizontal,
            shapes,
            renderPositionLabel,
            loadSequence,
            colorPriorities,
            communicator,
            id,
        } = this.props;
        const shapesId = `${id}Shapes`;
        const positionsId = `${id}Positions`;
        const doorsId = `${id}Doors`;
        return (
            <div ref={this.canvasDiv}>
                <Shapes
                    key={shapesId}
                    id={shapesId}
                    svg={this.state.svg}
                    shapes={shapes}
                    group={this.shapesGroup}
                    horizontal={horizontal}
                />
                <Positions
                    key={positionsId}
                    id={positionsId}
                    svg={this.state.svg}
                    loadSteps={loadSteps}
                    rectGroup={this.rectPositionGroup}
                    labelGroup={this.labelGroup}
                    onClickPosition={onClickPosition}
                    loadSequence={loadSequence}
                    horizontal={horizontal}
                    renderPositionLabel={renderPositionLabel}
                    colorPriorities={colorPriorities}
                    communicator={communicator}
                />
                <Doors
                    key={doorsId}
                    id={doorsId}
                    svg={this.state.svg}
                    doors={doors}
                    rectGroup={this.rectDoorGroup}
                    horizontal={horizontal}
                />
            </div>
        );
    }
}

export default Canvas;
