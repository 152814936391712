import CriticalTimesPage from './containers/CriticalTimesPage';
import CriticalTimeDetailPage from './containers/CriticalTimeDetailPage';
import selectors from './selectors';
import actionTypes from './actionTypes';
import filterReducer from './reducers/filterReducer';
import actions from './actions';

export default {
  containers: {
    CriticalTimesPage,
    CriticalTimeDetailPage,
  },
  reducer: {
    filter: filterReducer,
  },
  selectors,
  actions,
  actionTypes,
};
