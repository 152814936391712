import styled from 'styled-components';

const Title = styled.h1`
  display: inline;
  margin: 0 .2em 0 0;
  padding: 0;
  color: ${props => props.theme.palette.primary};
  fontSize: ${props => props.theme.font.large};
`;

export default Title;
