import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import Timezone from '../../../../../components/Timezone';
import MIcon from '../../../../../components/MIcon';
import ItemContainer from '../ItemContainer';
import ItemDate from './ItemDate';
import ItemIcon from '../ItemIcon';
import ItemDetailsContainer from '../ItemDetailsContainer';
import ItemDetails from '../ItemDetails';
import ItemIconCircle from '../ItemIconCircle';

class FlightItem extends Component {
  static propTypes = {
    flightInfo: PropTypes.object,
    selected: PropTypes.bool,
    onItemClick: PropTypes.func,
    // Injected by i18next
    t: PropTypes.func.isRequired,
  };

  listItemRef = React.createRef();

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.listItemRef.current) {
      this.listItemRef.current.scrollIntoView({
        block: 'nearest',
      });
    }
  }

  render() {
    const {flightInfo, selected, onItemClick, t} = this.props;
    const header = {
      flNr: flightInfo.flightNumber,
      dpAir: flightInfo.departureAirport.iata,
      arrAir: flightInfo.arrivalAirport.iata,
    };
    return (
        <div ref={selected?this.listItemRef:null}>
        <ItemContainer
            selected={selected}
            onClick={() => {
              onItemClick(flightInfo, header);
            }}
        >
          <ItemDate>
            <span>{t('communicator.flight.departureDate')}</span>:{' '}
            <Timezone date={flightInfo.departureTime} format="DD MMM HH:mm"/>
          </ItemDate>
          <ItemIcon>
            <ItemIconCircle secondary={flightInfo.changeRequest}>
              {flightInfo.changeRequest ? <MIcon>live_help</MIcon> : <MIcon>message</MIcon>}
            </ItemIconCircle>
            {flightInfo.message > 0 &&
            <div style={{marginTop: 10}}>
              <ItemIconCircle small>
                <div>
                  {flightInfo.message}
                </div>
              </ItemIconCircle>
            </div>}
          </ItemIcon>
          <div style={{position: 'relative', marginLeft: '55%', float: 'none'}}>
            <ItemDetailsContainer>
              {flightInfo.picture &&
              <div style={{width: '1%', textAlign: 'left', position: 'absolute', bottom: '-4px'}}>
              <span data-tip={t('communicator.flight.messageWithImage')}>
                <ReactTooltip/>
                <ItemIconCircle small>
                  <MIcon small>image</MIcon>
                </ItemIconCircle>
              </span>
              </div>}
              <ItemDetails hasImage={flightInfo.picture}>
                {`${flightInfo.flightNumber}: ${flightInfo.departureAirport.iata} - ${flightInfo
                    .arrivalAirport.iata}`}
              </ItemDetails>
            </ItemDetailsContainer>
          </div>
        </ItemContainer>
        </div>
    );
  };
}

export default withTranslation()(FlightItem);
