import React from "react";
import TranslatedLabel from "../../../../../../components/TranslatedLabel/TranslatedLabel";
import PropTypes from "prop-types";
import StyledPane from "../StyledPane";

class LoadSequencePctMacPanel extends React.Component {
    static propTypes = {
        flight: PropTypes.object.isRequired,
        loadSequence: PropTypes.object.isRequired,
    }

    render() {
        const getLabel = (keyword, defaultValue) => {
            return <TranslatedLabel
                keyword={`communicator.loadSequence.${keyword}`}
                defaultValue={defaultValue} />
        };

        const {flight, loadSequence, } = this.props;

        return <StyledPane style={{padding: 5, height:40}}>
            <div>{getLabel('limitPctMac', 'Limit % Mac')} {flight.pctMAC.pctMACMax}</div>
            <div>{getLabel('startPctMac', 'Start % Mac')} {loadSequence.startMac}</div>
        </StyledPane>;
    }
}

export default LoadSequencePctMacPanel;