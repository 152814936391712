// @flow
import createActionType from '../../app/utils/createActionType';

export default {
  FLIGHT_SET: createActionType('flight set'),
  DEPARTURE_TIME_SET: createActionType('departure time set'),
  CURRENT_SET: createActionType('current flag set'),
  AIRPORT_SET: createActionType('airport set'),
  CRITICAL_TIME_DATE_TO_SET: createActionType('CriticalTime dateTo set'),
  CRITICAl_TIME_DISTRIBUTION_SET: createActionType('CriticalTime Distribution set'),
  TIME_DISTRIBUTION_SET: createActionType('CriticalTime TimeDistribution set'),
};
