import React from 'react';
import PropTypes from "prop-types";
import {MenuItem} from "material-ui";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Menu from "@material-ui/core/Menu";
import TranslatedLabel from "../../../../../../components/TranslatedLabel/TranslatedLabel";
import {isFlightOnLoadFreighter, isOnLoad} from "../../flightUtils";
import commonSelectors from "../../../../../common/selectors";
import {compose} from "redux";
import {connect} from "react-redux";
import {getUldNumber} from "../../../../../../shared/packages/graphicalLoad/svgComponents/canvasUtil";

class DeckGraphMenu extends React.Component {
    static propTypes = {
        flight: PropTypes.object,
        anchorEl: PropTypes.object,
        distribution: PropTypes.number.isRequired,
        allPositionLoadSteps: PropTypes.array,
        onLockAction: PropTypes.func,
        onClose: PropTypes.func,
    }

    hasAccess = () => {
        const {flight: {baseFlightAccessFunctions},} = this.props;
        if (baseFlightAccessFunctions && baseFlightAccessFunctions.communicator) {
            const accessFunctions = baseFlightAccessFunctions.communicator;
            if (isFlightOnLoadFreighter(this.props, true, true)) {
                return accessFunctions.allowCommunicatorLockContainerFreighterFlights;
            } else if (isFlightOnLoadFreighter(this.props, true, false)) {
                return accessFunctions.allowCommunicatorLockContainerPassengerFlights;
            } else if (isFlightOnLoadFreighter(this.props, false, true)) {
                return accessFunctions.allowCommunicatorUnLockContainerFreighterFlights;
            } else if (isFlightOnLoadFreighter(this.props, false, false)) {
                return accessFunctions.allowCommunicatorUnLockContainerPassengerFlights;
            }
        }
        return false;
    }

    isOnLoad = () => {
        return isOnLoad(this.props, true);
    }

    hasMenuItemLock = () => {
        const {allPositionLoadSteps, } = this.props;
        const hasUlds = allPositionLoadSteps &&
            allPositionLoadSteps.filter(loadStep => loadStep.uld, []).length!==0;
        if (hasUlds) {
            return !this.isOnLoad();
        }
        return false;
    }

    hasMenuItems = () => {
        return this.hasAccess() && this.hasMenuItemLock();
    }

    render() {
        const {anchorEl, onClose, allPositionLoadSteps, onLockAction, } = this.props;
        const labelUldNumber = (allPositionLoadSteps && allPositionLoadSteps.length>1);
        return (
            <>
            {this.hasMenuItems()?
                <Menu
                    id={'DeckMenu'}
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={onClose}
                >
                    {allPositionLoadSteps.map((loadStep, index) =>
                            <MenuItemLock
                                key={`DeckGraphMenu${index}`}
                                labelUldNumber={labelUldNumber}
                                onLoad={this.isOnLoad()}
                                loadStep={loadStep}
                                onLockAction={onLockAction}
                            />
                    )}
                </Menu>:null
            }
        </>
        );
    }

}

class MenuItemLock extends React.Component {
    static propTypes = {
        labelUldNumber: PropTypes.bool,
        onLoad: PropTypes.bool,
        loadStep: PropTypes.object,
        onLockAction: PropTypes.func,
    }

    isLocked = () => {
        const {onLoad, loadStep, } = this.props;
        const uld = loadStep?loadStep.uld:null;
        if (uld) {
            return onLoad?uld.lockedForOnload:uld.lockedForOffload;
        }
        return false;
    }

    handleChange = () => {
        const {onLockAction, loadStep, } = this.props;
        const uld = loadStep?loadStep.uld:null;
        if (onLockAction && uld) {
            const uldId = uld.uldId;
            const action = !this.isLocked()?'LOCK':'UNLOCK';
            const lockAction = {
                uldId: uldId,
                action: action,
            }
            onLockAction(lockAction);
        }
    }

    getLabel = () => {
        const {labelUldNumber, loadStep, } = this.props;
        const uldNumber = labelUldNumber?`${getUldNumber(loadStep)}: `:'';
        return <>
            {uldNumber}<TranslatedLabel
            keyword={'communicator.loadSequence.lockForOffload'}
            defaultValue={'Lock for offload'}/>
            </>
    };

    getTstUldNumber = () => {
        const {loadStep, } = this.props;
        return getUldNumber(loadStep);
    }

    render() {
        return (
            <MenuItem>
                <FormControlLabel
                    control={<Checkbox
                        name="checkedLock"
                        checked={this.isLocked()}
                        onChange={this.handleChange}
                        color={"primary"}
                        data-tst-tag={'lockForOffload'}
                        data-tst-uldnr={this.getTstUldNumber()}
                    />}
                    label={this.getLabel()}
                />
            </MenuItem>
        );
    }
}

const mapStateToProps = state => ({
    flight: commonSelectors.getFlight(state),
});

const enhance = compose(
    connect(mapStateToProps),
);

export default enhance(DeckGraphMenu);