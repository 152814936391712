import styled from 'styled-components';

const ItemIconCircleRemark = styled.div`
  width: ${props => (props.small ? '8px' : '20px')};
  height: ${props => (props.small ? '8px' : '20px')};
  margin: 0 auto;
  margin-top: -8px;
  padding: 5px;
  position: relative;
  background: ${props => props.theme.palette.white};
  border-radius: 100%;
  color: ${props =>
    props.secondary ? props.theme.palette.secondary : props.theme.palette.primary};
  font-size: ${props => props.theme.font.small};
  line-height: ${props => (props.small ? '1em' : '')};
  border: 1px solid #333333;
`;

export default ItemIconCircleRemark;
