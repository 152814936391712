import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import { Table, TableBody, TableHeader, TableRow, TableRowColumn } from 'material-ui/Table';
import reduce from 'lodash/reduce'
import SuperHeader from '../../../../components/Table/SuperHeader';
import CustomTableHeaderColumn from '../../../../components/Table/CustomTableHeaderColumn';
import CustomTableRow from '../../../../components/Table/CustomTableRow';
import CustomTableRowColumn from '../../../../components/Table/CustomTableRowColumn';
import Timezone from '../../../../components/Timezone';

const fixedColumnWidth = { width: 100 };

const BaggagePrint = ({ tableHeader, ulds, flightInfo, getAmount }) => {
  const { t } = useTranslation();
  let totals = Array(tableHeader.length).fill(0);

  return (
    <div style={{ margin: 30 }}>
      <div style={{ marginBottom: 30 }}>
        <SuperHeader>
          <span>{t('communicator.baggage.flightInfo')}</span>
        </SuperHeader>
        <Table selectable={false}>
          <TableBody displayRowCheckbox={false}>
            <TableRow selectable={false}>
              <TableRowColumn>
                <span>{t('communicator.baggage.flightNumber')}</span>
              </TableRowColumn>
              <TableRowColumn>
                {flightInfo.flightNumber}
              </TableRowColumn>
            </TableRow>
            <TableRow selectable={false}>
              <TableRowColumn>
                <span>{t('communicator.baggage.departureAirport')}</span>
              </TableRowColumn>
              <TableRowColumn>
                {flightInfo.departureAirport.iata}
              </TableRowColumn>
            </TableRow>
            <TableRow selectable={false}>
              <TableRowColumn>
                <span>{t('communicator.baggage.arrivalAirport')}</span>
              </TableRowColumn>
              <TableRowColumn>
                {flightInfo.arrivalAirport.iata}
              </TableRowColumn>
            </TableRow>
            <TableRow selectable={false}>
              <TableRowColumn>
                <span>{flightInfo.estimatedDeparture ? t('communicator.baggage.estimated') : t('communicator.baggage.scheduled')} {t('communicator.baggage.departureDate')}</span>
              </TableRowColumn>
              <TableRowColumn>
                <Timezone date={flightInfo.estimatedDeparture || flightInfo.scheduledDeparture} />
              </TableRowColumn>
            </TableRow>
          </TableBody>
        </Table>
      </div>

      <SuperHeader>
        <span>{t('communicator.baggage.header')}</span>
      </SuperHeader>
      <Table selectable={false}>
        <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
          <CustomTableRow>
            <CustomTableHeaderColumn style={fixedColumnWidth}>
              {ulds.length > 0 && t('communicator.baggage.uld')}
            </CustomTableHeaderColumn>
            {tableHeader.map(type =>
              <CustomTableHeaderColumn key={type}>
                {type}
              </CustomTableHeaderColumn>
            )}
          </CustomTableRow>
        </TableHeader>
        <TableBody displayRowCheckbox={false}>
          {ulds.length > 0 &&
          ulds.map(uld =>
            <TableRow key={uld.uldId} selectable={false}>
              {uld.uldNumber.type === 'BEL'
                ? <TableRowColumn style={fixedColumnWidth}>
                  {uld.uldNumber.serial}
                </TableRowColumn>
                : <TableRowColumn style={fixedColumnWidth}>
                  {uld.uldNumber.type}
                  {uld.uldNumber.serial}
                  {uld.uldNumber.owner}
                </TableRowColumn>}
              {tableHeader.map((th, idx) => {
                  const amount = getAmount(th, uld.baggageDetails.baggageDetail);
                  totals[idx] = totals[idx] + amount.val;

                  return (
                    <TableRowColumn key={th}>
                      {amount.el}
                    </TableRowColumn>
                  )
                }
              )}
            </TableRow>
          )}
          {totals.length > 0 &&
          <CustomTableRow>
            <CustomTableRowColumn>
              {t('communicator.baggage.total')}: {reduce(totals, (s, t) => s + t, 0)}
            </CustomTableRowColumn>
            {totals.map((v, kt) =>
              tableHeader.map((type, k) => {
                if (kt === k) {
                  return (
                    <TableRowColumn key={v}>
                      {v}
                    </TableRowColumn>
                  );
                }
                return null;
              })
            )}
          </CustomTableRow>}
        </TableBody>
      </Table>
    </div>
  );
};

BaggagePrint.propTypes = {
  tableHeader: PropTypes.array,
  ulds: PropTypes.array,
  flightInfo: PropTypes.object,
  getAmount: PropTypes.func.isRequired,
};

export default BaggagePrint;
