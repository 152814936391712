import actionTypes from './actionTypes';

const setFlight = flight => ({
  type: actionTypes.FLIGHT_SET,
  payload: {
    flight,
  },
});

const setDepartureTime = departureTime => ({
  type: actionTypes.DEPARTURE_TIME_SET,
  payload: {
    departureTime,
  },
});

const setCurrentFlag = current => ({
  type: actionTypes.CURRENT_SET,
  payload: {
    current,
  },
});

const setAirport = airport => ({
  type: actionTypes.AIRPORT_SET,
  payload: {
    airport,
  },
});

const setCriticalTimeDateTo = dateTo => ({
  type: actionTypes.CRITICAL_TIME_DATE_TO_SET,
  payload: {
    dateTo,
  },
});

const setTimeDistribution = timeDistribution => ({
  type: actionTypes.TIME_DISTRIBUTION_SET,
  payload: {
    timeDistribution,
  },
});

const setDistribution = oldDistribution => ({
  type: actionTypes.CRITICAl_TIME_DISTRIBUTION_SET,
  payload: {
    oldDistribution,
  },
});


export default {
  setFlight,
  setDepartureTime,
  setCurrentFlag,
  setAirport,
  setCriticalTimeDateTo,
  setTimeDistribution,
  setDistribution,
};
