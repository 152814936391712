import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: ${props => props.height}px;
  width: 100%;
  overflow: auto;
`;

export default Wrapper;
