import { APP_INITIALIZED, StateMachineActions } from './actionTypes';

const actions = {
  initializeApp: () => ({
    type: APP_INITIALIZED,
  }),

  connect: (payload = null) => ({
    type: StateMachineActions['MACHINE_ACTION/CONNECTED'],
    payload,
  }),

  disconnect: (payload = null) => ({
    type: StateMachineActions['MACHINE_ACTION/DISCONNECTED'],
    payload,
  }),

  callrequest: (payload = null) => ({
    type: StateMachineActions['MACHINE_ACTION/CALLREQUESTED'],
    payload,
  }),

  cancelrequest: (payload = null) => ({
    type: StateMachineActions['MACHINE_ACTION/CANCELLEDREQUEST'],
    payload,
  }),

  calldenied: (payload = null) => ({
    type: StateMachineActions['MACHINE_ACTION/CALLDENIED'],
    payload,
  }),

  offersend: (payload = null) => ({
    type: StateMachineActions['MACHINE_ACTION/OFFERSENT'],
    payload,
  }),

  callstop: (payload = null) => ({
    type: StateMachineActions['MACHINE_ACTION/CALLSTOPPED'],
    payload,
  }),

  peerDisconnected: (payload = null) => ({
    type: StateMachineActions['MACHINE_ACTION/PEERDISCONNECTED'],
    payload,
  }),

  handlePeerDisconnected: (payload = null) => ({
    type: StateMachineActions['MACHINE_ACTION/HANDLEDPEERDISCONNECT'],
    payload,
  }),

  handleDenied: (payload = null) => ({
    type: StateMachineActions['MACHINE_ACTION/DENIED_HANDLED'],
    payload,
  }),

  handleDisconnected: (payload = null) => ({
    type: StateMachineActions['MACHINE_ACTION/HANDLEDISCONNECT'],
    payload,
  }),

  error: (payload = null) => ({
    type: StateMachineActions['MACHINE_ACTION/ERROR'],
    payload,
  }),

  handleError: (payload = null) => ({
    type: StateMachineActions['MACHINE_ACTION/HANDLEDERROR'],
    payload,
  }),
};

export default actions;
