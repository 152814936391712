import styled from 'styled-components';

const MsgBodyWrapper = styled.div`
  borderRadius: 1em;
  borderTopLeftRadius: ${props => (props.received ? 0 : '1em')};
  borderTopRightRadius: ${props => (props.received ? '1em' : 0)};
  backgroundColor: ${props =>
    props.unread
      ? '#f9f9b8'
      : props.received ? props.theme.palette.primaryPastel : props.theme.palette.primary};
  padding: 10px;
  margin: 0px 20px;
`;

export default MsgBodyWrapper;
