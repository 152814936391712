import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import connect, {container} from 'react-redux-fetch';
import {compose} from 'redux';
import Notification from 'react-web-notification';
import forEach from 'lodash/forEach';
import dropRight from 'lodash/dropRight';
import last from 'lodash/last';
import moment from 'moment';
import adapter from '../../../app/api/adapter';
import injectApiRoutes from '../../../app/api/injectApiRoutes';
import globals, {RefreshQualifier} from '../../../app/globals';
import getAssetLink from '../../../app/utils/getAssetLink';
import securitySelectors from '../../../modules/security/selectors';
import toMilliseconds from '../../../app/utils/toMilliseconds';
import actions from '../../common/actions';
import commonSelectors from '../../common/selectors';
import SystemMessagesOpenItems from "../components/OpenItems/SystemMessagesOpenItems/SystemMessagesOpenItems";
import FlightOpenItems from "../components/OpenItems";

const fetchIsFulfilled = container.getUtil('componentHelpers').fetchIsFulfilled;
const NOTIFICATION_TIMEOUT = 5000;

class OpenItemsPage extends Component {
  static propTypes = {
    flightId: PropTypes.string,
    airport: PropTypes.object,
    userAirportCode: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    onGetFlightHeader: PropTypes.func,
    onSystemMessagesClick: PropTypes.func,
    selectedFlight: PropTypes.string,
    dispatchOpenItemsPost: PropTypes.func,
    openItemsFetch: PropTypes.object,
    onTabChange: PropTypes.func,
    userFrequency: PropTypes.shape({
      openItems: PropTypes.number,
    }),

    // Injected by i18next
    t: PropTypes.func.isRequired,
    refreshOpenItems: PropTypes.bool,
    onRefreshOpenItems: PropTypes.func,
  };

  static contextTypes = {
    registerRefreshAction: PropTypes.func.isRequired,
    removeRefreshAction: PropTypes.func.isRequired,
  };

  state = {
    ignoreNotification: true,
  };

  componentWillMount() {
    this.handleDispatchOpenItemsPost(RefreshQualifier.MANUAL, true)();

    this.context.registerRefreshAction(
      'openItems',
      this.handleDispatchOpenItemsPost(RefreshQualifier.MANUAL)
    );
  }

  componentWillReceiveProps(nextProps) {
    this.showNotification = false;
    this.checkOpenItemsSystem(nextProps);
    this.checkOpenItemsFlights(nextProps);

    if (fetchIsFulfilled(this.props, nextProps, 'openItems')) {
      this.timer = setTimeout(
        this.handleDispatchOpenItemsPost(RefreshQualifier.AUTO),
        toMilliseconds(this.props.userFrequency.openItems)
      );
    }
  }

  checkOpenItemsSystem(nextProps) {
    const previousSystem =
        this.props.openItemsFetch.value
            ? this.props.openItemsFetch.value.system
            : null;
    const nextSystem =
        nextProps.openItemsFetch.value
            ? nextProps.openItemsFetch.value.system
            : null;
    if (previousSystem && nextSystem) {
      if (previousSystem.message < nextSystem.message) {
        this.showNotification = true;
      }
    } else if (!previousSystem && nextSystem) {
      this.showNotification = true;
    }
  }

  checkOpenItemsFlights(nextProps) {
    const previousFlightList =
        this.props.openItemsFetch.value && this.props.openItemsFetch.value.flights
            ? this.props.openItemsFetch.value.flights.flight
            : [];
    const nextFlightList =
        nextProps.openItemsFetch.value && nextProps.openItemsFetch.value.flights
            ? nextProps.openItemsFetch.value.flights.flight
            : [];

    if (previousFlightList.length > 0 && nextFlightList.length > 0) {
      if (previousFlightList.length === nextFlightList.length) {
        forEach(previousFlightList, (prevFlight, i) => {
          if (!nextFlightList[i] || prevFlight.message < nextFlightList[i].message) {
            this.showNotification = true;
          }
        });
      } else {
        const newNext = dropRight(nextFlightList, 1);
        forEach(previousFlightList, (prevFlight, i) => {
          if (!newNext[i] || prevFlight.message < newNext[i].message) {
            this.showNotification = true;
          }
        });
        const lastFL = last(nextFlightList);
        if (lastFL.message > 0) {
          this.showNotification = true;
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {refreshOpenItems, } = this.props;
    if (refreshOpenItems) {
      this.handleDispatchOpenItemsPost(RefreshQualifier.MANUAL)();
    }
  }1

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.context.removeRefreshAction('openItems');
  }

  currentMoment = () => {
    return moment();
  }

  handleDispatchOpenItemsPost = (refreshQualifier, useUserAirportCode) => () => {
    const {dispatchOpenItemsPost, airport, userAirportCode, onRefreshOpenItems} = this.props;
    onRefreshOpenItems(false);
    const defaultAirportCode = (useUserAirportCode && userAirportCode?userAirportCode.iata:'');
    const airportCode = (airport?airport.iata:defaultAirportCode);
    dispatchOpenItemsPost(this.currentMoment(), airportCode, refreshQualifier);
  };

  handleFlightItemClick = (flight, header) => {
    const { onTabChange, dispatch, onGetFlightHeader } = this.props;
    onTabChange('0');
    dispatch(actions.setDepartureTime(this.currentMoment()));
    dispatch(actions.setCurrentFlag(false));
    dispatch(actions.setFlight(flight));
    onGetFlightHeader(header);
  };

  handleSystemMessagesClick = () => {
    const {onTabChange, dispatch, onSystemMessagesClick} = this.props;
    onTabChange('0');
    dispatch(actions.setDepartureTime(this.currentMoment()));
    dispatch(actions.setCurrentFlag(false));
    dispatch(actions.setFlight({}));
    onSystemMessagesClick();
  }

  handlePermissionGranted = () => {
    this.setState({
      ignoreNotification: false,
    });
  };

  handlePermissionDenied = () => {
    this.setState({
      ignoreNotification: true,
    });
  };

  handleNotSupported = () => {
    this.setState({
      ignoreNotification: true,
    });
  };

  render() {
    const { openItemsFetch, t } = this.props;
    const options = {
      tag: 'newMessage',
      body: t('communicator.newMessage.notification'),
      icon: getAssetLink('img/brc_logo_225.png'),
      lang: 'en',
    };
    const value = openItemsFetch.value;
    return (
      <span>
        <SystemMessagesOpenItems
            system={value?value.system:null}
            onItemClick={this.handleSystemMessagesClick}
            selected={false}
        />
        <FlightOpenItems
          flights={value && value.flights
              ? value.flights.flight
              : []
          }
          onItemClick={this.handleFlightItemClick}
          selectedFlight={this.props.flightId}
        />

        {this.showNotification && (
          <Notification
            timeout={NOTIFICATION_TIMEOUT}
            title={t('communicator.newMessage.newMessage')}
            options={options}
            ignore={this.state.ignoreNotification}
            onPermissionGranted={this.handlePermissionGranted}
            onPermissionDenied={this.handlePermissionDenied}
            notSupported={this.handleNotSupported}
          />
        )}
      </span>
    );
  }
}

const mapPropToDispatchToProps = props => [
  {
    resource: 'openItems',
    method: 'POST',
    request: (date, airportCode, refreshQualifier = RefreshQualifier.MANUAL) => ({
      url: props.apiRoutes.getOpenItems(),
      body: {
        accessToken: props.accessToken,
        date: adapter.momentDateToServerDate(date),
        distribution: 'ON_LOAD',
        airportCode: airportCode,
        passengerFlights: true,
        freighterFlights: true,
        application: globals.applicationName,
        refreshQualifier,
      },
    }),
  },
];

const mapStateToProps = state => ({
  flightId: commonSelectors.getFlightId(state),
  airport: commonSelectors.getAirport(state),
  userFrequency: securitySelectors.getUserFrequency(state),
  userAirportCode: securitySelectors.getAirport(state),
});

const enhance = compose(
  injectApiRoutes,
  connect(
    mapPropToDispatchToProps,
    mapStateToProps
  )
);

export default enhance(withTranslation()(OpenItemsPage));