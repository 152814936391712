import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MenuItem from 'material-ui/MenuItem';
import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';
import MIcon from '../../../../components/MIcon';
import Divider from '@material-ui/core/Divider';
import call from '../../../call';

const AccountMenu = ({ account, onLogout, onRefresh, isChangePsswrdOnNextLogin, privacyNoticeUrl }) => {
  const getHRefMenuChangePassword = () => {
    if (isChangePsswrdOnNextLogin) {
        return '/changePasswordAfterLogin';
    } else {
        return '/changePassword';
    }
  }

  const { t } = useTranslation();
  const isInChangePassword = window.location.hash === '#' + getHRefMenuChangePassword();
  const isInSettings = window.location.hash === '#/settings';
  const isInAbout = window.location.hash === '#/about';
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateToPrivacyNotice = () => {
      if (privacyNoticeUrl) {
          window.open(privacyNoticeUrl);
      }
  }

  const getMenuItem = (inThisMenu, hrefMenu, labelKeyMenu) => {
      return (inThisMenu ? (
              <MenuItem primaryText={t('communicator.settings.home')} href="#/" />
          ) : (
              <MenuItem primaryText={t(labelKeyMenu)} href={`#${hrefMenu}`} />
          ))
  };

  const getExternalMenuItem = (labelKeyMenu, handleClick) => {
      return (handleClick?<MenuItem primaryText={t(labelKeyMenu)} onClick={handleClick} />:null);
  }

  const getNavigateToPrivacyNotice = () =>
      privacyNoticeUrl?navigateToPrivacyNotice:null;

  return (
    <div>
      <IconButton
        style={{ float: 'left', marginTop: 7, marginRight: 21 }}
        tooltip="Refresh"
        onClick={onRefresh}
      >
        <MIcon>refresh</MIcon>
      </IconButton>
      <span style={{ float: 'left' }}>
        <call.containers.ConnectionContainer />
        {account}
      </span>

      <IconButton onClick={handleClick}>
        <MIcon>keyboard_arrow_down</MIcon>
      </IconButton>
      <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
          {getMenuItem(isInChangePassword, getHRefMenuChangePassword(), 'communicator.changePassword.changePassword')}
          {getMenuItem(isInSettings, '/settings', 'communicator.settings.settings')}
          <Divider />
          {getMenuItem(isInAbout, '/about', 'communicator.about.about')}
          {getExternalMenuItem( 'rols.privacy.notice.title', getNavigateToPrivacyNotice())}
        <MenuItem primaryText={t('communicator.settings.signOut')} onClick={onLogout} disabled={isInSettings} />
      </Menu>
    </div>
  );
};

AccountMenu.propTypes = {
  account: PropTypes.string.isRequired,
  onLogout: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
};

export default AccountMenu;
