import { takeEvery, put, all } from 'redux-saga/effects';
import { FETCH } from 'react-redux-fetch';
import without from 'lodash/without';
import app from '../../../modules/app';

let pendingActions = [];

function* watchRequest(action) {
  const actionName = action.resource.name;
  pendingActions.push(actionName);
  // console.log('+', actionName, pendingActions.length, pendingActions);
  if (action.method === 'POST' && action.resource.action === 'newMessage') {
    yield put(app.actions.submittingNewMessage(true));
  }

  yield put(app.actions.storeHasPendingActions(true));
}

function* watchFulfil(action) {
  const actionName = action.resource.name;
  pendingActions = without(pendingActions, actionName);
  // console.log('-', actionName, pendingActions.length, pendingActions);
  if (action.method === 'POST' && action.resource.action === 'newMessage') {
    yield put(app.actions.submittingNewMessage(false));
  }

  if (pendingActions.length === 0) {
    yield put(app.actions.storeHasPendingActions(false));
  }
}

export function* watchApiPendingRequests() {
  yield all([
    takeEvery(FETCH.for('get').REQUEST, watchRequest),
    takeEvery(FETCH.for('get').FULFILL, watchFulfil),
    takeEvery(FETCH.for('get').REJECT, watchFulfil),
    takeEvery(FETCH.for('post').REQUEST, watchRequest),
    takeEvery(FETCH.for('post').FULFILL, watchFulfil),
    takeEvery(FETCH.for('post').REJECT, watchFulfil),
  ]);
}

export default {};
