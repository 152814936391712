import { container } from 'react-redux-fetch';
import newError from 'react-redux-fetch/lib/utils/errors';

const customResponseHandlerFunction = response => {
  if (response.headers.get('content-length') === '0' || response.status === 204) {
    return null;
  }

  const json = response.json();

  if (response.status === 200) {
    return json.then(data => {
      if (data.errorNumber && data.errorNumber !== 0) {
        const cause = { error: data.errorNumber, message: data.message };
        return Promise.reject(newError(cause));
      }
      return json;
    });
  }

  return response.status > 200 && response.status < 300
    ? json
    : json.then(cause => Promise.reject(newError(cause)));
};

const init = () => {
  container.setUtil('handleResponse', customResponseHandlerFunction);
};

export default init;
