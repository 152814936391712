const getTabStorageKey = () => `lastGraphicalLoadTab`;

const getDeckStorageKey = (deckIndex) => `lastGraphicalLoadPosition${deckIndex}`;

export const getStorageTabValue = (pFlightId) => {
    const storageKey = getTabStorageKey();
    try {
        const storedValue = sessionStorage.getItem(storageKey);
        if (storedValue) {
            const {flightId, tabValue} = JSON.parse(storedValue);
            if (flightId === pFlightId) {
                return Number.parseInt(tabValue);
            }
        }
    } catch (e) {}
    // Invalid stored value or other flight
    removeGraphicalLoadStorages();
    return null;
}

export const saveStorageTabValue = (flightId, tabValue) => {
    const storageKey = getTabStorageKey();
    const value = JSON.stringify({ flightId, tabValue })
    sessionStorage.setItem(storageKey, value);
};

export const getDeckStorageValues = (pFlightId, pDeckIndex) => {
    const storageKey = getDeckStorageKey(pDeckIndex);
    try {
        const storedPosition = sessionStorage.getItem(storageKey);
        if (storedPosition) {
            const {flightId, k, x, y} = JSON.parse(storedPosition);
            if (flightId === pFlightId) {
                return {k, x, y};
            }
        }
    } catch (e) {}
    // Invalid stored position or other flight
    sessionStorage.removeItem(storageKey);
    return null;
}

export const saveDeckStorageValues = (flightId, deckIndex, k, x, y) => {
    const storageKey = getDeckStorageKey(deckIndex);
    sessionStorage.setItem(storageKey, JSON.stringify({ flightId, k, x, y }));
}

export const removeGraphicalLoadStorages = () => {
    const storageKey = getTabStorageKey();
    sessionStorage.removeItem(storageKey);
    removeAllDeckStorages();
}

const removeAllDeckStorages = () => {
    for (let i=0; i<=5; i++) {
        const storageKey = getDeckStorageKey(i);
        sessionStorage.removeItem(storageKey);
    }
}
