import React from 'react';

import styled, {css} from 'styled-components';
import MaterialWorkIcon from 'material-ui/svg-icons/action/work';

const MIcon = styled.i`
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 30px !important;
  display: inline-block;
  line-height: 1.5;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
  padding: 10px;
`;

const MaterialIconCSS = css`
  height: 30px !important;
  width: 30px !important;
  color: ${props =>
    props.queuedMessagesPresent
      ? props.theme.palette.secondary
      : props.selected
      ? props.theme.palette.white
      : props.theme.palette.grey}!important;
  &:hover {
    color: ${props =>
      props.queuedMessagesPresent
        ? props.theme.palette.secondary
        : props.theme.palette.white}!important;
  }
`;

export const WorkIcon = styled(({queuedMessagesPresent, ...props}) => <MaterialWorkIcon {...props} />)`
  ${MaterialIconCSS};
`;

export default MIcon;
