import PropTypes from 'prop-types';
import React from 'react';
import injectI18n from '../hoc/injectI18n';

const FormattedMessage = ({ id, defaultMessage, translate }) =>
  <span>
    {translate(id, defaultMessage)}
  </span>;

FormattedMessage.propTypes = {
  id: PropTypes.string,
  defaultMessage: PropTypes.string,
  translate: PropTypes.func,
};

export default injectI18n(FormattedMessage);
