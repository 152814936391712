import {compose} from "redux";
import app from "../../../app";
import connect from "react-redux-fetch";
import PropTypes from "prop-types";
import get from "lodash/get";
import BaseChangePsswrdPage, {mapStateToProps} from "./BaseChangePsswrdPage";

class ChangePsswrdPage extends BaseChangePsswrdPage {
    static propTypes = {
        dispatchChangePasswordPost: PropTypes.func,
        changePasswordFetch: PropTypes.object,
    };

    executeChangePsswrd = (props) => {
        const {dispatchChangePasswordPost,} = this.props;
        dispatchChangePasswordPost(props);
    }

    getFetchValue = (props) => {
        const {changePasswordFetch} = props;
        return get(changePasswordFetch, 'value');
    }

    getFetch = (props) => {
        const {changePasswordFetch} = props;
        return changePasswordFetch;
    }

    getReferrer = () => {
        return '/changePassword';
    }

    isAfterLogin = () => {
        return false;
    }
}

const mapPropToDispatchToProps = props => [
    {
        resource: 'changePassword',
        method: 'POST',
        request: ({currentPw, newPw, newPwRepeat, }) => ({
            url: props.apiRoutes.changePassword(),
            body: {
                accessToken: props.accessToken,
                currentPassword: currentPw,
                newPassword: newPw,
                newPasswordRepeat: newPwRepeat,
            }
        }),
    },
];

const enhance = compose(
    app.injectApiRoutes,
    connect(mapPropToDispatchToProps, mapStateToProps));

export default enhance(ChangePsswrdPage);