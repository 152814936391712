import React from 'react';
import PropTypes from "prop-types";
import Canvas from "../../../../../../shared/packages/graphicalLoad/svgComponents/Canvas";
import "../../../../style/loadSequence.css";
import DeckGraphMenu from "./DeckGraphMenu";
import Label from "../../../../../../shared/packages/graphicalLoad/svgComponents/Label";

const FONT_SIZE = 10;

class DeckGraph extends React.Component {
    static propTypes = {
        id: PropTypes.string,
        distribution: PropTypes.number.isRequired,
        flight: PropTypes.object,
        deck: PropTypes.object,
        deckIndex: PropTypes.number,
        locations: PropTypes.object,
        loadSequence: PropTypes.object,
        height: PropTypes.number,
        onLockAction: PropTypes.func,
    }

    state = {
        anchorEl: null,
        selectedAllPositionLoadSteps: null,
    }

    handleClick = (loadStep, allPositionLoadSteps,  event) => {
        this.setState({
            anchorEl: event.target,
            selectedAllPositionLoadSteps: allPositionLoadSteps,
        });
    }

    handleMenuClose = () => {
        this.setState({anchorEl: null,});
    }

    renderPositionLabel = ({id, svg, group, loadStep, allPositionLoadSteps,
                               color, onClick}) => {
        return <Label
            key={id}
            id={id}
            click={onClick}
            loadStep={loadStep}
            allPositionLoadSteps={allPositionLoadSteps}
            svg={svg}
            group={group}
            fontSize={FONT_SIZE}
            color={color}
            horizontal={true}
            communicator={true}
        />;
    }

    render() {
        const {
            distribution,
            flight,
            deck,
            deckIndex,
            locations: {
                doors: { door },
                loadSteps: { loadStep },
            },
            loadSequence,
            height,
            id,
            onLockAction,
        } = this.props;
        const shapes = deck.shapes;
        const deckName = deck.deckName;
        const doorFromDeck = door.filter(
            doorToFilter => doorToFilter.deckInfo.deckName === deckName
        );
        const loadStepFromDeck = loadStep.filter(
            loadStepToFilter => loadStepToFilter.deckInfo.deckName === deckName
        );
        const canvasId = `${id}Canvas`;
        return (
            <div className={'divDeckGraph'}>
                <div style={{padding: 5,}}>
                    {deckName}
                </div>
                <div className={'divDeckGraphCanvas'}>
                    <Canvas
                        key={canvasId}
                        id={canvasId}
                        horizontal={true}
                        shapes={shapes}
                        loadSteps={loadStepFromDeck}
                        doors={doorFromDeck}
                        flightId={flight.flightId}
                        onClickPosition={this.handleClick}
                        deckIndex={deckIndex}
                        fontSize={FONT_SIZE}
                        renderPositionLabel={this.renderPositionLabel}
                        height={height}
                        loadSequence={loadSequence}
                        communicator={true}
                    />
                    <DeckGraphMenu
                        anchorEl={this.state.anchorEl}
                        distribution={distribution}
                        allPositionLoadSteps={this.state.selectedAllPositionLoadSteps}
                        onLockAction={onLockAction}
                        onClose={this.handleMenuClose}
                    />
                </div>
            </div>
        );
    }
}

export default DeckGraph;