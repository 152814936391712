import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import connect from 'react-redux-fetch';
import {compose} from 'redux';
import Button from '../../../components/Button';
import injectApiRoutes from '../../../app/api/injectApiRoutes';
import app from '../../../modules/app';
import ButtonWrapper from '../components/NewMessage/ButtonWrapper';
import NewMessage from '../components/NewMessage';
import commonSelectors from '../../common/selectors';

class NewMessageForm extends Component {
  static propTypes = {
    dispatchNewMessagePost: PropTypes.func,
    dispatchEngagedPersonnelPost: PropTypes.func,
    messagesFetch: PropTypes.object,
    engagedPersonnelFetch: PropTypes.object,
    flightId: PropTypes.string,
    submittingNewMsg: PropTypes.bool,
    distribution: PropTypes.string,

    // Injected by i18n
    t: PropTypes.func.isRequired,
  };

  state = {
    messageValue: '',
    showDialog: false,
    checkedRoles: [],
    checkedDoorRoles: [],
    checkedFlightRoles: [],
  };

  handleMessageValueChange = e => {
    this.setState({
      messageValue: e.currentTarget.value,
    });
  };

  handleMessageSubmit = () => {
    const selectedDoorRoles = { id: this.state.checkedDoorRoles };
    const selectedFlightRoles = { id: this.state.checkedFlightRoles };

    this.props.dispatchNewMessagePost(
      this.state.messageValue,
      selectedDoorRoles,
      selectedFlightRoles,
      this.props.distribution
    );
    this.handleDialogClose();
  };

  handleDialogOpen = e => {
    e.preventDefault();
    this.props.dispatchEngagedPersonnelPost(this.props.flightId, this.props.distribution);
    this.setState({ showDialog: true });
  };

  handleDialogClose = () => {
    this.setState({
      showDialog: false,
      messageValue: '',
      checkedDoorRoles: [],
      checkedFlightRoles: [],
    });
  };

  handleDoorCheckboxCheck = id => {
    const found = this.state.checkedDoorRoles.includes(id);
    if (found) {
      this.setState({
        checkedDoorRoles: this.state.checkedDoorRoles.filter(x => x !== id),
      });
    } else {
      this.setState({
        checkedDoorRoles: [...this.state.checkedDoorRoles, id],
      });
    }
  };

  handleFlightCheckboxCheck = id => {
    const found = this.state.checkedFlightRoles.includes(id);
    if (found) {
      this.setState({
        checkedFlightRoles: this.state.checkedFlightRoles.filter(x => x !== id),
      });
    } else {
      this.setState({
        checkedFlightRoles: [...this.state.checkedFlightRoles, id],
      });
    }
  };

  render() {
    const { engagedPersonnelFetch, messagesFetch, t } = this.props;

    const rolesChecked =
      this.state.checkedDoorRoles.length === 0 && this.state.checkedFlightRoles.length === 0;
    const postingMessage = messagesFetch.pending && !messagesFetch.meta;

    const actions = [
      <Button
          key={'cancelMessage'}
        label={<span>{t('communicator.newMessage.cancelMessageLabel')}</span>}
        onClick={this.handleDialogClose}
      />,
      <ButtonWrapper
          key={'sendMessage'}
        label={<span>{t('communicator.newMessage.sendMessageLabel')}</span>}
        primary
        onClick={this.handleMessageSubmit}
        disabled={rolesChecked || postingMessage}
      />,
    ];

    return (
      <NewMessage
        value={this.state.messageValue}
        onChange={this.handleMessageValueChange}
        onSubmit={this.handleMessageSubmit}
        submitting={this.props.submittingNewMsg}
        onDialogOpen={this.handleDialogOpen}
        showDialog={this.state.showDialog}
        actions={actions}
        engagedPersonnelFetch={engagedPersonnelFetch}
        onDoorCheckboxCheck={this.handleDoorCheckboxCheck}
        onFlightCheckboxCheck={this.handleFlightCheckboxCheck}
        checkedDoorRoles={this.state.checkedDoorRoles}
        checkedFlightRoles={this.state.checkedFlightRoles}
      />
    );
  }
}

const mapPropToDispatchToProps = props => [
  {
    resource: {
      name: 'messages',
      action: 'newMessage',
    },
    method: 'POST',
    request: (message, doorRole, flightRole, distribution) => ({
      url: props.apiRoutes.submitMessage(),
      body: {
        accessToken: props.accessToken,
        text: message,
        flightId: props.flightId,
        distribution: distribution,
        imSequence: 0,
        cargoFlights: true,
        passengerFlights: true,
        pic64: '',
        flightRole,
        doorRole,
      },
    }),
  },
  {
    resource: 'engagedPersonnel',
    method: 'POST',
    request: (flightId, distribution) => ({
      url: props.apiRoutes.getEngagedPersonnel(),
      body: {
        accessToken: props.accessToken,
        flightId: flightId || props.flightId,
        distribution: distribution,
        imSequence: 0,
      },
    }),
  },
];

const mapStateToProps = state => ({
  submittingNewMsg: app.selectors.submittingNewMsg(state),
  flightId: commonSelectors.getFlightId(state),
  distribution: commonSelectors.getDistribution(state),
});

const enhance = compose(
  injectApiRoutes,
  connect(
    mapPropToDispatchToProps,
    mapStateToProps
  )
);

export default enhance(withTranslation()(NewMessageForm));
