import actionTypes from './actionTypes';

export const dispatchEnqueueSnackbar = notification => {
  const k = notification.options && notification.options.key;

  return {
    type: actionTypes.ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      key: k || new Date().getTime() + Math.random(),
    },
  };
};

export const dispatchCloseSnackbar = k => ({
  type: actionTypes.CLOSE_SNACKBAR,
  dismissAll: !k, // dismiss all if no k has been defined
  k,
});

export const dispatchRemoveSnackbar = k => ({
  type: actionTypes.REMOVE_SNACKBAR,
  k,
});
