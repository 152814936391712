import moment from 'moment';
import get from 'lodash/get';

const getFlightId = state => get(state, 'common.flight.flightId');
const getFlight = state => get(state, 'common.flight');
const getDepartureTime = state =>
  state.common.departureTime && moment(state.common.departureTime);
const getCurrentFlag = state => get(state, 'common.current');
const getAirport = state => get(state, 'common.airport');
const getDistribution = state => get(state, 'common.flight.distribution');
const getTimeDistribution = state => get(state, 'common.timeDistribution');
const getOldDistribution = state => get(state, 'common.oldDistribution');

export default {
  getFlightId,
  getFlight,
  getDepartureTime,
  getCurrentFlag,
  getAirport,
  getDistribution,
  getTimeDistribution,
  getOldDistribution,
};
