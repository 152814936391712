import React from 'react';
import calculateSvgPosition from '../../lib/calculateSvgPosition';
import PropTypes from "prop-types";
import {addExtraTags} from "../canvasUtil";

class Rectangle extends React.Component {
    static propTypes = {
        id: PropTypes.string,
        svg: PropTypes.object,
        rectangle: PropTypes.object,
        onClick: PropTypes.func,
        group: PropTypes.object,
        fillColor: PropTypes.string,
        borderColor: PropTypes.string,
        horizontal: PropTypes.bool,
        nrDivs: PropTypes.number,
    }

    componentDidMount() {
        const { svg, rectangle, onClick, group, horizontal, fillColor, borderColor, hidden, nrDivs } = this.props;
        const backGroundColor = fillColor ? fillColor : '#2F64FF';
        const strokeColor = borderColor ? borderColor : '#000000';
        const opacity = hidden?0:1;
        const hasDivs = nrDivs && nrDivs>1;

        const { width, height, x, y } = calculateSvgPosition(rectangle, horizontal);

        const rect = svg.rect(width, height);
        rect.style({
            fill: backGroundColor,
            stroke: strokeColor,
            opacity: opacity,
        });

        if (onClick && !hasDivs) {
            rect.click(onClick);
            addExtraTags(rect, rectangle);
        }

        group.add(rect);
        rect.move(x, y);
    }

    render() {
        return null;
    }
}

export default Rectangle;
