import styled from 'styled-components';

const CurrentToggleWrapper = styled.div`
  display: flex;
  width: 80%;
  margin: 20px auto 10px auto;
  border: 1px solid ${props => props.theme.palette.primaryPastelLight};
  borderRadius: 0.4em;
  padding: 0.5em;
  box-sizing: border-box;
`;

export default CurrentToggleWrapper;
