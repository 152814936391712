import callMiddleware from './middleware/callMiddleware';
import actions from './actions';
import selectors from './selectors';
import { StateMachineActions } from './actionTypes';
import CallContainer from './containers/CallContainer';
import ConnectionContainer from './containers/ConnectionContainer';
import callReducer from './reducers/callReducer';
import { CallState, CallType, MessageTypes } from './constants';
import errorMiddleware from './middleware/errorMiddleware';

export default {
  reducer: callReducer,
  middlewares: [callMiddleware, errorMiddleware],
  messageTypes: MessageTypes,
  actions,
  selectors,
  actionTypes: StateMachineActions,
  callStates: CallState,
  callTypes: CallType,
  containers: { CallContainer, ConnectionContainer },
};
