export class ColorDuration {
    constructor(colors) {
        this.colors = colors;

        this.SCHEDULED = { backgroundColor: this.filterColorByType('CRT-SCHEDULED') };
        this.NEARTHRESHOLD = { backgroundColor: this.filterColorByType('CRT-NEAR TRESHOLD') };
        this.TOOLATE = { backgroundColor: this.filterColorByType('CRT-TOO LATE') };
        this.EXECUTED = { backgroundColor: this.filterColorByType('CRT-EXECUTED') };
        this.EXECUTEDLATE = { backgroundColor: this.filterColorByType('CRT-EXECUTED LATE') };
        this.EMPTY = { backgroundColor: '' };
    }

    getColor(time) {
        if (time) {
            if (time.tooLateDur) return this.TOOLATE;
            if (time.nearThresholdDur) return this.NEARTHRESHOLD;
            if (time.executedLateDur) return this.EXECUTEDLATE;
            if (time.executedDur) return this.EXECUTED;
        }
        // alert('No data found');
        return this.SCHEDULED;
    }

    filterColorByType(type) {
        if (this.colors) {
            for (let i = 0; i < this.colors.length; i++) {
                if (this.colors[i].name === type) {
                    return this.colors[i].fill.value;
                }
            }
        }
    }
}
