import React, { useState } from 'react';
import { DatePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';

import DatePickerWrapper from './DatePickerWrapper';
import MIcon from '../../../../components/MIcon';

const ButtonDatePicker = ({ defaultDate, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleIconClick = () => {
    setIsOpen(true);
  };

  return (
    <DatePickerWrapper>
      <MIcon style={{ padding: '12px 20px 10px', cursor: 'pointer' }} onClick={handleIconClick}>
        date_range
      </MIcon>
      <DatePicker
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        style={{ marginRight: 25 }}
        name="date"
        value={defaultDate}
        onChange={onChange}
        format="DD MMM YYYY"
        autoOk
      />
    </DatePickerWrapper>
  );
};

ButtonDatePicker.propTypes = {
  defaultDate: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default ButtonDatePicker;
