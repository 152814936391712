import styled, {css} from 'styled-components';
import FlatButton from 'material-ui/FlatButton';

export const COLOR_TYPES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  LIGHT: 'light',
};

export const BTN_VARIANTS = {
  OUTLINED: 'outlined',
  CONTAINED: 'contained',
};

const newGetColor = ({ color, theme }) => {
  switch (color) {
    case COLOR_TYPES.PRIMARY:
      return theme.palette.primary;
    case COLOR_TYPES.SECONDARY:
      return theme.palette.secondary;
    case COLOR_TYPES.LIGHT:
      return theme.palette.white;
    default:
      return theme.palette.black;
  }
};

const convertOldMethodToNew = ({ primary, secondary, light }) => {
  if (primary) return { color: COLOR_TYPES.PRIMARY };
  if (secondary) return { color: COLOR_TYPES.SECONDARY };
  if (light) return { color: COLOR_TYPES.LIGHT };
};

const getColor = ({ color, primary, secondary, light, theme }) => {
  return newGetColor({ color: color || convertOldMethodToNew({ primary, secondary, light }), theme });
};

const ContainedButton = css`
  background-color: ${props => getColor(props)}!important;
  color: ${props =>
    props.light || props.color === COLOR_TYPES.LIGHT
      ? props.theme.palette.black
      : props.theme.palette.white}!important;

  &:hover {
    border: 1px solid ${props => getColor(props)}!important;
    color: ${props => getColor(props)}!important;
    background-color: transparent !important;
  }
`;

const OutlinedButton = css`
  border: 1px solid ${props => getColor(props)}!important;
  color: ${props => getColor(props)}!important;

  &:hover {
    background-color: ${props => getColor(props)}!important;
    color: ${props =>
      props.light ? props.theme.palette.black : props.theme.palette.white}!important;
  }
`;

const getButtonType = props => {
  const { variant } = props;
  switch (variant) {
    case BTN_VARIANTS.CONTAINED:
      return ContainedButton;
    case BTN_VARIANTS.OUTLINED:
    default:
      return OutlinedButton;
  }
};

const Button = styled(FlatButton)`
  ${props => getButtonType(props)};
  height: 36px !important;
  margin-top: 10px !important;
  line-height: 1em !important;
  border-radius: 1em !important;

  span {
    font-weight: inherit !important;
  }
`;

export default Button;
