import React from 'react';
import TranslatedLabel from "../../../../../../../components/TranslatedLabel/TranslatedLabel";
import {Button} from "@material-ui/core";
import PropTypes from "prop-types";
import {compose} from "redux";
import {connect} from "react-redux";
import commonSelectors from "../../../../../../common/selectors";
import ConfirmDeleteFlight from "./ConfirmDeleteFlight";

class DeleteButton extends React.Component {
    static propTypes = {
        flight: PropTypes.object,
        keyword: PropTypes.string.isRequired,
        defaultValue: PropTypes.string.isRequired,
        action: PropTypes.string.isRequired,
        distribution: PropTypes.number.isRequired,
        onClick: PropTypes.func.isRequired,
        pending: PropTypes.bool,
    }

    state = {
        open: false,
    }

    handleClick = () => {
        this.setState({open: true,});
    }

    handleClickConfirmYes = () => {
        this.setState({open: false,});
        const {action, onClick} = this.props;
        onClick({
            action: action,
        });
    }

    handleClickConfirmNo = () => {
        this.setState({open: false,});
    }

    hasAccessToForceDelete = () => {
        const {flight: {baseFlightAccessFunctions},} = this.props;
        if (baseFlightAccessFunctions && baseFlightAccessFunctions.communicator) {
            const accessFunctions = baseFlightAccessFunctions.communicator;
            return accessFunctions.allowFlightForceDeleteFlight;
        }
        return false;
    }

    hasAccessToDelete = () => {
        const {flight: {baseFlightAccessFunctions},} = this.props;
        if (baseFlightAccessFunctions && baseFlightAccessFunctions.communicator) {
            const accessFunctions = baseFlightAccessFunctions.communicator;
            return accessFunctions.allowFlightDelete;
        }
        return false;
    }

    render() {
        const getLabel = (keyword, defaultValue) => {
            return <TranslatedLabel
                keyword={`communicator.loadSequence.${keyword}`}
                defaultValue={defaultValue}/>
        };

        const {keyword, defaultValue, action, distribution, pending} = this.props;

        return (this.hasAccessToDelete() || this.hasAccessToForceDelete())
            ? <>
                <Button
                    variant="contained"
                    color="secondary"
                    style={{margin: 10}}
                    onClick={this.handleClick}
                    disabled={pending}
                    data-tst-tag={keyword}
                >
                    {getLabel(keyword, defaultValue)}
                </Button>
                    <ConfirmDeleteFlight
                        open={this.state.open}
                        action={action}
                        distribution={distribution}
                        onClickYes={this.handleClickConfirmYes}
                        onClickNo={this.handleClickConfirmNo}
                    />
            </>
            : null;
    }
}

const mapStateToProps = state => ({
    flight: commonSelectors.getFlight(state),
});

const enhance = compose(
    connect(mapStateToProps),
);

export default enhance(DeleteButton);