import styled from 'styled-components';

const TextFieldWrapper = styled.div`
  background-color: rgba(100, 100, 100, 0.2);
  margin: 1em 1em;
  padding: 0 0 .25em 0;
  border-radius: 1.5em;
`;

export default TextFieldWrapper;
