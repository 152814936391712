import React, {useEffect, useRef} from 'react';
import Pie from "./Pie";

const PieChart = ({ percentage }) => {
  /* //Css implementation doesn't work on the zebra devices
  const pct = 100 - percentage;
  return (
      <Pie style={{background: `conic-gradient(orange 0% ${pct}%, #6fb07f ${pct}% 100%)`}} />
  );*/

  const canvasRef = useRef(null);

  useEffect(() => {
    const draw = (ctx) => {
      const x = 10;
      const y = 10;
      const r = 10;
      const total = 100.0;
      const data = [total - percentage, percentage];
      const colors = [ 'orange', '#6fb07f'];
      let lastend = -Math.PI/2;
      for (let i = 0; i < data.length; i++) {
        ctx.fillStyle = colors[i];
        ctx.beginPath();
        ctx.moveTo(x, y);
        ctx.arc(x, y, r, lastend, lastend +
            (Math.PI * 2 * (data[i] / total)), false);
        ctx.lineTo(x, y);
        ctx.fill();
        lastend += Math.PI * 2 * (data[i] / total);
      }
    }

    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    draw(context);
  });

  return <Pie><canvas ref={canvasRef} width={20} height={20}/></Pie>
};
export default PieChart;
