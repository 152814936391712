import React from 'react';
import { useDispatch } from 'react-redux';
import actions from '../../actions';
import usePeerConnection from '../../hooks/usePeerConnection';
import { CallType, MessageTypes } from '../../constants';
import Audio from '../Audio';
import Video from '../Video';

const Initiator = ({ sendMessage, selectedCallType }) => {
  const dispatch = useDispatch();
  const [peerConnectionRef, localStreamRef, remoteStreamRef, callType] = usePeerConnection({
    isCaller: true,
    audio: true,
    video: true,
    sendToUser: sendMessage,
    selectedCallType,
  });

  const isSelectedTypeVideoCall = callType !== CallType.ONLY_VOICE;

  const handleStopClicked = () => {
    if (peerConnectionRef.current) {
      sendMessage({ type: MessageTypes.CALL_STOP });
      peerConnectionRef.current.stop();
      dispatch(actions.callstop());
    }
  };

  return (
    <>
      {isSelectedTypeVideoCall ? (
        <Video domRef={remoteStreamRef} domRefOther={localStreamRef} onStopBtnClicked={handleStopClicked} />
      ) : (
        <Audio domRef={remoteStreamRef} onStopBtnClicked={handleStopClicked} />
      )}
    </>
  );
};

export default Initiator;
