import React, { useState } from 'react';
import Draggable from 'react-draggable';
import { useSelector } from 'react-redux';

import styled from '@material-ui/styles/styled';
import makeStyles from '@material-ui/styles/makeStyles';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import CallEnd from '@material-ui/icons/CallEnd';
import Fullscreen from '@material-ui/icons/Fullscreen';
import FeaturedVideo from '@material-ui/icons/FeaturedVideo';
import BrandingWatermark from '@material-ui/icons/BrandingWatermark';

import selectors from '../../selectors';

const WhiteIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.white,
}));

const useStyles = makeStyles({
  videoWrapper: ({ videoState }) => ({
    position: 'absolute',
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    zIndex: 3,
    overflow: 'hidden',
    backgroundColor: 'black',
    ...(videoState === VideoStates.FULL_SCREEN && {
      height: '100%',
      width: '100%',
      backgroundColor: 'black',
    }),
    ...(videoState === VideoStates.MINIMISED && {
      width: 350,
      backgroundColor: 'black',}),
  }),
  videoHeader: {
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.54)',
    color: 'white',
  },
  videoElement: ({ videoState }) => ({
    ...(videoState === VideoStates.NORMAL && { height: '300px', width: '100%' }),
    ...(videoState === VideoStates.FULL_SCREEN && { height: '70%', width: '100%' }),
    ...(videoState === VideoStates.MINIMISED && { width: 350 }),
  }),
  videoElementOwn: ({ videoState }) => ({
    ...(videoState === VideoStates.NORMAL && { height: '150px', width: '100%' }),
    ...(videoState === VideoStates.FULL_SCREEN && { height: '20%', width: '100%' }),
    ...(videoState === VideoStates.MINIMISED && { width: 150 }),
  }),
});

const VideoStates = {
  FULL_SCREEN: 'FULL_SCREEN',
  NORMAL: 'NORMAL',
  MINIMISED: 'MINIMISED',
};

const Video = props => {
  const { domRef, domRefOther, onStopBtnClicked, ...rest } = props;
  const toOnlineName = useSelector(selectors.getToOnlineName);
  const [videoState, setVideoState] = useState(VideoStates.NORMAL);
  const [position, setPosition] = useState({
    x: 0,
    y: 0,
  });

  const classes = useStyles({ videoState });

  const setFullScreen = () => {
    setPosition({
      x: 0,
      y: 0,
    });
    setVideoState(VideoStates.FULL_SCREEN);
  };

  const setNormal = () => {
    setVideoState(VideoStates.NORMAL);
  };

  const setMinimised = () => {
    setVideoState(VideoStates.MINIMISED);
  };

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({
      x: x + ui.deltaX,
      y: y + ui.deltaY,
    });
  };

  return (
    <Draggable position={position} onDrag={handleDrag} bounds="body" handle="#videoHeader">
      <div className={classes.videoWrapper}>
        <div id="videoHeader" className={classes.videoHeader}>
          {videoState !== VideoStates.FULL_SCREEN && (
            <WhiteIconButton onClick={setFullScreen}>
              <Fullscreen />
            </WhiteIconButton>
          )}
          {videoState !== VideoStates.NORMAL && (
            <WhiteIconButton onClick={setNormal}>
              <FeaturedVideo />
            </WhiteIconButton>
          )}
          {videoState !== VideoStates.MINIMISED && (
            <WhiteIconButton onClick={setMinimised}>
              <BrandingWatermark />
            </WhiteIconButton>
          )}
          <span>{toOnlineName}</span>
          <div className={classes.endIcon}>
            <Fab size="medium" color="secondary" aria-label="CallEnd" onClick={onStopBtnClicked}>
              <CallEnd />
            </Fab>
          </div>
        </div>
        <video className={classes.videoElement} ref={domRef} autoPlay playsInline {...rest} />
        {domRefOther && <video className={classes.videoElementOwn} ref={domRefOther} muted={true} autoPlay playsInline {...rest} />}
      </div>
    </Draggable>
  );
};

export default Video;
