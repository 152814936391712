import i18next from "i18next";
import { initReactI18next } from "react-i18next";


const initI18n = (resource, language) =>
    i18next
        .use(initReactI18next)
        .init({
            resources: {
                [language]: {
                    translation: resource,
                }
            },
            debug: false,
            lng: language,
            interpolation: {
                escapeValue: false,
                prefix: '${',
                suffix: '}'
            },
            returnEmptyString: true,
            returnNull: true,
            overloadTranslationOptionHandler() {
                return {
                    defaultValue: ''
                }
            }
        });

export default initI18n;
