import PropTypes from 'prop-types';
import React from 'react';
import connect from 'react-redux-fetch';
import { compose } from 'redux';
import {withTranslation} from 'react-i18next';
import delay from 'lodash/delay';
import forEach from 'lodash/forEach';
import isNumber from 'lodash/isNumber';
import indexOf from 'lodash/indexOf';
import injectApiRoutes from '../../../app/api/injectApiRoutes';
import CenteredCircularProgress from '../../../components/CenteredCircularProgress';
import RampInstructions from '../components/RampInstructions';
import commonSelectors from '../../common/selectors';

class RampInstructionsPage extends React.Component {
  static propTypes = {
    flightId: PropTypes.string,
    dispatchRampInstructionsPost: PropTypes.func,
    rampInstructionsFetch: PropTypes.object,
    dispatchRampStopPost: PropTypes.func,
    dispatchRampStartPost: PropTypes.func,

    // Injected by i18next
    t: PropTypes.func.isRequired,
  };

  static contextTypes = {
    registerRefreshAction: PropTypes.func.isRequired,
    removeRefreshAction: PropTypes.func.isRequired,
  };

  state = {
    onLoadSelected: [],
    offLoadSelected: [],
    onMsg: '',
    offMsg: '',
  };

  componentWillMount() {
    this.showLoader = false;
    this.props.dispatchRampInstructionsPost();
    this.context.registerRefreshAction('rampInstructions', this.props.dispatchRampInstructionsPost);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.rampInstructionsFetch !== this.props.rampInstructionsFetch) {
      this.showLoader = false;
    }
  }

  componentWillUnmount() {
    this.context.removeRefreshAction('rampInstructions');
  }

  handleSelectedRows = (selectedRows, type) => {
    if (type === 'on') {
      this.setState({ onLoadSelected: selectedRows });
    } else {
      this.setState({ offLoadSelected: selectedRows });
    }
  };

  dispatchRampInstructionsPost = () => {
    this.showLoader = true;
    delay(() => {
      this.props.dispatchRampInstructionsPost();
    }, 1600); // TODO #dirty
  };

  handleOnClick = (doorsList, button) => {
    const onDoorSeq = this.props.rampInstructionsFetch.value.loadSequences.onLoadSequenceDoorStates
      .loadSequenceDoorState;

    forEach(this.state.onLoadSelected, dr => {
      if (isNumber(dr)) {
        if (
          (button === 'stop' && onDoorSeq[dr].state.canStop) ||
          (button === 'start' && onDoorSeq[dr].state.canStart)
        ) {
          doorsList.push(onDoorSeq[dr].doorId);
        }
      } else {
        // 'all' if all selected
        forEach(onDoorSeq, door => {
          if (
            (button === 'stop' && door.state.canStop) ||
            (button === 'start' && door.state.canStart)
          ) {
            if (indexOf(doorsList, door.doorId) === -1) {
              doorsList.push(door.doorId);
            }
          }
        });
      }
    });
    return { doorId: doorsList };
  };

  handleOffClick = (doorsList, button) => {
    const offDoorSeq = this.props.rampInstructionsFetch.value.loadSequences
      .offLoadSequenceDoorStates.loadSequenceDoorState;

    forEach(this.state.offLoadSelected, dr => {
      if (isNumber(dr)) {
        if (
          (button === 'stop' && offDoorSeq[dr].state.canStop) ||
          (button === 'start' && offDoorSeq[dr].state.canStart)
        ) {
          doorsList.push(offDoorSeq[dr].doorId);
        }
      } else {
        // 'all' if all selected
        forEach(offDoorSeq, door => {
          if (
            (button === 'stop' && door.state.canStop) ||
            (button === 'start' && door.state.canStart)
          ) {
            if (indexOf(doorsList, door.doorId) === -1) {
              doorsList.push(door.doorId);
            }
          }
        });
      }
    });

    return { doorId: doorsList };
  };

  handleButtonClick = (type, button) => {
    const movementType = type === 'on' ? 'ON_LOAD' : 'OFF_LOAD';
    const doorsList = [];
    let doors = { doorId: doorsList };
    let message = '';

    if (type === 'on') {
      message = this.state.onMsg;
      doors = this.handleOnClick(doorsList, button);
    } else {
      message = this.state.offMsg;
      doors = this.handleOffClick(doorsList, button);
    }

    const fullMessage = `Load Control ${button === 'stop' ? 'stopped ' : 'continued '} operations.
    Reason: ${message}`;

    if (doors.doorId.length > 0) {
      if (button === 'stop') {
        this.props.dispatchRampStopPost(movementType, doors, fullMessage);
      } else {
        this.props.dispatchRampStartPost(movementType, doors, fullMessage);
      }
    }

    if (type === 'on') {
      this.setState({
        onLoadSelected: [],
        onMsg: '',
      });
    } else {
      this.setState({
        offLoadSelected: [],
        offMsg: '',
      });
    }
    // delay for data to be updated
    this.dispatchRampInstructionsPost();
  };

  handleOnMsgChange = (event, value) => {
    this.setState({ onMsg: value });
  };

  handleOffMsgChange = (event, value) => {
    this.setState({ offMsg: value });
  };

  render() {
    const { flightId, rampInstructionsFetch, t } = this.props;
    const { onLoadSelected, offLoadSelected, onMsg, offMsg } = this.state;

    if (rampInstructionsFetch.fulfilled) {
      const loadSequences = rampInstructionsFetch.value.loadSequences;

      return (
        <RampInstructions
          flightId={flightId}
          onDoors={loadSequences.onLoadSequenceDoorStates.loadSequenceDoorState}
          offDoors={loadSequences.offLoadSequenceDoorStates.loadSequenceDoorState}
          onSelectedRows={this.handleSelectedRows}
          onLoadSelected={onLoadSelected}
          offLoadSelected={offLoadSelected}
          onStopClick={this.handleButtonClick}
          onContinueClick={this.handleButtonClick}
          onMsg={onMsg}
          offMsg={offMsg}
          onOnMsgChange={this.handleOnMsgChange}
          onOffMsgChange={this.handleOffMsgChange}
          showLoader={this.showLoader}
        />
      );
    } else if (rampInstructionsFetch.rejected) {
      return <div>{t('communicator.rampInstructions.noRampInstructions')}</div>;
    }

    return <CenteredCircularProgress />;
  }
}

const mapPropToDispatchToProps = props => [
  {
    resource: 'rampInstructions',
    method: 'POST',
    request: () => ({
      url: props.apiRoutes.getRampInstructions(),
      body: {
        accessToken: props.accessToken,
        flightId: props.flightId,
        imSequence: 0,
      },
    }),
  },
  {
    resource: 'rampStop',
    method: 'POST',
    request: (movementType, doors, message) => ({
      url: props.apiRoutes.rampStop(),
      body: {
        accessToken: props.accessToken,
        flightId: props.flightId,
        movementType,
        doors,
        message,
      },
    }),
  },
  {
    resource: 'rampStart',
    method: 'POST',
    request: (movementType, doors, message) => ({
      url: props.apiRoutes.rampStart(),
      body: {
        accessToken: props.accessToken,
        flightId: props.flightId,
        movementType,
        doors,
        message,
      },
    }),
  },
];

const mapStateToProps = state => ({
  flightId: commonSelectors.getFlightId(state),
});

const enhance = compose(injectApiRoutes, connect(mapPropToDispatchToProps, mapStateToProps));

export default enhance(withTranslation()(RampInstructionsPage));
