import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import SystemMsgWrapper from '../SystemMsgWrapper';
import MsgFrom from '../MsgFrom';
import MsgTo from '../MsgTo';
import MsgDate from '../MsgDate';
import Timezone from '../../../../../components/Timezone';
import PropTypes from 'prop-types';
import React from 'react';
import MsgBodyButtonWrapper from '../MsgBodyButtonWrapper';
import MsgText from './SystemMsgText';
import AckMsgDate from '../AckMsgDate';
import CircularProgress from 'material-ui/CircularProgress';
import MsgButton from '../MsgButton';
import MIcon from '../../../../../components/MIcon';

const SystemMsgBody = styled.div`
  background-color: ${props =>
    props.read ? props.theme.palette.primaryPastel : props.theme.palette.black};
  border-radius: 1em;
  color: ${props => (props.read ? props.theme.palette.black : props.theme.palette.white)};
  padding: 10px;
  margin: 0 20px;
  white-space: pre-wrap;
`;
const SystemMsg = ({ msg, sent, read, readMessage, isPending }) => {
  const { t } = useTranslation();

  return (
      <SystemMsgWrapper>
        {msg.from && <MsgFrom>{t('communicator.message.from')}: {msg.from} </MsgFrom>}
        {msg.to && <MsgTo>{t('communicator.message.to')}: {msg.to} </MsgTo>}
        <SystemMsgBody read={read}>
          {' '}
          <MsgBodyButtonWrapper>
            <MsgText read={read}>
              {msg.text}{' '}
              {msg.ackTime && (
                  <AckMsgDate>
                    <Timezone date={msg.ackTime}/> acknowledged
                  </AckMsgDate>
              )}
            </MsgText>
            {isPending ? (
                <CircularProgress size={30}/>
            ) : (
                msg.canMarkAsRead && (
                    <MsgButton variant="contained" color="light" onClick={readMessage} disabled={isPending}>
                      {t('communicator.systemMsg.read')}
                    </MsgButton>
                )
            )}
            {sent && read && <MIcon>done_all</MIcon>}
          </MsgBodyButtonWrapper>
        </SystemMsgBody>
        <MsgDate>
          <Timezone date={msg.time}/>
        </MsgDate>
      </SystemMsgWrapper>
  );
};

export default SystemMsg;

SystemMsg.propTypes = {
  msg: PropTypes.shape({
    canMarkAsRead: PropTypes.bool.isRequired,
    changeRequest: PropTypes.bool.isRequired,
    from: PropTypes.string.isRequired,
    fullImageId: PropTypes.number,
    pic64: PropTypes.string,
    read: PropTypes.bool.isRequired,
    sequenceId: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    thumbImageId: PropTypes.number.isRequired,
    time: PropTypes.string.isRequired,
    ackTime: PropTypes.string,
    to: PropTypes.string,
  }),
  received: PropTypes.bool,
  sent: PropTypes.bool,
  read: PropTypes.bool,
  dispatchReadPost: PropTypes.func,
  readMsgUpdate: PropTypes.func,
  readMessage: PropTypes.func,
  isPending: PropTypes.bool,
};
