import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import HashRouter from "react-router-dom/HashRouter";
// TODO: make this work
// http://stackoverflow.com/questions/33127746/single-page-application-with-java-ee-wildfly-server-side-configuration
// import BrowserRouter from 'react-router-dom/BrowserRouter';
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import { ThemeProvider } from "styled-components";
import { ThemeProvider as StylesMuiThemeProvider } from "@material-ui/styles";
import { MuiThemeProvider as CoreMuiThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";

import CircularProgress from "material-ui/CircularProgress";

import configureTheme from "../../../app/styles/theme";
import configureMuiTheme, { convertTheme } from "../../../app/styles/muiTheme";
import security from "../../../modules/security";
import settings from "../../../modules/settings";
import appSelectors from "../selectors";
import Layout from "../components/Layout";
import Loader from "../components/Loader";
import I18nGate from "../../../app/translate/I18nGate";
import call from '../../call';

class App extends Component {
  static propTypes = {
    // injected by redux
    isAuthenticated: PropTypes.bool.isRequired,
    isInitialized: PropTypes.bool.isRequired,
    logout: PropTypes.func.isRequired,
    theme: PropTypes.object.isRequired,
    dispatch: PropTypes.func,
  };

  componentWillMount() {
    configureTheme(this.props.theme);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.theme !== nextProps.theme) {
      // maybe better check
      configureTheme(nextProps.theme);
    }
  }

  canRenderApp() {
    return this.props.theme && this.props.isInitialized;
  }

  render() {
    const { isAuthenticated, logout, theme, dispatch } = this.props;

    if (this.canRenderApp()) {
      const configuredTheme = configureMuiTheme(theme);
      const convertedTheme = convertTheme(configuredTheme);
      return (
        <ThemeProvider theme={theme}>
          <StylesMuiThemeProvider theme={convertedTheme}>
            <CoreMuiThemeProvider theme={convertedTheme}>
              <MuiThemeProvider muiTheme={configuredTheme}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <I18nGate loading={<Loader />} dispatch={dispatch}>
                    <HashRouter>
                      {/* TODO Change to BrowserRouter with basename if HTML5 pushstate is implemented */}
                      {/* <BrowserRouter basename={process.env.PUBLIC_URL}> */}
                      <Layout isAuthenticated={isAuthenticated} onLogout={logout} />
                      {/* </BrowserRouter> */}
                    </HashRouter>
                    <call.containers.CallContainer />
                  </I18nGate>
                </MuiPickersUtilsProvider>
              </MuiThemeProvider>
            </CoreMuiThemeProvider>
          </StylesMuiThemeProvider>
        </ThemeProvider>
      );
    }

    return (
      <MuiThemeProvider>
        <CircularProgress />
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: security.selectors.isAuthenticated(state),
  isInitialized: appSelectors.isInitialized(state),
  theme: settings.selectors.getTheme(state),
});

const mapDispatchToProps = dispatch => ({
  logout: bindActionCreators(security.actions.logout, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
