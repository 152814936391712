import styled from 'styled-components';

const LeftPane = styled.aside`
  flex: 1.2;
  background: ${props => props.theme.palette.primaryPastel};
  borderRight: 1px solid ${props => props.theme.palette.greyLight};
  overflow: auto;
`;

export default LeftPane;
