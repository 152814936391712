import PropTypes from 'prop-types';
import React from 'react';
import Lightbox from 'react-image-lightbox';
import {withTranslation} from 'react-i18next';
import CircularProgress from 'material-ui/CircularProgress';
import Timezone from '../../../../../components/Timezone';
import MIcon from '../../../../../components/MIcon';
import FullImage from '../../../containers/FullImage';
import MsgWrapper from '../MsgWrapper';
import MsgFrom from '../MsgFrom';
import MsgTo from '../MsgTo';
import MsgBodyButtonWrapper from '../MsgBodyButtonWrapper';
import MsgBodyWrapper from '../MsgBodyWrapper';
import MsgButton from '../MsgButton';
import MsgText from '../MsgText';
import MsgDate from '../MsgDate';
import AckMsgDate from '../AckMsgDate';

class FreeTextMsg extends React.Component {
  static propTypes = {
    msg: PropTypes.shape({
      canMarkAsRead: PropTypes.bool.isRequired,
      changeRequest: PropTypes.bool.isRequired,
      from: PropTypes.string.isRequired,
      fullImageId: PropTypes.number,
      pic64: PropTypes.string,
      read: PropTypes.bool.isRequired,
      sequenceId: PropTypes.number.isRequired,
      text: PropTypes.string.isRequired,
      thumbImageId: PropTypes.number.isRequired,
      time: PropTypes.string.isRequired,
      ackTime: PropTypes.string,
      to: PropTypes.string.isRequired,
    }),
    received: PropTypes.bool,
    sent: PropTypes.bool,
    unread: PropTypes.bool,
    read: PropTypes.bool,
    dispatchReadPost: PropTypes.func,
    readMsgUpdate: PropTypes.func,
    readMessage: PropTypes.func,
    isPending: PropTypes.bool,
    flightId: PropTypes.string.isRequired,
    distribution: PropTypes.string.isRequired,

    // Injected by i18next
    t: PropTypes.func.isRequired,
  };

  state = {
    showLightbox: false,
  };

  toggleLightbox = () => {
    this.setState({
      showLightbox: !this.state.showLightbox,
    });
  };

  render() {
    const {
      msg,
      received,
      sent,
      unread,
      read,
      readMessage,
      isPending,
      flightId,
      distribution,
      t,
    } = this.props;

    return (
      <MsgWrapper received={received} data-msg-unread={received && unread}>
        {msg.from && <MsgFrom>{t('communicator.message.from')}: {msg.from} </MsgFrom>}
        {msg.to && <MsgTo>{t('communicator.message.to')}: {msg.to} </MsgTo>}
        <MsgBodyWrapper received={received} unread={received && unread}>
          <MsgBodyButtonWrapper>
            <MsgText received={received} unread={received && unread}>
              {msg.text}{' '}
              {msg.ackTime && (
                <AckMsgDate>
                  <Timezone date={msg.ackTime} /> {t('communicator.message.acknowledged')}
                </AckMsgDate>
              )}
            </MsgText>
            {isPending ? (
              <CircularProgress size={30} />
            ) : (
              msg.canMarkAsRead && (
                <MsgButton onClick={readMessage} disabled={isPending}>
                  {t('communicator.message.read')}
                </MsgButton>
              )
            )}
            {sent && read && <MIcon white>done_all</MIcon>}
          </MsgBodyButtonWrapper>
          {msg.pic64 !== '' && (
            <img
              alt="ROLS"
              style={{ width: '100%', smarginLeft: 10, cursor: 'pointer' }}
              src={`data:image/png;base64,${msg.pic64}`}
              onClick={this.toggleLightbox}
            />
          )}

          {this.state.showLightbox && (
            <FullImage
              distribution={distribution}
              flightId={flightId}
              messageId={msg.sequenceId}
              imageId={msg.fullImageId}
            >
              {mainSrc => (
                <Lightbox
                  mainSrc={`data:image/png;base64,${mainSrc}`}
                  mainSrcThumbnail={`data:image/png;base64,${msg.pic64}`}
                  onCloseRequest={this.toggleLightbox}
                  clickOutsideToClose
                  enableZoom
                  imageTitle={!mainSrc ? t('communicator.message.loading') : ''}
                />
              )}
            </FullImage>
          )}
        </MsgBodyWrapper>
        <MsgDate>
          <Timezone date={msg.time} />
        </MsgDate>
      </MsgWrapper>
    );
  }
}

export default withTranslation()(FreeTextMsg);
