import React from 'react';
import ConfirmDialog from "../../../../../../../components/Dialog/ConfirmDialog";
import PropTypes from "prop-types";

class ConfirmActionLoadSequence extends React.Component {
    static propTypes = {
        open: PropTypes.bool.isRequired,
        action: PropTypes.string.isRequired,
        distribution: PropTypes.number.isRequired,
        onClickYes: PropTypes.func.isRequired,
        onClickNo: PropTypes.func.isRequired,
    }

    render() {
        const {open, action, distribution, onClickYes, onClickNo} = this.props;
        const distKeyword = (distribution===1? 'onLoad': 'offLoad');
        const actionKeyword = action.toLowerCase();
        return <ConfirmDialog
            open={open}
            keywordTitle={`communicator.loadSequence.confirm.title.${actionKeyword}.${distKeyword}`}
            defaultTitle={DEFAULT.title[actionKeyword][distKeyword]}
            keywordContent={`communicator.loadSequence.confirm.content.${actionKeyword}.${distKeyword}`}
            defaultContent={DEFAULT.content[actionKeyword][distKeyword]}
            keywordYes={'communicator.yes'}
            defaultYes={DEFAULT.yes}
            keywordNo={'communicator.no'}
            defaultNo={DEFAULT.no}
            onClickYes={onClickYes}
            onClickNo={onClickNo}
        />
    }
}

const DEFAULT = {
    title: {
        reset: {
            onLoad: 'Please confirm sequence reset',
            offLoad: 'Please confirm sequence reset',
        },
    },
    content: {
        reset: {
            onLoad: 'Are you sure you want to reset the load sequence? ' +
                'This will also reset any manually generated offloads and put the cargo ' +
                'back on original planning position. Any cargo in conflict will become unplanned.',
            offLoad: 'Are you sure you want to reset the load sequence?',
        },
    },
    yes: 'Yes',
    no: 'No',
}

export default ConfirmActionLoadSequence;