import styled from 'styled-components';

const StyledPane = styled.aside`
  background: linear-gradient(
    to bottom,
    ${props => props.theme.palette.primary} 0%,
    ${props => props.theme.palette.primaryDark} 100%
  );
  color: ${props => props.theme.palette.white};
  overflow-y: hidden;
  overflow-x: hidden;
`;

export default StyledPane;
