import React from 'react';
import TranslatedLabel from "../../../../../../../components/TranslatedLabel/TranslatedLabel";
import PropTypes from "prop-types";
import Dropdown from "../../../../../../common/components/Dropdown/Dropdown";

class Method extends React.Component {
    static propTypes = {
        flight: PropTypes.object.isRequired,
        method: PropTypes.object,
        onChangeMethod: PropTypes.func,
    }

    handleChangeMethod = method => {
        const {onChangeMethod} = this.props;
        if (onChangeMethod) {
            onChangeMethod(method);
        }
    };

    render() {
        const getLabel = (keyword, defaultValue) => {
            return <TranslatedLabel
                keyword={`communicator.loadSequence.${keyword}`}
                defaultValue={defaultValue} />
        };

        const {flight, method} = this.props;
        const loadSequenceMethods = flight.loadSequenceMethods.loadsequenceMethod;
        return <Dropdown
                label={getLabel('method', 'Method')}
                items={loadSequenceMethods}
                item={method}
                getItemLabel={(loadSequenceMethod)=>loadSequenceMethod?loadSequenceMethod.loadsequenceMethodName:null}
                onItemChange={this.handleChangeMethod}
                color={'primary'}
             />;
    }
}

export default Method;