import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import isNull from 'lodash/isNull';
import ChangeReqMsgContainer from '../../containers/ChangeReqMsgContainer';
import FreeTextMsgContainer from '../../containers/FreeTextMsgContainer';
import MessageContainer from './MessageContainer';
import RowItem from './RowItem';
import EmptyDiv from './EmptyDiv';
import NoMessagesReceived from './NoMessagesReceived';
import SystemMsgContainer from '../../containers/SystemMsgContainer';
import Filter, { MessageTypes } from './Filter';
import EmptyHeight from './Filter/EmptyHeight';

const FILTER_HEIGHT = 95;

class ChatDetail extends Component {
  state = {
    shownMessageType: MessageTypes.ALL,
    selectedRole: MessageTypes.ALL,
    windowWidth: 0,
  };

  handleMessageTypeChange = shownMessageType => {
    this.setState({ shownMessageType });
  };

  handleRoleChange = selectedRole => {
    this.setState({ selectedRole });
  };

  render() {
    const {
      messagesReceived,
      onReadMsgUpdate,
      loaderInfo,
      flightId,
      distribution,
      senderFilter,
      t,
    } = this.props;
    const { shownMessageType, selectedRole, windowWidth } = this.state;

    if (messagesReceived.length === 0) {
      return (
        <NoMessagesReceived>
          <span>{t('communicator.chat.noMessages')}</span>
        </NoMessagesReceived>
      );
    }

    const checkUserMessage = message => {
      if (message.systemMessage) return false;
      if (message.to !== "Load control cargo") return false;
      if (selectedRole === MessageTypes.ALL) return true;
      return (message.from || message.to) && message.from
        ? message.from.includes(selectedRole)
        : message.to.includes(selectedRole);
    };

    const filterMessages = message => {
      switch (shownMessageType) {
        case MessageTypes.USER_MESSAGES:
          return checkUserMessage(message);
        case MessageTypes.SYSTEM_MESSAGES:
          return message.systemMessage;
        default:
          return true;
      }
    };

    const handleOnResize = () => {
      const windowWidth = window.innerWidth;
      this.setState(prevState => {
        if (prevState.windowWidth !== windowWidth) {
          return { windowWidth };
        }
      });
    };

    return (
      <MessageContainer>
        <Filter
          senderFilter={senderFilter}
          messageTypeValue={shownMessageType}
          selectedRole={selectedRole}
          onTypeChange={this.handleMessageTypeChange}
          onRoleChange={this.handleRoleChange}
          filterHeight={FILTER_HEIGHT}
          onResize={handleOnResize}
        />
        <EmptyHeight
          height={FILTER_HEIGHT}
          messageTypeValue={shownMessageType}
          windowWidth={windowWidth}
        />
        {messagesReceived.filter(filterMessages).map(msg => (
          <RowItem key={msg.sequenceId} id={msg.sequenceId}>
            {msg.systemMessage ? (
                <SystemMsgContainer
                  msg={msg}
                  sent={isNull(msg.from)}
                  read={msg.read}
                  readMsgUpdate={onReadMsgUpdate}
                  loaderInfo={loaderInfo}
                />
            ) : msg.changeRequest ? (
              <ChangeReqMsgContainer
                msg={msg}
                received={!isNull(msg.from)}
                sent={isNull(msg.from)}
                unread={!msg.read}
                read={msg.read}
                readMsgUpdate={onReadMsgUpdate}
                loaderInfo={loaderInfo}
              />
            ) : flightId ? (
              <FreeTextMsgContainer
                msg={msg}
                received={!isNull(msg.from)}
                sent={isNull(msg.from)}
                unread={!msg.read}
                read={msg.read}
                readMsgUpdate={onReadMsgUpdate}
                loaderInfo={loaderInfo}
                flightId={flightId}
                distribution={distribution}
              />
            ) : <EmptyDiv />}
            {!msg.systemMessage && <EmptyDiv />}
          </RowItem>
        ))}
      </MessageContainer>
    );
  }
}

ChatDetail.propTypes = {
  messagesReceived: PropTypes.array,
  senderFilter: PropTypes.object,
  onReadMsgUpdate: PropTypes.func,
  loaderInfo: PropTypes.object,
  flightId: PropTypes.string,
  distribution: PropTypes.string,

  // Injected by i18next
  t: PropTypes.func.isRequired,
};

export default withTranslation()(ChatDetail);
