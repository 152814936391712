import React from 'react';
import { useSelector } from 'react-redux';
import selectors from '../selectors';
import ConnectionIndicator from '../components/ConnectionIndicator';
import { CallState } from '../constants';

const ConnectionContainer = () => {
  const socketState = useSelector(selectors.getSocketState);
  return <ConnectionIndicator connected={socketState !== CallState.IDLE} />;
};

export default ConnectionContainer;
