import App from './containers/App';
import { applicationLoadedChecker } from './sagas/applicationLoadedChecker';
import { apiErrorHandler } from './sagas/apiErrorHandler';
import { watchApiPendingRequests } from './sagas/watchApiPendingRequests';
import { refreshBrowserOnLogout } from './sagas/refreshBrowserOnLogout';
import reducer from './reducer';
import selectors from './selectors';
import actions from './actions';
import actionTypes from './actionTypes';

export default {
  containers: {
    App,
  },
  sagas: {
    applicationLoadedChecker,
    apiErrorHandler,
    watchApiPendingRequests,
    refreshBrowserOnLogout,
  },
  reducer,
  selectors,
  actions,
  actionTypes,
};
