import PropTypes from 'prop-types';
import React from 'react';
import {compose} from 'redux';
import connect from 'react-redux-fetch';
import Redirect from 'react-router-dom/Redirect';
import isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import injectApiRoutes from '../../../app/api/injectApiRoutes';
import globals from '../../../app/globals';
import getMessageFromPromise from '../../../app/utils/getMessageFromPromise';
import actions from '../actions';
import selectors from '../selectors';
import Login from '../components/Login';
import settings from '../../settings';
import differenceWithoutFunctionCheck from '../../../app/utils/differenceWithoutFunctionCheck';
import i18next from "i18next";
import convertResourceToObject from "../../../app/utils/convertResourceToObject";
import convertBackendLanguageForFrontend from "../../../app/utils/convertBackendLanguageForFrontend";

function hasAccessToRols(accessFunctions) {
  return accessFunctions.allowAccessRolsCommunicator;
}

function hasAccessToDashboard(accessFunctions) {
  return accessFunctions.allowAccessDashboard;
}

function hasAccessToLoadSequence(accessFunctions) {
  return accessFunctions.allowCommunicatorLoadSequenceMenuFreighterFlights
      || accessFunctions.allowCommunicatorLoadSequenceMenuPassengerFlights;
}

class LoginPage extends React.Component {
  state = {
    username: '',
  };

  componentWillReceiveProps(nextProps) {
    const promise = nextProps.userFetch;
    if (
      !nextProps.isAuthenticated &&
      promise.fulfilled &&
      !isEmpty(differenceWithoutFunctionCheck(nextProps, this.props))
    ) {
      if (hasAccessToRols(promise.value.accessFunctions)
          || hasAccessToDashboard(promise.value.accessFunctions)
          || hasAccessToLoadSequence(promise.value.accessFunctions)
      ) {
        const newLanguage = convertBackendLanguageForFrontend(promise.value.language.locale, '_', '-');
        i18next.addResourceBundle(newLanguage, 'translation', convertResourceToObject(promise.value.languageTexts.languageText), true, true);
        i18next.changeLanguage(newLanguage).then(() => {
          this.props.dispatch(settings.actions.setLanguageId(_get(promise, 'language.id')));
          if (!this.isDifferentVersion(promise.value.version)) {
            this.props.dispatch(actions.authenticate(promise.value, this.state.username,
                promise.value.onlineCode,
                hasAccessToDashboard(promise.value.accessFunctions),
                hasAccessToRols(promise.value.accessFunctions),
                hasAccessToLoadSequence(promise.value.accessFunctions),));
            this.props.dispatch(actions.setChangePsswrdOnNextLogin(promise.value.changePasswordOnNextLogin));
          }
        });
      }
    }
  }

  getLoginErrors = () => {
    const promise = this.props.userFetch;
    if (promise.rejected) {
      const msg = getMessageFromPromise(promise) || '';
      if (msg.indexOf(globals.errors.userLocked) !== -1) {
        return ['Please provide valid credentials.'];
      }
      return [msg];
    } else if (promise.fulfilled && (!hasAccessToRols(promise.value.accessFunctions
        || !hasAccessToDashboard(promise.value.accessFunctions)
        || !hasAccessToLoadSequence(promise.value.accessFunctions)))) {
      return ['Please provide valid credentials.'];
    }
    return [];
  };

  handleLogin = (username, pw) => {
    this.setState({
      username,
    });
    this.props.dispatchUserPost(username, pw);
  };

  isDifferentVersion = (version) => {
    const REACT_APP_VERSION = process.env.REACT_APP_VERSION;
    return version && REACT_APP_VERSION && version!==REACT_APP_VERSION;
  }

  render() {
    const {isAuthenticated, userFetch, privacyNoticeUrl} = this.props;
    if (userFetch.fulfilled && this.isDifferentVersion(userFetch.value.version)) {
      return (<Redirect to={{pathname: '/restart', state: {referrer: '/login'}}}/>);
    } else if (isAuthenticated) {
      if (userFetch.value.changePasswordOnNextLogin) {
        return (<Redirect to={{pathname: '/changePasswordAfterLogin', state: {referrer: '/login'}}}/>);
      } else if (userFetch.fulfilled && (hasAccessToDashboard(userFetch.value.accessFunctions)
          && !hasAccessToRols(userFetch.value.accessFunctions)
          && !hasAccessToLoadSequence(userFetch.value.accessFunctions)
      )) {
        return (<Redirect to={{pathname: '/dashboard', state: {referrer: '/login'}}}/>);
      }
      return (<Redirect to={{pathname: '/', state: {referrer: '/login'}}}/>);

    } else {
      return (<Login onLogin={this.handleLogin} privacyNoticeUrl={privacyNoticeUrl} errors={this.getLoginErrors()}/>);
    }
  }
}

LoginPage.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  isDashboard: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  dispatchUserPost: PropTypes.func.isRequired,
  userFetch: PropTypes.object.isRequired,
  privacyNoticeUrl: PropTypes.string
};

const mapStateToProps = state => ({
  isAuthenticated: selectors.isAuthenticated(state),
  isDashboard: selectors.isDashboard(state),
  isLoadSequence: selectors.isLoadSequence(state),
  isLoadAudit: selectors.isLoadAudit(state),
  privacyNoticeUrl: selectors.getPrivacyNoticeUrl(state),
});

const mapPropToDispatchToProps = props => [
  {
    resource: 'user',
    method: 'post',
    request: (username, pw) => ({
      url: props.apiRoutes.login(),
      body: {
        username,
        password: pw,
      },
      meta: {
        suppressError: true,
      },
    }),
  },
];

const enhance = compose(
  injectApiRoutes,
  connect(
    mapPropToDispatchToProps,
    mapStateToProps
  )
);

export default enhance(LoginPage);
