import styled from 'styled-components';

const Bar = styled.header`
  padding: 0 24px;
  borderBottom: 1px solid ${props => props.theme.palette.greyLight};
  lineHeight: 64px;
  height: 70px;
`;

export default Bar;
