import React from 'react';
import PropTypes from "prop-types";
import {svgClasses} from "../SvgClasses";
import calculateSvgPosition from "../lib/calculateSvgPosition";
import {addExtraTags, getUldNumber} from "./canvasUtil";

class Label extends React.Component {
    static propTypes = {
        svg: PropTypes.object,
        loadStep: PropTypes.object,
        allPositionLoadSteps: PropTypes.array,
        group: PropTypes.object,
        fontSize: PropTypes.number,
        color: PropTypes.string,
        horizontal: PropTypes.bool,
        communicator: PropTypes.bool,
        nrDivs: PropTypes.number,
    }

    componentDidMount() {
        const { svg, group, fontSize, color, } = this.props;
        const style = { size: fontSize, fill: color };
        const containerTextGroup = svg.group();
        this.addItemsToContainerTextGroup(containerTextGroup, style);
        this.addSubPositionRectsToContainerTextGroup(containerTextGroup);
        containerTextGroup.clipWith(this.getRect());
        group.add(containerTextGroup);
    }

    getRect = () => {
        const { loadStep, svg, horizontal } = this.props;
        const { width, height, x, y } = calculateSvgPosition(loadStep, horizontal);
        const rect = svg.rect(width, height);
        rect.move(x, y);
        return rect;
    }

    addItemsToContainerTextGroup (containerTextGroup, style) {
        this.addPosition(containerTextGroup, style);
        this.addNoFitOrUld(containerTextGroup, style);
        this.addEllipsis(containerTextGroup, style);
    }

    isShowedField (fieldName) {
        return true;
    }

    getPlainText = (text) => {
        const { loadStep, allPositionLoadSteps, } = this.props;
        return allPositionLoadSteps.indexOf(loadStep)===0?text:'';
    }

    addPosition = (containerTextGroup, style) => {
        if (this.isShowedField("positionName")) {
            const { nrDivs, loadStep, allPositionLoadSteps, svg, click, } = this.props;
            const {
                position: { positionName },
            } = loadStep;
            const positionText = svg
                .plain(this.getPlainText(positionName))
                .font(style)
                .attr('class', svgClasses.position.class);
            if (nrDivs<=1) {
                positionText.click(click);
            }
            addExtraTags(positionText, loadStep, allPositionLoadSteps);
            containerTextGroup.add(positionText);
        }
    }

    addNoFitOrUld = (containerTextGroup, style) => {
        const { communicator, loadStep, allPositionLoadSteps } = this.props;
        const showedUldNumber = (!communicator||loadStep.uld)
            && this.isShowedField("uldNumber");
        const showedNoFit = !communicator && this.isShowedField("noFit");
        if (showedUldNumber||showedNoFit) {
            const { svg, click, } = this.props;
            const {
                uld,
            } = loadStep;
            const getPlainUld = () => {
                return svg.plain(getUldNumber(loadStep));
            };
            const noFitOrUld = uld ? (showedUldNumber ? getPlainUld() : null)
                : (showedNoFit ? svg.plain(this.getNoFitLabel()) : null);
            if (noFitOrUld) {
                noFitOrUld
                    .attr('class', svgClasses.nofitUld.class)
                    .font(style)
                    .click(click);
                if (uld && uld.rampPresent) {
                    noFitOrUld.addClass('rampPresent');
                }
                addExtraTags(noFitOrUld, loadStep, allPositionLoadSteps);
                containerTextGroup.add(noFitOrUld);
            }
        }
    }

    addEllipsis = (containerTextGroup, style) => {
        const { nrDivs, svg } = this.props;
        if (nrDivs && nrDivs>1 && this.isShowedField("uldNumber")) {
            const elipsis = svg.plain('...');
            elipsis
                .attr('class', svgClasses.ellipsis.class)
                .font(style);
            containerTextGroup.add(elipsis);
        }
    }

    addSubPositionRectsToContainerTextGroup = (containerTextGroup) => {
        const { nrDivs, } = this.props;
        if (nrDivs && nrDivs>1 && this.isShowedField("uldNumber")) {
            const { click, } = this.props;
            const rect = this.getRect();
            rect.style({
                "fill-opacity": "0.0",
            });
            rect.attr('class', svgClasses.subPosition.class)
                .click(click);
            containerTextGroup.add(rect);
        }
    }

    getNoFitLabel = () => {
        return 'No fit';
    };

    render() {
        return null;
    }
}

export default Label;