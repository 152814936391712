import React from 'react';
import PropTypes from 'prop-types';
import reduxFetch from 'react-redux-fetch';
import injectApiRoutes from '../../../app/api/injectApiRoutes';


class FullImage extends React.PureComponent {
  static propTypes = {
    children: PropTypes.func.isRequired,
    // injected by reduxFetch
    dispatchFullImagePost: PropTypes.func.isRequired,
    fullImageFetch: PropTypes.shape({
      fulfilled: PropTypes.bool,
      value: PropTypes.shape({
        image: PropTypes.shape({
          fullImageId: PropTypes.number,
          pic64: PropTypes.string,
          thumbnailId: PropTypes.number,
        })
      })
    }),
  };

  componentWillMount() {
    this.props.dispatchFullImagePost();
  }

  render() {
    if (this.props.fullImageFetch.fulfilled) {
      return this.props.children(this.props.fullImageFetch.value.image.pic64);
    }

    return this.props.children();
  }
}

function mapPropToDispatchToProps(props) {
  return [
    {
      resource: 'fullImage',
      method: 'POST',
      request: () => ({
        url: props.apiRoutes.getFullImage(),
        body: {
          accessToken: props.accessToken,
          distribution: props.distribution,
          flightId: props.flightId,
          imSequence: 0,
          messageId: props.messageId,
          imageId: props.imageId,
        }
      })
    }
  ]
}

export default injectApiRoutes(reduxFetch(mapPropToDispatchToProps)(FullImage));
