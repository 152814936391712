export const CallState = {
  IDLE: 'IDLE',
  CONNECTED: 'CONNECTED',
  DISCONNECTED: 'DISCONNECTED',
  CALL_DENIED: 'CALL_DENIED',
  REQUEST_CALL: 'REQUEST_CALL',
  CALL_INITIALIZING: 'CALL_INITIALIZING',
  ANSWERING_CALL: 'ANSWERING_CALL',
  WAITING_FOR_ANSWER: 'WAITING_FOR_ANSWER',
  CALLING_INITIATOR: 'CALLING_INITIATOR',
  CALLING_ANSWER: 'CALLING_ANSWER',
  PEER_DISCONNECTED: 'PEER_DISCONNECTED',
  ERROR: 'ERROR',
};

export const MessageTypes = {
  CALL_REQUEST: 'CALLREQUEST',
  CANCEL_REQUEST: 'CANCELREQUEST',
  CANDIDATE: 'CANDIDATEAUDIO',
  ANSWER: 'ANSWER',
  OFFER: 'OFFER',
  CALL_ANSWER: 'CALLANSWER',
  CALL_DENIED: 'CALLDENIED',
  CALL_STOP: 'CALLSTOP',
  PARTNER_READY: 'PARTNERREADY',
};

export const CallType = {
  SHOW_THEIR_CAMERA: 'SHOWTHEIRCAMERA',
  SHOW_THIS_CAMERA: 'SHOWTHISCAMERA',
  ONLY_VOICE: 'ONLYVOICE',
};
