import PropTypes from 'prop-types';
// @see https://react-router.now.sh/route-config
import React from 'react';
import Route from 'react-router-dom/Route';
import Redirect from 'react-router-dom/Redirect';
import security from '../modules/security';
import communicator from '../modules/communicator';
import settings from '../modules/settings';
import about from '../modules/about';
import dashboard from '../modules/dashboard';
import loadSequence from '../modules/loadSequence';
import loadAudit from '../modules/loadAudit';
import changePsswrd from '../modules/changePsswrd';
import restart from '../modules/restart'

const getRender = (Container) => (
    (props) => <Container {...props} />);

const routes = [
  {
    path: '/login',
    render: getRender(security.containers.LoginPage),
    exact: true,
  },
  {
    path: '/restart',
    render: getRender(restart.containers.RestartContainer),
    exact: true,
  },
  {
    path: '/changePassword',
    render: getRender(changePsswrd.containers.ChangePsswrdPage),
    exact: true,
  },
  {
    path: '/changePasswordAfterLogin',
    render: getRender(changePsswrd.containers.ChangePsswrdAfterLoginPage),
    exact: true,
  },
  {
    path: '/',
    render: getRender(communicator.containers.CommunicatorPage),
    exact: true,
    secure: true,
  },
  {
    path: '/settings',
    render: getRender(settings.containers.SettingsPage),
    exact: true,
  },
  {
    path: '/dashboard',
    render: getRender(dashboard.containers.CriticalTimesPage),
    exact: true,
  },
  {
    path: '/dashboarddetail',
    render: getRender(dashboard.containers.CriticalTimeDetailPage),
    exact: true,
  },
  {
    path: '/about',
    render: getRender(about.containers.AboutPage),
    exact: true,
  },
  {
    path: '/loadSequence',
    component: loadSequence.containers.LoadSequencePage,
    exact: true,
  },
  {
    path: '/loadAudit',
    component: loadAudit.containers.LoadAuditPage,
    exact: true,
  },
];

export default routes;

export const MatchWithSubRoutes = route =>
    route.secure && !route.isAuthenticated
        ? <Redirect
            to={{
              pathname: '/login',
              state: { from: route.location },
            }}
        />
        : <Route {...route} />;
// <Route
//   {...without(route, 'component')}
//   render={props => (
//     route.secure && !route.isAuthenticated ? (
//       <Redirect
//         to={{
//           pathname: '/login',
//           state: { from: props.location },
//         }}
//       />
//     ) : (
//       // pass the sub-routes down to keep nesting
//       <route.component {...props} routes={route.routes} />
//     )
//   )}
// />

MatchWithSubRoutes.propTypes = {
  location: PropTypes.object,
};
