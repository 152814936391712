import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import Wrapper from './Wrapper';
import LoadPaneWrapper from './LoadPaneWrapper';
import LoadPane from './LoadPane';

const RampInstructions = ({
  onDoors,
  offDoors,
  onSelectedRows,
  onLoadSelected,
  offLoadSelected,
  onStopClick,
  onContinueClick,
  onMsg,
  offMsg,
  onOnMsgChange,
  onOffMsgChange,
  showLoader,
}) => {
  const { t } = useTranslation();

  return <Wrapper>
    <LoadPaneWrapper>
      <LoadPane
          doors={onDoors}
          title={t('communicator.rampInstructions.on')}
          type="on"
          onSelectedRows={onSelectedRows}
          selectedList={onLoadSelected}
          onStopClick={onStopClick}
          onContinueClick={onContinueClick}
          message={onMsg}
          onMsgChange={onOnMsgChange}
          showLoader={showLoader}
      />
    </LoadPaneWrapper>

    <LoadPaneWrapper>
      <LoadPane
          doors={offDoors}
          title={t('communicator.rampInstructions.off')}
          type="off"
          onSelectedRows={onSelectedRows}
          selectedList={offLoadSelected}
          onStopClick={onStopClick}
          onContinueClick={onContinueClick}
          message={offMsg}
          onMsgChange={onOffMsgChange}
          showLoader={showLoader}
      />
    </LoadPaneWrapper>
  </Wrapper>;
};

RampInstructions.propTypes = {
  onDoors: PropTypes.array,
  offDoors: PropTypes.array,
  onSelectedRows: PropTypes.func,
  onLoadSelected: PropTypes.array,
  offLoadSelected: PropTypes.array,
  onStopClick: PropTypes.func,
  onContinueClick: PropTypes.func,
  onMsg: PropTypes.string,
  offMsg: PropTypes.string,
  onOnMsgChange: PropTypes.func,
  onOffMsgChange: PropTypes.func,
  showLoader: PropTypes.bool,
};

export default RampInstructions;
