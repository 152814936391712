import PropTypes from 'prop-types';
import React from 'react';
import MIcon from '../../../../../components/MIcon';
import PrefixIcon from '../PrefixIcon';
import SystemMessageItem from "./SystemMessagesItem";

const SystemMessagesOpenItems = ({ system, onItemClick, selected}) =>
    system ?<div>
      <PrefixIcon>
          <MIcon>error</MIcon>
      </PrefixIcon>
          {system.message > 0 &&
              (<SystemMessageItem
                  onItemClick={onItemClick}
                  system={system}
                  selected={selected}
              />)}
            </div>:null;

SystemMessagesOpenItems.propTypes = {
  system: PropTypes.object,
  onItemClick: PropTypes.func,
};

export default SystemMessagesOpenItems;
