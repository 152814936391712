export class ColorEndTime {
  constructor(colors) {
    this.colors = colors;

    this.SCHEDULED = { backgroundColor: this.filterColorByType('CRT-SCHEDULED') };
    this.NEARTHRESHOLD = { backgroundColor: this.filterColorByType('CRT-NEAR TRESHOLD') };
    this.TOOLATE = { backgroundColor: this.filterColorByType('CRT-TOO LATE') };
    this.EXECUTED = { backgroundColor: this.filterColorByType('CRT-EXECUTED') };
    this.EXECUTEDLATE = { backgroundColor: this.filterColorByType('CRT-EXECUTED LATE') };
    this.EMPTY = { backgroundColor: '' };
  }

  getColor(time) {
    if (time) {
      if (time.tooLateEnd) return this.TOOLATE;
      if (time.nearThreshold) return this.NEARTHRESHOLD;
      if (time.executedLateEnd) return this.EXECUTEDLATE;
      if (time.executedEnd) return this.EXECUTED;
    }
    // alert('No data found');
    return this.SCHEDULED;
  }

  filterColorByType(type) {
    if (this.colors) {
      for (let i = 0; i < this.colors.length; i++) {
        if (this.colors[i].name === type) {
          return this.colors[i].fill.value;
        }
      }
    }
  }
}
