import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment-timezone';
import connect from 'react-redux-fetch';
import find from 'lodash/find';
import security from '../../modules/security';
import { iataTZRoute } from '../../app/api/routes';

class Timezone extends Component {
  static propTypes = {
    dispatchTimezonesGet: PropTypes.func,
    timezonesFetch: PropTypes.object,
    airport: PropTypes.object,
    date: PropTypes.string,
    format: PropTypes.string,
  };

  componentWillMount() {
    this.props.dispatchTimezonesGet();
  }

  mapTimezones = (timezones, iata, date, format) => {
    const zone = find(timezones, { iata });
    return moment(date).tz(zone.timezone).format(format || 'DD-MMM-YYYY, HH:mm');
  };

  render() {
    const { airport, date, format, timezonesFetch } = this.props;
    if (timezonesFetch.fulfilled) {
      return (
        <span>
          {this.mapTimezones(timezonesFetch.value, airport.iata, date, format)}
        </span>
      );
    }
    return null;
  }
}

const mapStateToProps = (state, props) => ({
  airport: props.airport || security.selectors.getAirport(state),
});

export default connect(
  [
    {
      resource: 'timezones',
      request: {
        url: iataTZRoute(),
        comparison: 1,
      },
    },
  ],
  mapStateToProps
)(Timezone);
