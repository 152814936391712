// @flow
/**
 * Write all front-end <-> back-end translators in this file.
 */
import moment from 'moment';
import isUndefined from 'lodash/isUndefined';

const momentDateToServerDate = (
  momentDate: moment,
  hour: number,
  minute: number,
  second: number
): string => {
  const finalHour = !isUndefined(hour) ? hour : moment().get('hour');
  const finalMinute = !isUndefined(minute) ? minute : moment().get('minute');
  const finalSecond = !isUndefined(second) ? second : moment().get('second');
  return momentDate
    .set('hour', finalHour)
    .set('minute', finalMinute)
    .set('second', finalSecond)
    .toISOString();
};

const momentDateToServerStartOfDay = (momentDate: moment): string =>
  momentDateToServerDate(momentDate, 0, 0, 0);

export default {
  momentDateToServerDate,
  momentDateToServerStartOfDay,
};
