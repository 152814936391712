import LoginPage from './containers/LoginPage';
import reducer from './reducer';
import selectors from './selectors';
import actions from './actions';
import actionTypes from './actionTypes';

export default {
  containers: {
    LoginPage,
  },
  reducer,
  selectors,
  actions,
  actionTypes,
};
