import { MessageTypes } from '../constants';
import actions from '../actions';

class PeerConnection {
  _pc;
  _isCaller;
  _audio;
  _video;
  _remoteStreamRef;
  _localStreamRef;
  _dispatch;
  _sendToUser;
  _isStarted = false;

  constructor({ isCaller, audio, video, remoteStreamRef, localStreamRef, dispatch, sendToUser }) {
    this._isCaller = isCaller;
    this._audio = audio;
    this._video = video;
    this._remoteStreamRef = remoteStreamRef;
    this._localStreamRef = localStreamRef;
    this._dispatch = dispatch;
    this._sendToUser = sendToUser;

    this._pc = new RTCPeerConnection();
    this._pc.ontrack = this.onReceiveTrack;
    this._pc.onicecandidate = this.sendLocalCandidate;
    this._pc.oniceconnectionstatechange = this.onDisconnect;
  }

  onReceiveTrack = e => {
    this._remoteStreamRef.current.srcObject = e.streams[0];
  };

  sendLocalCandidate = event => {
    if (!event.candidate) return;
    this._sendToUser({
      type: MessageTypes.CANDIDATE,
      data: JSON.stringify(event.candidate),
    });
  };

  onDisconnect = () => {
    if (this._pc.iceConnectionState === 'disconnected') {
      this._dispatch(actions.peerDisconnected());
    }
  };

  async start() {
    const userMediaStream = await navigator.mediaDevices.getUserMedia({
      audio: this._audio,
      video: this._video,
    });
    this._localStreamRef.current = this._localStreamRef.current || { srcObject: null };
    this._localStreamRef.current.srcObject = userMediaStream;

    userMediaStream.getTracks().forEach(track => {
      this._pc.addTrack(track, userMediaStream);
    });

    if (!this._isCaller) {
      try {
        const offer = await this._pc.createOffer({
          offerToReceiveVideo: true,
          offerToReceiveAudio: true,
        });

        await this._pc.setLocalDescription(offer);

        this._sendToUser({
          type: MessageTypes.OFFER,
          data: JSON.stringify(offer),
        });
      } catch (e) {
        console.debug('error in handleSendOfferToBackend', e);
        throw e;
      }
    }

    this._isStarted = true;
  }

  stop() {
    this._pc.close();
    if (this._localStreamRef.current && this._localStreamRef.current.srcObject) {
      this._localStreamRef.current.srcObject.getTracks().forEach(track => track.stop());
    }
    this._isStarted = false;
  }

  handle(lastMessage) {
    if (lastMessage) {
      if (this._isCaller) {
        this.handleMessageAsCaller(lastMessage);
      } else {
        this.handleMessageAsReplier(lastMessage);
      }
    }
  }

  handleMessageAsCaller(lastMessage) {
    switch (lastMessage.type) {
      case MessageTypes.OFFER:
        this.handleOfferReceived(lastMessage).then(() => {
          console.log('Offer received handled');
        });
        break;
      case MessageTypes.CANDIDATE:
        this.handleCandidateMessageReceived(lastMessage).then(() =>
          console.log('Candidate received handled')
        );
        break;
      default:
        break;
    }
  }

  async handleOfferReceived(message) {
    const receivedOffer = message.data;
    await this._pc.setRemoteDescription(new RTCSessionDescription(receivedOffer));
    const answer = await this._pc.createAnswer();
    await this._pc.setLocalDescription(answer);

    this.sendAnswerToPeer(answer);
  }

  sendAnswerToPeer(answer) {
    this._sendToUser({
      type: MessageTypes.ANSWER,
      data: JSON.stringify(answer),
    });
  }

  handleMessageAsReplier(lastMessage) {
    switch (lastMessage.type) {
      case MessageTypes.ANSWER:
        this.handleAnswerReceived(lastMessage).then(() => {
          console.log('Answer received handled');
        });
        break;
      case MessageTypes.CANDIDATE:
        this.handleCandidateMessageReceived(lastMessage).then(() =>
          console.log('Candidate received handled')
        );
        break;
      default:
        break;
    }
  }

  async handleAnswerReceived(message) {
    try {
      const answer = message.data;
      await this._pc.setRemoteDescription(new RTCSessionDescription(answer));
    } catch (e) {
      console.debug('error handleAnswerRecieved', e);
      console.debug('error handleAnswerRecieved msg', message);
      throw e;
    }
  }

  async handleCandidateMessageReceived(message) {
    const candidate = new RTCIceCandidate(message.data);
    await this._pc.addIceCandidate(candidate);
  }

  get isStarted() {
    return this._isStarted;
  }
}

export default PeerConnection;
