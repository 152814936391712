import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import Wrapper from './Wrapper';
import Header from './Header';
import Label from "./Label";
import AboutWrapper from "./AboutWrapper";

const About = ({
                   height,
                   t,
                   isAuthenticated,
                    data,
               }) => {
    return (
        {isAuthenticated} &&
        <Wrapper height={height}>
            <Header>{t('communicator.about.header')}</Header>
            <AboutWrapper>
                <Label>{t('communicator.about.version')}: {process.env.REACT_APP_VERSION}</Label>
                {data.map((item) => {
                    return <Label key={item}>{item}</Label>
                })}
            </AboutWrapper>
        </Wrapper>
    );
};

About.propTypes = {
    height: PropTypes.number,
    isAuthenticated: PropTypes.bool,
    data: PropTypes.array,

    // Injected by i18next
    t: PropTypes.func.isRequired,
};

export default withTranslation()(About);
