import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import reduxFetch from 'react-redux-fetch';
import { compose } from 'redux';

import { Dialog } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import selector from '../selectors';
import actions from '../actions';
import { CallState, CallType, MessageTypes } from '../constants';
import Answer from '../components/Answer';
import Initiator from '../components/Initiator';
import injectApiRoutes from '../../../app/api/injectApiRoutes';
import FormattedMessage from '../../i18n/components/FormattedMessage';

const CallContainer = ({ dispatchSendMessagePost }) => {
  const dispatch = useDispatch();
  const socketState = useSelector(selector.getSocketState);
  const lastMessage = useSelector(selector.getLastMessage);
  const toOnlineName = useSelector(selector.getToOnlineName);
  const [selectedCallType, setSelectedCallType] = useState(null);

  useEffect(() => {
    if (socketState === CallState.REQUEST_CALL && lastMessage && lastMessage.callType) {
      setSelectedCallType(lastMessage.callType);
    }

    if (socketState === CallState.CONNECTED) {
      setSelectedCallType(null);
    }

  }, [socketState, lastMessage]);

  const handleCallDenied = () => {
    dispatch(actions.handleDenied());
  };

  const handlePeerDisconnected = () => {
    dispatch(actions.handlePeerDisconnected());
  };

  const handleDisconnected = () => {
    dispatch(actions.handleDisconnected());
  };

  const handleError = () => {
    dispatch(actions.handleError());
  };

  useEffect(() => {
    if (socketState === CallState.CALL_DENIED) {
      const timeOut = setTimeout(handleCallDenied, 5000);
      return () => clearTimeout(timeOut);
    }
    if (socketState === CallState.PEER_DISCONNECTED) {
      const timeOut = setTimeout(handlePeerDisconnected, 5000);
      return () => clearTimeout(timeOut);
    }
    if (socketState === CallState.DISCONNECTED) {
      const timeOut = setTimeout(handleDisconnected, 5000);
      return () => clearTimeout(timeOut);
    }
    if (socketState === CallState.ERROR) {
      const timeOut = setTimeout(handleError, 5000);
      return () => clearTimeout(timeOut);
    }
    // eslint-disable-next-line
  }, [socketState]);

  const sendToBackend = message => {
    return dispatchSendMessagePost({ ...message });
  };

  const handleAccept = () => dispatch(actions.offersend());

  const handleDeny = () => {
    dispatch(actions.calldenied());
    sendToBackend({
      type: MessageTypes.CALL_DENIED,
    });
  };

  const handleCancel = () => {
    dispatch(actions.cancelrequest());
    sendToBackend({ type: MessageTypes.CANCEL_REQUEST });
  };

  if (socketState === CallState.ANSWERING_CALL) {
    const isVideoCall = lastMessage.data.callType !== CallType.ONLY_VOICE;
    return (
      <Dialog open={socketState === CallState.ANSWERING_CALL}>
        <DialogTitle>
          <FormattedMessage id="call.incomingCall" defaultMessage="Request call" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {lastMessage.fromOnlineName} requested {isVideoCall ? 'a video' : 'a phone'} call
          </DialogContentText>
          <DialogActions>
            <Button onClick={handleDeny}>Deny</Button>
            <Button onClick={handleAccept}>Accept</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }

  if (socketState === CallState.WAITING_FOR_ANSWER || socketState === CallState.CALLING_ANSWER) {
    return <Answer sendMessage={sendToBackend} />;
  }

  if (
    (socketState === CallState.REQUEST_CALL && selectedCallType) ||
    socketState === CallState.CALLING_INITIATOR
  ) {
    return (
      <>
        {socketState === CallState.REQUEST_CALL && (
          <Dialog open={socketState === CallState.REQUEST_CALL}>
            <DialogTitle>
              <FormattedMessage id="call.waitingAnswer" defaultMessage="Waiting for answer" />
            </DialogTitle>
            <DialogContent>
              <DialogContentText>Calling to {toOnlineName}</DialogContentText>
              <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
              </DialogActions>
            </DialogContent>
          </Dialog>
        )}
        <Initiator selectedCallType={selectedCallType} sendMessage={sendToBackend} />
      </>
    );
  }

  if (socketState === CallState.CALL_DENIED) {
    return (
      <Dialog open={socketState === CallState.CALL_DENIED}>
        <DialogTitle>
          <FormattedMessage id="call.denied" defaultMessage="Call denied" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{toOnlineName} denied your call</DialogContentText>
          <DialogActions>
            <Button onClick={handleCallDenied}>OK</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }

  if (socketState === CallState.PEER_DISCONNECTED) {
    return (
      <Dialog open={socketState === CallState.PEER_DISCONNECTED}>
        <DialogTitle>
          <span>Disconnected</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{toOnlineName} disconnected</DialogContentText>
          <DialogActions>
            <Button onClick={handlePeerDisconnected}>OK</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }

  if (socketState === CallState.DISCONNECTED) {
    return (
      <Dialog open={socketState === CallState.DISCONNECTED}>
        <DialogTitle>
          <span>Disconnected</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>You lost your connection</DialogContentText>
          <DialogActions>
            <Button onClick={handleDisconnected}>OK</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }

  if (socketState === CallState.ERROR) {
    return (
      <Dialog open={socketState === CallState.ERROR}>
        <DialogTitle>
          <span>Error</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Something went wrong. Try again later</DialogContentText>
          <DialogActions>
            <Button onClick={handleError}>OK</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }

  return null;
};

const mapStateToProps = state => ({
  toOnlineCode: selector.getToOnlineCode(state),
  toOnlineName: selector.getToOnlineName(state),
});

const mapPropToDispatchToProps = ({ apiRoutes, accessToken, toOnlineCode, toOnlineName }) => [
  {
    resource: 'sendMessage',
    method: 'post',
    request: message => ({
      url: apiRoutes.sendvoicecallmessage(),
      body: {
        accessToken: accessToken,
        message: { ...message, toOnlineCode, toOnlineName },
      },
      clearValueOnRequest: true,
    }),
  },
];

const enhance = compose(
  injectApiRoutes,
  connect(mapStateToProps),
  reduxFetch(mapPropToDispatchToProps)
);

export default enhance(CallContainer);
