import React from 'react';
import PropTypes from "prop-types";
import AlertDialog from "../../../../components/Dialog/AlertDialog";

class AlertMaxRows extends React.Component {
    static propTypes = {
        maxRows: PropTypes.object.isRequired,
        open: PropTypes.bool.isRequired,
        onClickOk: PropTypes.func.isRequired,
    }

    render() {
        const {maxRows, open, onClickOk,} = this.props;
        return <AlertDialog
            open={open}
            keywordTitle={null}
            defaultTitle={maxRows.title}
            keywordContent={null}
            defaultContent={maxRows.content}
            keywordOk={'communicator.ok'}
            defaultOk={'Ok'}
            onClickOk={onClickOk}
        />
    }
}

export default AlertMaxRows;