import React from 'react';
import {TableRow, TableRowColumn} from 'material-ui/Table';
import FormattedMessage from '../../../i18n/components/FormattedMessage';

const TableRowStyled = () => {
  return (
    <TableRow selectable={false}>
      <TableRowColumn>
        <FormattedMessage id="print.unit" defaultMessage="Units" />
      </TableRowColumn>
      <TableRowColumn>
        <FormattedMessage id="print.unit" defaultMessage="Weight" />
      </TableRowColumn>
      <TableRowColumn>
        <FormattedMessage id="print.start" defaultMessage="Start" />
      </TableRowColumn>
      <TableRowColumn>
        <FormattedMessage id="print.end" defaultMessage="End" />
      </TableRowColumn>
      <TableRowColumn>
        <FormattedMessage id="print.duration" defaultMessage="Duration" />
      </TableRowColumn>
    </TableRow>
  );
};

export default TableRowStyled;
