import styled from 'styled-components';
import FlatButton from 'material-ui/FlatButton';

const MIconWrapper = styled(FlatButton)`
  width: 10%;
  height: 64px!important;
  marginRight: -1px!important;
  // lineHeight: 1em!important;
  borderRadius: 0!important;
  border: 1px solid ${props =>
    props.selected ? props.theme.palette.primary : props.theme.palette.greyLight}!important;
  color: ${props => props.theme.palette.primary}!important;

  span {
    fontWeight: inherit!important;
  }

  backgroundColor: ${props => props.selected && props.theme.palette.primary}!important;
  color: ${props =>
    props.selected ? props.theme.palette.white : props.theme.palette.grey}!important;

  &:hover {
    backgroundColor: ${props => props.theme.palette.primary}!important;
    color: ${props => props.theme.palette.white}!important;
  }
`;

export default MIconWrapper;
