export default function calculateSvgCoordinates({ coordinates: { x1, y1, x2, y2 } }, horizontal) {
    return !horizontal?{
        x1: y1.value,
        y1: x1.value,
        x2: y2.value,
        y2: x2.value,
    }:{
        x1: x1.value,
        y1: y1.value,
        x2: x2.value,
        y2: y2.value,
    };
}