import { Machine } from 'xstate';
import { CallState } from '../constants';
import { StateMachineActions } from '../actionTypes';

const callMachine = Machine({
  initial: CallState.IDLE,
  states: {
    IDLE: {
      on: {
        [`${StateMachineActions['MACHINE_ACTION/CONNECTED']}`]: CallState.CONNECTED,
      },
    },
    CONNECTED: {
      on: {
        [`${StateMachineActions['MACHINE_ACTION/DISCONNECTED']}`]: CallState.IDLE,
        [`${StateMachineActions['MACHINE_ACTION/CALLREQUESTED']}`]: CallState.REQUEST_CALL,
        [`${StateMachineActions['SOCKET/CALLREQUESTED']}`]: CallState.ANSWERING_CALL,
      },
    },
    DISCONNECTED: {
      on: {
        [`${StateMachineActions['MACHINE_ACTION/CONNECTED']}`]: CallState.CONNECTED,
        [`${StateMachineActions['MACHINE_ACTION/HANDLEDISCONNECT']}`]: CallState.IDLE,
      },
    },
    REQUEST_CALL: {
      on: {
        [`${StateMachineActions['MACHINE_ACTION/DISCONNECTED']}`]: CallState.DISCONNECTED,
        [`${StateMachineActions['MACHINE_ACTION/CANCELLEDREQUEST']}`]: CallState.CONNECTED,
        [`${StateMachineActions['SOCKET/CALLDENIED']}`]: CallState.CALL_DENIED,
        [`${StateMachineActions['SOCKET/OFFER']}`]: CallState.CALLING_INITIATOR,
        [`${StateMachineActions['MACHINE_ACTION/ERROR']}`]: CallState.ERROR,
      },
    },
    CALL_DENIED: {
      on: {
        [`${StateMachineActions['MACHINE_ACTION/DENIED_HANDLED']}`]: CallState.CONNECTED,
      },
    },
    ANSWERING_CALL: {
      on: {
        [`${StateMachineActions['MACHINE_ACTION/DISCONNECTED']}`]: CallState.DISCONNECTED,
        [`${StateMachineActions['MACHINE_ACTION/CALLDENIED']}`]: CallState.CONNECTED,
        [`${StateMachineActions['SOCKET/CALLREQUESTED']}`]: CallState.CONNECTED,
        [`${StateMachineActions['MACHINE_ACTION/OFFERSENT']}`]: CallState.WAITING_FOR_ANSWER,
        [`${StateMachineActions['MACHINE_ACTION/ERROR']}`]: CallState.ERROR,
      },
    },
    WAITING_FOR_ANSWER: {
      on: {
        [`${StateMachineActions['MACHINE_ACTION/DISCONNECTED']}`]: CallState.DISCONNECTED,
        [`${StateMachineActions['SOCKET/ANSWER']}`]: CallState.CALLING_ANSWER,
        [`${StateMachineActions['MACHINE_ACTION/ERROR']}`]: CallState.ERROR,
      },
    },
    CALLING_INITIATOR: {
      on: {
        [`${StateMachineActions['MACHINE_ACTION/DISCONNECTED']}`]: CallState.DISCONNECTED,
        [`${StateMachineActions['MACHINE_ACTION/PEERDISCONNECTED']}`]: CallState.PEER_DISCONNECTED,
        [`${StateMachineActions['MACHINE_ACTION/CALLSTOPPED']}`]: CallState.CONNECTED,
        [`${StateMachineActions['SOCKET/CALLSTOPPED']}`]: CallState.CONNECTED,
      },
    },
    CALLING_ANSWER: {
      on: {
        [`${StateMachineActions['MACHINE_ACTION/DISCONNECTED']}`]: CallState.DISCONNECTED,
        [`${StateMachineActions['MACHINE_ACTION/PEERDISCONNECTED']}`]: CallState.PEER_DISCONNECTED,
        [`${StateMachineActions['MACHINE_ACTION/CALLSTOPPED']}`]: CallState.CONNECTED,
        [`${StateMachineActions['SOCKET/CALLSTOPPED']}`]: CallState.CONNECTED,
      },
    },
    PEER_DISCONNECTED: {
      on: {
        [`${StateMachineActions['MACHINE_ACTION/HANDLEDPEERDISCONNECT']}`]: CallState.CONNECTED,
        [`${StateMachineActions['MACHINE_ACTION/DISCONNECTED']}`]: CallState.DISCONNECTED,
      },
    },
    ERROR: {
      on: {
        [`${StateMachineActions['MACHINE_ACTION/HANDLEDERROR']}`]: CallState.CONNECTED,
        [`${StateMachineActions['MACHINE_ACTION/DISCONNECTED']}`]: CallState.DISCONNECTED,
      },
    },
  },
});

const initialState = { callState: CallState.IDLE };

const reducer = (state = initialState, action) => {
  if (action.type.startsWith('SOCKET/') || action.type.startsWith('MACHINE_ACTION/')) {
    switch (action.type) {
      case StateMachineActions['MACHINE_ACTION/CALLREQUESTED']:
        return {
          ...state,
          callState: callMachine.transition(state.callState, action.type).value,
          message: action.payload ? action.payload : state.message,
          toOnlineCode: action.payload.body.toOnlineCode,
          toOnlineName: action.payload.body.toOnlineName,
        };
      case StateMachineActions['SOCKET/CALLREQUESTED']:
        return {
          ...state,
          callState: callMachine.transition(state.callState, action.type).value,
          message: action.payload ? action.payload : state.message,
          toOnlineCode: action.payload.body.fromOnlineCode,
          toOnlineName: action.payload.body.fromOnlineName,
        };
      default:
        return {
          ...state,
          callState: callMachine.transition(state.callState, action.type).value,
          message: action.payload ? action.payload : state.message,
        };
    }
  }

  return state;
};

export default reducer;
