import styled from 'styled-components';

const ItemIconCircle = styled.div`
  width: ${props => (props.small ? '15px' : '25px')};
  height: ${props => (props.small ? '15px' : '25px')};
  margin: 0 auto;
  margin-top: -8px;
  padding: 5px;
  position: relative;
  background: ${props => props.theme.palette.white};
  border-radius: 100%;
  color: ${props =>
    props.secondary ? props.theme.palette.secondary : props.theme.palette.primary};
  font-size: ${props => props.theme.font.small};
  line-height: ${props => (props.small ? '1.5em' : '')};
`;

export default ItemIconCircle;
