import styled from 'styled-components';

const SubTitle = styled.h2`
  display: inline;
  margin: 0;
  padding: 0;
  color: ${props => props.theme.palette.black};
  fontSize: ${props => props.theme.font.large};
`;

export default SubTitle;
