import React from "react";
import TextField from "material-ui/TextField";
import Button from "../../security/components/Button";
import TranslatedLabel from "../../../components/TranslatedLabel/TranslatedLabel";
import PropTypes from "prop-types";
import Header from "../../about/components/Header";
import Wrapper from "../../about/components/Wrapper";
import FormWrapper from "./FormWrapper";
import Label from "./Label";
import {Divider} from "material-ui";
import TextFieldWrapper from "./TextFieldWrapper";
import CircularProgress from "material-ui/CircularProgress";

class ChangePsswrd extends React.Component {
    state = {
        currentPw: '',
        newPw: '',
        newPwRepeat: '',
    }

    static propTypes = {
        height: PropTypes.number,
        onChangePsswrd: PropTypes.func.isRequired,
        afterLogin: PropTypes.bool,
        pending: PropTypes.bool,
    };

    handleSubmit = () => {
        const {onChangePsswrd, } = this.props;
        onChangePsswrd(this.state);
    }

    handleCurrentPwChange = e => {
        this.setState({
            currentPw: e.currentTarget.value,
        });
    };

    handleNewPwChange = e => {
        this.setState({
            newPw: e.currentTarget.value,
        });
    };

    handleNewPwRepeatChange = e => {
        this.setState({
            newPwRepeat: e.currentTarget.value,
        });
    };

    render() {
        const {height, afterLogin, pending, } = this.props;
        const getLabel = (keyword, defaultValue) => {
            return <TranslatedLabel
                keyword={`communicator.changePassword.${keyword}`}
                defaultValue={defaultValue} />
        };

        const getLabelCurrentPsswrd = () =>
            getLabel('currentPassword', 'Current password');
        const getLabelNewPsswrd = () =>
            getLabel('newPassword', 'New password');
        const getLabelNewPsswrdRepeat = () =>
            getLabel('newPasswordRepeat', "Repeat new password");

        return <Wrapper height={height}>
            <Header>{getLabel('titleChangePassword', 'Change password')}</Header>
            <FormWrapper onSubmit={this.handleSubmit} autoComplete="off">
                {!afterLogin &&
                    <>
                        <Label>{getLabelCurrentPsswrd()}</Label>
                        <TextFieldWrapper>
                            <TextField
                                data-tst-tag="currentPwTextField"
                                hintText={getLabelCurrentPsswrd()}
                                label={getLabelCurrentPsswrd()}
                                type="password"
                                onChange={this.handleCurrentPwChange}
                                underlineFocusStyle={{borderColor: '#4CB384'}}
                                autoComplete="off"
                            />
                        </TextFieldWrapper>
                        <Divider />
                    </>}
                <Label>{getLabelNewPsswrd()}</Label>
                <TextFieldWrapper>
                    <TextField
                        data-tst-tag="newPwTextField"
                        hintText={getLabelNewPsswrd()}
                        label={getLabelNewPsswrd()}
                        type="password"
                        onChange={this.handleNewPwChange}
                        underlineFocusStyle={{ borderColor: '#4CB384' }}
                        autoComplete="off"
                    />
                </TextFieldWrapper>
                <Divider />
                <Label>{getLabelNewPsswrdRepeat()}</Label>
                <TextFieldWrapper>
                    <TextField
                        data-tst-tag="newPwRepeatTextField"
                        hintText={getLabelNewPsswrdRepeat()}
                        label={getLabelNewPsswrdRepeat()}
                        type="password"
                        onChange={this.handleNewPwRepeatChange}
                        underlineFocusStyle={{ borderColor: '#4CB384' }}
                        autoComplete="off"
                    />
                </TextFieldWrapper>
                <div style={{padding: 10}}>
                    {pending?
                        <CircularProgress />
                        :
                        <Button
                            data-tst-tag = "changePwButton"
                            onClick={this.handleSubmit}
                            label={getLabel('btnChangePassword', 'Change password')}
                            type="submit"
                            disabled={pending}
                        />
                    }
                </div>
            </FormWrapper>
        </Wrapper>
    }
}

export default ChangePsswrd;