// @flow
import indexOf from 'lodash/indexOf';

/* global XMLHttpRequest:true*/
/* eslint no-undef: "error"*/

const notFound = [];
const imageExists = (imageUrl: string): boolean => {
  const http = new XMLHttpRequest();

  if (indexOf(notFound, imageUrl) > -1) {
    return false;
  }

  http.open('HEAD', imageUrl, false);
  http.send();

  if (http.status === 404) {
    if (indexOf(notFound, imageUrl) === -1) {
      notFound.push(imageUrl);
    }
  }

  return http.status !== 404;
};

export default imageExists;
