import PropTypes from 'prop-types';
import React from 'react';
import reduxFetch, {container} from 'react-redux-fetch';
import {compose} from 'redux';
import injectApiRoutes from '../../../app/api/injectApiRoutes';
import CenteredCircularProgress from '../../../components/CenteredCircularProgress';
import CriticalTimePrint from '../component/CriticalTime/CriticalTimePrint';
import commonSelectors from '../../common/selectors';
import globals, {RefreshQualifier} from '../../../app/globals';
import adapter from '../../../app/api/adapter';
import * as dateHelpers from '../../../app/utils/dateHelpers';
import moment from 'moment';
import securitySelectors from '../../security/selectors';
import flightService from '../../common/domain/flight';
import find from 'lodash/find';
import get from 'lodash/get';
import Button from '../../../components/Button';
import FormattedMessage from '../../i18n';
import CriticalTimePopUp from '../component/CriticalTime/CriticalTimeFilterPopUp';
import actions from '../../common/actions';
import Redirect from 'react-router-dom/Redirect';
import isEqual from 'lodash/isEqual';
import toMilliseconds from '../../../app/utils/toMilliseconds';
import dashboardSelector from '../selectors';
import dashboardAction from '../actions';
import Wrapper from '../component/Select/Wrapper';
import {withTranslation} from "react-i18next";
import AlertMaxRows from "../component/CriticalTime/AlertMaxRows";

const fetchIsFulfilled = container.getUtil('componentHelpers').fetchIsFulfilled;

class CriticalTimesPage extends React.Component {
  static propTypes = {
    dispatchCriticalTimesPost: PropTypes.func,
    dispatchAirportsPost: PropTypes.func,
    dispatchColorsPost: PropTypes.func,
    dispatch: PropTypes.func.isRequired,
    criticalTimesFetch: PropTypes.object,
    selectedDateFrom: PropTypes.object,
    selectedDateTo: PropTypes.object,
    selectedTimeFrom: PropTypes.object,
    selectedTimeTo: PropTypes.object,
    selectedAirport: PropTypes.object,
    selectedLoad: PropTypes.string,
    timeDistribution: PropTypes.string,
    airportsFetch: PropTypes.object,
    colorsFetch: PropTypes.object,
    flightId: PropTypes.string,
    distribution: PropTypes.string,
    history: PropTypes.object,
    userFrequency: PropTypes.shape({
      flights: PropTypes.number,
    }),
    // Injected by i18next
    t: PropTypes.func.isRequired,
  };

  static contextTypes = {
    registerRefreshAction: PropTypes.func.isRequired,
    removeRefreshAction: PropTypes.func.isRequired,
  };

  state = {
    selectedDateFrom: moment(this.props.selectedDateFrom),
    selectedTimeFrom: this.props.selectedTimeFrom,
    selectedDateTo: this.props.selectedDateTo,
    selectedTimeTo: this.props.selectedTimeTo,
    selectedAirport: this.props.selectedAirport,
    selectedLoad: this.props.selectedLoad,
    showPopup: false,
    redirect: false,
    isConfirmed: false,
    openAlertMaxRows: true,
  };

  componentDidMount() {
    this.props.dispatchCriticalTimesPost();
    this.props.dispatchAirportsPost();
    this.props.dispatchColorsPost();
    this.context.registerRefreshAction(
      'criticalTimes',
      this.fetchCriticalTimes(RefreshQualifier.AUTO)
    );
  }

  clearTimeOutCriticalTimes = () => {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (fetchIsFulfilled(this.props, nextProps, 'criticalTimes')) {
      this.clearTimeOutCriticalTimes();
      this.timer = setTimeout(
        this.fetchCriticalTimes(RefreshQualifier.AUTO),
        toMilliseconds(this.props.userFrequency.flights)
      );
    }
  }

  componentWillUnmount() {
    this.clearTimeOutCriticalTimes();
    this.context.removeRefreshAction('criticalTimes');
  }

  componentWillUpdate(nextProps) {
    if (this.isDateDifferent(nextProps)) {
      this.clearTimeOutCriticalTimes();
      this.fetchCriticalTimes(RefreshQualifier.MANUAL)(
        nextProps.selectedDateFrom,
        nextProps.selectedDateTo,
        nextProps.distribution
      );
      this.props.dispatch(this.setActionDepartureTime(nextProps.selectedDateFrom));
      this.props.dispatch(this.setActionAirport(nextProps.selectedAirport));
      this.props.dispatch(actions.setDistribution(nextProps.distribution));
    }
  }

  setActionDepartureTime = (selectedDateFrom) => {
    return (actions.setDepartureTime(selectedDateFrom));
  }

  setActionAirport = (airport) => {
    return (actions.setAirport(airport));
  }

  getAirport = (airportCode) => {
    const {airportsFetch} = this.props;
    const airports = get(airportsFetch, 'value.airports.airport') || [];
    return find(airports, airport => airport.iata===airportCode);
  }

  setActionTimeDistribution = (distribution) => {
    return actions.setTimeDistribution(distribution);
  }

  shouldComponentUpdate(nextProps) {
    const flightsFetchIsDifferent = !isEqual(
      this.props.criticalTimesFetch,
      nextProps.criticalTimeFlightFetch
    );
    return (
      this.isDateDifferent(nextProps) ||
      flightsFetchIsDifferent ||
      !this.state.showPopup ||
      this.state.showPopup
    );
  }

  togglePopup() {
    this.setState(prevState => ({
      showPopup: !prevState.showPopup,
    }));
  }

  fetchCriticalTimes = refetchQualifier => (dateFrom, dateTo, distribution) => {
    const finalDate = moment(dateFrom) || moment(this.props.selectedDateFrom) || moment();
    const selectedDate = dateTo || this.props.selectedDateTo;
    const selectedAirportCode = getAirportCode(this.state.selectedAirport);
    const selectedDistribution = distribution || this.props.selectedLoad;
    const finalIsCurrent = false;
    const startOfDay = flightService.shouldFetchFlightsFromStartOfDay(finalDate);

    if (refetchQualifier==null || refetchQualifier!==RefreshQualifier.AUTO) {
      this.handleAlertMaxRowsAllowed();
    }
    this.props.dispatchCriticalTimesPost(
      startOfDay,
      finalIsCurrent,
      refetchQualifier,
      finalDate,
      selectedDate,
      selectedDistribution,
      selectedAirportCode,
    );
  };

  handleBackButtonClick = () => {
    this.props.history.goBack();
  };

  handleListItemClick = (distribution, flight) => {
    this.props.dispatch(actions.setFlight(flight));
    this.props.dispatch(this.setActionTimeDistribution(distribution));
    this.setState(prevState => ({
      redirect: !prevState.redirect,
    }));
  };

  handleDropDownChangeDistribution = (distribution) => {
    const distributionCode = distribution?distribution.value:'ON_LOAD';
    this.props.dispatch(actions.setDistribution(distributionCode));
    this.props.dispatch(dashboardAction.setDistribution(distributionCode));
    this.setState({
      selectedLoad: distributionCode,
    });
  };

  handleDropDownChangeAirport = (airport) => {
    this.props.dispatch(this.setActionAirport(airport));
    this.setState({
      selectedAirport: airport,
    });
  };

  handleDateChangeFrom = date => {
    this.props.dispatch(this.setActionDepartureTime(date));
    this.setState({
      selectedDateFrom: date,
    });
  };

  handleDateChangeTo = date => {
    this.props.dispatch(dashboardAction.setDateTo(date));
    /*this.props.dispatch(actions.setCriticalTimeDateTo(this.state.selectedDateTo));*/
    this.setState({
      selectedDateTo: date,
    });
  };

  handleTimeChangeTo = time => {
    this.props.dispatch(dashboardAction.setTimeTo(time));
    this.setState({
      selectedTimeTo: time,
    });
  };

  handleTimeChangeFrom = time => {
    this.props.dispatch(dashboardAction.setTimeFrom(time));
    this.setState({
      selectedTimeFrom: time,
    });
  };

  handleConfirmChange = () => {
    this.props.dispatch(
      dashboardAction.setFilter(
        this.state.selectedTimeFrom,
        this.state.selectedDateTo,
        this.state.selectedTimeTo,
        this.state.selectedLoad
      )
    );
    this.props.dispatch(this.setActionTimeDistribution(this.state.selectedLoad));

    this.setState(prevState => ({
      showPopup: !prevState.showPopup,
      isConfirmed: !prevState.isConfirmed,
    }));

    this.clearTimeOutCriticalTimes();
    this.fetchCriticalTimes(RefreshQualifier.MANUAL)(
      this.state.selectedDateFrom,
      this.state.selectedDateTo,
      this.state.selectedLoad
    );
  };

  resetForm = () => {
    this.setState({
      selectedDateFrom: moment(),
      selectedTimeFrom: null,
      selectedDateTo: null,
      selectedTimeTo: null,
      selectedAirport: null,
      selectedLoad: 'ON_LOAD',
    });
    this.props.dispatch(
      dashboardAction.setFilter(
        this.state.selectedTimeFrom,
        this.state.selectedDateTo,
        this.state.selectedTimeTo,
        this.state.selectedLoad
      )
    );
    this.props.dispatch(this.setActionDepartureTime(moment()));
    this.props.dispatch(this.setActionAirport(null));
    this.props.dispatch(this.setActionTimeDistribution(null));
  };

  isDateDifferent = nextProps =>
    (nextProps.selectedDateFrom &&
    !moment(nextProps.selectedDateFrom).isSame(moment(this.props.selectedDateFrom), 'day')) ||
    (nextProps.selectedDateTo &&
    !moment(nextProps.selectedDateTo).isSame(moment(this.props.selectedDateTo), 'day'));

  handleAlertMaxRowsClickOk = () => {
    this.setState({openAlertMaxRows: false, });
  }

  handleAlertMaxRowsAllowed = () => {
    this.setState({openAlertMaxRows: true, });
  }

  render() {
    const {
      selectedDateFrom,
      selectedDateTo,
      selectedTimeFrom,
      selectedTimeTo,
      selectedLoad,
      selectedAirport,
      redirect,
    } = this.state;
    const { criticalTimesFetch, airportsFetch, colorsFetch } = this.props;

    if (redirect) {
      return <Redirect to="/dashboarddetail" />;
    }

    const criticalTimesValue = get(criticalTimesFetch, 'value');
    const colorsValue = get(colorsFetch, 'value');
    const airportsValue = get(airportsFetch, 'value');
    if (criticalTimesValue && colorsValue && airportsValue) {
      const allowedToGoback = criticalTimesValue.hasAccess;
      const maxRows = (criticalTimesFetch.fulfilled?criticalTimesValue.maxRows:null);

      const airports = get(airportsFetch, 'value.airports.airport') || [];
      const times = get(criticalTimesFetch, 'value.criticalTimes.criticalTime') || [];
      const colors = get(colorsFetch, 'value.colorDefinitions.colorDefinition') || [];

      return (
        <div>
          {!criticalTimesFetch.fulfilled ? (
            <CenteredCircularProgress size={20} />
          ) : (
            <div style={{ visibility: 'hidden' }}>
              <CenteredCircularProgress size={20} />
            </div>
          )}
          <div>
            <div>
              {!this.state.showPopup ? (
                <Wrapper>
                  <Button
                    label={<FormattedMessage id="dashboard.filter" defaultMessage="FILTER" />}
                    style={{ width: '10%', marginBottom: 0, marginHeight: -10 }}
                    onClick={() => this.togglePopup()}
                    data-tst-tag={"filter-button"}
                  />
                </Wrapper>
              ) : (
                ''
              )}
              {this.state.showPopup ? (
                <CriticalTimePopUp
                  selectedDateFrom={selectedDateFrom}
                  selectedDateTo={selectedDateTo}
                  selectedTimeFrom={selectedTimeFrom}
                  selectedTimeTo={selectedTimeTo}
                  selectedLoad={selectedLoad}
                  selectedAirport={selectedAirport}
                  airports={airports}
                  onDropDownChangeAirport={this.handleDropDownChangeAirport}
                  onDropDownChangeDistribution={this.handleDropDownChangeDistribution}
                  onDateChangeFrom={this.handleDateChangeFrom}
                  onDateChangeTo={this.handleDateChangeTo}
                  onTimeChangeFrom={this.handleTimeChangeFrom}
                  onTimeChangeTo={this.handleTimeChangeTo}
                  onConfirm={this.handleConfirmChange}
                  resetForm={this.resetForm}
                />
              ) : (<>
                <CriticalTimePrint
                  criticalTimes={times}
                  onListClick={this.handleListItemClick}
                  onBackButtonClick={this.handleBackButtonClick}
                  colors={colors}
                  isAllowedToGoBack={allowedToGoback}
                />
                {maxRows?<AlertMaxRows
                  maxRows={maxRows}
                  open={this.state.openAlertMaxRows}
                  onClickOk={this.handleAlertMaxRowsClickOk}/>:null}
                </>)
              }
            </div>
          </div>
        </div>
      );
    }

    return <CenteredCircularProgress />;
  }
}

const getDateFrom = (refreshQualifier, dateFrom, props) => {
  return refreshQualifier === 'MANUAL'
      ? dateFrom
          ? adapter.momentDateToServerStartOfDay(moment(dateFrom))
          : adapter.momentDateToServerStartOfDay(moment(props.selectedDateFrom))
      : adapter.momentDateToServerStartOfDay(moment(props.selectedDateFrom))
}

const getDateTo = (refreshQualifier, dateTo, props) => {
  return refreshQualifier === 'MANUAL'
      ? dateTo
      ? adapter.momentDateToServerStartOfDay(moment(dateTo))
      : adapter.momentDateToServerStartOfDay(moment(props.selectedDateTo))
      : props.selectedDateTo
      ? adapter.momentDateToServerStartOfDay(moment(props.selectedDateTo))
      : null;
}

const getAirportCode = (airport) => {
  return airport?airport.iata:'';
}

const getCriticalTimesAirportCode = (refreshQualifier, airportCode, props) => {
  return airportCode?airportCode:getAirportCode(props.selectedAirport);
}

const mapPropToDispatchToProps = props => [
  {
    resource: 'criticalTimes',
    method: 'POST',
    request: (
      startOfDay = true,
      isCurrent = false,
      refreshQualifier = RefreshQualifier.MANUAL,
      dateFrom,
      dateTo,
      distribution,
      airportCode,
    ) => ({
      url: props.apiRoutes.getCriticalTimes(),
      body: {
        accessToken: props.accessToken,
        dateFrom: getDateFrom(refreshQualifier, dateFrom, props),
        dateTo: getDateTo(refreshQualifier, dateTo, props),
        localTimeOffset: dateHelpers.currentTimezoneOffset(getDateFrom(refreshQualifier, dateFrom, props)),
        distribution: distribution?distribution:props.distribution,
        current: isCurrent,
        airportCode: getCriticalTimesAirportCode(refreshQualifier, airportCode, props),
        passengerFlights: true,
        freighterFlights: true,
        application: globals.applicationName,
        refreshQualifier,
      },
    }),
  },
  {
    resource: 'airports',
    method: 'POST',
    request: () => ({
      url: props.apiRoutes.getAirports(),
      body: {
        accessToken: props.accessToken,
      },
    }),
  },
  {
    resource: 'colors',
    method: 'POST',
    request: () => ({
      url: props.apiRoutes.getColors(),
      body: {
        accessToken: props.accessToken,
      },
    }),
  },
];

const mapStateToProps = state => ({
  flightId: commonSelectors.getFlightId(state),
  distribution: commonSelectors.getOldDistribution(state),
  timeDistribution: commonSelectors.getTimeDistribution(state),
  userFrequency: securitySelectors.getUserFrequency(state),
  selectedDateFrom: commonSelectors.getDepartureTime(state),
  selectedDateTo: dashboardSelector.getDateTo(state),
  selectedTimeFrom: dashboardSelector.getTimeFrom(state),
  selectedTimeTo: dashboardSelector.getTimeTo(state),
  selectedAirport: commonSelectors.getAirport(state),
  selectedLoad: dashboardSelector.getDistribution(state),
});

const enhance = compose(
  injectApiRoutes,
  reduxFetch(mapPropToDispatchToProps, mapStateToProps)
);

export default enhance(withTranslation()(CriticalTimesPage));
