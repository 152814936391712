import React from 'react';
import PropTypes from "prop-types";
import DeckGraph from "./DeckGraph/DeckGraph";
import SplitPane from "react-split-pane";

class Graph extends React.Component {
    static propTypes = {
        id: PropTypes.string,
        distribution: PropTypes.number.isRequired,
        flight: PropTypes.object,
        decks: PropTypes.object,
        locations: PropTypes.object,
        loadSequence: PropTypes.object,
        height: PropTypes.number,
        onLockAction: PropTypes.func,
    }

    state = {
        initSplitHeight: 0,
        splitHeight: 0,
    }

    constructor(props) {
        super(props);
        const splitHeight = this.getInitSplitHeight();
        this.state = {
            initSplitHeight: splitHeight,
            splitHeight: splitHeight,
        };
    }

    getInitSplitHeight = () => {
        const {decks, height, } = this.props;
        const nrDecks = decks && decks.deck?decks.deck.length:0;
        return nrDecks!==0?height/nrDecks:0;
    }

    handleChange = (height) => {
        this.setState({
            splitHeight: height,
        });
    }

    render() {
        const {distribution, flight, decks, locations,
            loadSequence, id, height, onLockAction} = this.props;
        const splitHeight = this.state.splitHeight;

        return (
            <SplitPane
                split={'horizontal'}
                minSize={20}
                maxSize={height-20}
                defaultSize={this.state.initSplitHeight}
                onChange={this.handleChange}
            >
                {decks.deck.map((deck, index) => {
                    const deckHeight = (index===0?splitHeight:height-splitHeight);
                    const idIndex = `${id}Deck${index}`;
                    return <DeckGraph
                        key={idIndex}
                        id={idIndex}
                        distribution={distribution}
                        flight={flight}
                        deck={deck}
                        deckIndex={index}
                        locations={locations}
                        loadSequence={loadSequence}
                        height={deckHeight}
                        onLockAction={onLockAction}
                    />
                })}
            </SplitPane>
        );
    }
}

export default Graph;