import styled from 'styled-components';

const Header = styled.h1`
  width: 70%;
  color: ${props => props.theme.palette.grey};
  font-size: ${props => props.theme.font.large};
  margin: 0 0 0 1em;
  line-height: 64px;
`;

export default Header;
