import { injectGlobal } from 'styled-components';

const configureTheme = theme => {
  // eslint-disable-next-line
  injectGlobal`

    @font-face {
      font-family: 'Roboto';
      src: url('./fonts/Roboto-Light-webfont.eot');
      src: url('./fonts/Roboto-Light-webfont.eot?#iefix') format('embedded-opentype'),
           url('./fonts/Roboto-Light-webfont.woff') format('woff'),
           url('./fonts/Roboto-Light-webfont.ttf') format('truetype'),
           url('./fonts/Roboto-Light-webfont.svg#robotolight') format('svg');
      font-weight: normal;
      font-style: normal;
    }

    @font-face {
      font-family: 'Material Icons';
      font-style: normal;
      font-weight: 400;
      src: url(./fonts/MaterialIcons-Regular.eot); /* For IE6-8 */
      src: local('Material Icons'),
           local('MaterialIcons-Regular'),
           url(./fonts/MaterialIcons-Regular.woff2) format('woff2'),
           url(./fonts/MaterialIcons-Regular.woff) format('woff'),
           url(./fonts/MaterialIcons-Regular.ttf) format('truetype');
    }

    body {
      margin: 0;
      font-family: Roboto, sans-serif;
      fontSize: ${theme.font.default};
      color: ${theme.palette.black};
    }
    h1 {
      font-weight: normal;
    }
    
    #react-print {
        display: none;
      }

      @media print {
        #react-no-print {
          display: none;
        }

        #react-print {
          display: block;
        }
    }
  `;
};

export default configureTheme;
