import actionTypes from './actionTypes';

const setWebServiceUrl = url => ({
  type: actionTypes.WEBSERVICE_URL_SET,
  payload: {
    url,
  },
});

const setTheme = theme => ({
  type: actionTypes.THEME_SET,
  payload: {
    theme,
  },
});

export default {
  setWebServiceUrl,
  setTheme,
};
