import PropTypes from "prop-types";
import React from "react";
import TranslatedLabel from "../../../components/TranslatedLabel/TranslatedLabel";
import {Button} from "@material-ui/core";
import globals from "../../../app/globals";
import commonSelectors from "../../common/selectors";
import {compose} from "redux";
import injectApiRoutes from "../../../app/api/injectApiRoutes";
import reduxFetch from "react-redux-fetch";
import get from "lodash/get";
import isEqual from "lodash/isEqual";

class Audit2ExcelButton extends React.Component {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        flightId: PropTypes.string,
        distribution: PropTypes.number.isRequired,
        dispatchAudit2ExcelPost: PropTypes.func,
        audit2ExcelFetch: PropTypes.object,
    };

    state = {
        pending: false,
    }

    componentDidMount() {
        this.setPending(false);
    }

    setPending = (pending) => {
        this.setState({pending: pending});
    }

    isDisabled = () => {
        return !(this.props.flightId>0) || this.state.pending;
    }

    handleClick = () => {
        if (!this.isDisabled()) {
            this.setPending(true);
            this.props.dispatchAudit2ExcelPost(this.props);
        }
    };

    isDifferent = (obj1, obj2, property) => {
        return !isEqual(get(obj1, property), get(obj2, property));
    }

    componentDidUpdate(prevProps) {
        const { audit2ExcelFetch } = this.props;
        const fetchIsDifferent = this.isDifferent(
            prevProps.audit2ExcelFetch, audit2ExcelFetch, 'value');
        if (fetchIsDifferent && audit2ExcelFetch && audit2ExcelFetch.fulfilled) {
            const value = get(audit2ExcelFetch, 'value');
            this.downloadExcelFile(value);
            this.setPending(false);
        }
    }

    downloadExcelFile = (excel) => {
        const link = document.createElement('a');
        link.style.display = 'none';
        const mimeType = "application/vnd.openxml";
        link.href=`data:${mimeType};base64,${excel.excelFile}`;
        link.download = excel.excelFileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    getLabel = (keyword, defaultValue) => {
        return <TranslatedLabel
            keyword={`communicator.loadHistory.audit2Excel.${keyword}`}
            defaultValue={defaultValue}/>
    }

    render() {
        return <Button
            variant="contained"
            color="primary"
            style={{marginRight: 20, whiteSpace: "nowrap"}}
            onClick={this.handleClick}
            data-tst-tag={'audit2Excel'}
            disabled={this.isDisabled()}
        >
            {this.getLabel('label', 'Export to Excel')}
        </Button>
    }
}

const getDistribution = (props) =>
    props.distribution===1?"ON_LOAD":"OFF_LOAD";

const mapPropToDispatchToProps = props => [
    {
        resource: 'audit2Excel',
        method: 'POST',
        request: ({
            url: props.apiRoutes.audit2Excel(),
            body: {
                accessToken: props.accessToken,
                application: globals.applicationName,
                flightId: props.flightId,
                distribution: getDistribution(props),
            },
        }),
    },
];

const mapStateToProps = state => ({
    flightId: commonSelectors.getFlightId(state),
});

const enhance = compose(
    injectApiRoutes,
    reduxFetch(mapPropToDispatchToProps, mapStateToProps)
);

export default enhance(Audit2ExcelButton);