import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import reduxFetch, { container } from 'react-redux-fetch';
import { compose } from 'redux';
import delay from 'lodash/delay';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import CenteredCircularProgress from '../../../components/CenteredCircularProgress';
import injectApiRoutes from '../../../app/api/injectApiRoutes';
import toMilliseconds from '../../../app/utils/toMilliseconds';
import securitySelectors from '../../../modules/security/selectors';
import ChatDetail from '../components/ChatDetail';
import commonSelectors from '../../../modules/common/selectors';
import actions from '../actions';
import { RefreshQualifier } from '../../../app/globals';

const fetchIsFulfilled = container.getUtil('componentHelpers').fetchIsFulfilled;
const scrollToFirstUnreadMargin = 200;

const buildMessagesKey = flightId => `messages-${flightId}`;

const getMessagesCallFromProps = props => props[`${buildMessagesKey(props.flightId)}Fetch`];

const getMessagesDispatchFromProps = props => props[`dispatchMessages-${props.flightId}Post`];

class ChatDetailPage extends Component {
  static propTypes = {
    flightId: PropTypes.string,
    dispatchMessagesPost: PropTypes.func,
    messagesFetch: PropTypes.object,
    dispatchCheckNewMessagesPost: PropTypes.func,
    dispatch: PropTypes.func,
    checkNewMessagesFetch: PropTypes.object,
    distribution: PropTypes.string,
    userFrequency: PropTypes.shape({
      chatMessages: PropTypes.number,
    }),
  };

  static contextTypes = {
    registerRefreshAction: PropTypes.func.isRequired,
    removeRefreshAction: PropTypes.func.isRequired,
  };

  state = {
    // By default, only a loader is shown with the initial fetch.
    // We also want to show the loader if the user picks another flight.
    forceShowLoader: false,
    // for the read functionality
    loaderInfo: {
      showCircularProgress: false,
      msgId: 0,
    },
  };

  // REACT LIFECYCLE

  componentWillMount() {
    this.lastImSequence = 0;
    this.mounted = true;
    this.onOtherFlightSelected(this.props);

    this.context.registerRefreshAction(
      'newMessageCheck',
      this.checkForNewMessages(RefreshQualifier.MANUAL)
    );
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.flightId !== nextProps.flightId) {
      this.onOtherFlightSelected(nextProps);
    }

    if (fetchIsFulfilled(this.props, nextProps, buildMessagesKey(nextProps.flightId))) {
      this.onMessagesFetched(nextProps);
    }

    if (fetchIsFulfilled(this.props, nextProps, 'checkNewMessages')) {
      this.onCheckNewMessagesFetched(nextProps);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const stateIsDifferent = !isEqual(this.state, nextState);
    if (stateIsDifferent) {
      return true;
    }

    const messagesAreDifferent = !isEqual(
      get(getMessagesCallFromProps(this.props), 'value.instantMessaging'),
      get(getMessagesCallFromProps(nextProps), 'value.instantMessaging')
    );

    return messagesAreDifferent;
  }

  componentWillUpdate() {
    const node = ReactDOM.findDOMNode(this).parentElement; // eslint-disable-line
    this.shouldScrollToBottom = node.scrollTop + node.offsetHeight === node.scrollHeight;
  }

  componentDidUpdate() {
    if (
      this.state.forceShowLoader ||
      (get(getMessagesCallFromProps(this.props), 'value.instantMessaging.instantMessage') || [])
        .length === 0
    ) {
      return;
    }
    if (this.shouldScrollToBottom) {
      // Add a small delay to make sure everthing is rendered before scrolling to bottom
      // delay(this.scrollToFirstUnread, 200);
      delay(this.scrollToBottom, 200);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    this.stopTimer();
    this.context.removeRefreshAction('newMessageCheck');
  }

  // TRANSITION HANDLERS

  onOtherFlightSelected(nextProps) {
    if (!nextProps.flightId) {
      return;
    }

    nextProps.dispatch(actions.clearMessages());
    getMessagesDispatchFromProps(nextProps)(nextProps.flightId, nextProps.distribution, 0);

    this.setState({
      forceShowLoader: true,
    });
    this.lastImSequence = 0;
  }

  onMessagesFetched(nextProps) {
    const messagesFlightId = get(getMessagesCallFromProps(nextProps), 'value.flight.flightId');

    // When a message fetch returns when we already selected another flight, do nothing
    if (messagesFlightId !== nextProps.flightId) {
      return;
    }

    this.startCheckingForNewMessages();
    const messages =
      get(getMessagesCallFromProps(nextProps), 'value.instantMessaging.instantMessage') || [];

    this.lastImSequence = messages.length > 0 ? messages[0].sequenceId : 0;

    this.setState(state => ({
      forceShowLoader: false,
      loaderInfo: { showCircularProgress: false, msgId: state.loaderInfo.msgId },
    }));
  }

  onCheckNewMessagesFetched(nextProps) {
    // TODO check why numberOfMessages is not reset to 0 after fetching new messages
    // TODO check why numberOfMessages is always the same
    const numberOfMessages = get(nextProps.checkNewMessagesFetch, 'value.numberOfMessages') || 0;
    const messages =
      get(getMessagesCallFromProps(nextProps), 'value.instantMessaging.instantMessage') || [];
    if (numberOfMessages > 0 || messages.length > 0) {
      // TODO append new msg
      // Note: this doesn't work, gives back empty array.
      // TODO append new msg to a custom list?
      // this.props.dispatchMessagesPost(nextProps.flightId, this.lastImSequence, true);

      getMessagesDispatchFromProps(this.props)(
        nextProps.flightId,
        nextProps.distribution,
        0,
        null,
        RefreshQualifier.AUTO
      );
    } else {
      this.startCheckingForNewMessages();
    }
  }

  // INTERVAL CHECKER

  startCheckingForNewMessages = () => {
    this.stopTimer();
    this.timer = setTimeout(
      this.checkForNewMessages(RefreshQualifier.AUTO),
      toMilliseconds(this.props.userFrequency.chatMessages)
    );
  };

  stopTimer = () => {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  checkForNewMessages = refreshQualifier => () => {
    if (this.props.flightId && this.props.distribution) {
      this.props.dispatchCheckNewMessagesPost(
        this.props.distribution,
        this.lastImSequence,
        refreshQualifier
      );
    }
  };

  // UI

  scrollToBottom = () => {
    if (!this.mounted) {
      return;
    }
    // based on http://blog.vjeux.com/2013/javascript/scroll-position-with-react.html
    const node = ReactDOM.findDOMNode(this).parentElement; // eslint-disable-line
    node.scrollTop = node.scrollHeight;
  };

  scrollToFirstUnread = () => {
    if (!this.mounted) {
      return;
    }

    const node = ReactDOM.findDOMNode(this); // eslint-disable-line
    const unreadNodes = node.querySelectorAll('[data-msg-unread="true"]');

    if (unreadNodes.length > 0) {
      node.parentElement.scrollTop =
        unreadNodes[unreadNodes.length - 1].offsetTop - scrollToFirstUnreadMargin;
    }
  };

  handleReadMsgUpdate = (value, id) => {
    if (value) {
      // this.props.dispatchMessagesPost(this.props.flightId, this.props.distribution, 0);
      getMessagesDispatchFromProps(this.props)(this.props.flightId, this.props.distribution, 0);
      this.setState({ loaderInfo: { showCircularProgress: true, msgId: id } });
    }
  };

  render() {
    if (this.state.forceShowLoader) {
      return <CenteredCircularProgress />;
    }

    const { flightId, distribution } = this.props;

    const messagesFetch = getMessagesCallFromProps(this.props);
    return flightId ? (
      messagesFetch.fulfilled || messagesFetch.value ? (
        <ChatDetail
          messagesReceived={
            messagesFetch.value.instantMessaging
              ? messagesFetch.value.instantMessaging.instantMessage
              : []
          }
          onReadMsgUpdate={this.handleReadMsgUpdate}
          showCircularProgress={this.state.showCircularProgress}
          loaderInfo={this.state.loaderInfo}
          flightId={flightId}
          distribution={distribution}
          senderFilter={messagesFetch.value.senderFilter}
        />
      ) : (
        <CenteredCircularProgress />
      )
    ) : (
      <div />
    );
  }
}

const mapPropToDispatchToProps = props => [
  {
    resource: buildMessagesKey(props.flightId),
    method: 'POST',
    request: (
      flightId,
      distribution,
      imSequence,
      append,
      refreshQualifier = RefreshQualifier.MANUAL
    ) => ({
      url: props.apiRoutes.getMessages(),
      body: {
        accessToken: props.accessToken,
        flightId,
        imSequence,
        distribution: distribution,
        refreshQualifier,
      },
      meta: append
        ? {
            addToList: {
              path: 'instantMessaging.instantMessage',
              idName: 'sequenceId',
            },
          }
        : {},
    }),
  },
  {
    resource: 'checkNewMessages',
    method: 'POST',
    request: (distribution, imSequence = 0, refreshQualifier = RefreshQualifier.MANUAL) => ({
      url: props.apiRoutes.checkForNewMessages(),
      body: {
        accessToken: props.accessToken,
        flightId: props.flightId,
        distribution: distribution,
        imSequence,
        refreshQualifier,
      },
    }),
  },
];

const mapStateToProps = state => ({
  flightId: commonSelectors.getFlightId(state),
  distribution: commonSelectors.getDistribution(state),
  userFrequency: securitySelectors.getUserFrequency(state),
});

const enhance = compose(
  injectApiRoutes,
  connect(mapStateToProps),
  reduxFetch(mapPropToDispatchToProps)
);

export default enhance(ChatDetailPage);
