import PropTypes from 'prop-types';
import React from 'react';
import getAssetImageLink from '../../app/utils/getAssetImageLink';

const Logo = ({ company }) =>
  <img src={getAssetImageLink(`${company}_logo_circle.png`)} alt="logo" height="40" width="40" />;

Logo.propTypes = {
  company: PropTypes.string,
};

export default Logo;
