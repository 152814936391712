import Immutable from 'seamless-immutable';
import {REHYDRATE} from 'redux-persist/constants';
import actionTypes from './actionTypes';

const INITIAL_STATE = {
  authenticated: false,
  user: {},
  username: '',
  onlineCode: '',
  isDashboard: false,
  isRols: false,
  isLoadSequence: false,
  isLoadAudit: false,
  accessFunctions: [],
  changePsswrdOnNextLogin: false,
  privacyNoticeUrl: null,
};

const securityReducer = (state = Immutable.from(INITIAL_STATE), action) => {
  switch (action.type) {
    case actionTypes.AUTHENTICATE:
      return state
        .set('authenticated', true)
        .set('user', action.payload.user)
        .set('username', action.payload.username)
        .set('onlineCode', action.payload.onlineCode)
        .set('isDashboard', action.payload.isDashboard)
        .set('isRols', action.payload.isRols)
        .set('isLoadSequence', action.payload.isLoadSequence)
          .set('isLoadAudit', true)
        .set('accessFunctions', action.payload.accessFunctions);

    case actionTypes.LOGOUT:
      return Immutable.from(INITIAL_STATE);
    case actionTypes.CHANGE_PSSWRD_ON_NEXT_LOGIN:
      return state.set('changePsswrdOnNextLogin', action.payload.changePsswrdOnNextLogin);
    case actionTypes.SET_PRIVACY_NOTICE_URL:
      return state.set('privacyNoticeUrl', action.payload.privacyNoticeUrl);
    case REHYDRATE:
      return Immutable.from(action.payload.security ? action.payload.security : INITIAL_STATE);
    default:
      return state;
  }
};

export default securityReducer;
