import styled from 'styled-components';
import Button from '../../../../components/Button';

const ButtonWrapper = styled(Button)`
  width: 70%!important;
  marginLeft: 20px!important;
  // maxWidth: 70%!important;
  color: ${props => props.disabled && props.theme.palette.black}!important;
  borderColor: ${props => props.disabled && props.theme.palette.black}!important;

  &:hover {
    backgroundColor: ${props =>
      props.disabled
        ? props.theme.palette.black
        : props.secondary && props.theme.palette.secondary}!important;
    color: ${props => props.theme.palette.white}!important;
    cursor: ${props => props.disabled && 'no-drop'}!important;
  }
`;

export default ButtonWrapper;
