import React, {Component} from 'react';
import TranslatedLabel from "../../../components/TranslatedLabel/TranslatedLabel";

class RestartContainer extends Component {
   render() {
        return (
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%',}}>
                <div style={{ alignItems: 'center', flexDirection: 'column', width: '500px', }}>
                    <h3 style={{textAlign: 'center'}}>
                        <TranslatedLabel
                            keyword={'rols.utils.restart.differentVersion'}
                            defaultValue={'Close your browser completely and restart.'} />
                    </h3>
                </div>
            </div>
        );
    }
}

export default RestartContainer;
