import React from 'react';
import PropTypes from "prop-types";
import Rectangle from "./shapes/Rectangle";

class Positions extends React.Component {
    static propTypes = {
        id: PropTypes.string,
        svg: PropTypes.object,
        loadSteps: PropTypes.array,
        rectGroup: PropTypes.object,
        labelGroup: PropTypes.object,
        onClickPosition: PropTypes.func,
        loadSequence: PropTypes.object,
        horizontal: PropTypes.bool,
        renderPositionLabel: PropTypes.func,
        colorPriorities: PropTypes.object,
        communicator: PropTypes.bool,
    }

    getAllPositionLoadSteps = (loadStep) => {
       const {loadSteps,} = this.props;
       return loadSteps.filter(ls =>
           loadStep.position.positionId===ls.position.positionId);
    }

    render() {
        const { svg, loadSteps, rectGroup, labelGroup, onClickPosition,
            horizontal, loadSequence, communicator, renderPositionLabel, colorPriorities, id } = this.props;
        return (
            <>
                {svg && loadSteps.map((loadStep, index) => {
                    const allPositionLoadSteps =
                        this.getAllPositionLoadSteps(loadStep);
                    const onClick = event => {
                        onClickPosition(loadStep, allPositionLoadSteps, event);
                    };
                    const positionIdIndex = `${id}Position${index}`;
                    return (
                        <Position
                            key={positionIdIndex}
                            id={positionIdIndex}
                            svg={svg}
                            loadStep={loadStep}
                            allPositionLoadSteps={allPositionLoadSteps}
                            rectGroup={rectGroup}
                            labelGroup={labelGroup}
                            loadSequence={loadSequence}
                            horizontal={horizontal}
                            onClick={onClick}
                            renderLabel={renderPositionLabel}
                            communicator={communicator}
                            colorPriorities={colorPriorities}
                        />
                    );
                })}
            </>);
    }
}

class Position extends React.Component {
    static propTypes = {
        id: PropTypes.string,
        svg: PropTypes.object,
        loadStep: PropTypes.object,
        allPositionLoadSteps: PropTypes.array,
        rectGroup: PropTypes.object,
        labelGroup: PropTypes.object,
        loadSequence: PropTypes.object,
        horizontal: PropTypes.bool,
        onClick: PropTypes.func,
        renderLabel: PropTypes.func,
        communicator: PropTypes.bool,
        colorPriorities: PropTypes.object,
    }

    getFillColor = () => {
        const {loadStep, } = this.props;
        return loadStep.graphColor ?
            loadStep.graphColor.fill.value : null;
    }

    getBorderColor = () => {
        const {loadStep, } = this.props;
        return loadStep.graphColor ?
            loadStep.graphColor.border.value : null;
    }

    getTextColor = () => {
        const {loadStep, } = this.props;
        return loadStep.graphColor.font.value;
    }

    getColorPriority = (loadStep) => {
        const {colorPriorities, } = this.props;
        const colorPrioritiesList = colorPriorities && colorPriorities.item?colorPriorities.item:null;
        return colorPrioritiesList && loadStep.graphColor?
            colorPrioritiesList.findIndex((item) =>item.name===loadStep.graphColor.name):1000;
    }

    isHidden = () => {
        const {loadStep, allPositionLoadSteps, colorPriorities} = this.props;
        if (colorPriorities && allPositionLoadSteps) {
            const ls = allPositionLoadSteps.reduce(
                (previousLoadStep, loadStep) => previousLoadStep &&
                this.getColorPriority(previousLoadStep)<=this.getColorPriority(loadStep)?
                    previousLoadStep:loadStep, null);
            return loadStep!==ls;
        }
        return false;
    }

    render() {
        const { svg, loadStep, allPositionLoadSteps, rectGroup, labelGroup,
            horizontal, id, onClick, renderLabel, communicator } = this.props;
        const rectId = `${id}Rect`;
        const labelId = `${id}Label`;
        const nrDivs = allPositionLoadSteps && !communicator?allPositionLoadSteps.length:1;
        return (
            <>
                <Rectangle
                    key={rectId}
                    id={rectId}
                    onClick={onClick}
                    rectangle={loadStep}
                    svg={svg}
                    group={rectGroup}
                    fillColor={this.getFillColor()}
                    borderColor={this.getBorderColor()}
                    hidden={this.isHidden()}
                    horizontal={horizontal}
                    nrDivs={nrDivs}
                />
                {
                    renderLabel({
                        id: labelId,
                        svg: svg,
                        group: labelGroup,
                        loadStep: loadStep,
                        allPositionLoadSteps: allPositionLoadSteps,
                        color: this.getTextColor(),
                        onClick: onClick,
                        nrDivs: nrDivs,
                    })
                }
            </>
        );
    }
}

export default Positions;
