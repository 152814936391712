import PropTypes from 'prop-types';
import React from 'react';
import reduxFetch from 'react-redux-fetch';
import {compose} from 'redux';
import injectApiRoutes from '../../../app/api/injectApiRoutes';
import CenteredCircularProgress from "../../../components/CenteredCircularProgress";
import get from "lodash/get";
import isEqual from 'lodash/isEqual';
import globals from "../../../app/globals";
import LoadAuditDistribution from "../component/distribution/LoadAuditDistribution";
import commonSelectors from "../../common/selectors";

class LoadAuditDistributionPage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    flightId: PropTypes.string,
    distribution: PropTypes.number,
    height: PropTypes.number,
    dispatchLoadAuditPost: PropTypes.func,
    loadAuditFetch: PropTypes.object,
  };

  static contextTypes = {
    registerRefreshAction: PropTypes.func.isRequired,
    removeRefreshAction: PropTypes.func.isRequired,
  };

  isEqualPropsInput = (props) => {
    return this.props.flightId===props.flightId
        && this.props.distribution===props.distribution;
  }

  componentDidMount() {
    this.fetchLoadAudit();
    this.context.registerRefreshAction(
        'loadAudit',
        this.fetchLoadAudit
    );
  }

  componentWillUnmount() {
    this.context.removeRefreshAction('loadAudit');
  }

  componentDidUpdate(prevProps) {
    if (this.props.flightId>0 && !this.isEqualPropsInput(prevProps)) {
      this.fetchLoadAudit();
    }
  }

  fetchLoadAudit = () => {
    if (this.props.flightId>0) {
      this.props.dispatchLoadAuditPost(this.props);
    }
  }

  isDifferent = (obj1, obj2, property) => {
    return !isEqual(get(obj1, property), get(obj2, property));
  }

  shouldComponentUpdate(nextProps) {
    const fetchIsDifferent = this.isDifferent(
        this.props.loadAuditFetch, nextProps.loadAuditFetch, 'value');
    const heightDifferent = this.isDifferent(this.props, nextProps, 'height');
    return (fetchIsDifferent || !this.isEqualPropsInput(nextProps)
        || heightDifferent);
  }

  render() {
    const { loadAuditFetch, flightId, distribution, height } = this.props;
    if (!flightId) {
      return (<div />);
    } else if (loadAuditFetch.fulfilled) {
      const value = get(loadAuditFetch, 'value');
      const id = `dist${(new Date()).getTime()}`;
      return (
          <LoadAuditDistribution
              key={id}
              id={id}
              value={value}
              distribution={distribution}
              height={height}
          />
      );
    }
    return <CenteredCircularProgress />;
  }
}

const getDistribution = (props) =>
    props.distribution===1?"ON_LOAD":"OFF_LOAD";

const mapPropToDispatchToProps = props => [
  {
    resource: 'loadAudit',
    method: 'POST',
    request: ({
      url: props.apiRoutes.getLoadAudit(),
      body: {
        accessToken: props.accessToken,
        application: globals.applicationName,
        flightId: props.flightId,
        distribution: getDistribution(props),
      },
    }),
  },
];

const mapStateToProps = state => ({
  flightId: commonSelectors.getFlightId(state),
});

const enhance = compose(
  injectApiRoutes,
  reduxFetch(mapPropToDispatchToProps, mapStateToProps)
);

export default enhance(LoadAuditDistributionPage);