const getWebServiceUrl = state => state.settings.webServiceUrl;
const getTheme = state => state.settings.theme;
const getThemeValue = state => state.settings.themeValue;
const getThemesList = state => state.settings.themesList;

export default {
  getWebServiceUrl,
  getTheme,
  getThemeValue,
  getThemesList,
};
