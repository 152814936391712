import PropTypes from 'prop-types';
/*
 * TODO: Move this file to another (language?) module
 */
import React from 'react';
import at from 'lodash/at';
import connect from 'react-redux-fetch';
import { translationsRoute } from '../../../app/api/routes';

const getDeepProperty = (path, obj) => at(obj, path)[0] || '';

const injectI18n = WrappedComponent => {
  class InjectI18n extends React.Component {
    static propTypes = {
      labelsFetch: PropTypes.object,
      dispatchLabelsGet: PropTypes.func,
    };

    componentWillMount() {
      if (!this.props.labelsFetch.fulfilled && !this.props.labelsFetch.pending) {
        this.props.dispatchLabelsGet('en'); // TODO: get this from the state
      }
    }

    translate = (label, defaultLabel) => {
      const labels = this.props.labelsFetch.value;

      return (labels && getDeepProperty(label, labels)) || defaultLabel || label;
    };

    render() {
      const { labelsFetch, ...other } = this.props; // eslint-disable-line
      return <WrappedComponent {...other} translate={this.translate} />;
    }
  }

  const mapPropToDispatchToProps = () => [
    {
      resource: 'labels',
      request: lang => ({
        url: translationsRoute(lang),
      }),
    },
  ];
  // const mapStateToProps = state = ({
  // language: selectors.getLanguage(state),
  // });

  return connect(mapPropToDispatchToProps)(InjectI18n);
};

export default injectI18n;
