import styled from "styled-components";

const MessageIcon = styled.div`
  width: 15%;
  textAlign: center;
  marginLeft: 10px;
  marginRight: 10px;
  marginTop: 15px;
  position: relative;
`;

export default MessageIcon;