import CommunicatorPage from './containers/CommunicatorPage';
import selectors from './selectors';
import actions from './actions';
import actionTypes from './actionTypes';

export default {
  containers: {
    CommunicatorPage,
  },
  selectors,
  actions,
  actionTypes,
};
