import { fork, all } from 'redux-saga/effects';

import app from './app';

export default function* root() {
  yield all([
    // APP
    fork(app.sagas.refreshBrowserOnLogout),
    fork(app.sagas.applicationLoadedChecker),
    fork(app.sagas.apiErrorHandler),
    fork(app.sagas.watchApiPendingRequests),
  ]);
}
