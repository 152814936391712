import LoadSequenceTable from "./LoadSequenceTable";
import React from "react";
import PropTypes from "prop-types";
import MethodPanel from "./MethodPanel/MethodPanel";
import LoadSequencePctMacPanel from "./LoadSequencePctMacPanel";

class LoadSequencePanel extends React.Component {
    static propTypes = {
        flight: PropTypes.object.isRequired,
        distribution: PropTypes.number.isRequired,
        loadSequence: PropTypes.object.isRequired,
        onLoadSequenceAction: PropTypes.func.isRequired,
        onDeleteFlight: PropTypes.func.isRequired,
        height: PropTypes.number,
    }

    render() {
        const {flight, distribution, loadSequence, onLoadSequenceAction, onDeleteFlight,  height, } = this.props;
        const loadSequenceTableHeight = height - 40 - 75 - 28 - 10 - 10;
        return (<div style={{width: '100%'}}>
            <LoadSequencePctMacPanel
                flight={flight}
                loadSequence={loadSequence}
            />
            <LoadSequenceTable
                loadSequence={loadSequence}
                height={loadSequenceTableHeight}
            />
            <MethodPanel
                flight={flight}
                distribution={distribution}
                onLoadSequenceAction={onLoadSequenceAction}
                onDeleteFlight={onDeleteFlight}
            />
        </div>);
    }
}

export default LoadSequencePanel;