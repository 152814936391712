import React from 'react';
import About from "../components/About";
import PropTypes from "prop-types";
import actions from "../actions";
import security from "../../security";
import selectors from "../selectors";
import {compose} from "redux";
import app from "../../../app";
import connect from "react-redux-fetch";
import get from "lodash/get";

class AboutPage extends React.Component{
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        webServiceUrl: PropTypes.string,
        isAuthenticated: PropTypes.bool.isRequired,
        themeValue: PropTypes.number,
        themesList: PropTypes.array,
        history: PropTypes.object,
        data: PropTypes.array,

        dispatchAboutPost: PropTypes.func,
        aboutFetch: PropTypes.object,
    };

    state = {
        height: 0,
        url: this.props.webServiceUrl,
        themeValue: this.props.themeValue,
        data: this.props.data
    };

    componentDidMount() {
        const {
            dispatch,
            dispatchAboutPost } = this.props;
        const { url, themeValue } = this.state;

        dispatch(actions.setWebServiceUrl(url));
        dispatch(actions.setTheme(themeValue));

        dispatchAboutPost();
    }

    getAbout = () => {
        const {aboutFetch} = this.props;
        return get(aboutFetch, 'value.items.item') || [];
    }

    render(){
        const {isAuthenticated } = this.props;
        const { height, url, themeValue } = this.state;

        return <About
            height={height}
            url={url}
            theme={themeValue}
            isAuthenticated={isAuthenticated}
            data={this.getAbout()}/>
    }
}

const mapStateToProps = state => ({
    webServiceUrl: selectors.getWebServiceUrl(state),
    themeValue: selectors.getThemeValue(state),
    themesList: selectors.getThemesList(state),
    isAuthenticated: security.selectors.isAuthenticated(state),
});

const mapPropToDispatchToProps = props => [
    {
        resource: 'about',
        method: 'POST',
        request: () => ({
            url: props.apiRoutes.about(),
            body: {
                accessToken: props.accessToken,
            }
        }),
    },
];

const enhance = compose(app.injectApiRoutes, connect(mapPropToDispatchToProps, mapStateToProps));

export default enhance(AboutPage);