import React from 'react';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';

const ConnectionIndicatorCircle = styled('div')`
  border-radius: 50%;
  background-color: ${({ connected }) => (connected ? 'green' : 'red')};
  border: 1px solid ${({ connected }) => (connected ? 'green' : 'red')};
  height: 10px;
  width: 10px;
  display: inline-block;
  margin-right: 8px;
`;
const ConnectionIndicator = ({ connected }) => (
  <Tooltip title={`${connected ? 'Connected' : 'Disconnected'}`} placement="bottom">
    <ConnectionIndicatorCircle connected={connected} />
  </Tooltip>
);

export default ConnectionIndicator;
