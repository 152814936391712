import PropTypes from 'prop-types';
import React from 'react';
import { MessageTypes } from './Filter';

const MIN_WIDTH = 945;

const EmptyHeight = ({ height, messageTypeValue, windowWidth }) => (
  <div
    style={{
      height:
        messageTypeValue === MessageTypes.USER_MESSAGES && windowWidth < MIN_WIDTH
          ? height * 2
          : height,
    }}
  />
);

EmptyHeight.propTypes = {
  height: PropTypes.number.isRequired,
  messageTypeValue: PropTypes.number.isRequired,
  windowWidth: PropTypes.number.isRequired,
};

export default EmptyHeight;
