import React, { useEffect } from 'react';
import reduxFetch from 'react-redux-fetch';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import injectApiRoutes from '../../../app/api/injectApiRoutes';
import ContactList from '../components/ContactList';
import commonSelectors from '../../common/selectors';
import useInterval from '../../../app/hooks/useInterval';
import securitySelectors from '../../security/selectors';
import toMilliseconds from '../../../app/utils/toMilliseconds';
import CenteredCircularProgress from '../../../components/CenteredCircularProgress';

const ContactListPage = (
  {
    allUsersFetch,
    dispatchAllUsersPost,
    dispatchSendMessagePost,
    flightId,
    distribution,
    userFrequency,
  },
  context
) => {
  const sendCallMessage = fetchCall => message => {
    fetchCall(message);
  };

  const refresh = () => {
    dispatchAllUsersPost();
  };

  useInterval(refresh, toMilliseconds(userFrequency.contactList));

  useEffect(() => {
    context.registerRefreshAction('onlineContactsCheck', refresh);
    return () => context.removeRefreshAction('onlineContactsCheck');
    // eslint-disable-next-line
  }, []);

  useEffect(refresh, [flightId, distribution]);

  return (
    <>
      {allUsersFetch.fulfilled || allUsersFetch.value ? (
        <ContactList
          contacts={allUsersFetch.value.contacts.contact}
          doorContacts={allUsersFetch.value.doorContacts.doorContact}
          sendCallMessage={sendCallMessage(dispatchSendMessagePost)}
        />
      ) : (
        <CenteredCircularProgress />
      )}
    </>
  );
};

ContactListPage.propTypes = {
  accessToken: PropTypes.string,
  allUsersFetch: PropTypes.object,
  dispatchAllUsersPost: PropTypes.func,
  dispatchSendMessagePost: PropTypes.func,
  distribution: PropTypes.string,
  flightId: PropTypes.string,
};

const mapStateToProps = state => ({
  flightId: commonSelectors.getFlightId(state),
  distribution: commonSelectors.getDistribution(state),
  userFrequency: securitySelectors.getUserFrequency(state),
});

const mapPropToDispatchToProps = ({ apiRoutes, accessToken, flightId, distribution }) => [
  {
    resource: 'allUsers',
    method: 'post',
    request: {
      url: apiRoutes.getContactList(),
      body: {
        accessToken: accessToken,
        flightId: flightId,
        distribution: distribution,
      },
    },
  },
  {
    resource: 'sendMessage',
    method: 'post',
    request: message => ({
      url: apiRoutes.sendvoicecallmessage(),
      body: { accessToken, message: { ...message } },
      clearValueOnRequest: true,
    }),
  },
];

const enhance = compose(
  injectApiRoutes,
  connect(mapStateToProps),
  reduxFetch(mapPropToDispatchToProps)
);

ContactListPage.contextTypes = {
  registerRefreshAction: PropTypes.func,
  removeRefreshAction: PropTypes.func,
};

export default enhance(ContactListPage);
