import get from 'lodash/get';

const getTimeFrom = state => get(state, 'filter.timeFrom');
const getDateTo = state => get(state, 'filter.dateTo');
const getTimeTo = state => get(state, 'filter.timeTo');
const getDistribution = state => get(state, 'filter.distribution');

export default {
  getTimeFrom,
  getDateTo,
  getTimeTo,
  getDistribution,
};
