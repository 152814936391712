import React from 'react';
import ItemIconCircle
    from '../../../communicator/components/OpenItems/ItemIconCircle';
import ItemIconCircleRemark from './ItemIconCircleRemark';
import MIconRemark from './MIconRemark';
import MessageIcon from './MessageIcon';

const RemarkIcon = ({ number }) => {
  return (
    <div>
      <MessageIcon>
        <ItemIconCircle small>
          <MIconRemark>textsms</MIconRemark>
        </ItemIconCircle>
        <div style={{ marginTop: -10, marginLeft: 20, marginBottom: -10 }}>
          <ItemIconCircleRemark small>
            <div>{number}</div>
          </ItemIconCircleRemark>
        </div>
      </MessageIcon>
    </div>
  );
};

export default RemarkIcon;
