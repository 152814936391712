// @flow
import moment from 'moment';
import * as dateHelpers from '../../../app/utils/dateHelpers';

/**
 *  Should the date which will be sent to the server start from 00:00, or current time?
 *  @param date Moment : The date which will be used in the fetch
 *  @return true if date is different from today. false if today.
 */
const shouldFetchFlightsFromStartOfDay = (date: moment): boolean => !date.isSame(moment(), 'day');

const shouldShowCurrentToggle = (date: moment): boolean => dateHelpers.dateIsToday(date);

export default {
  shouldFetchFlightsFromStartOfDay,
  shouldShowCurrentToggle,
};
