import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import ErrorIcon from '@material-ui/icons/Error';
import ReactTooltip from "react-tooltip";
import globals, {RefreshQualifier} from "../../../../app/globals";
import adapter from "../../../../app/api/adapter";
import toMilliseconds from "../../../../app/utils/toMilliseconds";
import isEqual from "lodash/isEqual";
import reduxFetch, {container} from 'react-redux-fetch';
import commonSelectors from '../../selectors';
import securitySelectors from "../../../security/selectors";
import * as dateHelpers from "../../../../app/utils/dateHelpers";
import {compose} from "redux";
import injectApiRoutes from "../../../../app/api/injectApiRoutes";
import momentPropTypes from "react-moment-proptypes";
import flightService from "../../domain/flight";
import get from "lodash/get";

const fetchIsFulfilled = container.getUtil('componentHelpers').fetchIsFulfilled;

class SystemMessagesButton extends Component {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        onClick: PropTypes.func,
        dispatchSystemOpenItemsPost: PropTypes.func,
        systemOpenItemsFetch: PropTypes.object,
        isCurrent: PropTypes.bool,
        departureTime: momentPropTypes.momentObj,
        airport: PropTypes.object,
        userFrequency: PropTypes.shape({
            openItems: PropTypes.number,
        }),
        // Injected by i18next
        t: PropTypes.func.isRequired,
    }

    isEnabled = () => {
        const {onClick} = this.props;
        return onClick;
    }

    componentDidMount() {
        if (this.isEnabled()) {
            this.fetchSystemOpenItems(RefreshQualifier.MANUAL)();
            this.autoFetchSystemOpenItems();
        }
    }

    shouldComponentUpdate(nextProps) {
        if (this.isEnabled()) {
            const systemOpenItemsFetchIsDifferent = !isEqual(this.props.systemOpenItemsFetch,
                nextProps.systemOpenItemsFetch);
            return (
                (this.isDateDifferent(nextProps) ||
                    systemOpenItemsFetchIsDifferent ||
                    this.isCurrentFlagDifferent(nextProps))
            );
        } else {
            return true;
        }
    }

    componentDidUpdate(prevProps) {
        if (this.isEnabled()) {
            if (this.isDateDifferent(prevProps) || this.isCurrentFlagDifferent(prevProps)) {
                this.fetchSystemOpenItems(RefreshQualifier.MANUAL)();
            }
            if (fetchIsFulfilled(this.props, prevProps, 'systemOpenItems')) {
                this.autoFetchSystemOpenItems();
            }
        }
    }

    componentWillUnmount() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }

    autoFetchSystemOpenItems() {
        this.timer = setTimeout(
            this.fetchSystemOpenItems(RefreshQualifier.AUTO),
            toMilliseconds(this.props.userFrequency.openItems)
        );
    }

    fetchSystemOpenItems = refetchQualifier => () => {
        const finalDate = this.props.departureTime;
        const finalIsCurrent = flightService.shouldShowCurrentToggle(finalDate)
            ? this.props.isCurrent: false;
        const airport = this.props.airport;
        const airportCode = (airport?airport.iata:'');
        const startOfDay = flightService.shouldFetchFlightsFromStartOfDay(finalDate);
        this.props.dispatchSystemOpenItemsPost(
            finalDate,
            startOfDay,
            finalIsCurrent,
            airportCode,
            refetchQualifier
        );
    };

    isDateDifferent = nextProps =>
        nextProps.departureTime && !nextProps.departureTime.isSame(this.props.departureTime, 'day');

    isCurrentFlagDifferent = nextProps => !isEqual(this.props.isCurrent, nextProps.isCurrent);

    render() {
        const { systemOpenItemsFetch, onClick, t } = this.props;
        const system = this.isEnabled()?get(systemOpenItemsFetch, 'value.system'):null;
        return (
            system ?
                <span data-tip={t('communicator.systemMessages.label')} style={{display: "flex"}}>
                    <IconButton onClick={onClick} style={{margin:10}}>
                        <Badge badgeContent={system.message} color="error">
                            <ErrorIcon color={"primary"}/>
                        </Badge>
                    </IconButton>
                <ReactTooltip/>
            </span>
                : null
        );
    }
}

const getDate = (date, startOfDay) => {
    return startOfDay
        ? adapter.momentDateToServerStartOfDay(date)
        : adapter.momentDateToServerDate(date);
}

const mapPropToDispatchToProps = props => [
    {
        resource: 'systemOpenItems',
        method: 'POST',
        request: (date,
                  startOfDay = true,
                  isCurrent = false,
                  airportCode = '',
                  refreshQualifier = RefreshQualifier.MANUAL) => ({
            url: props.apiRoutes.getSystemOpenItems(),
            body: {
                accessToken: props.accessToken,
                date: getDate(date, startOfDay),
                localTimeOffset: dateHelpers.currentTimezoneOffset(getDate(date, startOfDay)),
                current: isCurrent,
                airportCode: airportCode,
                distribution: 'ON_LOAD',
                passengerFlights: false,
                freighterFlights: false,
                application: globals.applicationName,
                refreshQualifier,
            },
        }),
    },
];

const mapStateToProps = state => ({
    departureTime: commonSelectors.getDepartureTime(state),
    isCurrent: commonSelectors.getCurrentFlag(state),
    airport: commonSelectors.getAirport(state),
    userFrequency: securitySelectors.getUserFrequency(state),
});

const enhance = compose(
    injectApiRoutes,
    reduxFetch(mapPropToDispatchToProps, mapStateToProps)
);

export default enhance(withTranslation()(SystemMessagesButton));