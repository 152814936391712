import { MessageTypes } from './constants';

export const APP_INITIALIZED = 'call/initialized';

export const StateMachineActions = {
  'MACHINE_ACTION/CONNECTED': 'MACHINE_ACTION/CONNECTED',
  'MACHINE_ACTION/DISCONNECTED': 'MACHINE_ACTION/DISCONNECTED',
  'MACHINE_ACTION/HANDLEDISCONNECT': 'MACHINE_ACTION/HANDLEDISCONNECT',
  'MACHINE_ACTION/DENIED_HANDLED': 'MACHINE_ACTION/DENIED_HANDLED',
  'MACHINE_ACTION/CALLREQUESTED': `MACHINE_ACTION/CALLREQUESTED`,
  'MACHINE_ACTION/CANCELLEDREQUEST': `MACHINE_ACTION/CANCELLEDREQUEST`,
  'MACHINE_ACTION/CALLDENIED': `MACHINE_ACTION/CALLDENIED`,
  'MACHINE_ACTION/OFFERSENT': `MACHINE_ACTION/OFFERSENT`,
  'MACHINE_ACTION/CALLSTOPPED': `MACHINE_ACTION/CALLSTOPPED`,
  'MACHINE_ACTION/PEERDISCONNECTED': 'MACHINE_ACTION/PEERDISCONNECTED',
  'MACHINE_ACTION/HANDLEDPEERDISCONNECT': 'MACHINE_ACTION/HANDLEDPEERDISCONNECT',
  'MACHINE_ACTION/ERROR': 'MACHINE_ACTION/ERROR',
  'MACHINE_ACTION/HANDLEDERROR': 'MACHINE_ACTION/HANDLEERROR',
  'SOCKET/CALLREQUESTED': `SOCKET/${MessageTypes.CALL_REQUEST}`,
  'SOCKET/CALLDENIED': `SOCKET/${MessageTypes.CALL_DENIED}`,
  'SOCKET/OFFER': `SOCKET/${MessageTypes.OFFER}`,
  'SOCKET/ANSWER': `SOCKET/${MessageTypes.ANSWER}`,
  'SOCKET/CALLSTOPPED': `SOCKET/${MessageTypes.CALL_STOP}`,
};
