import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import uniqBy from 'lodash/uniqBy';
import map from 'lodash/map';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from 'material-ui/CircularProgress';
import Checkbox from 'material-ui/Checkbox';
import CenteredCircularProgress from '../../../../components/CenteredCircularProgress';
import ButtonWrapper from './ButtonWrapper';
import TextField from './TextField';
import Root from './Root';
import { DialogContent, DialogTitle } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';

const NewMessage = ({
  value,
  onChange,
  submitting,
  onDialogOpen,
  showDialog,
  actions,
  engagedPersonnelFetch,
  onDoorCheckboxCheck,
  onFlightCheckboxCheck,
  checkedDoorRoles,
  checkedFlightRoles,
  t,
}) => {
  let doorRoleCheckboxList = [];
  let flightRoleCheckboxList = [];

  if (engagedPersonnelFetch.fulfilled) {
    const {
      value: { doorUsers, flightUsers },
    } = engagedPersonnelFetch;
    const uniqueDoorRoles = uniqBy(doorUsers.flightDoorRole, 'doorId');
    const uniqueFlightRoles = uniqBy(flightUsers.flightRoles, 'id');

    doorRoleCheckboxList = map(uniqueDoorRoles, role => (
      <Checkbox
        key={role.doorId}
        label={`${role.role} - ${role.deckName} - ${role.doorName}`}
        checked={checkedDoorRoles.includes(role.doorId)}
        onCheck={() => onDoorCheckboxCheck(role.doorId)}
        style={{ marginBottom: 10, marginTop: 20 }}
      />
    ));
    flightRoleCheckboxList = map(uniqueFlightRoles, role => (
      <Checkbox
        key={role.id}
        label={role.role}
        checked={checkedFlightRoles.includes(role.id)}
        onCheck={() => onFlightCheckboxCheck(role.id)}
        style={{ marginBottom: 10, marginTop: 20 }}
      />
    ));
  }

  return (
    <Root onSubmit={onDialogOpen}>
      <TextField
        value={value}
        onChange={onChange}
        hintText={<span>{t('communicator.newMessage.typeMessage')}</span>}
        fullWidth
        disabled={submitting}
        autoComplete="off"
      />
      {submitting
        ? <CircularProgress />
        : <ButtonWrapper
            label={<span>{t('communicator.newMessage.submitMessageLabel')}</span>}
          type="submit"
          primary
          disabled={!value}
        />
      }
      <Dialog open={showDialog} maxWidth={'sm'} fullWidth={true}>
        <DialogTitle>{t('communicator.newMessage.engagedPersonnel')}</DialogTitle>
        <DialogContent>
          <div>
            {engagedPersonnelFetch.fulfilled ? (
              <div>
                {doorRoleCheckboxList}
                {flightRoleCheckboxList}
                {doorRoleCheckboxList.length === 0 && flightRoleCheckboxList.length === 0 && (
                  <div>
                    <span>{t('communicator.newMessage.noEngagedPersonnel')}</span>
                  </div>
                )}
              </div>
            ) : (
              <CenteredCircularProgress />
            )}
          </div>
          <DialogActions>{actions}</DialogActions>
        </DialogContent>
      </Dialog>
    </Root>
  );
};

NewMessage.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onDialogOpen: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  showDialog: PropTypes.bool,
  actions: PropTypes.array,
  engagedPersonnelFetch: PropTypes.object,
  checkedDoorRoles: PropTypes.array,
  checkedFlightRoles: PropTypes.array,
  onDoorCheckboxCheck: PropTypes.func,
  onFlightCheckboxCheck: PropTypes.func,

  // Injected by i18next
  t: PropTypes.func.isRequired,
};

export default withTranslation()(NewMessage);
