import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import ContactItem from './ContactListItem';
import FormattedMessage from '../../../i18n';
import NoContactsReceived from './NoContactsReceived';
import call from '../../../call';

const useStyle = makeStyles({
  noContentContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100% - 57px - 47px)',
  },
  listTitle: {
    paddingLeft: 16,
  },
});

const ContactList = ({ contacts, doorContacts, sendCallMessage }) => {
  const classes = useStyle();
  const [callPossible, setCallPossible] = useState(false);
  const dispatch = useDispatch();
  const socketState = useSelector(call.selectors.getSocketState);

  useEffect(() => {
    navigator.mediaDevices &&
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(stream => {
          if (stream.getAudioTracks().length > 0 && socketState === call.callStates.CONNECTED) {
            setCallPossible(true);
          } else {
            setCallPossible(false);
          }

          stream.getTracks().forEach(stream => stream.stop());
        })
        .catch(e => console.error(e));
  }, [socketState]);

  const startCall = (toOnlineCode, toOnlineName, callType) => () => {
    dispatch(
      call.actions.callrequest({
        body: { callType, toOnlineCode, toOnlineName, },
      })
    );
    sendCallMessage({
      toOnlineCode,
      toOnlineName,
      type: call.messageTypes.CALL_REQUEST,
      data: JSON.stringify({ callType }),
    });
  };

  const mapContactToContactItem = contact => {
    return (
      <ContactItem
        key={contact.userName}
        contact={contact}
        audioPossible={callPossible}
        onStartVoiceClicked={startCall(contact.targetName, contact.userName, call.callTypes.ONLY_VOICE)}
        videoPossible={callPossible}
        onStartVideoClicked={startCall(contact.targetName, contact.userName, call.callTypes.SHOW_THEIR_CAMERA)}
      />
    );
  };

  return (
    <React.Fragment>
      {(contacts && contacts.length !== 0) || (doorContacts && doorContacts.length !== 0) ? (
        <List component="nav">
          <Typography className={classes.listTitle} variant="h6">
            Contacts
          </Typography>
          {contacts && contacts.length !== 0 && contacts.map(mapContactToContactItem)}
          <Typography className={classes.listTitle} variant="h6">
            Door Contacts
          </Typography>
          {doorContacts && doorContacts.length !== 0 && doorContacts.map(mapContactToContactItem)}
        </List>
      ) : (
        <NoContactsReceived>
          <FormattedMessage id="contact.noContacts" defaultMessage="No assignees online" />
        </NoContactsReceived>
      )}
    </React.Fragment>
  );
};

ContactList.propTypes = {
  contacts: PropTypes.array.isRequired,
  doorContacts: PropTypes.array.isRequired,
  sendCallMessage: PropTypes.func.isRequired,
};

export default ContactList;
