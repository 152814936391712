import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import MIcon from '../../../../../components/MIcon';
import ItemContainer from '../ItemContainer';
import ItemIcon from '../ItemIcon';
import ItemDetailsContainer from '../ItemDetailsContainer';
import ItemDetails from '../ItemDetails';
import ItemIconCircle from '../ItemIconCircle';
import ItemEmpty from "./ItemEmpty";

const SystemMessagesItem = ({ system, selected, onItemClick, t }) => {
  return (
    <ItemContainer
      selected={selected}
      onClick={() => {
        onItemClick();
      }}
    >
      <ItemEmpty/>
      <ItemIcon>
        {system.message > 0 &&
          <div style={{ marginTop: 10 }}>
            <ItemIconCircle small>
              <div>
                {system.message}
              </div>
            </ItemIconCircle>
          </div>}
      </ItemIcon>
      <div style={{position: 'relative', marginLeft: '55%', float: 'none'}}>
        <ItemDetailsContainer>
          {system.picture &&
            <div style={{ width: '1%', textAlign: 'left', position: 'absolute', bottom: '-4px' }}>
              <span data-tip={t('communicator.systemMessages.messageWithImage')}>
                <ReactTooltip />
                <ItemIconCircle small>
                  <MIcon small>image</MIcon>
                </ItemIconCircle>
              </span>
            </div>}
          <ItemDetails hasImage={system.picture}>
            {t('communicator.systemMessages.label')}
          </ItemDetails>
        </ItemDetailsContainer>
      </div>
    </ItemContainer>
  );
};

SystemMessagesItem.propTypes = {
  system: PropTypes.object,
  onItemClick: PropTypes.func,

  // Injected by i18next
  t: PropTypes.func.isRequired,
};

export default withTranslation()(SystemMessagesItem);
