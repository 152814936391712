import { takeEvery } from 'redux-saga/effects';
import security from '../../../modules/security';

export function* refreshBrowserOnLogout() {
  yield takeEvery(security.actionTypes.LOGOUT, () => {
    // "poor mans logout" -> longpolling causes logging in again if we don't hard refresh :(
    // Can be removed if sockets are implemented.
    localStorage.setItem('reduxPersist:security', null);
    window.location.reload(true);
  });
}
