import PropTypes from 'prop-types';
import React from 'react';
import connect from 'react-redux-fetch';
import {compose} from 'redux';
import get from 'lodash/get';
import injectApiRoutes from '../../../app/api/injectApiRoutes';
import commonSelectors from '../../common/selectors';
import ChangeReqMsg from '../components/Message/ChangeReqMsg';

class ChangeReqMsgContainer extends React.Component {
  static propTypes = {
    msg: PropTypes.object,
    received: PropTypes.bool,
    unread: PropTypes.bool,
    loaderInfo: PropTypes.object,
    dispatchActionRequestPost: PropTypes.func,
    readMsgUpdate: PropTypes.func,
    actionRequestFetch: PropTypes.object,
  };

  componentWillReceiveProps(nextProps) {
    if (get(nextProps.actionRequestFetch, 'meta.imSequence') !== nextProps.msg.sequenceId) {
      return;
    }

    if (this.props.actionRequestFetch.pending && nextProps.actionRequestFetch.fulfilled) {
      this.props.readMsgUpdate(true, nextProps.actionRequestFetch.meta.imSequence);
    }
  }

  sendActionRequest = (imSequence, actionId, selectedOption, reason) => {
    const chosenOption = { option: [selectedOption] };
    this.props.dispatchActionRequestPost(imSequence, actionId, chosenOption, reason);
  };

  render() {
    const { loaderInfo, msg, actionRequestFetch } = this.props;

    const isFetchingMessages =
      loaderInfo.msgId === msg.sequenceId && loaderInfo.showCircularProgress;
    const isPostingAction =
      get(actionRequestFetch, 'meta.imSequence') === msg.sequenceId && actionRequestFetch.pending;

    return (
      <ChangeReqMsg
        msg={this.props.msg}
        received={this.props.received}
        unread={this.props.unread}
        loaderInfo={this.props.loaderInfo}
        sendActionRequest={this.sendActionRequest}
        isPending={isFetchingMessages || isPostingAction}
      />
    );
  }
}

const mapPropToDispatchToProps = props => [
  {
    resource: 'actionRequest',
    method: 'POST',
    request: (imSequence, actionId, chosenOption, reason) => ({
      url: props.apiRoutes.sendActionRequest(),
      body: {
        accessToken: props.accessToken,
        distribution: props.distribution,
        flightId: props.flightId,
        imSequence,
        actionId,
        chosenOption,
        reason: reason
      },
      meta: {
        imSequence,
      },
    }),
  },
];

const mapStateToProps = state => ({
  flightId: commonSelectors.getFlightId(state),
  flightDepartureAirport: commonSelectors.getAirport(state),
  distribution: commonSelectors.getDistribution(state),
});

const enhance = compose(injectApiRoutes, connect(mapPropToDispatchToProps, mapStateToProps));

export default enhance(ChangeReqMsgContainer);
