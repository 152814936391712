import { takeEvery, put, all } from 'redux-saga/effects';
import { FETCH } from 'react-redux-fetch';
import get from 'lodash/get';
import truncate from 'lodash/truncate';
import security from '../../../modules/security';
import globals from '../../../app/globals';
import { dispatchEnqueueSnackbar } from '../../notification/actions';

function* handleNotAuthenticated() {
  yield put(security.actions.logout());
  // "poor mans logout" -> longpolling causes logging in again if we don't hard refresh :(
  // Can be removed if sockets are implemented.
  setTimeout(() => window.location.reload(true), 200);
}

function* watchReject(response) {
  const errorMsg = get(response, 'reason.cause.message', '');
  const errorNr = get(response, 'reason.cause.error');

  if (errorMsg && errorMsg.indexOf(globals.errors.invalidAccessToken) !== -1) {
    yield handleNotAuthenticated();
  }
  if (errorNr && errorNr === globals.errorNumbers.INVALID_ACCESS_TOKEN) {
    yield handleNotAuthenticated();
  }

  if (!get(response.request, 'meta.suppressError')) {
    const message = truncate(errorMsg || get(response, 'reason.message') || 'Error', { length: 1500, omission: ' [...]' });
    const k = new Date().getTime() + Math.random();
    const enqueSnackbarAction = dispatchEnqueueSnackbar({
      message,
      options: {
        k,
        variant: 'error',
      },
    });
    yield put(enqueSnackbarAction);
  }
}

export function* apiErrorHandler() {
  yield all([
    takeEvery(FETCH.for('post').REJECT, watchReject),
    takeEvery(FETCH.for('get').REJECT, watchReject),
  ]);
}

export default {};
