export const svgClasses = {
    position: {
        class: 'pos',
        selector: '.pos',
    },
    sequence: {
        class: 'seq',
        selector: '.seq',
    },
    nofitUld: {
        class: 'nofitUld',
        selector: '.nofitUld',
    },
    foreignObject: {
        class: 'foreignObject',
        selector: 'foreignObject',
    },
    graphLoadState: {
        class: 'graphLoadState',
        selector: '.graphLoadState',
    },
    graphLoadAction: {
        class: 'graphLoadAction',
        selector: '.graphLoadAction',
    },
    contentCheckResult: {
        class: 'contentCheckResult',
        selector: '.contentCheckResult',
    },
    subPosition: {
        class: 'subPos',
        selector: '.subPos',
    },
    totalWeight: {
        class: 'totalWeight',
        selector: '.totalWeight',
    },
    ellipsis: {
        class: 'ellipsis',
        selector: '.ellipsis',
    },
};
