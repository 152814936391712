import PropTypes from 'prop-types';
import React from 'react';
import LoadAuditPanel from "./Bottom/LoadSequencePanel/LoadAuditPanel";
import "../../style/splitPane.css";
import styled from "styled-components";

class LoadAuditDistribution extends React.Component {
    static propTypes = {
        id: PropTypes.string,
        value: PropTypes.object,
        distribution: PropTypes.number,
        height: PropTypes.number,
    };

    render() {
        const {
            value, distribution, height} = this.props;
        const {
            flight,
            loadSequenceDistributionAudit,
        } = value;
        return (
            <Container>
                <LoadAuditPanel
                    flight={flight}
                    distribution={distribution}
                    loadAudits={loadSequenceDistributionAudit}
                    height={height}
                />
            </Container>
        );
    }
}

const Container = styled.div`
  width: 100%,
  overflow-y: hidden;
  overflow-x: hidden;
  `;

export default LoadAuditDistribution;